import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

const publicUrl = process.env.PUBLIC_URL + '/';

const MobileGridBooking = (props) => {
    console.log('MobileGridBooking', props);
    return <>
      { props.rows.map(row => {
        return <Box className="event-row">
                    <Box className="event-row-inner">
                        <Box className="col-4">FIRST NAME</Box>
                        <Box className="col-8"><span>{ row.traveler.first_name }</span></Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">LAST NAME</Box>
                        <Box className="col-8">{row.traveler.last_name}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">DATE</Box>
                        <Box className="col-8">{moment(row.created_at).format('MMMM DD, YYYY')} </Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">TIME</Box>
                        <Box className="col-8">{moment(row.created_at).format('h:mm A')} </Box>
                    </Box>
                </Box>
      })
    }
    </>;
  };

  MobileGridBooking.propTypes = {
    history: PropTypes.object.isRequired,
  };
  
  const enhance = compose(
    withRouter,
  );

  export default withRouter(enhance(MobileGridBooking));
import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import PropTypes from "prop-types";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import $ from 'jquery';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const VERIFY_PHONE_NUMBER = loader('./../../../graphql/schema/auth/validate-phone-number.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';
const wpWebApp = process.env.REACT_APP_WP_WEBAPP_URL;

const Login = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumerError, setPhoneNumberError] = useState("");
  const [countryKey, setCountryKey] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState(false);
  const [hasError, setHasError] = useState({});

  const handlePhoneNumber = (number) => {
    if ((number !== undefined)) {
      setHasError({});
      setMobileNumber(false);
      $('#outlined-phone-number-input').prop('disabled', false);
      if (isValidPhoneNumber(number)) {
        setPhoneNumber(number);
        setPhoneNumberError("");
      } else {
        setPhoneNumberError('Please Enter valid Phone Number.');
      }
    }
  }

  useEffect(() => {
    var userAgent = navigator.userAgent || window.opera;

    // if (userAgent.includes("Android")) {
    //     window.location.replace("https://play.google.com/store/apps/details?id=com.huvr&hl=en&gl=US");
    // } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
    //     window.location.replace("https://apps.apple.com/us/app/huvr-app/id1536389358");
    // } else {
    //   console.log("Web Browser");
    // }
  });

  const handleFocus = () => {
    if ($('#outlined-phone-number-input').val() === '') {
      $('#outlined-phone-number-input').prop('disabled', true);
      setPhoneNumberError('Please Select Country Calling Code.');
    }
  }

  const handleCountryCode = (country) => {
    setCountryKey(country);
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneNumber === "") {
      setPhoneNumberError('Please enter Phone Number.');
      formIsValid = false;
    }
    setHasError(errors);
    return formIsValid;
  }


  const postLogin = (resUser) => {
    apolloClient.writeData({ data: { isLoggedIn: false } });
    let error = {};
    UserUtils.setPhoneNumber(phoneNumber);
    UserUtils.setCountryKey(countryKey);
    if (resUser.status === "NEW_USER") {
      setSuccessMessage(resUser.message);
      history.push('verify-otp');
    } else if (resUser.status === "OTP_FAIL") {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else if (resUser.status === "USER_EXIST") {
      // REDIRECT TO LOGIN OTP PAGE IF USER EXIST
      setSuccessMessage(resUser.message);
      history.push('/verify-login');
    }
  };

  const { loading } = useQuery(VERIFY_PHONE_NUMBER, {
    variables: {
      phone: phoneNumber,
      version: process.env.REACT_APP_WEB_VERSION,
      device: 'web',
      token: ''
    },
    fetchPolicy: 'network-only',
    skip: mobileNumber === false,
    onCompleted({
      verifyPhoneNumber: {
        status: status,
        message: message
      },
    }) {
      postLogin({ status, message });
    }
  });

  const submitPhoneNumber = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setMobileNumber(true);
    }
  };

  return (
    <div className="signup-container-row2">
      {loading && <PageLoader loading={loading} />}
      <div className="signup-row">
        <div className="signup-left2">
          <div className="signup-cnt">
            <h4 component="h4">Login</h4>
            <p>Huvr opens you to the world to virtual exploration in travel, news, healthcare, education, sports, events, real estate, and more—all through the power of the gig economy and licensed brands.</p>
            {/* <a href={wpWebApp} className="btn" target="_blank"><i className="fa fa-external-link"></i> Learn More</a> */}
          </div>
        </div>
        <div className="signup-right2">
          <div className="signup-form">
            <div className="form-title">
              <h4 component="h4">Welcome Back</h4>
              <p>Access your account by signing in for a seamless and personalized experience.</p>
            </div>
            <form name="phoneVerfication" onSubmit={submitPhoneNumber}>
              {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
              <div className="error-box">
              {hasError.otpError !== undefined && 
                <>
                  <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />
                  <p className="is-invalid">{hasError.otpError}</p>
                </>
              }
              {phoneNumerError !== "" && 
                <>
                  <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />
                  <p className="is-invalid">{phoneNumerError}</p>
                </>
              }
              </div>
              <div className="form-input-box2">
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry=""
                  id="outlined-phone-number-input"
                  placeholder="Enter Phone Number"
                  className={`phone-input-data`}
                  type="text"
                  name="phoneNumber"
                  margin="normal"
                  // variant="outlined"
                  onFocus={handleFocus}
                  value={phoneNumber}
                  onCountryChange={handleCountryCode}
                  onChange={handlePhoneNumber}
                />
              </div>
              <div className="signup-two">
                <div className="signup-checkbox">
                  <input type="checkbox" />
                  <label>Remember Me</label>
                </div>
                <div className="signup-forgot">
                  <Link to="/forgot-password"> <span>Forgot Password?</span></Link>
                </div>
              </div>
              <div className="form-btn">
                <input type="submit" color="inherit" value="Login" />
              </div>
            </form>
            <div className="form-signup-cnt">
              <p>Need an account? <Link to="/register">Register Here</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  // withApollo,
);

export default enhance(Login);

import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, CircularProgress, DialogTitle, Divider, Typography, Button, OutlinedInput, InputAdornment, Box, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment-timezone';
import SubHeader from '../../components/layout/sub-header';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../payments/payment-form';
import { Bo } from 'react-flags-select';
import { Form, Field } from 'react-final-form';
import { TextField, Checkbox } from 'final-form-material-ui';
import ReactFlagsSelect from 'react-flags-select';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const publicUrl = process.env.PUBLIC_URL + '/';
const tripDetails = { color: '#00A9CB', marginBottom: '15px' };
const tripDetailsFinal = { color: '#00A9CB', fontSize: '15px' };
const detailValue = { color: '#6F6F6F', fontSize: '15px' };
const btnContained = { border: '1px solid #F1AB54', color: '#fff', backgroundColor: '#F1AB54', borderRadius: '100px', padding: 10, width: 120, marginLeft: 15};
const stepTitle = { color: '#00A9CB', textAlign: 'center' };

const EventRegistrationStepTwo = (props) => {
  const { history, location } = props;
  const [step, setStep] = useState('details');


  useEffect(() => {
console.log('step', step);
  });

  const next = () => {
    if(step == 'details') {
      setStep('payment');
    } else if(step == 'payment') {
      setStep('success');
    }
  }

  const cancel = () => {
      if(step == 'payment') {
        setStep('details');
      } else if(step == 'success') {
        setStep('payment');
      }
  }


  return (
    <>
     <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Group Trips" />
          <div className='inner-menu'>
            {/* <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Event Registration</a> */}
          </div>
          <Box className="content content-wrapper">
            <Box className="row outer-box event-reg-box" style={{ width: '55%', margin: 'auto', padding: 30, overflow: 'visible'}}>
              <Box className="col-md-12 ">
                <Form 
                  onSubmit={() => {}} 
                  render={({ handleSubmit }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className="form-in">
                        <span>First Name*</span>
                        <Field
                          id="first_name"
                          placeholder="First Name"
                          type="text"
                          name="first_name"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                          // defaultValue={myDetails && myDetails.me.first_name}
                        />
                      </div>
                      <div className="form-in">
                        <span>Last Name*</span>
                        <Field
                          id="last_name"
                          placeholder="Last Name"
                          type="text"
                          name="last_name"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                          // defaultValue={myDetails && myDetails.me.last_name}
                        />
                      </div>

                      <div className="form-in">
                        <span>Email Address</span>
                        <Field
                          id="email"
                          placeholder="Email Address"
                          type="text"
                          name="email"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                          // defaultValue={myDetails && myDetails.me.email}
                          style={{ backgroundColor: '#fff' }}
                          // disabled={myDetails && myDetails.me.isEmailVerified}
                        >
                        </Field>
                      </div>
                      <div className="form-in">
                        <span>State/Province*</span>
                        <Field
                          id="state"
                          placeholder="State/Province"
                          type="text"
                          name="state"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                          // defaultValue={myDetails && myDetails.me.last_name}
                        />
                      </div>
                      <div className="form-in">
                          <label>Country <span className="is-invalid">*</span></label>
                          <Field
                              id="country"
                              name="country"
                          >
                              {({ input }) => (
                                  <div>
                                      <ReactFlagsSelect
                                          {...input}
                                          searchPlaceholder=""
                                          searchable
                                          // selected={countryCode}
                                          // onSelect={code => setCountryCode(code)}
                                          // className={(submitted && countryCode == '') ? 'has-error' : ''}
                                      />
                                  </div>
                              )}
                          </Field>
                          {/* <FormHelperText error hidden={!(submitted && countryCode == '')}>Please Select Country</FormHelperText> */}
                      </div>
                      <div className="form-in">
                        <Field
                          type="checkbox"
                          name="updates"
                          margin="normal"
                          // variant="outlined"
                         component={Checkbox}
                         style={{ padding: 0}}
                         />
                         <span>Would you like to be updated about new exclusive events?</span>
                      </div>

                      <div className="form-btn">
                        <Button type="submit" >Proceed to Payment</Button>
                      </div>
                    </form>
                  )}
                />
              </Box>
            </Box>
          </Box>
      </div>
    </div>
    </>
  )
}

EventRegistrationStepTwo.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(EventRegistrationStepTwo)

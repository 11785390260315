import uiResolvers from './ui';

/**
 * Export all custom resolvers
 *
 */
const ressolve = [
  uiResolvers,
];
export default ressolve;

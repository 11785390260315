import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
// import {
//     GoogleMap,
//     LoadScript,
//     Marker,
//     InfoWindow
// } from "@react-google-maps/api";
import GoogleMapReact from 'google-map-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, List, ListItem, ListItemText } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { database, onMessageListener } from '../../utilities/firebase';
import { ref, onValue } from 'firebase/database';
import UserUtils from '../../utilities/UserUtils';
import DownloadLink from '../../components/layout/download-link';
import ScheduleTrip from '../traveller/schedule-trip';
import ScheduleTripAmbassador from '../traveller/schedule-trip-ambassador';
import ScheduleTripTimer from '../traveller/schedule-trip-timer';
import { detect } from 'detect-browser';
import { makeStyles } from '@material-ui/styles';
import { set } from 'date-fns';
import moment from 'moment-timezone';
import PageLoader from './../../components/layout/ui/page-loader';
import SubHeader from '../../components/layout/sub-header';
import { unset } from 'lodash';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { format, utcToZonedTime } from 'date-fns-tz';


const publicUrl = process.env.PUBLIC_URL + '/';
const disableSafari = process.env.REACT_APP_DISABLE_SAFARI === 'true';

const GET_TRIP_RATE = loader('../../graphql/schema/destinationView/get-trip-rate.graphql');
const SEND_BOOKIN_REQUEST_TO_WALKER = loader('../../graphql/schema/walker/send-booking-request-to-walker.graphql');
const AUTHORIZE_STRIPE = loader('../../graphql/schema/traveller/authorize-stripe.graphql');
const VERIFY_VIDEO_CALL_AMBASSADOR = loader('../../graphql/schema/traveller/verify-video-call-ambassador-antmedia.graphql');
const UPCOMING_WALKS = loader('../../graphql/schema/traveller/traveller-upcomingWalks.graphql');
const GET_SAVE_SCHEDULE_TRIP = loader('../../graphql/schema/walker/save-schedule-trip.graphql');


const Styles = {
    container: {
        overflow: 'auto',
        backgroundColor: '#f2f2f2',
        borderRadius: 5
    },
    title: {
        backgroundColor: '#071C55',
        borderRadius: '5px 5px 0px 0px',
        paddingBottom: 20,
        paddingTop: 20,
    },
    blue: {
        color: '#01B9B7',
    },
    gray: {
        color: '#777',
    },
    p: {
        hyphens: 'none',
    }
};


const useStyles = makeStyles(() => ({
    container: {
        marginTop: '-30px',
    },
    details: {
        width: '50px',
        background: '#071C55',
        color: '#fff',
        borderRadius: 5,
        fontFamily: 'Poppins, sans-serif',
        fontSize: '10px',
        marginBottom: '2px',
    },
    firstName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '35px'
    },
    lastName: {
        marginLeft: 3,
    },
    star: {
        fontSize: '9px',
        color: '#F5A94B',
    },
    caret: {
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '5px solid #071C55',
        marginTop: -2,
        zIndex: 0,
    },
    scheduleBtnContainer: {
        position: 'absolute',
        width: '100%',
        bottom: 20,
        left: 0,
        margin: 0,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    scheduleBtn: {
        pointerEvents: 'auto',
        minWidth: '220px',
    },
    unsupported: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
        background: 'rgba(255,255,255,.85)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            maxWidth: '800px',
            textAlign: 'center'
        }
    },
    avatar: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        objectFit: 'cover',
        zIndex: 1,
    },
    iconButton: {
        '& .MuiSvgIcon-root': {
            color: '#049dc4', // Change this to your desired color
        },
    },
}));

const ShowWalkerOnMap = (props) => {

    const { history, location } = props;
    console.log('location', location);
    const browser = detect();
    const [scheduleTrip, setScheduleTrip] = useState(location.state && location.state.scheduleTrip);

    const [latLong, setLatLong] = useState('');
    const [walkerData, setWalkerData] = useState({});
    const [openWalkerDetails, onOpenWalkerDetails] = useState(false);
    const [selectedWalker, setSelectedWalker] = useState(false);
    const [destinationId, setDestinationId] = useState(0);
    const [tripRate, setTripRate] = useState([]);
    const [tripRateActive, setTripRateActive] = useState(0);
    const [customTripValue, setCustomTripValue] = useState(5);
    const [customTrip, setCustomTrip] = useState(false);
    const [tripRatePopUp, setTripRatePopUp] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isPaymentMethod, setIsPaymentMethod] = useState(false);
    const [closeScheduleTrip, setCloseScheduleTrip] = useState(false);
    const [closeScheduleTripAmbassador, setCloseScheduleTripAmbassador] = useState(false);
    const [closeScheduleTripTimer, setCloseScheduleTripTimer] = useState(false);
    const [isAmbassador, setIsAmbassador] = useState(false);
    const [streamCode, setStreamCode] = useState('');
    const [inputStreamCode, setInputStreamCode] = useState('');
    const [scheduleTripId, setScheduleTripId] = useState('');
    const [rating, setRating] = useState(0);
    const [upComingTrips, setUpComingTrips] = useState([]);
    const [serverTime, setServerTime] = useState(null);
    const [bookedToAmbassador, setBookedToAmbassador] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [scheduleTripModal, setScheduleTripModal] = useState(false);
    const [tripRateBtn, setTripRateBtn] = useState(false);
    const [successScheduleTrip, setSuccessScheduleTrip] = useState(false);
    const classes = useStyles();





    useEffect(() => {
        setIsLoading(props.showModal);
    }, [props.showModal]);

    const { data } = useQuery(GET_TRIP_RATE, {
        variables: { "data": destinationId },
        fetchPolicy: 'network-only',
    });

    const [sendBookingRequestToWalker] = useMutation(SEND_BOOKIN_REQUEST_TO_WALKER, {
        onCompleted() {
            setTripRatePopUp(false)
        }
    });

    const [saveScheduleTrip, { loading: scheduleTripLoading }] = useMutation(GET_SAVE_SCHEDULE_TRIP, {
        onCompleted({
            saveScheduleTrip: {
                status,
                message,
            }
        }) {
            setMessage(message);
            if (status === 'SUCCESS') {
                setSuccessScheduleTrip(true);
                // document.getElementById('success-schedule-trip').scrollTo(0, 0);
            }
            else if (status === 'OTHER_SCHEDULE_TRIP') {
                setShowOtherScheduleTrip(true);
                // setOtherScheduleTrip(message);
            }

            setScheduleTripModal(false);
            onOpenWalkerDetails(false);
            setIsLoading(false)
            setShowMessage(true);
        }
    });

    const [authorizeStripe] = useMutation(AUTHORIZE_STRIPE, {
        onCompleted({
            authorizeStripe: {
                status,
                message
            }
        }) {
            if (status === 'SUCCESS') {
                setLoading('WAITING FOR WALKER');
                sendBookingRequestToWalker({
                    variables: {
                        data: {
                            walker_id: selectedWalker && selectedWalker.id,
                            destination_id: destinationId,
                            tripe_price: customTripValue + 5,
                            tripe_minute: customTripValue,
                            booking_price_type: "N"
                        }
                    }
                })
                setTimeout(() => {
                    setIsLoading(false);
                    props.callback('modal', false);
                }, 60000);
            } else {
                setIsLoading(false);
                props.callback('modal', false);
                setIsPaymentMethod(true);
                setPaymentMethod(message);
            }
        }
    });

    const { upcomingtrips, loading: upcomingTriploading, refetch } = useQuery(UPCOMING_WALKS, {
        variables: { "pageNumdata": 1, "first": 100 },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted({ getTravelerUpcomingTrips }) {
            setIsLoading(false);
            props.callback('modal', false);
            if (getTravelerUpcomingTrips && getTravelerUpcomingTrips.data.length) {
                setUpComingTrips(getTravelerUpcomingTrips);
                if (scheduleTrip && isAmbassador) {
                    const booked = getTravelerUpcomingTrips.data.some(loc => loc.destination.name === scheduleTrip.locationName
                        && loc.schedule_date === scheduleTrip.date && loc.schedule_time === scheduleTrip.time);
                    console.log('booked', booked);
                    setBookedToAmbassador(booked);

                    if (!booked) {
                        setTimeout(() => {
                            setCloseScheduleTripAmbassador(true);
                        }, 1000);
                    } else if (booked && serverTime) {
                        setCloseScheduleTripTimer(true);
                    }
                }
            } else {
                setCloseScheduleTripAmbassador(true);
            }
        }
    });

    const [verifyVideoCallAmbassador] = useMutation(VERIFY_VIDEO_CALL_AMBASSADOR, {
        onCompleted({
            verifyVideoCallAmbassador: {
                status,
                message,
                session_id,
                token_id,
                booking_id,
                walker_id,
                is_re_join,
                time_zone,
                wss,
                tripe_minute,
                stream_id,
            }
        }) {
            if (status === 'SUCCESS') {
                setIsLoading(false);
                props.callback('modal', false);
                history.push('/video-call-ambassador', {
                    roomID: session_id,
                    securityToken: token_id,
                    userType: 'traveller',
                    scheduleTripId: scheduleTrip.id,
                    bookingId: booking_id,
                    walkerId: walker_id,
                    isReJoin: is_re_join,
                    timeZone: time_zone,
                    wss: wss,
                    tripMinutes: tripe_minute,
                    streamId: stream_id
                });
            }
            if (status === 'PENDING') {
                setServerTime(time_zone);
                refetch();
                onOpenWalkerDetails(false);
            }

            if (status == 'FULL') {
                setMessage(message);
                setIsLoading(false);
                setShowMessage(true);
            }
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        const Data = JSON.parse(UserUtils.getDestinationData());
        setLatLong(Data);
        setDestinationId(Data.id);
        if (data) {
            setTripRate(data.getTripRate);
        }
        if (!(disableSafari && browser.name === 'safari')) {
            setOpen(true);
        }
        const listRef = ref(database, 'users');
        onValue(listRef, (snapshot) => {
            const firebaseData = (snapshot.val() && snapshot.val().walkers) || {};
            setWalkerData(firebaseData);
        });
        history.listen((prevHistory, action) => {
            if (
                typeof prevHistory.location != 'undefined'
                && typeof prevHistory.location.state !== 'undefined'
                && typeof prevHistory.location.state.isLoading !== 'undefined'
            ) {
                setIsLoading(prevHistory.location.state.isLoading);
                props.callback('modal', prevHistory.location.state.isLoading);
            }
        });
        if (scheduleTrip && scheduleTrip.status != 4) {
            setOpen(false);
        }
    }, [data]);

    const MapMarker = ({ data: data }) => {
        const { user, user_type } = data;
        if (!user.avatar) {
            user.avatar = publicUrl + 'assets/images/inner-page/profile.jpg';
        }
        return (
            <div
                className={`d-flex align-items-center justify-content-center flex-column ${classes.container}`}
                onClick={onSelectWalker.bind(this, data)}
            >
                <div
                    className={`d-flex flex-column align-items-center ${classes.details}`}
                    style={{ backgroundColor: user_type === 'ambassador' ? '#C10916' : '#202464' }}
                >
                    <div className="d-flex flex-row align-items-center">
                        <div className={classes.firstName}>{user.first_name}</div>
                        <div className={classes.lastName}>{user.last_name.charAt(0).toUpperCase()}.</div>
                    </div>
                    <div><i className={`fa fa-star ${classes.star}`}></i> {data.over_all_rating}</div>
                    {setRating(data.over_all_rating)}
                </div>
                <img
                    src={user.avatar}
                    style={{
                        objectFit: 'cover',
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        borderWidth: 2,
                        borderColor: user_type === 'ambassador' ? '#C10916' : '#202464',
                        borderStyle: 'solid',
                        backgroundColor: user_type === 'ambassador' ? '#C10916' : '#202464',
                    }}
                />
                <div className={classes.caret} style={{ borderTopColor: user_type === 'ambassador' ? '#C10916' : '#202464' }}></div>
            </div>
        )
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCustomTrip = (event, value) => {
        setCustomTripValue(value);
    };

    const handleStartTrips = () => {
        // alert('asd');
        // onOpenWalkerDetails(false);
        // if (upComingTrips.length != 0) {
        //     const bookedScheduledTrip = upComingTrips.data.find(loc => loc.walker_id === selectedWalker.id);
        //     if (bookedScheduledTrip) {
        //         alert(bookedScheduledTrip);
        //         setScheduleTrip({
        //             epoch: bookedScheduledTrip.epoch,
        //             destinationTimeZone: bookedScheduledTrip.time_zone,
        //             location: bookedScheduledTrip.destination.name,
        //             userType: 'walker'
        //         });
        //         var currentTimezoneDateTime = moment.utc(upComingTrips.serverDateTime, null).tz(bookedScheduledTrip.time_zone);
        //         setServerTime(currentTimezoneDateTime);
        //         setCloseScheduleTripTimer(true);
        //     } else {
        //         setTripRatePopUp(true);
        //     }
        // } else {
        setTripRatePopUp(true);
        // }

    }

    const selectTripRate = (trip_rate, index) => {
        setCustomTripValue(trip_rate.minutes);
        setCustomTrip(false);
        setTripRateActive(index + 1);
        setTripRateBtn(true);

    }

    const handleChangeCustomTrip = () => {
        setTripRateActive(0);
        setCustomTrip(!customTrip);
    }

    const handleclosePopUp = () => {
        setTripRatePopUp(false)
        onOpenWalkerDetails(false)
    }

    const onSelectWalker = (data) => {
        console.log('data', data);
        if (!(disableSafari && browser.name === 'safari')) {
            setSelectedWalker(data.user);
            setIsAmbassador(data.user_type === 'ambassador');
            setStreamCode(data.stream_code);
            setInputStreamCode('');
            setScheduleTripId(data.schedule_trip_id);
            onOpenWalkerDetails(true);
        }
    }

    const handleSubmit = () => {
        setTripRatePopUp(false);
        setIsLoading(true);
        props.callback('modal', true);
        setLoading('PROCESSING REQUEST');
        authorizeStripe({
            variables: {
                amount: customTripValue + 5,
            }
        });

    }

    const onCloseScheduleTrip = () => {
        setCloseScheduleTrip(!closeScheduleTrip);
    }

    // const onCloseScheduleAmbassadorTrip = () => {
    //     setCloseScheduleTripAmbassador(false);
    // }

    // const onCloseScheduleTimer = () => {
    //     setCloseScheduleTripTimer(false);
    // }

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    const handleSubmitSchedule = async () => {
        setScheduleTripModal(true)
        setIsLoading(true);
        props.callback('modal', true);
        setLoading('PROCESSING REQUEST');
        authorizeStripe({
            variables: {
                amount: customTripValue + 5,
            }
        });
    }


    return (
        <>
            {upcomingTriploading && <PageLoader loading={upcomingTriploading} />}
            <div className="traveller-page" style={{ minHeight: '600px' }}>
                <div className="traveller-row">
                    <SubHeader title="Select Walker On Map" />
                    <div style={{ height: '600px', position: 'relative', width: '100%' }}>
                        <div className="travelle-body p-0">
                            {disableSafari && browser.name === 'safari' &&
                                <div className={classes.unsupported}>
                                    <p>The Huvr Web Application currently does not support traveling to destinations in the Safari browser, we apologize for any inconvenience. Please use an alternate browser like Google Chrome or download the Huvr mobile application for iOS and Android as we work to quickly resolve this temporary issue with Safari.</p>
                                </div>
                            }
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyAd_8tk5z2ySbn4uRcZRXCSIjtac6kYGHI' }}
                                center={{ lat: latLong.latitude, lng: latLong.longitude }}
                                zoom={scheduleTrip ? 18 : 14}
                                style={{
                                    height: '600px',
                                    position: 'absolute',
                                    width: '100%',
                                    left: 0,
                                }}
                            >
                                {(walkerData && Object.keys(walkerData).length > 0) ? Object.keys(walkerData).map((key) => {
                                    const marker = walkerData[key];
                                    if (!marker.user) {
                                        return null;
                                    }
                                    return (
                                        <MapMarker
                                            key={`map_marker_${key}`}
                                            lat={marker.latitude}
                                            lng={marker.longitude}
                                            data={marker}
                                        />
                                    );
                                }) : null}
                            </GoogleMapReact>
                            {(!scheduleTrip || (scheduleTrip && scheduleTrip.status !== 4)) &&
                                <div className={`schedule-btn ${classes.scheduleBtnContainer}`}>
                                    <button onClick={onCloseScheduleTrip} className={`btn btn-yellow ${classes.scheduleBtn}`}>Schedule Trip</button>
                                </div>
                            }
                        </div>
                    </div>

                    <Dialog aria-labelledby="customized-dialog-title" open={false}>
                        <DialogContent>
                            <div className="schedule-popup-div">
                                <h4>Select A Walker</h4>
                                <p>To Begin Your Trip Select a Walker from Live Map</p>
                                <p>If no walkers are available you can schedule a trip.</p>
                                <div className="select-popup-btn">
                                    <button className="btn btn-yellow" onClick={handleClose}>OK</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog aria-labelledby="customized-dialog-title" open={openWalkerDetails}>
                        <div className="text-center p-2" style={{ maxWidth: 800, minWidth: 400 }} >
                            <DialogContent>
                                <div className="popup-scroll">
                                    <button type="button" className="pop-close-btn" onClick={() => handleclosePopUp()}><i className="la la-times"></i></button>
                                </div>
                                <div className="walker-row-one">
                                    <figure>
                                        <img src={selectedWalker.avatar} className={classes.avatar} />
                                    </figure>
                                    <h4 style={{ fontSize: 29 }}> {selectedWalker.first_name ? selectedWalker.first_name : 'N/A'} {selectedWalker.last_name ? selectedWalker.last_name.charAt(0).toUpperCase() + '.' : ''}</h4>
                                </div>
                                <div className="rating_icons">
                                    <Rating
                                        style={{ margin: 10 }}
                                        emptySymbol="fa fa-star-o fa-2x"
                                        fullSymbol="fa fa-star fa-2x "
                                        initialRating={parseFloat(rating)}
                                        readonly
                                    />
                                    <div className="row" style={{
                                        boxShadow: "0px 1px 20px rgb(0 0 0 / 15%)",
                                        padding: 10,

                                    }}>
                                        {!isAmbassador &&
                                            <div className="col-md-6 com-sm-12" style={{ fontSize: 16, fontWeight: 400 }}><span style={{ color: "#049DC5" }}>No. of Walks:</span>&nbsp; {selectedWalker.walker_profile && selectedWalker.walker_profile.no_of_walks ? selectedWalker.walker_profile.no_of_walks : 0}</div>
                                        }
                                        <div className="col-md-6 com-sm-12" style={{ fontSize: 16, fontWeight: 400 }}><span style={{ color: "#049DC5" }}>Rating:</span>&nbsp;{parseFloat(rating)}</div>
                                    </div>

                                    {/* <span className="walker-rating-text">{parseFloat(rating)}</span> */}
                                </div>
                                {/* {!isAmbassador &&
                                    <div className="walker-rating-text mb-2"><small style={{ fontWeight: 600 }}>No. of Walks: <span>{selectedWalker.walker_profile && selectedWalker.walker_profile.no_of_walks ? selectedWalker.walker_profile.no_of_walks : 0}</span></small></div>
                                }
                                {isAmbassador ?
                                    <p className="mb-2 mt-2">{selectedWalker.walker_profile && selectedWalker.walker_profile.ambassador_description ? selectedWalker.walker_profile.ambassador_description : ''}</p>
                                    :
                                    <p className="mb-2">{selectedWalker.walker_profile && selectedWalker.walker_profile.description ? selectedWalker.walker_profile.description : ''}</p>
                                } */}
                            </DialogContent>
                            <DialogActions>
                                <div className="w-100 mb-3 d-flex flex-column align-items-center">
                                    <button
                                        style={{ width: 200, borderRadius: 22 }}
                                        className="btn btn-yellow"
                                        onClick={handleStartTrips}
                                    >
                                        Start Trip
                                    </button>
                                </div>
                            </DialogActions>
                        </div>
                    </Dialog>


                    {/* check rate */}
                    <Dialog aria-labelledby="customized-dialog-title" open={tripRatePopUp}>
                        <div className="schedule-pick-row walker-map-popup" style={{ width: 500, height: 700, overflowY: "auto" }}>
                            <DialogContent>
                                <div className="popup-scroll">
                                    <button type="button" className="pop-close-btn" onClick={() => handleclosePopUp()}><i className="la la-times"></i></button>
                                </div>
                                <h4 className="package-title" style={{ fontWeight: 400, fontSize: 24 }}>Trip Rates</h4>
                                <p style={{ marginTop: 0, textAlign: 'center', fontSize: 18, fontWeight: 400 }}>* $10 USD for first five minutes of trip</p>
                                <p style={{ marginBottom: 20, textAlign: 'center', fontSize: 18, fontWeight: 400 }}>* $1 USD for every minutes thereafter</p>
                                {!customTrip &&
                                    <>
                                        {tripRate.map((d, index) => {
                                            return (
                                                <div key={`trip_rate_${index}`} className={`price-btn ${tripRateActive === (index + 1) ? 'selected-rate' : ''}`} onClick={() => selectTripRate(d, index)}>
                                                    {`${d.minutes} Minutes for $${d.price}`}
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                                <div className={`price-btn price-btn-last ${customTrip ? 'custom-selected-rate' : ''}`} onClick={handleChangeCustomTrip}>Custom Length</div>
                                {customTrip &&
                                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                                        <Grid item xs>
                                            <Slider value={customTripValue} onChange={handleCustomTrip} aria-labelledby="continuous-slider" min={5} max={60} />
                                            <p>{`${customTripValue} Minutes for $${parseFloat(customTripValue) + 5}`}</p>
                                        </Grid>
                                    </div>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div className="schedule-pick-row">
                                    <h4 className="package-title">Important</h4>
                                    <div className="schedulePickRowList">
                                        <p style={Styles.p}>1. Your scheduled trip will be in <span className="text-red">Pending</span> status <span className="text-red">Until</span> the request has been <span className="text-red">Accepted</span> by a walker.</p>
                                        <p style={Styles.p}>2. You will be notified once your request has been accepted.</p>
                                        <br />
                                        <p style={Styles.p}>You can check the status of your scheduled trips in the Upcoming section of <a href='#' onClick={() => history.push('/your-trips', { upcoming_trip: true })}>Your Trips</a></p>
                                    </div>
                                </div>
                            </DialogActions>
                            <DialogActions>
                                <div className="text-center">
                                    <button className="btn btn-yellow"
                                        onClick={handleSubmit}
                                        style={{ width: "60%", borderRadius: 22 }}
                                        disabled={!tripRateBtn ? true : false}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </DialogActions>
                        </div>
                    </Dialog>




                    <Dialog open={isPaymentMethod}>
                        <div className="schedule-pick-row walker-map-popup" style={{ width: 100 }}>
                            <DialogContent
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 20 }}>
                                <h6 style={{ marginBottom: 20, }}>Alert</h6>
                                <p style={{ textAlign: 'center' }}>{paymentMethod}</p>
                                <div className="text-center mt-4">
                                    <button className="btn btn-yellow" onClick={() => history.push('/payment-methods')}>Payment Methods</button>
                                </div>
                            </DialogContent>
                        </div>
                    </Dialog>
                    <Dialog open={isLoading} maxWidth="xs">
                        <div className="schedule-pick-row walker-map-popup">
                            <DialogContent
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 20, height: 100, overflow: 'hidden' }}>
                                <h6 style={{ marginBottom: 20, }}>{loading}</h6>
                                <CircularProgress />
                            </DialogContent>
                        </div>
                    </Dialog>
                    <Dialog open={showMessage} maxWidth="xs">
                        <div className="schedule-pick-row walker-map-popup">
                            <DialogContent>
                                <div className="walker-close">
                                    <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={() => setShowMessage(false)}></i>
                                </div>
                                <h4 className="package-title">{message}</h4>
                            </DialogContent>
                            <DialogActions>
                                <div className="text-center">
                                    <button className="btn btn-yellow" onClick={() => setShowMessage(false)}>Close</button>
                                </div>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div >
            </div >
            {closeScheduleTripTimer &&
                <ScheduleTripTimer {...props} onCloseScheduleTrip={onCloseScheduleTimer} scheduleTrip={scheduleTrip} serverTime={serverTime} />
            }
            {
                closeScheduleTrip &&
                <ScheduleTrip {...props} onCloseScheduleTrip={onCloseScheduleTrip} />
            }
            {
                scheduleTrip && closeScheduleTripAmbassador &&
                <ScheduleTripAmbassador {...props} onCloseScheduleTrip={onCloseScheduleAmbassadorTrip} scheduleTrip={scheduleTrip} />
            }
        </>
    )
}

ShowWalkerOnMap.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(ShowWalkerOnMap);
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Dialog, DialogContent, CircularProgress, DialogTitle, Divider, Typography, Button, OutlinedInput, InputAdornment, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
const publicUrl = process.env.PUBLIC_URL + '/';
const ADD_CARD = loader('../../graphql/schema/payments/add-card.graphql');
const tripDetails = { color: '#00A9CB', fontSize: '15px' };

const PaymentForm = (props) => {

  const { history } = props;

  const apolloClient = useApolloClient();
  const [countryCode, setCountryCode] = useState('US');
  const [hasError, setHasError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000)
  }, [])

  const postResponseData = (res) => {
    apolloClient.writeData({ data: { isLoggedIn: true } });
    let error = {};
    if (res.status === 'CARD_ADDED') {
      setSuccessMessage(res.message);
      setTimeout(() => {
        history.push('/payment-methods');
      }, 2000);
    } else {
      error.cardError = res.message;
      setHasError(error);
    }
  };

  const [addCardSubmit, { loading: loading }] = useMutation(ADD_CARD, {
    onCompleted({
      addCard: {
        message: message,
        status: status,
      },
    }) {
      postResponseData({ status, message });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error.cardError = formatedErrors;
      setHasError(error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
    const data = {};
    data.address_country = countryCode;
    const result = await stripe.createToken(card, data);
    if (result.error) {
      console.log(result.error.message, 'if');
      errors.cardError = result.error.message;
      setHasError(errors);
    } else if (countryCode === '') {
      errors.cardError = 'Country is Required.';
      setHasError(errors);
    } else {
      setHasError({});
      props.next(result);
      // CLEAR CARD ELEMENT FIELDS
      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();
      setCountryCode('');
    }
  }

  return (
    <>
     <div className="group-trip-payment">
          <form onSubmit={handleSubmit}>
        {(loading || loader) && <PageLoader loading={(loading || loader)} />}
      

          {successMessage !== "" &&
            <div className="success-msg">
              {<p className="success-msg">{`${successMessage}.`}</p>}
            </div>
          }

          {hasError.cardError && <div className="is-invalid">{hasError.cardError}</div>}

          <div className="form-in">
            <label style={tripDetails}>Card Number</label>
            <CardNumberElement options={{placeholder: "**** **** **** ****"}}/>
            <figure className="form-card">
              <img src={publicUrl + "assets/images/inner-page/credit-card-solid.png"} alt="" />
            </figure>
          </div>
          <div className="formin-row">
            <div className="formin-half">
              <div className="form-in">
                <label style={tripDetails}>Exp. Date</label>
                <CardExpiryElement />
                <figure className="form-card">
                  <img src={publicUrl + "assets/images/inner-page/credit-card-solid.png"} alt="" />
                </figure>
              </div>
            </div>
            <div className="formin-half">
              <div className="form-in">
                <label style={tripDetails}>CVC</label>
                <CardCvcElement options={{placeholder: "****"}} />
                <figure className="form-card">
                  <img src={publicUrl + "assets/images/inner-page/credit-card-solid.png"} alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div id="country-select" className="form-in" style={{ marginBottom: '100' }}>
            <label style={tripDetails}>Country</label>
            <ReactFlagsSelect
              searchPlaceholder="Search countries"
              searchable
              selected={countryCode}
              onSelect={code => setCountryCode(code)}
            />
          </div>
          {/* <div className="form-btn btn-height">
            <button className="btn btn-yellow">Enter Card Information</button>
          </div> */}
          <Box style={{ textAlign: 'center', marginTop: 25 }}>
              <Button variant="outlined" onClick={() => props.cancel()} style={{ border: '1px solid #F1AB54', color: '#F1AB54', borderRadius: '100px', padding: 10, width: 120, marginRight: 15}}>Cancel</Button>
              <Button variant="contained" type="submit" style={{ border: '1px solid #F1AB54', color: '#fff', backgroundColor: '#F1AB54', borderRadius: '100px', padding: 10, width: 120, marginLeft: 15}}>Next</Button>
          </Box>
      </form>
      </div>
    </>
  )
}


PaymentForm.propTypes = {
  history: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(PaymentForm);
import React from 'react';

const RemoteTrackComponent = ({ tracks }) => {
    const refVideo = (node, track) => {
      if (node && track) {
        node.srcObject = new MediaStream([track.track]);
        
        node.addEventListener('canplay', () => {
          // Start playback once the media is ready
          node.play()
            .then(() => {
              // Playback started successfully
              // track.track.muted = false;
              console.log('Playback started successfully!!! ', track.track);
            })
            .catch((error) => {
              console.error('Failed to start playback:', error);
            });
        });
  
        // Add event listener for error handling
        node.addEventListener('error', (event) => {
          console.error('Error occurred while loading media:', event.target.error);
        });
      }
    }
  
    return <>
      { Object.values(tracks).map(item => {
        if(item.track.kind == 'video') {
          return <video style={{ width: '100%',height: '100%'}}
            key={`${item.track.id}_video`}
            ref={(node) => refVideo(node, item)}
            playsInline
            autoPlay
          ></video>;
        } else {
          return <audio
            key={`${item.track.id}_audio`}
            ref={(node) => refVideo(node, item)}
            playsInline
            autoPlay
          ></audio>;
        }
      })
    }
    </>;
  };
  
  const RemoteTrack = React.memo(RemoteTrackComponent);
  export default RemoteTrack;
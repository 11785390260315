import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import * as commonFunctions from '../../utilities/commonFunctions';
import UserUtils from '../../utilities/UserUtils';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import DOMPurify from "dompurify";
import SubHeader from '../../components/layout/sub-header';

const GET_ADS = loader('../../graphql/schema/ads/get-ads.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const DESTINATIONS_DATA = loader('../../graphql/schema/destinationView/get-destinations-by-category.graphql');

const SelectDestination = (props) => {

  const { history } = props;

  const [destinationsData, setDestinationsData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [categoryName, setCategoryName] = useState('');
  const [adList, setAdList] = useState({});
  const [modalAd, setModalAd] = useState({});
  const [selected, setSelected] = useState(null);
  const [adsLoading, setAdsLoading] = useState(false);
  const AD_TYPE = 'web_app_select_destinations';

  const [ getAds, { data: adsData, loading: adsLoader } ] = useLazyQuery(GET_ADS, {
    variables: { 
      ad_page: AD_TYPE, 
      ad_category: categoryId, 
      ad_destination: selected ? selected.id : null, 
      ad_user: (selected && selected.pro) ? selected.pro.admin_id : null 
    },
    fetchPolicy: 'no-cache',
    onCompleted() {
      setAdsLoading(false);

      if(selected) {
        let modalAdlist = modalAd;
        let popupAd = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'popup' && item.ad_location_lookup.ad_value == 'bottom');

        if(popupAd.length) {
          popupAd.forEach(item => {
            modalAdlist[`d${item.ad_destination}`] = DOMPurify.sanitize(item.ad_content.replace('></a>', '/></a>'));
          });
          setModalAd(modalAdlist);
        }

        openPopUp(selected);
        setSelected(null);
      } else {
        let list = adList;
        let inlineAds = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'inline' && item.ad_location_lookup.ad_value == 'center');
        inlineAds.forEach(item => {
          list[`d${item.ad_destination}`] = DOMPurify.sanitize(item.ad_content.replace('></a>', '/></a>'));
        });

        setAdList(list);
      }
    }
  });

  const [ getDestinations, { data, loading, error } ] = useLazyQuery(DESTINATIONS_DATA, {
    variables: {"category_id": categoryId },
    fetchPolicy: "no-cache",
    onCompleted() {
      setDestinationsData(data.getDestinationsByCategory);
      setPageCount(1);

      setTimeout(() => {
        let id = (new URLSearchParams(window.location.search)).get('id');

        if(id !== '' && id) {
          document.getElementById(`d-${id}`).click();
        }
      }, 1000);
    }
  });

  const handlePage = (pageNo) => {
    let selected = pageNo.selected + 1;
    setPage(selected);
    scrollToTop();
}

  useEffect(() => {
    if (history.location.state && history.location.state.category_id) {
      setCategoryId(history.location.state.category_id);
    } else {
      setCategoryId(29);
    }

    if (history.location.state && history.location.state.category_name) {
      setCategoryName(history.location.state.category_name);
    }

    setAdsLoading(true);
    getAds();
    getDestinations();
  }, [])

  useEffect(() => {
    if (error) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
        //setHaserror(errorMsg)
    }
  }, [data, loading, error])

  const scrollToTop = () => {
    if ($("#destinationsTab").offset() !== undefined) {
      $('html, body').animate({
        scrollTop: $("#destinationsTab").offset().top
      }, 1000)
    }
  }

  const ScheduleTrip = (data) => {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').removeClass();
    UserUtils.setDestinationData(JSON.stringify(data));

    console.log('SCheduletrip', data);
    console.log('categoryName',categoryName);
    console.log('Group trips', data.group_trips);
    if(categoryName == 'Group Trips' && data.group_trips.length) {
      let groupTrip = data.group_trips[0];

      history.push('/show-walker-on-map', {
      "scheduleTrip" : {
          "date": groupTrip.schedule_date,
          "destinationId": groupTrip.destination.id,
          "destinationTimeZone": groupTrip.destination.time_zone,
          "epoch": groupTrip.epoch,
          "location": groupTrip.destination.location,
          "locationName": groupTrip.destination.name,
          "publish": groupTrip.publish,
          "scheduleMinute": groupTrip.schedule_minute,
          "status": groupTrip.status,
          "streamCode": groupTrip.publish? groupTrip.stream_code : '',
          "time": groupTrip.schedule_time,
          "walkerId": groupTrip.walker_id,
          "isGroup": true
        }
      });
    } else {
      history.push('/show-walker-on-map');
    }
  }

  const handleStations = (destination) => {
    history.push('/select-station', { destination: destination, category: categoryId });
  }

  const onItemClick = (d, index) => {
    setSelected({ ...d, index });
    setAdsLoading(true);
    getAds();
  }

  const openPopUp = (d) => {
    let modal = $(`#modal-${d.index}`);
    let backdrop = $('#backdrop');
    
    backdrop.addClass('modal-backdrop fade show');
    modal.addClass('show');
    modal.css('display', 'block');
  }

  const closePopUp = (d) => {
    let modal = $(`#modal-${d.index}`);
    let backdrop = $('#backdrop');

    setSelected(null);
    
    backdrop.removeClass('modal-backdrop fade show');
    modal.removeClass('show');
    modal.css('display', 'none');
  }

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      {adsLoading && <PageLoader loading={adsLoader} /> }
      <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="All Destinations" />
          <div id="gtrip-wrapper" className="container">
            <div className='inner-menuv2'>
              <Link to="/destination-categories" className='prev-nav'>Destination Categories</Link> / <a className='current-nav'>All Destinations</a>
            </div>
            <div id="all-destinations" className="destination-body" style={{ boxShadow: 'none'}}>
            {destinationsData.length > 0 && destinationsData.map((d, index) => {
                return (
                  <div key={index} className="destination-box dest-box">
                    <div className="box-left dest-box-left">
                      <figure>
                        <img
                          className="select-dest-img"
                          alt={d.name}
                          src={`${d.image ? d.image : publicUrl + "assets/images/destination-list/1.png"}`}
                        />
                      </figure>
                     <div>
                      <h4 className="dest-name">{d.name}</h4>
                      <p className="dest-location">{d.location}</p>
                     </div>
                    </div>
                    <div className="box-center">
                      <div dangerouslySetInnerHTML={{ __html: adList[`d${d.id}`] }}></div>
                    </div>
                    <div>
                    </div>
                    {d.schedule_trips.length == 0 ? (
                      <div className="box-right dest-box-right">
                        <i className="fa fa-angle-right" id={`d-${d.id}`} onClick={() => onItemClick(d, index)}></i>
                      </div>
                    ): (
                      <div className="box-right dest-box-right">
                        <i className="fa fa-angle-right" id={`d-${d.id}`} onClick={() => handleStations(d)}></i>
                      </div>
                    )}
                  </div>
                )
              })}

              {pageCount > 1
                ? <div className="search-pagination">
                  <ul className="pagination pagination-sm m-0 float-right">
                    <ReactPaginate
                      previousLabel={<i className="fa fa-angle-double-left"></i>}
                      nextLabel={<i className="fa fa-angle-double-right"></i>}
                      breakLabel={<a href="#/">...</a>}
                      pageCount={pageCount}
                      onPageChange={handlePage}
                      breakClassName={"break-me"}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      pageClassName="page-item"
                      pageLinkClassName="page-link" />
                  </ul>
                </div>
                : ''
              }
            </div>
          </div>
        </div>
      </div>

      {/* popup start here */}
      {destinationsData.map((d, index) => {
        return (
          <div key={index} className="modal fade destination-body-popup popupv2" id={`modal-${index}`}>
            <div>
              <div className="modal-dialog modal-dialog-centered dialog-centered">
                <div className="modal-content payment-popup-body">
                  <div className="popup-scroll">
                    <button type="button" className="close" onClick={() => closePopUp({...d, index})}><i className="la la-times"></i></button>
                    <figure className="destination-img">
                      {d.banner ?
                        <img
                            className="select-dest-img-modal"
                            alt={d.name}
                            src={d.banner}
                          />
                      :
                        <img
                          className="select-dest-img-modal"
                          alt={d.name}
                          src={`${d.image ? d.image : publicUrl + "assets/images/destination-list/1.png"}`}
                        />
                      }
                    </figure>
                    <div className="payment-popup-cnt default-popup-cnt">
                      <h4>{d.name}</h4>
                      <em>{d.location}</em>
                      <p>{d.description}</p>
                    </div>
                  </div>
                  <div className="default-popup-btn">
                    <button className="btn btn-yellow" onClick={() => ScheduleTrip(d)}>Huvr there Now!</button>
                  </div>
                  <div className="modal-footerv2">
                    {modalAd[`d${d.id}`] && <div dangerouslySetInnerHTML={{ __html: modalAd[`d${d.id}`] }}></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div id="backdrop"></div>
    </>
  )
}

SelectDestination.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(SelectDestination);

import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined';
import userUtils from '../../utilities/UserUtils';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import { Input, MenuItem, Chip } from '@material-ui/core';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import SubHeader from '../../components/layout/sub-header';

const SAVE_USER_PREFERENCE = loader('../../graphql/schema/settings/save-user-preferences.graphql');
const GET_USER_PREFERENCE = loader('../../graphql/schema/settings/get-user-preferences.graphql');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

const interestList = [
  { id: 0, name: 'Landmarks'},
  { id: 1, name: 'Shoppings'},
  { id: 2, name: 'Museums'},
  { id: 3, name: 'Beaches'},
  { id: 4, name: 'Architecture'},
  { id: 5, name: 'History'},
  { id: 6, name: 'Dating'},
  { id: 7, name: 'Real Estate'},
  { id: 8, name: 'News'},
  { id: 9, name: 'Events'},
  { id: 10, name: 'Sports'},
  { id: 11, name: 'Charity'},
  { id: 12, name: 'Education'},
  { id: 13, name: 'Antiques'},
];

const languageList = [
  { id: 0, name: 'English' },
  { id: 1, name: 'Spanish' },
  { id: 2, name: 'Chinese' },
  { id: 3, name: 'Hindi' },
  { id: 4, name: 'Japanese' },
  { id: 5, name: 'Arabic' },
  { id: 6, name: 'Bengali' },
  { id: 7, name: 'Portuguese' },
  { id: 8, name: 'Russian' },
  { id: 9, name: 'Lahnda' },
  { id: 10, name: 'Italian' },
  { id: 11, name: 'Greek' },
  { id: 12, name: 'French' },
  { id: 13, name: 'German' },
];

const Settings = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const classes = useStyles();

  const [frequency, setFrequency] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [interest, setInterest] = useState([]);
  const [newDestination, setNewDestination] = useState(false);
  const [liveEvent, setLiveEvent] = useState(false);
  const [liveNews, setLiveNews] = useState(false);
  const [pauseNotification, setPauseNotification] = useState(false);
  const [showTravellerSplash, setShowTravellerSplash] = useState(false);
  const [showWalkerSplash, setShowWalkerSplash] = useState(false);

  const { data: getUserPreference, loading, refetch } = useQuery(GET_USER_PREFERENCE);

  const [updateSaveSettings] = useMutation(SAVE_USER_PREFERENCE, {
    refetchQueries: [
      {
        query: GET_USER_PREFERENCE,
        fetchPolicy: 'network-only',
      },
    ],
  });

  useEffect(() => {
    refetch();

    if (getUserPreference) {
      setFrequency(getUserPreference && getUserPreference.getUserPreference.frequency);
      setLanguages(getUserPreference && getUserPreference.getUserPreference.languages ? getUserPreference.getUserPreference.languages : []);
      setInterest(getUserPreference && getUserPreference.getUserPreference.interests ? getUserPreference.getUserPreference.interests : []);
      setNewDestination(getUserPreference && getUserPreference.getUserPreference.new_destination);
      setLiveEvent(getUserPreference && getUserPreference.getUserPreference.live_event);
      setLiveNews(getUserPreference && getUserPreference.getUserPreference.live_news);
      setPauseNotification(getUserPreference && getUserPreference.getUserPreference.pause_notification);
      setShowTravellerSplash(getUserPreference && getUserPreference.getUserPreference.show_traveller_splash);
      setShowWalkerSplash(getUserPreference && getUserPreference.getUserPreference.show_walker_splash);
      
    }
  }, [getUserPreference])

  const handleChangeFrequency = (e) => {
    setFrequency(e.target.value);
    updateSaveSettings({
      variables: {
        data: {
          frequency: e.target.value
        },
      },
    });
  }

  const handleChangeLanguages = (e) => {
    setLanguages(e.target.value);
    updateSaveSettings({
      variables: {
        data: {
          languages: e.target.value
        },
      },
    });
  }

  const handleChangeInterest = (e) => {
    setInterest(e.target.value);
    updateSaveSettings({
      variables: {
        data: {
          interests: e.target.value
        },
      },
    });
  }

  const handleChangeNewDestination = (e) => {
    setNewDestination(e.target.checked);
    updateSaveSettings({
      variables: {
        data: {
          new_destination: e.target.checked
        },
      },
    });
  }

  const handleChangeLiveEvent = (e) => {
    setLiveEvent(e.target.checked);
    updateSaveSettings({
      variables: {
        data: {
          live_event: e.target.checked
        },
      },
    });
  }

  const handleChangeLiveNews = (e) => {
    setLiveNews(e.target.checked);
    updateSaveSettings({
      variables: {
        data: {
          live_news: e.target.checked
        },
      },
    });
  }

  const handleChangePauseNotification = (e) => {
    setPauseNotification(e.target.checked);
    updateSaveSettings({
      variables: {
        data: {
          pause_notification: e.target.checked
        },
      },
    });
  }

  const handleChangeShowTravellerSplash = (e) => {
    setShowTravellerSplash(e.target.checked);
    updateSaveSettings({
      variables: {
        data: {
          show_traveller_splash: e.target.checked
        },
      },
    });
  }

  const handleChangeShowWalkerSplash = (e) => {
    setShowWalkerSplash(e.target.checked);
    updateSaveSettings({
      variables: {
        data: {
          show_walker_splash: e.target.checked
        },
      },
    });
  }

  const handleSignout = () => {
    userUtils.removeAccessToken();
    apolloClient.resetStore();
    history.push('/');
  };

  const handleTwitter = () => {
    window.open('https://twitter.com/HuvrApp', '_blank');
  }

  const handleInstagram = () => {
    window.open('https://www.instagram.com/huvrapp/', '_blank');
  }

  const handleContactus = () => {
    window.open('https://go.huvr.com/contact-us/', '_blank');
  }

  const handleFaq = () => {
    window.open('https://go.huvr.com/faq/', '_blank');
  }

  const handleWhatsnew = () => {
    window.open('https://go.huvr.com/whats-new/', '_blank');
  }

  const handleCommunityguidelines = () => {
    window.open('https://go.huvr.com/community-guidelines/', '_blank');
  }

  const handleTermsofservices = () => {
    window.open('https://go.huvr.com/terms-of-use/', '_blank');
  }

  const handlePrivacypolicy = () => {
    window.open('https://go.huvr.com/privacy-policy/', '_blank');
  }

  return (
    <div className="traveller-page">
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-row">
        <SubHeader title="Settings" />
        <div className="container">
          <div className="travelle-body">
            <div className="edit-form-row row" style={{ maxWidth: 1200, paddingTop:45 }}>
              {/* <div className="col-sm-12">
                <div className="setting-title">
                    <h4>Notifications</h4>
                  </div>
              </div> */}
              {/* <div className="col-sm-12 col-lg-6">
                <div className="setting-box">
                  <div className="setting-div">
                    <div className="setting-left">
                      Frequency
                    </div>
                    <div className="setting-right">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={frequency}
                        onChange={handleChangeFrequency}
                      >
                        <MenuItem value='0'>Very Frequent</MenuItem>
                        <MenuItem value='1'>Frequent</MenuItem>
                        <MenuItem value='2'>Normal</MenuItem>
                        <MenuItem value='3'>Infrequent</MenuItem>
                        <MenuItem value='4'>Very Infrequent</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="setting-switch">
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          control={<Switch color="primary" checked={newDestination} onChange={handleChangeNewDestination} name="newDestination" />}
                          label="Include New Destinations"
                          labelPlacement="start"
                          disabled={true}
                        />
                        <FormControlLabel
                          control={<Switch color="primary" checked={liveEvent} onChange={handleChangeLiveEvent} name="liveEvent" />}
                          label="Include Live Events"
                          labelPlacement="start"
                          disabled={true}
                        />
                        <FormControlLabel
                          control={<Switch color="primary" checked={liveNews} onChange={handleChangeLiveNews} name="liveNews" />}
                          label="Include Live News"
                          labelPlacement="start"
                          disabled={true}
                        />
                        <FormControlLabel
                          control={<Switch color="primary" checked={pauseNotification} onChange={handleChangePauseNotification} name="pauseNotification" />}
                          label="Pause Notifications"
                          labelPlacement="start"
                          disabled={true}

                        />
                        <FormControlLabel
                          control={<Switch color="primary" checked={showTravellerSplash} onChange={handleChangeShowTravellerSplash} name="showTravellerSplash" />}
                          label="Traveler Splash Screens"
                          labelPlacement="start"
                          disabled={true}

                        />
                        <FormControlLabel
                          control={<Switch color="primary" checked={showWalkerSplash} onChange={handleChangeShowWalkerSplash} name="showWalkerSplash" />}
                          label="Walker Splash Screens"
                          labelPlacement="start"
                          disabled={true}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
                <div className="setting-box">
                  <div className="setting-div">
                    <div className="setting-left">
                      Interests
                    </div>
                  </div>
                  <div className="setting-div w-100">
                    <div className="setting w-100 mt-3">
                      <Select
                        fullWidth
                        multiple
                        value={interest}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {(selected).map((value) => {
                              let obj = interestList.find(o => o.id === value);
                              return (
                                <Chip key={obj.id} label={obj.name} className={classes.chip} />
                              )
                            })}
                          </div>
                        )}
                        onChange={handleChangeInterest}
                      >
                        {interestList.map((val) => {
                          return <MenuItem key={`${val.id}_interest`} value={val.id}>{val.name}</MenuItem>
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-sm-12 col-lg-12">
                <div className="setting-box setting-box2">
                  <div className="setting-div">
                    <div className="setting-left">
                      What&apos;s New
                    </div>
                    <div className="setting-right">
                      <IconButton onClick={handleWhatsnew} >
                        <CallMadeOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div className="setting-div">
                    <div className="setting-left">
                      FAQ
                    </div>
                    <div className="setting-right">
                      <IconButton onClick={handleFaq} >
                        <CallMadeOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className="setting-div">
                    <div className="setting-left">
                      Contact Us
                    </div>
                    <div className="setting-right">
                      <IconButton onClick={handleContactus} >
                        <CallMadeOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className="setting-div">
                    <div className="setting-left">
                      Community Guidlines
                    </div>
                    <div className="setting-right">
                      <IconButton onClick={handleCommunityguidelines} >
                        <CallMadeOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className="setting-div">
                    <div className="setting-left">
                      Terms Of Services
                    </div>
                    <div className="setting-right">
                      <IconButton onClick={handleTermsofservices} >
                        <CallMadeOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className="setting-div">
                    <div className="setting-left">
                      Privacy Policy
                    </div>
                    <div className="setting-right">
                      <IconButton onClick={handlePrivacypolicy}>
                        <CallMadeOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className="setting-div">
                    <div className="setting-left">
                      Connect
                    </div>
                    <div className="setting-right">
                      <Button onClick={handleTwitter}>Twitter</Button>
                    </div>
                  </div>
                  <div className="setting-div">
                    <div className="setting-left">
                      Connect
                    </div>
                    <div className="setting-right">
                      <Button onClick={handleInstagram}>Instagram</Button>
                    </div>
                  </div>
                </div>
                <div className="setting-box">
                  <div className="setting-div">
                    <div className="setting-left">
                      Languages I Speak
                    </div>
                  </div>
                  <div className="setting-div w-100">
                    <div className="setting w-100 mt-3">
                      <Select
                        fullWidth
                        multiple
                        value={languages}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {(selected).map((value) => {
                              let obj = languageList.find(o => o.id === value);
                              return (
                                <Chip key={obj.id} label={obj.name} className={classes.chip} />
                              )
                            })}
                          </div>
                        )}
                        onChange={handleChangeLanguages}
                      >
                        {languageList.map((val) => {
                          return <MenuItem key={`${val.id}_language`} value={val.id}>{val.name}</MenuItem>
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-12">
                <div className="text-center">
                  <button onClick={handleSignout} className="btn btn-yellow" style={{ width: 300 }}>LOGOUT</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Settings.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(Settings);

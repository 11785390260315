import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useMutation, useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import PropTypes from "prop-types";
import { confirmAlert } from 'react-confirm-alert';
import * as commonFunctions from '../../../utilities/commonFunctions';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const USER_REGISTER = loader('./../../../graphql/schema/auth/user-details.graphql');
const VERIFY_EMAIL = loader('./../../../graphql/schema/register/verify-email.graphql');
const MY_DETAILS = loader('./../../../graphql/schema/auth/me.graphql');
const LOGIN = loader('./../../../graphql/schema/auth/login.graphql');
const AMBASSADOR_ACCEPT_INVITATION = loader('./../../../graphql/schema/ambassador/accept-invitation.graphql');
const TRAVELER_WALKER_TOGGLE = loader('./../../../graphql/schema/auth/traveler-walker-toggle.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const RegisterStepTwo = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryKey, setCountryKey] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});
  const [loginLoading, setLoginLoading] = useState(false);
  const [ambassadorId, setAmbassadorId] = useState('');
  const [userId, setUserId] = useState('');

  const [getMyDetails, { data }] = useLazyQuery(MY_DETAILS, {
    fetchPolicy: "no-cache"
  });

  const [acceptInvitation, { acceptLoading }] = useMutation(AMBASSADOR_ACCEPT_INVITATION, {
    errorPolicy: 'ignore',
    onCompleted({
      acceptInvitation
    }) {
      UserUtils.setUserType('A');
      history.push('/');
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body text-center p-5" style={{ maxWidth: 550, borderRadius: 5 }}>
              <h1 className="pb-3">Welcome to Huvr Pro!</h1>
              <p style={{ hyphens: 'none' }}>{ acceptInvitation.message }</p>
                {acceptInvitation.message.includes('verification steps') &&
                    <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                        history.push('/complete-verification-ambassador');
                        onClose();
                    }}>
                        Complete Verification
                    </button>
                }

                {!acceptInvitation.message.includes('verification steps') &&
                    <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                        onClose();
                    }}>
                        Close
                    </button>
                }
            </div>
          );
        },
        overlayClassName: 'header-overlay',
        afterClose: () => {
          UserUtils.removeAmbassadorId();
        }
      });
    }
  });

  const [travelerWalkerToggle, { loading }] = useMutation(TRAVELER_WALKER_TOGGLE, {
    onCompleted({ travelerWalkerToggle: { type } }) {
      apolloClient.writeData({ data: { isWalkerTraveller: type === 'W' } });
    },
  });

  useEffect(() => {
    const phone = UserUtils.getPhoneNumber();
    const country_key = UserUtils.getCountryKey();
    if (phone !== null) {
      setPhoneNumber(phone);
      setCountryKey(country_key);
    } else {
      history.push('/register');
    }
    const ambassador_id = UserUtils.getAmbassadorId();
    if (ambassador_id) {
      setAmbassadorId(ambassador_id);
    }
    if (data && data.me) {
      UserUtils.setUserAvatar(data.me.avatar);
      UserUtils.setUserFirstName(data.me.first_name);
      UserUtils.setUserLastName(data.me.last_name);
      UserUtils.setUserRating(data.me.over_all_rating);
      UserUtils.setUserPhoneCountryKey(data.me.phone_country_key);
    }
    return () => {
      UserUtils.removePhoneNumber();
      UserUtils.removeCountryKey();
    };
  }, [data]);


  const handlePhoneNumber = (e) => {
    setHasError({});
    setPhoneNumber(e.target.value)
  }

  const handleFirstName = (e) => {
    setHasError({});
    setFirstName(e.target.value)
  }

  const handleLastName = (e) => {
    setHasError({});
    setLastName(e.target.value)
  }

  const handleEmail = (e) => {
    setHasError({});
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setHasError({});
    setPassword(e.target.value)
  }

  const handleConfirmPassword = (e) => {
    setHasError({});
    setConfirmPassword(e.target.value)
  }

  const validateField = (value) => {
    let hasError = false;
    if (commonFunctions.isEmpty(value)) {
      hasError = true;
    }
    return hasError;
  }

  const validateForm = () => {
    const minLength = 8;
    let formIsValid = true;
    let errors = {};

    if (validateField(firstName)) {
      errors['firstName'] = 'First Name is required.';
      formIsValid = false;
    }

    if (validateField(lastName)) {
      errors['lastName'] = 'Last Name is required.';
      formIsValid = false;
    }

    if (validateField(phoneNumber)) {
      errors['phoneNumber'] = 'Phone Number is required.';
      formIsValid = false;
    }

    if (validateField(email)) {
      errors['email'] = 'Email is required.';
      formIsValid = false;
    } else if (!commonFunctions.validateEmail(email)) {
      errors['email'] = 'Enter valid a email.';
      formIsValid = false;
    }

    if (validateField(password)) {
      errors['password'] = 'Password is required.';
      formIsValid = false;
    }
    if (!validateField(password) && !commonFunctions.isValidPassword(password)) {
      errors['password'] = 'Invalid password format.';
      formIsValid = false;
    }

    if (validateField(confirmPassword)) {
      errors['confirmPassword'] = 'Confirm Password is required.';
      formIsValid = false;
    }

    if (!validateField(password) && !validateField(confirmPassword) && password !== confirmPassword) {
      errors['confirmPassword'] = 'Must be same Password and Confirm Password.';
      formIsValid = false;
    }

    setHasError(errors);
    return formIsValid;
  };

  const [updateVerifyEmail] = useMutation(VERIFY_EMAIL, {
    onCompleted() {
      if (ambassadorId !== '') {
        acceptInvitation({
          variables: {
            ambassador_id: ambassadorId,
            walker_id: userId
          }
        });
      } else {
        history.push({
          pathname: '/destination-categories',
          state: {
            isNewlyRegister: true
          }
        });
      }

    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setHasError(formatedErrors);
    },
  });

  const postLogin = (resUser) => {
    apolloClient.writeData({ data: { isLoggedIn: true } });
    let error = {};
    if (resUser.status !== 'USER_REGISTERED') {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else {
      setLoginLoading(true);
      setSuccessMessage(resUser.message);

      UserUtils.setAccessToken(resUser.accessToken);
      UserUtils.setUserAvatar(resUser.user.avatar);
      UserUtils.setUserFirstName(resUser.user.first_name);
      UserUtils.setUserLastName(resUser.user.last_name);
      UserUtils.setUserRating(resUser.user.over_all_rating);
      UserUtils.setUserPhoneCountryKey(resUser.user.phone_country_key);
      localStorage.setItem('access_token', resUser.accessToken);
      apolloClient.writeData({ data: { isLoggedIn: true } });
      UserUtils.setUserID(resUser.user.id);
      setUserId(resUser.user.id)
      
      getMyDetails();
      updateVerifyEmail();
    }
  };
  /* eslint-disable no-unused-vars*/
  const [submitLogin] = useMutation(LOGIN, {
    onCompleted({
      login: {
        access_token: accessToken,
        status: status,
        message: message,
        user: user,
      },
    }) {
      postLogin({ accessToken, status, message, user });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const [submitSignUp, { loading: signUpLoading }] = useMutation(USER_REGISTER, {
    onCompleted({
      register: {
        access_token: accessToken,
        message: message,
        status: status,
        user: user
      }
    }) {
      postLogin({ accessToken, message, status, user });
      // setLoginLoading(true);
      // console.log(access_token);
      // if (status === 'USER_REGISTERED') { 
      //   setSuccessMessage(message);
      //   // submitLogin({
      //   //   variables: {
      //   //     data: {
      //   //       "otp": '',
      //   //       "username": phoneNumber,
      //   //       "password": password,
      //   //       "phone_country_key": countryKey,
      //   //       "phone_country_code": "",
      //   //       "time_zone": "",
      //   //       "device_token": ""
      //   //     },
      //   //   },
      //   // });
      // } else {
      //   setLoginLoading(false);
      //   setHasError({'failed': message});
      // }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error['failed'] = formatedErrors;
      setHasError(error);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitSignUp({
        variables: {
          "data": {
            "first_name": firstName,
            "last_name": lastName,
            "phone_number": phoneNumber,
            "phone_country_code": "",
            "phone_country_key": countryKey,
            "email": email,
            "password": password,
            "password_confirmation": confirmPassword,
            "time_zone": "",
            "device_token": ""
          },
        },
      });
    }
  }

  return (
    <>
      <div className="signup-container-row2 profile-details">
        {(signUpLoading || acceptLoading) && <PageLoader loading={signUpLoading || acceptLoading} />}
        <div className="signup-row">
          {/* <p className="modal-close" ><Link to="/"><i className="la la-times" /></Link></p> */}
          <div className="signup-left2">
            <div className="signup-cnt">
              <h4 component="h4">Sign Up</h4>
              <p>Huvr is an app connecting virtual Travelers to human Huvr approved Walkers across the globe. They provide individual or group experiences unlike anything on the market today.</p>
              <Link to="/" className="btn"><i className="fa fa-external-link"></i> Learn More</Link>
            </div>
          </div>
          <div className="signup-right2">
            <div className="signup-form">
              <div className="form-title" style={{ marginBottom: 30}}>
                <h4 component="h4">Profile Details</h4>
              </div>
              <form onSubmit={onSubmit}>
                {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
                <div className="error-box">
                  {hasError.failed !== undefined && 
                    <>
                      <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />
                      <p className="is-invalid">{hasError.failed}</p>
                    </>
                  }
                  {hasError.confirmPassword !== undefined &&
                    <>
                      <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />
                      <p className="is-invalid">{hasError.confirmPassword}</p>
                    </>
                  }
                </div>
                <div className="form-input-box2">
                  <input
                    placeholder="Enter Phone Number"
                    type="text"
                    name="phoneNumber"
                    className={`${hasError.phoneNumber !== undefined ? 'is-invalid' : ''} vl-input`}
                    value={phoneNumber}
                    onChange={handlePhoneNumber}
                    disabled
                  />
                </div>
                <div className="form-input-box2">
                  {/* <label variant="subtitle2" component="label">First Name</label> */}
                  <input
                    placeholder="Enter First Name"
                    type="text"
                    name="firstName"
                    value={firstName}
                    className={`${hasError.firstName !== undefined ? 'is-invalid' : ''} vl-input`}
                    onChange={handleFirstName}
                    disabled={loginLoading}
                  />
                  {/* {hasError.firstName !== undefined && <p className="is-invalid">{hasError.firstName}</p>} */}
                </div>
                <div className="form-input-box2">
                  {/* <label variant="subtitle2" component="label">Last Name</label> */}
                  <input
                    placeholder="Enter Last Name"
                    type="text"
                    name="lastName"
                    className={`${hasError.lastName !== undefined ? 'is-invalid' : ''} vl-input`}
                    value={lastName}
                    onChange={handleLastName}
                    disabled={loginLoading}
                  />
                  {/* {hasError.lastName !== undefined && <p className="is-invalid">{hasError.lastName}</p>} */}
                </div>
                <div className="form-input-box2">
                  {/* <label variant="subtitle2" component="label">Email</label> */}
                  <input
                    placeholder="Enter Email"
                    type="text"
                    name="email"
                    className={`${hasError.email !== undefined ? 'is-invalid' : ''} vl-input`}
                    value={email}
                    onChange={handleEmail}
                    disabled={loginLoading}
                  />
                  {/* {hasError.email !== undefined && <p className="is-invalid">{hasError.email}</p>} */}
                </div>
                <div className="form-input-box2">
                  {/* <label variant="subtitle2" component="label">Password</label> */}
                  <input
                    placeholder="Enter Password"
                    type="password"
                    name="password"
                    className={`${hasError.password !== undefined ? 'is-invalid' : ''} vl-input`}
                    value={password}
                    onChange={handlePassword}
                    disabled={loginLoading}
                  />
                  {/* {hasError.password !== undefined && <p className="is-invalid">{hasError.password}</p>} */}
                </div>
                <div className="form-input-box2">
                  {/* <label variant="subtitle2" component="label">Confirm Password</label> */}
                  <input
                    placeholder="Confirm Password"
                    type="password"
                    className={`${hasError.confirmPassword !== undefined ? 'is-invalid' : ''} vl-input`}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    disabled={loginLoading}
                  />
                </div>
                <div className="form-btn">
                  <button type="submit" color="inherit" disabled={loginLoading} style={loginLoading ? { opacity: 0.5 } : {}}>
                    {loginLoading ? 'Logging In...' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

RegisterStepTwo.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(RegisterStepTwo);
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import $ from 'jquery';
import UserUtils from '../../utilities/UserUtils';
import DownloadLink from '../../components/layout/download-link';
import { loader } from 'graphql.macro';
import DOMPurify from "dompurify";
import PageLoader from '../../components/layout/ui/page-loader';
import moment from 'moment-timezone';
import SubHeader from '../../components/layout/sub-header';
import { QueryBuilder, WatchLater, CheckCircle, CancelRounded, ErrorRounded } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';


import { Dialog, DialogActions, DialogContent, Modal } from '@material-ui/core';




const GET_ADS = loader('../../graphql/schema/ads/get-ads.graphql');
const VERIFY_VIDEO_CALL_AMBASSADOR = loader('../../graphql/schema/traveller/verify-video-call-ambassador-antmedia.graphql');
const UPCOMING_WALKS = loader('../../graphql/schema/traveller/traveller-upcomingWalks.graphql');
const GET_SAVE_SCHEDULE_TRIP_AMBASSADOR = loader('../../graphql/schema/walker/save-schedule-trip-ambassador.graphql');


const publicUrl = process.env.PUBLIC_URL + '/';

const SelectStation = (props) => {

    const { history, location } = props;
    const [destination, setDestination] = useState();
    const [category, setCategory] = useState(null);
    const [adList, setAdList] = useState({});
    const [modalAd, setModalAd] = useState({});
    const [selected, setSelected] = useState(null);
    const [adsLoading, setAdsLoading] = useState(false);
    const [inputStreamCode, setInputStreamCode] = useState('');
    const [loading, setLoading] = useState('');


    const [isLoading, setIsLoading] = useState(false);
    const [userType, setUserType] = useState(false);
    const [serverTime, setServerTime] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [scheduleTripId, setScheduleTripId] = useState('');
    const [openWalkerDetails, onOpenWalkerDetails] = useState(false);
    const [upComingTrips, setUpComingTrips] = useState([]);
    const [scheduleTrip, setScheduleTrip] = useState(location.state && location.state.scheduleTrip);
    const [selectedWalker, setSelectedWalker] = useState(false);
    const [tripRatePopUp, setTripRatePopUp] = useState(false);
    const [bookingId, setBookingId] = useState('');

    const [closeScheduleTrip, setCloseScheduleTrip] = useState(false);
    const [closeScheduleTripAmbassador, setCloseScheduleTripAmbassador] = useState(false);
    const [closeScheduleTripTimer, setCloseScheduleTripTimer] = useState(false);


    const [showOtherScheduleTrip, setShowOtherScheduleTrip] = useState(false);
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [registerModal, setRegisterModal] = useState(false);
    const [tripIndex, setTripIndex] = useState(false);



    const AD_TYPE = 'web_app_select_station';

    useEffect(() => {
        const user_type = localStorage.getItem('huvr::user_type');
        setUserType(user_type)

        if (history.location.state && history.location.state.destination) {
            setDestination(history.location.state.destination);
        }

        if (history.location.state && history.location.state.category) {
            setCategory(history.location.state.category);
            setAdsLoading(true);
            getAds();
        }
    }, [])

    const [getAds, { data: adsData, loading: adsLoader }] = useLazyQuery(GET_ADS, {
        variables: {
            ad_page: AD_TYPE,
            ad_category: category
        },
        fetchPolicy: 'no-cache',
        onCompleted() {
            setAdsLoading(false);

            // console.log('adsData ==>', adsData);

            if (selected) {
                let modalAdlist = modalAd;
                let popupAd = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'popup' && item.ad_location_lookup.ad_value == 'bottom');

                if (popupAd.length) {
                    popupAd.forEach(item => {
                        modalAdlist[`s${item.ad_station}`] = DOMPurify.sanitize(item.ad_content.replace('></a>', '/></a>'));
                    });
                    setModalAd(modalAdlist);
                }

                openPopUp(selected);
                setSelected(null);
            } else {
                let list = adList;
                let inlineAds = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'inline' && item.ad_location_lookup.ad_value == 'center');
                inlineAds.forEach(item => {
                    list[`s${item.ad_station}`] = DOMPurify.sanitize(item.ad_content.replace('></a>', '/></a>'));
                });

                setAdList(list);

                // console.log('list ==> ', adList);
            }
        }
    });



    const onItemClick = (trip, trip_id, booking_id, index) => {
        setSelected({ ...trip, index });
        setScheduleTripId(trip_id);
        setAdsLoading(true);
        getAds();
        setBookingId(booking_id);
    }

    const openPopUp = (trip) => {
        let modal = $(`#modal-${trip.index}`);
        let backdrop = $('#backdrop');

        backdrop.addClass('modal-backdrop fade show');
        modal.addClass('show');
        modal.css('display', 'block');
    }

    const closePopUp = (trip) => {
        let modal = $(`#modal-${trip.index}`);
        let backdrop = $('#backdrop');

        setSelected(null);

        backdrop.removeClass('modal-backdrop fade show');
        modal.removeClass('show');
        modal.css('display', 'none');
        setMessage('');
        setInputStreamCode('');
    }


    const handleStartTrips = (trip) => {
        // if (userType === "A") {
        setIsLoading(true);
        props.callback('modal', true);

        let verifyVideoCall = {
            variables: {
                schedule_trip_id: scheduleTripId,
                stream_code: inputStreamCode
            }
        };
        if (bookingId) {
            verifyVideoCall.variables.booking_id = bookingId;
        }
        verifyVideoCallAmbassador(verifyVideoCall);
        setTripIndex(trip)
        closePopUp(trip);



    }


    const { upcomingtrips, loading: upcomingTriploading, refetch } = useQuery(UPCOMING_WALKS, {
        variables: { "pageNumdata": 1, "first": 100 },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted({ getTravelerUpcomingTrips }) {
            setIsLoading(false);
            props.callback('modal', false);
            if (getTravelerUpcomingTrips && getTravelerUpcomingTrips.data.length) {
                setUpComingTrips(getTravelerUpcomingTrips);
                if (scheduleTrip && isAmbassador) {
                    const booked = getTravelerUpcomingTrips.data.some(loc => loc.destination.name === scheduleTrip.locationName
                        && loc.schedule_date === scheduleTrip.date && loc.schedule_time === scheduleTrip.time);
                    // console.log('booked', booked);
                    setBookedToAmbassador(booked);

                    if (!booked) {
                        setTimeout(() => {
                            setCloseScheduleTripAmbassador(true);
                        }, 1000);
                    } else if (booked && serverTime) {
                        setCloseScheduleTripTimer(true);
                    }
                }
            } else {
                setCloseScheduleTripAmbassador(true);
            }
        }
    });




    const [verifyVideoCallAmbassador] = useMutation(VERIFY_VIDEO_CALL_AMBASSADOR, {
        onCompleted({
            verifyVideoCallAmbassador: {
                status,
                message,
                session_id,
                token_id,
                booking_id,
                walker_id,
                is_re_join,
                time_zone,
                wss,
                tripe_minute,
                stream_id,
            }
        }) {
            setStatus(status)
            if (status === 'SUCCESS') {
                setIsLoading(false);
                props.callback('modal', false);
                history.push('/video-call-ambassador', {
                    roomID: session_id,
                    securityToken: token_id,
                    userType: 'traveller',
                    scheduleTripId: scheduleTrip,
                    bookingId: booking_id,
                    walkerId: walker_id,
                    isReJoin: is_re_join,
                    timeZone: time_zone,
                    wss: wss,
                    tripMinutes: tripe_minute,
                    streamId: stream_id
                });

            }
            if (status === 'PENDING') {
                setServerTime(time_zone);
                refetch();
                onOpenWalkerDetails(false);
                setIsLoading(false);
                if (!bookingId) {
                    setTitle('Successfully Booked');
                    setMessage("You will be notified 5 minutes before the \n start the tour");
                    setStatus('SUCCESS')
                } else {
                    setTitle("PENDING");
                    setMessage("The schedule trip is not started yet.");
                    setStatus('PENDING')

                }
                setShowMessage(true);
            }

            if (status == 'FULL') {
                setStatus('FULL')
                setTitle("");
                setMessage(message);
                setIsLoading(false);
                setShowMessage(true);
            }

            if (status == 'INVALID_STREAM_CODE') {
                setTitle("Booking Failed");
                setMessage("Invalid stream code!");
                setIsLoading(false);
                setShowMessage(true);
            }
        }
    });

    const closeDialogModal = () => {
        setIsLoading(false);
        setShowMessage(false)
        if (status === 'SUCCESS') {
            history.push('/your-trips');
        }
    }

    const openRegisterModal = (trip) => {
        setRegisterModal(true)
        closePopUp(trip)
    }

    const closeRegisterModal = (trip) => {
        setRegisterModal(false)
        closePopUp(trip)
        history.push('/your-trips');
    }


    return (
        <>
            {adsLoading && <PageLoader loading={upcomingTriploading} />}
            <div className="traveller-pagev2" id="destinationsTab">
                <div className="traveller-row">
                    <SubHeader title="All Destinations" />
                    <div className="container">
                        <div className='inner-menuv2'>
                            <Link to="/destination-categories" className='prev-nav'>Destination Categories</Link> / <a className='current-nav'>Select Schedule trips</a>
                        </div>
                        <div className="destination-body-new">
                            <div className="destination-body " style={{ borderRadius: 10, boxShadow: "unset" }}>
                                {destination && destination.schedule_trips.length > 0 && destination.schedule_trips.map((trip, index) => {
                                    let image = destination.image ? destination.image : publicUrl + "assets/images/destination-list/1.png";
                                    if (trip.station.image) {
                                        image = trip.station.image
                                    }
                                    let booking_id = trip.booking && trip.booking.id ? trip.booking.id : '';

                                    return (
                                        <div className="destination-box destination-box-schedule-trip " key={index} style={{ padding: 15, cursor: 'pointer' }} id={`s-${trip.station.id}`} onClick={() => onItemClick(trip.station, trip.id, booking_id, index)} >
                                            <div className="box-left" >
                                                <figure>
                                                    <img alt="" src={image} style={{ maxWidth: 90, width: 93, height: 93 }} />
                                                </figure>
                                            </div>
                                            <div className="box-center" >
                                                <div className="d-flex align-items-center flex-wrap">
                                                    {trip.status === 4 && (
                                                        <p className='d-flex font-weight-bold align-items-center trip-status-danger'>
                                                            <div className='live-trip-dot-white' style={{ marginTop: 1, fontSize: 16 }}></div>
                                                            LIVE NOW
                                                        </p>
                                                    )}
                                                    {(trip.status === 0 || trip.status === 1) && (
                                                        <p className="trip-status-warning">
                                                            <span style={{ fontSize: 16 }}> <QueryBuilder style={{ color: "white", fontSize: 16, marginBottom: -2 }} /> Scheduled </span>
                                                        </p>
                                                    )}
                                                    <p className="trip-date-gray" style={{ color: "#6F6F6F", fontSize: 16 }}>
                                                        <span style={{ color: "#6F6F6F", fontSize: 16 }}> <WatchLater style={{ color: "#6F6F6F", fontSize: 16, marginBottom: -2 }} /> {trip.schedule_minute} min - {moment(`${trip.schedule_date} ${trip.schedule_time}`).format('ddd, MMM Do, h:mm A')}</span>
                                                    </p>
                                                </div>
                                                <h4 style={{ size: 32, fontWeight: 400 }}>{trip.name}</h4>
                                                <p style={{ color: "#6F6F6F" }}>{trip.destination.name}, {trip.station.name}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* popup start here */}
            {destination && destination.schedule_trips.map((trip, index) => {
                let banner = destination.image ? destination.image : publicUrl + "assets/images/destination-list/1.png";
                if (trip.station.banner) {
                    banner = trip.station.banner
                } else if (trip.station.image) {
                    banner = trip.station.image
                } else if (destination.banner) {
                    banner = destination.banner
                }
                return (
                    <div key={index} className="modal fade destination-body-popup popupv2" id={`modal-${index}`}>
                        <div className="modal-dialog modal-dialog-centered modal-lg" style={{ maxWidth: 630 }}>
                            <div className="modal-content">
                                <div className="popup-scroll">
                                    <button type="button" className="pop-close-btn" onClick={() => closePopUp({ ...trip.station, index })}><i className="la la-times"></i></button>
                                    <figure className="destination-img">
                                        <img
                                            className="select-dest-img-modal w-100"
                                            alt={trip.name}
                                            src={banner}
                                            style={{ height: 358 }}
                                        />
                                    </figure>
                                    <div className="payment-popup-cnt" style={{ paddingBottom: "unset" }}>
                                        <div className="container">
                                            <p className="modal-welcome-text"> Welcome To</p>
                                            <h4 className="modal-trip-name">{trip.name}</h4>
                                            <p>{trip.destination.name}, {trip.station.name}</p>
                                            <hr />
                                            <p>{trip.description}</p>
                                            <br />
                                            {/* {message && <p className='text-danger'>*<b>{message}</b></p>} */}
                                            {(trip.publish && trip.booking === null && trip.status === 4) && (
                                                <>
                                                    <input type="text" className="form-control" placeholder="Enter Stream Code"
                                                        style={{ textAlign: "center", display: "unset", width: "40%", borderRadius: 20 }}
                                                        value={inputStreamCode}
                                                        onChange={({ target }) => setInputStreamCode(target.value)}
                                                    />
                                                </>

                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="default-popup-btn">
                                    {/* Check if public or not */}
                                    {trip.publish ? (
                                        // Check if stream started or not
                                        (bookingId || trip.status === 4) ? (
                                            <button
                                                className="btn btn-yellow"
                                                onClick={() => handleStartTrips({ ...trip.station, index })}
                                            >
                                                Join Now
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-yellow"
                                                // onClick={() => handleStartTrips()}
                                                onClick={() => openRegisterModal({ ...trip.station, index })}
                                            >
                                                Huvr There Now
                                            </button>
                                        )
                                    ) : (
                                        <button
                                            className="btn btn-yellow"
                                            onClick={() => handleStartTrips({ ...trip.station, index })}
                                        >
                                            {trip.status === 4 ? "Join Now" : "Book Now"}
                                        </button>
                                    )}
                                </div>
                                {modalAd[`s${trip.station.id}`] && <div dangerouslySetInnerHTML={{ __html: modalAd[`s${trip.station.id}`] }}></div>}
                            </div>
                        </div>
                    </div>
                )
            })}

            <div id="backdrop"></div>

            <Dialog aria-labelledby="customized-dialog-title" open={showMessage} >
                <DialogContent>
                    <div className="schedule-popup-div">

                        {status === 'SUCCESS' && (
                            <>
                                <CheckCircle style={{ fontSize: 50, color: "#A6E06A" }} />
                                <h3 className='text-blue'>{title}</h3>
                            </>

                        )}

                        {status === 'INVALID_STREAM_CODE' && (
                            <>
                                <CancelRounded className='text-danger' style={{ fontSize: 50 }} />
                                <h3 className='text-danger'>{title}</h3>
                            </>

                        )}

                        {status === 'PENDING' && (
                            <>
                                <ErrorRounded className='text-yellow' style={{ fontSize: 50 }} />
                                <h3 className='text-warning'>{title}</h3>
                            </>

                        )}
                        <button type="button" className="pop-close-btn" onClick={() => setShowMessage()}><i className="la la-times"></i></button>
                        <p>{message}</p>
                        <hr />
                        <div className="select-popup-btn">
                            <button className="btn btn-yellow" onClick={() => closeDialogModal()}>Close</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={isLoading} maxWidth="xs" style={{ zIndex: 999999 }}>
                <div className="walker-map-popup">
                    <DialogContent
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 20, height: 100, overflow: 'hidden' }}>
                        <h6 style={{ marginBottom: 20, }}>{loading}</h6>
                        <CircularProgress />
                    </DialogContent>
                </div>
            </Dialog>

            <Dialog aria-labelledby="customized-dialog-title" open={registerModal} >
                <div className="text-center p-2" style={{ maxWidth: 400 }}>
                    <DialogContent style={{ maxHeight: "calc(-87px + 100vh)", overflowY: "auto" }}>
                        <button type="button" className="pop-close-btn" onClick={() => setRegisterModal(false)}><i className="la la-times"></i></button>
                        <div className="row">
                            <div className="col-md-12" >
                                <h4 className="text-title-navy-blue">Register for</h4>
                                <small className="text-sub-title">a virtual trip to</small>
                                <p>SM - Mall of Asia</p>
                                <hr />
                                <p className="text-title text-blue">DESTINATION TIMEZONE</p>
                                <p className="text-title text-blue">Test Station MOA</p>
                            </div>

                            <div className="col-md-12" style={{ background: "#071D55", color: "white" }}>
                                <p className="text-white">Thursday, Jun 6</p>
                                <h4 className="text-white">02:17 PM</h4>
                                <small className="text-white">Phillippine Standard Time</small>

                            </div>
                            <div className="col-md-12 ">
                                <br />
                                <h5 className="text-title">Time: 11 minutes</h5>
                                <hr />
                                <p className='text-title text-blue'>YOUR TIMEZONE</p>
                            </div>
                            <div className="col-md-12" style={{ background: "#071D55", color: "white" }}>
                                <p className="text-white">Thursday, Jun 6</p>
                                <h4 className="text-white">02:17 PM</h4>
                                <p className="text-white">Phillippine Standard Time</p  >
                            </div>

                            <div className="col-md-12" >
                                <hr />
                                <input type="text" className="form-control" placeholder="Enter Stream Code"
                                    style={{ textAlign: "center", display: "unset", width: "100%", borderRadius: 20 }}
                                    value={inputStreamCode}
                                    onChange={({ target }) => setInputStreamCode(target.value)}
                                />
                            </div>
                            <div className="col-md-12">
                                <br />
                                <button
                                    className="btn btn-yellow"
                                    // onClick={() => handleStartTrips()}
                                    style={{ borderRadius: 22, width: 208 }}
                                    onClick={() => handleStartTrips(tripIndex)}
                                >
                                    Book Now
                                </button>

                            </div>
                            <br />
                            <br />
                            <br />
                            <div className="col-md-12">
                                <h4>Reminder</h4>
                                <p>You will be notified 5 minutes before
                                    the start of the trip.</p >
                            </div>



                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )


}

SelectStation.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);





export default enhance(SelectStation);

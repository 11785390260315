import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LazyImage from '../../utilities/lazy-image';
import ScrollIntoView from 'react-scroll-into-view';
import * as commonFunction from '../../utilities/commonFunctions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Divider, Slide } from '@material-ui/core';
import { detect } from 'detect-browser';
import { useCookies } from 'react-cookie';
import userUtils from '../../utilities/UserUtils';

const publicUrl = process.env.PUBLIC_URL + '/';
const disableSafari = process.env.REACT_APP_DISABLE_SAFARI === 'true';
const WALKER_TRAVELER_TOGGLE = loader('../../graphql/schema/auth/walker-traveler-toggle.graphql');

const useStyles = makeStyles((theme) => ({
	link: {
		color: 'rgba(255, 255, 255, 0.75)',
		cursor: 'pointer',
		'&:hover': {
			color: 'var(--main-color-one)'
		}
	},
	cookies: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		width: '100%',
		backgroundColor: 'var(--main-color-one)',
		zIndex: 98,
		fontSize: 13,
		boxShadow: '0px 0px 20px rgb(0 0 0 / 40%)',
		'&> div': {
			minHeight: 115,
			padding: '15px 0px',
		},
		'& p': {
			marginBottom: 10,
		},
		'& .btn': {
			width: 180,
			padding: '0.25rem 0.5rem !important',
			fontSize: '.875rem',
			lineHeight: '1.5 !important',
			borderRadius: '0.2rem !important',
			height: '40px !important',
		}
	}
}));

const FooterV2 = (props) => {
	
	const { history } = props;
    const classes = useStyles();
	const browser = detect();
	const [showCookies, setShowCookies] = useState(false);
	const [cookies, setCookie] = useCookies(['unsupported_browser']);
	const [isAmbassadorOrWalker, setIsAmbassadorOrWalker] = useState(false);

	const { data: toggleMode } = useQuery(WALKER_TRAVELER_TOGGLE);
  
	useEffect(() => {
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";
		document.body.appendChild(minscript);
		
		if (disableSafari && (browser.name === 'safari'  && cookies && !cookies.unsupported_browser)) {
			setTimeout(() => {
				setShowCookies(true);
			}, 2000);
		}

		var isWalkerOrAmbassador = userUtils.getUserType() == 'W' || userUtils.getUserType() == 'A';
		setIsAmbassadorOrWalker(isWalkerOrAmbassador);
	}, []);

	const homeHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#home").offset().top - 100
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	}

	const aboutHuvrHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#about-huvr").offset().top - 100
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const blogHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".blog-area").offset().top - 160
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const handleLoggedInUserDestination = (e) => {
		e.preventDefault();
		if (!commonFunction.isLogin() || !isAmbassadorOrWalker ) {
			history.push('/destination-categories');
		} else {
			const pageName = props.location.pathname;
			if (pageName !== '/') {
				$('#loader-overlay').css({ "visibility": "visible" });
				history.push('/');
				setTimeout(function () {
					$('html, body').animate({
						scrollTop: $(".destination-carousel").offset().top - 160
					}, 0);
				}, 500);
				setTimeout(function () {
					$('#loader-overlay').css({ "visibility": "hidden" });
				}, 2000);
			}
		}
	};

	const handleCookies = () => {
		setShowCookies(false);
		setCookie('unsupported_browser', true, { path: '/' });
	}

	return (
		<footer className="footer-areav2" style={{ background: '#143971'}}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="footer-widget widget">
							<div className="about_us_widget">
								<Link to="/" className="footer-logo">
									<LazyImage src={publicUrl + "assets/images/footer-logo.png"} alt="footer logo" />
								</Link>
                                <Divider style={{ backgroundColor: '#fff', height: 1.5, marginBottom : 25}}/>
							</div>
						</div>
					</div>
				</div>
                <div className="row">
					<div className="col-md-6">
                        <p className="footer-paragraph" style={{ color: '#fff', fontWeight: 400 }}>We’ve created the World’s first Real-Time Virtual <br/>Services Platform</p>
                    </div>
                    <div className="col-md-6">
                        <div className="traveller-head-right">
                            <p className='dl-label'>Real time virtual travel at a click. &nbsp;
                                <span className="app-icon">
                                    <img className='dl-icons' onClick={() => handleDeepLink('ios')} src={publicUrl + "assets/images/inner-page/apple-store-icon.png"} />&nbsp;
                                    <img className='dl-icons' onClick={() => handleDeepLink('android')} src={publicUrl + "assets/images/inner-page/play-store-icon.png"} />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
			</div>
			<Slide direction="up" in={showCookies}>
				<div className={classes.cookies}>
					<div className="container">
						<p>The Huvr Web Application currently does not support traveling to destinations in the Safari browser, we apologize for any inconvenience. Please use an alternate browser like Google Chrome or download the Huvr mobile application for iOS and Android as we work to quickly resolve this temporary issue with Safari.</p>
						<button className="btn btn-blue btn-sm" onClick={handleCookies}>I Understand</button>
					</div>
				</div>
			</Slide>
		</footer>
	)
}

export default FooterV2;

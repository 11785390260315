import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { SnackbarProvider } from 'notistack';
import client from './apollo/apollo-client';
import Router from './router';
import { messaging } from './utilities/firebase';
import { getToken } from 'firebase/messaging';
import { CookiesProvider } from 'react-cookie';
import UserUtils from './utilities/UserUtils';

getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_KEY }).then((currentToken) => {
  if (currentToken) {
    localStorage.setItem('device_token', currentToken);
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});

function App() {
  useEffect(() => {
    const handleLoad = () => {
        UserUtils.setClosedAds('[]');
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <ApolloProvider client={ client }>
      <SnackbarProvider maxSnack={ 3 }>
        <CookiesProvider>
          <Router/>
        </CookiesProvider>
      </SnackbarProvider>
    </ApolloProvider>
  );
}

export default App;

import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import PageLoader from '../../components/layout/ui/page-loader';
import SubHeader from '../../components/layout/sub-header';
import * as commonFunctions from '../../utilities/commonFunctions';
import Rating from 'react-rating';
import AdPopup from '../../utilities/ad-popup';
import AdBanner from '../../utilities/ad-banner';
import UserUtils from '../../utilities/UserUtils';

const YOUR_TRIPS = loader('../../graphql/schema/traveller/your-trips.graphql');
const UPCOMING_WALKS = loader('../../graphql/schema/traveller/traveller-upcomingWalks.graphql');
const CANCELLED_TRIP = loader('../../graphql/schema/walker/walker-cancelled-schedule-trip.graphql');
const GET_ADS = loader('../../graphql/schema/ads/get-ads.graphql');

const GroupTrips = (props) => {
  const { history, location } = props;

  const [archivedTrip, setArchivedTrips] = useState('');
  const [showNoVideoUrlAlert, setNoVideoUrlAlert] = useState(false);
  const [upcomingTrip, setUpcomingTrips] = useState('');
  const [showCancelledTrip, setShowCancelledTrip] = useState(false);
  const [messageCancelledTrip, setMessageCancelledTrip] = useState('');
  const [active, setActive] = useState('upcomingTrip');
  const [playVideo, setPlayVideo] = useState(false);
  const [video, setVideo] = useState('');
  const [showAd, setShowAd] = useState(false);
  const [ads, setAds] = useState([]);
  const [popupAd, setPopupAd] = useState(null);
  const [headerAd, setHeaderAd] = useState(null);
  const AD_TYPE = 'web_app_your_trips';
  const publicUrl = process.env.PUBLIC_URL + '/';

  const { data: adsData } = useQuery(GET_ADS, {
    variables: { ad_user: UserUtils.getUserID(), ad_page: AD_TYPE },
    fetchPolicy: 'no-cache',
    onCompleted() {
      console.log('data ===> ', adsData);
      let popup = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'popup');
      let header = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'banner' && item.ad_location_lookup.ad_value == 'top');

      if(popup.length) {
        setPopupAd(popup[0]);
      }

      if(popup.length) {
        setHeaderAd(header[0]);
      }
    }
  });

  const { loading: loadingPastTrips, refetch: refetchPastTrips } = useQuery(YOUR_TRIPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted({ archivedTrips }) {
      setArchivedTrips(archivedTrips.slice(0, 3));
    }
  });

  const { loading: myUpcomingTripLoading, refetch: refetchUpcomingTrips } = useQuery(UPCOMING_WALKS, {
    variables: { "pageNumdata": 1, "first": 100 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted({ getTravelerUpcomingTrips }) {
      if (getTravelerUpcomingTrips && getTravelerUpcomingTrips.data) {
        setUpcomingTrips(getTravelerUpcomingTrips.data);
      }
    }
  });

  const [cancelledTrip, { loading: loadingCancelledTrip }] = useMutation(CANCELLED_TRIP, {
    onCompleted({
      cancelledScheduleTrip: {
        message,
        status
      }
    }) {
      refetchUpcomingTrips();
      setShowCancelledTrip(true);
      setMessageCancelledTrip(message);
    }
  });

  useEffect(() => {
    if (location.state && location.state.upcoming_trip) {
      setActive('upcomingTrip');
    }
  });

  const handleSubscribe = () => {
    window.scrollTo(0, 0);
    history.push('/membership');
  }

  const onCancelledScheduleTrip = (value) => {
    cancelledTrip({
      variables: {
        data: {
          cancelled_user_type: 'T',
          schedule_id: value.id,
          is_pro_schedule_trip: value.is_pro_schedule_trip,
        }
      }
    });
  }

  const handleCancelledTrips = () => {
    history.push('/traveller-cancelled-trips');
  }

  const handleVideoPlay = (value) => {
    setVideo(value);
    setPlayVideo(true);
  }

  return (
    <>
      <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Group Trips" />
          <div className='inner-menu'>
            {/* <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Group Trips</a> */}
          </div>
          <div id="gtrip-wrapper" className="container">
            {/* {headerAd && <AdBanner type={AD_TYPE} content={headerAd} /> } */}
            <div className="gtrips-tab-row">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a className={`nav-item nav-link ${active === 'upcomingTrip' && 'active'}`} data-toggle="tab" href="#upcoming-trip" role="tab" aria-controls="upcoming-trip" aria-selected="true" onClick={() => refetchUpcomingTrips()}>Upcoming Trips</a>
                  <a className={`nav-item nav-link ${active === 'pastTrip' && 'active'}`} data-toggle="tab" href="#past-trip" role="tab" aria-controls="past-trip" aria-selected="true" onClick={() => refetchPastTrips()}>Past Trips</a>
                  <a style={{ marginLeft: 'auto'}} className='nav-item nav-link canceled-trip-tab' onClick={handleCancelledTrips}>Cancelled Trips</a>
                </div>
              </nav>
              <div className="tab-content tab-content-new" id="nav-tabContent">
                <div id="past-trip" className={`tab-pane fade show ${active === 'pastTrip' && 'active'}`}>
                  {loadingPastTrips ?
                    <p className="no-data"><CircularProgress /></p>
                    :
                    <div className="subscribe-btn">
                      {archivedTrip.length > 0 ? archivedTrip.map((d, index) => {
                        return (
                          <div className="panel-row pd-top-30" key={index}>
                            <div className="panel-head">
                              <div className="panel-head-left">
                                <p className="trip-date mb-2">{moment(d.created_at).format('LLLL')}</p>
                                <h6>{d.destination.name}</h6>
                                <div className="list-rating-row">
                                  <div className="price-rate">
                                    <span><i className="fa fa-dollar"></i>{d.total_trip_price || 0}</span>
                                  </div>
                                  <div className="list-rating">
                                    <Rating
                                      emptySymbol="fa fa-star-o fa-2x"
                                      fullSymbol="fa fa-star fa-2x "
                                      initialRating={`${d.ratings === null ? 0 : d.ratings.rating}`}
                                      readonly
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="panel-head-right">
                                <div className="list-rating-flex">
                                  <div className="trip-price-btn">
                                    <img className='arrow-right' src={publicUrl + "assets/images/inner-page/arrow-right-filled.png"} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="video-popup-wrap trip-video">
                              <div className="thumb">
                                <img src={d.destination.image} alt="video" />
                              </div>
                              {d.video_url === null || !d.is_video_processed ?
                                <div className="video-popup-btn">
                                  <a onClick={() => setNoVideoUrlAlert(true)} className="video-play-btn" style={{color: 'white'}}>
                                    <i className="fa fa-play" />
                                  </a>
                                </div>
                                :
                                <div className="video-popup-btn">
                                  <a data-video={d.video_url} className="video-play-btn"  style={{color: 'white'}} onClick={() => handleVideoPlay(d.video_url)}>
                                    <i className="fa fa-play" />
                                  </a>
                                </div>
                              }
                            </div>
                          </div>
                        )
                      }) :
                        <p className="no-data">No Past Trip.</p>
                      }
                    </div>
                  }
                  {/* <div className="subscribe-btn">
                    <button className="btn btn-yellow" style={{ minWidth: 300 }} onClick={() => handleSubscribe()}>Subscribe today to Save More Trips</button>
                  </div> */}
                </div>
                <div id="upcoming-trip" className={`tab-pane fade show ${active === 'upcomingTrip' && 'active'}`}>
                  {myUpcomingTripLoading || loadingCancelledTrip ?
                    <p className="no-data"><CircularProgress /></p>
                    :
                    <div className="subscribe-btn">
                      {upcomingTrip.length > 0 ? upcomingTrip.map((d, index) => {
                        return (
                          <div className="panel-row" key={`upcoming_${index}`}>
                            <div className="panel-head flex-wrap">
                              <div className="panel-head-left text-left">
                                <div className="d-flex align-items-center flex-wrap">
                                  <p className={`mb-2 ${d.status ? 'trip-status-success' : 'trip-status-pending'}`}>
                                    {d.status ? 'Accepted' : 'Pending' }
                                  </p>
                                  <p className="trip-date mb-2">{moment(`${d.schedule_date} ${d.schedule_time}`).tz(d.time_zone).format('LLLL')}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <h6 className='trip-destination'>{d.destination.name}</h6>
                                </div>
                                <div className="d-flex align-items-center flex-wrap">
                                  {d.station &&
                                    <p className="mb-2">{d.station.name}</p>
                                  }
                                  <p className="time mb-2 ml-3"><img src={`${publicUrl}assets/images/inner-page/clock-icon.png`} /> {moment(moment(`${d.schedule_date} ${d.schedule_time}`).tz(d.time_zone)).fromNow()}</p>
                                </div>
                              </div>
                              <div className="panel-head-right">
                                <div className="list-rating-flex">
                                  <div className="list-rating-row">
                                    <div className="list-rating">
                                    </div>
                                  </div>
                                  <div className="trip-cancel-btn">
                                    <button className="btn btn-danger" onClick={() => onCancelledScheduleTrip(d)}>Cancel</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) :
                        <p className="no-data">No Upcoming Schedule Trip.</p>
                      }
                    </div>
                  }
                  {/* <div className="subscribe-btn">
                    <button className="btn btn-yellow" style={{ minWidth: 300 }} onClick={handleCancelledTrips}>Cancelled Trips</button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog aria-labelledby="customized-dialog-title" open={showNoVideoUrlAlert}>
        <DialogContent>
          <div className="schedule-popup-div">
            <h3>This Video is Processing!</h3>
            <p>Your video could take 30+ minutes to process. Please check back soon.</p>
            <div className="select-popup-btn">
              <button className="btn btn-yellow" onClick={() => setNoVideoUrlAlert(false)}>I Understand</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog aria-labelledby="customized-dialog-title" open={showCancelledTrip}>
        <DialogContent>
          <div className="schedule-popup-div">
            <p>{messageCancelledTrip}</p>
            <div className="select-popup-btn">
              <button className="btn btn-yellow" onClick={() => setShowCancelledTrip(false)}>Okay</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={playVideo} maxWidth="xl" 
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
          <div className="">
              <div className="walker-close">
                  <i className="la la-times" onClick={() => setPlayVideo(false)} style={{color: 'white'}}></i>
              </div>
              <DialogContent >
                    <video preload="false" autoplay id="your-trip-play" controls controlsList="nodownload" style={{width: 900}}>
                      <source src={video} type="video/mp4"></source>
                    </video>
              </DialogContent>
          </div>
      </Dialog>
      { popupAd && <AdPopup type={AD_TYPE} content={popupAd} /> }
    </>
  )
}

GroupTrips.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(GroupTrips)

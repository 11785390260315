import React from 'react';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';

const SendGiftCard = () => {
 
  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Send Gift Card" />
          <div className="container">
            <div className="travelle-body">
              <figure className="coming-soon">
                <img src={publicUrl + "assets/images/inner-page/comingSoon.png"} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SendGiftCard;

import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import LazyImage from '../../utilities/lazy-image';

const awsS3url = process.env.REACT_APP_AWS_S3_URL;
const BLOGS_DATA = loader('../../graphql/schema/dashboard/blog.graphql');

const BlogSection = (props) => {

  const { history } = props;
  const [blogs, setBlogs] = useState([]);

  const { data: blogData } = useQuery(BLOGS_DATA, {
    variables: { "first": 5, "page": 1 },
    onCompleted() {
      setBlogs(blogData.blog.data.map((blog, i) => blog));
    }
  });

  const handleThumbnail = (divIndex) => {
    const thumbnail = blogs[divIndex].thumbnail;
    return thumbnail ? awsS3url + thumbnail : awsS3url + "blog-thumbnail_1635467816.jpeg";
  }

  const handleAuthor = (divIndex) => {
    const author = blogs[divIndex].written_by;
    return author ? author : "anonymous";
  }

  const handleWrittenDate = (divIndex) => {
    const date = blogs[divIndex].written_date;
    if (date) {
      return new Date(date).toDateString();
    }
    return "---";
  }

  const handleTitle = (divIndex) => {
    const title = blogs[divIndex].title;
    return title ? title : "---";
  }

  const handleContent = (divIndex) => {
    const description = blogs[divIndex].description;
    return description? description : "---";
  }

  const redirectToBlogPage = (blog) => {
    history.push({
      pathname: '/blog',
      state: {
        blog: blog
      }
    });
  }

  const createBlogTiles = () => {
    return blogs.map((blog, i) => {
      if (i >= 1) {
        return (
          <div className="col-sm-6" key={i}>
            <div className="single-blog style-three">
              <div className="thumb">
                <LazyImage src={handleThumbnail(i)} />
              </div>
              <div className="single-blog-details-wrap">
                <div className="single-blog-details text-center">
                  <a className="tag-author">{handleAuthor(i)}</a>
                  <p className="date">{handleWrittenDate(i)}</p>
                  <h4 className="title">{handleTitle(i)}</h4>
                  <a className="btn btn-yellow" onClick={() => redirectToBlogPage(blogs[i])}><span>Read More<i className="la la-arrow-right" /></span></a>
                </div>
              </div>
            </div>
          </div>
        )
      }
    });
  }

  return (
    <>
      {blogs.length > 0 &&
        <div id="our-blog" className="blog-area mg-top-92 viaje-go-top blog-area-row wow animated fadeInUp mb-5" style={{ paddingTop: 150, marginTop: -80 }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title wow animated fadeInUp">Our Blogs</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="single-blog single-blog-wrap style-two">
                  <div className="thumb single-blog-left-wrap">
                    <LazyImage src={handleThumbnail(0)} alt="blog" />
                  </div>
                  <div className="single-blog-details single-blog-right-wrap">
                    <a className="tag-author">{handleAuthor(0)}</a>
                    <p className="date">{handleWrittenDate(0)}</p>
                    <h4 className="title">{handleTitle(0)}</h4>
                    <p className="content">{handleContent(0)}</p>
                    <a className="btn btn-yellow" onClick={() => redirectToBlogPage(blogs[0])}>
                      <span>Read More<i className="la la-arrow-right" /></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  {createBlogTiles()}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

BlogSection.propTypes = {
  history: PropTypes.object.isRequired,
};

export default BlogSection;
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, Icon, CircularProgress } from '@material-ui/core';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { OTPublisher, OTSession, OTSubscriber, OTStreams } from 'opentok-react';
import { useMutation, useQuery } from "@apollo/react-hooks";
import { loader } from 'graphql.macro';
import Checkbox from './check-box';
import ConnectionStatus from './connection-status';
import WalkerVideoCallRequest from './walker-videocall-request';
import DownloadLink from '../../components/layout/download-link';
const OT = require('@opentok/client');
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import WalkerRating from './../walker-rating/walker-rating';
import walkerRating from './../walker-rating/walker-rating';
import SubHeader from '../../components/layout/sub-header';

// const SEND_BOOKING_REQUEST_TO_WALKER = loader('../../graphql/schema/video-call/send-booking-request-to-walker.graphql');
//const WALKER_ACCEPT_REJECT_BOOKING_REQUEST = loader('../../graphql/schema/video-call/walker-accept-reject-booking-request.graphql');
//const VERIFY_VIDEOCALL_TRAVELLER_VONAGE = loader('../../graphql/schema/video-call/verify-videocall-traveller-vonage.graphql');
const EXTEND_VIDEOCALL = loader('../../graphql/schema/video-call/extend-videocall.graphql');
const END_VIDEOCALL_VONAGE = loader('../../graphql/schema/video-call/end-videocall-vonage.graphql');

const apiKey = process.env.REACT_APP_API_KEY;
let currentTimestamp = Date.now();
let myTimer = () => {};

const VideoCall = (props) => {

  const { history, location } = props;
  const sessionId = (typeof location.state !== 'undefined') ? location.state.vonage_session_id : null;
  const token = (typeof location.state !== 'undefined') ? location.state.vonage_token_id : null;
  const timerWalk = (typeof location.state !== 'undefined') ? (location.state.tripe_minute * 60) * 1000 : 0;
  // const sessionId = "2_MX40NzIzNDI1NH5-MTYzODI3ODY1ODA4Mn5wM0owQmFPSmtjdHhVWVNTd1FJS3dQMFl-fg";
  // const token = "T1==cGFydG5lcl9pZD00NzIzNDI1NCZzaWc9M2ZjYjQ1OWVjMGE2YTY5OGEyOTM5ZTI3NGI5MzhkM2E4MTdmMGJjMDpzZXNzaW9uX2lkPTJfTVg0ME56SXpOREkxTkg1LU1UWXpPREkzT0RZMU9EQTRNbjV3TTBvd1FtRlBTbXRqZEhoVldWTlRkMUZKUzNkUU1GbC1mZyZjcmVhdGVfdGltZT0xNjM4Mjc4NjU4JnJvbGU9cHVibGlzaGVyJm5vbmNlPTE2MzgyNzg2NTguMTQyOTEyNDEwNTkwNjkmY29ubmVjdGlvbl9kYXRhPW5hbWUlM0RFcmljJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9Zm9jdXM=";

  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const [showExtendCall, setShowExtendCall] = useState(false);
  const [currentTimerValue, setCurrentTimerValue] = useState('');
  const [timerValue, setTimerValue] = useState(timerWalk);
  const [travellerConnectionID, setTravellerConnectionID] = useState('');
  const [walkerConnectionID, setWalkerConnectionID] = useState('');
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [showWalkerRating, setShowWalkerRating] = useState(false);
  const [walkerRating, setWalkerRating] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [connection, setConnection] = useState({ seconds: 15, status: 'CONNECTING' });
  const [timer, setTimer] = useState(15);
  const [showEndWalk, setShowEndWalk] = useState(false);
  const [connecting] = useState({
    seconds: 15,
    status: 'CONNECTING',
    start: function () {
      this.interval = setInterval(() => {
        this.seconds--;
        console.log(this.seconds);
        if (this.seconds <= 0 && this.status === 'CONNECTING') {
          this.status = 'EXITING';
          this.seconds = 5;
        } else if (this.seconds <= 0 && this.status === 'EXITING') {
          this.stop();
          onEndedVideoCall();
        } else if (this.seconds <= 0) {
          onEndedVideoCall();
        }
        setConnection({ seconds: this.seconds, status: this.status });
        setTimer(this.seconds);
      }, 1000);
    },
    stop: function () {
      clearInterval(this.interval);
    },
    reload: function ({ seconds, status }) {
      this.stop();
      setConnection({ seconds: seconds, status: status });
      setTimer(seconds);
      this.seconds = seconds;
      this.status = status;
      this.start();
    }
  });

  const toggleAudio = useCallback(() => setAudio(!audio));
  const toggleVideo = useCallback(() => setVideo(!video));

  const [extendVideoCall, { loading: disabledExtendVideoCall }] = useMutation(EXTEND_VIDEOCALL);
  const [endedVideoCall, { loading: disabledEndedVideoCall }] = useMutation(END_VIDEOCALL_VONAGE, {
    onCompleted({
      endVideoCallVonage: {
        message,
        status
      }
    }) {
      connecting.stop();
      setIsLoading(false);
      setShowWalkerRating(true);
    }
  });

  useEffect(() => {
    currentTimestamp = Date.now();
    if (typeof location.state === 'undefined') {
      history.push('/destination-categories');
    } else {
      setWalkerRating({
        walker_id: location.state.walker_id,
        booking_id: location.state.booking_id,
      });
      history.listen((prevHistory, action) => {
          if (
              typeof prevHistory.location != 'undefined'
              && typeof prevHistory.location.state !== 'undefined' 
              && typeof prevHistory.location.state.isDisconnected !== 'undefined'
          ) {
            setWalkerRating({
              walker_id: prevHistory.location.state.walker_id,
              booking_id: prevHistory.location.state.booking_id,
            });
            setIsDisconnected(prevHistory.location.state.isDisconnected);
            setShowWalkerRating(prevHistory.location.state.showWalkerRating);
          }
      });
      connecting.start();
    }
  }, []);

  const sessionEventHandlers = {
    sessionConnected: (event) => {
      console.log(event);
      currentTimestamp = Date.now();
    },
    sessionDisconnected: (event) => {
      onEndedVideoCall();
      console.log('sessionDisconnected', event);
    },
    connectionCreated: (event) => {
      currentTimestamp = Date.now();
    },
    connectionDestroyed: (event) => {
      onEndedVideoCall();
      console.log('connectionDestroyed', event);
    },
    streamCreated: (event) => {
      console.log('streamCreated', event);
    },
    streamDestroyed: (event) => {
      console.log('streamDestroyed', event);
    },
  };

  const subscriberEventHandlers = {
    connected: ({ target: { stream: { connection: { connectionId: travelerId } }, streamId: walkerId } }) => {
      console.log('[subscriberEventHandlers - connected]');
      currentTimestamp = Date.now();
      setIsLoading(false);
      setTravellerConnectionID(travelerId);
      setWalkerConnectionID(walkerId);
      connecting.stop();
    },
    disconnected: () => {
      console.log('[subscriberEventHandlers - disconnected]');
    },
    error: (error) => {
      console.log('subscriberEventHandlers error:', error);
    },
  };

  const onEndedVideoCall = () => {
    if (!isDisconnected) {
      setShowEndWalk(false);
      setIsDisconnected(true);
      setIsLoading(true);
      connecting.reload({ seconds: 5, status: 'PROCESSING'});
      endedVideoCall({
        variables: {
          data: {
            booking_id: location.state.booking_id,
            ended_by: 'TRAVELER',
            early_call_end: currentTimerValue > 0,
            call_duration: moment().diff(moment(currentTimestamp), 'seconds'),
            traveller_connection_id: travellerConnectionID,
            walker_connection_id: walkerConnectionID,
          }
        },
      });
    }
  }

  const onExtendVideoCall = (minutes, prices) => {
    const updateTime = minutes * 60;
    const newEndTime = moment().add(currentTimerValue + updateTime, 'seconds');
    extendVideoCall({
      variables: {
        data: {
          booking_id: location.state.booking_id,
          extend_minute: minutes,
          extend_price: prices,
          epoch: newEndTime.format('X').toString(),
        }
      }
    })
      .then(({ data }) => {
        if (data.extendVideoCall.status === 'SUCCESS') {
          setTimerValue(timerValue + (minutes * 60000));
        }
        setShowExtendCall(false);
      });
  };

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (!completed) {
      if (hours > 0) {
        return (
          <div className="d-flex flex-row">
            <span style={Styles.counter}>{String(hours).padStart(2, '0')}</span>
            <span style={{ padding: '5px 10px', fontWeight: 'bold' }}>:</span>
            <span style={Styles.counter}>{String(minutes).padStart(2, '0')}</span>
            <span style={{ padding: '5px 10px', fontWeight: 'bold' }}>:</span>
            <span style={Styles.counter}>{String(seconds).padStart(2, '0')}</span>
          </div>
        );
      }
      return (
        <div className="d-flex flex-row">
          <span style={Styles.counter}>{String(minutes).padStart(2, '0')}</span>
          <span style={{ padding: '5px 10px', fontWeight: 'bold' }}>:</span>
          <span style={Styles.counter}>{String(seconds).padStart(2, '0')}</span>
        </div>
      );
    }
    return (
      <div className="d-flex flex-row">
        <span style={Styles.counter}>00</span>
        <span style={{ padding: '5px 10px', fontWeight: 'bold' }}>:</span>
        <span style={Styles.counter}>00</span>
      </div>
    );
  };

  const onCountdownTick = (time) => {
    setCurrentTimerValue(time.total / 1000);
    if (time.minutes === 2 && time.seconds === 0) {
      setShowExtendCall(true);
    }
  };

  return (
    <>
      <div className="traveller-page" style={{ minHeight: 'auto' }}>
        <div className="traveller-row">
          <SubHeader title="Live Walk" />
          <div style={Styles.container}>
            {!isDisconnected && typeof location.state !== 'undefined' &&
              <>
                <OTSession
                  apiKey={apiKey}
                  sessionId={sessionId}
                  token={token}
                  eventHandlers={sessionEventHandlers}
                  onError={(err) => console.log(err)}
                >
                  <OTPublisher
                    properties={{
                      publishAudio: audio,
                      publishVideo: false,
                      videoSource: undefined,
                    }}
                    style={Styles.publisher}
                  />
                  <OTStreams>
                    <OTSubscriber
                      properties={{
                        subscribeToAudio: true,
                        subscribeToVideo: true,
                        height: '100%',
                        width: '100%',
                        fitMode: 'contain'
                      }}
                      eventHandlers={subscriberEventHandlers}
                      onError={(err) => console.log(err)}
                      style={Styles.subscriber}
                    />
                  </OTStreams>
                </OTSession>
                {!isLoading &&
                  <div className="d-flex align-items-center justify-content-center w-100" style={Styles.controlsContainer}>
                    <div className="d-flex align-items-center justify-content-center" style={Styles.controls}>
                      <div className="text-center">
                        <button disabled={disabledEndedVideoCall} className="btn btn-danger rounded-circle" style={Styles.button} onClick={() => setShowEndWalk(true)}>
                          <i className="fa fa-square p-0" style={Styles.icon}></i>
                        </button>
                        <span>End Call</span>
                      </div>
                      <div className="text-center" onClick={() => setShowExtendCall(true)}>
                        <Countdown
                          date={currentTimestamp + timerValue}
                          now={() => Date.now(currentTimestamp)}
                          onComplete={() => onEndedVideoCall()}
                          renderer={countdownRenderer}
                          onTick={(time) => onCountdownTick(time)}
                        />
                        <span>Add Time</span>
                      </div>
                      <div className="text-center">
                        <button disabled={disabledEndedVideoCall} className="btn btn-blue rounded-circle" style={Styles.button} onClick={toggleAudio}>
                          {audio &&
                            <i className="fa fa-microphone p-0" style={Styles.icon}></i>
                          }
                          {!audio &&
                            <i className="fa fa-microphone-slash p-0" style={Styles.icon}></i>
                          }
                        </button>
                        <span>Mute</span>
                      </div>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
      <Dialog open={showExtendCall} maxWidth="xs">
        <div className="schedule-pick-row walker-map-popup">
          <DialogContent style={Styles.center}>
            <p style={{ marginBottom: 20, }}>Your Walk is Ending Soon</p>
            <h3 style={{ marginBottom: 20, }}>Extend Your Walk?</h3>
            <table>
              <tr>
                <td>
                  <button disabled={disabledExtendVideoCall} className="btn btn-blue" style={{ width: 100 }} onClick={() => onExtendVideoCall(5, 5.00)}>$5.00</button>
                </td>
                <td className="pl-2">Extend for 5 minutes</td>
              </tr>
              <tr>
                <td>
                  <button disabled={disabledExtendVideoCall} className="btn btn-blue" style={{ width: 100 }} onClick={() => onExtendVideoCall(10, 10.00)}>$10.00</button>
                </td>
                <td className="pl-2">Extend for 10 minutes</td>
              </tr>
            </table>
            <p style={{ marginTop: 20 }} onClick={() => setShowExtendCall(false)}>No Thank You</p>
          </DialogContent>
        </div>
      </Dialog>
      {showWalkerRating &&
        <WalkerRating {...props} booking={walkerRating}/>
      }
      <Dialog open={isLoading} maxWidth="xs">
          <div className="schedule-pick-row walker-map-popup">
              <DialogContent
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 20, height: 100, overflow: 'hidden' }}>
                  <h6 style={{ marginBottom: 20, }}>{connection.status} {connection.status !== 'PROCESSING' ? `(${timer})` : null}</h6>
                  {connection.status === 'EXITING' ? <p>The connection has been expired.</p> : <CircularProgress />}
              </DialogContent>
          </div>
      </Dialog>
      <Dialog open={showEndWalk} maxWidth="xs">
          <div className="schedule-pick-row walker-map-popup">
              <div className="walker-close">
                  <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={() => setShowEndWalk(false)}></i>
              </div>
              <DialogContent
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 20 }}>
                  <h6 style={{ marginBottom: 20 }}>Do you want to end walk?</h6>
                  <div className="text-center mt-4">
                      <button className="btn btn-yellow mr-2" style={{ width: '100px' }} onClick={() => onEndedVideoCall()}>Yes</button>
                      <button className="btn btn-blue" style={{ width: '100px' }} onClick={() => onEndedVideoCall()}>No</button>
                  </div>
              </DialogContent>
          </div>
      </Dialog>
    </>
  );
}

const Styles = {
  container: {
    backgroundColor: '#000',
    height: 650,
  },
  publisher: {
    display: 'none',
  },
  subscriber: {
    width: '100%',
    height: '650px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 20,
  },
  controlsContainer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
  },
  controls: {
    padding: '10px 20px 0px 30px',
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: '#fff',
    borderRadius: 50,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    margin: '0 16px',
    padding: 0,
  },
  counter: {
    backgroundColor: '#071C55',
    color: '#fff',
    borderRadius: '5px',
    fontWeight: 'bold',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    padding: 0,
    margin: 0,
  }
};

VideoCall.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(VideoCall);
import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import LazyImage from '../../utilities/lazy-image';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Dialog, Button} from '@material-ui/core';
import * as commonFunction from '../../utilities/commonFunctions';
import AdPopup from '../../utilities/ad-popup';
import AdBanner from '../../utilities/ad-banner';
import UserUtils from '../../utilities/UserUtils';
import { head } from 'lodash';
import SubHeader from '../../components/layout/sub-header';

const DESTINATION_CATEGORIES = loader('./../../graphql/schema/destinationView/destination-categories.graphql');
const UPCOMING_WALKS = loader('../../graphql/schema/traveller/traveller-upcomingWalks.graphql');
const GET_ADS = loader('../../graphql/schema/ads/get-ads.graphql');
const pageTitleStyle = {
  display: 'table-cell',
  verticalAlign: 'middle'
}

const DestinationCategories = (props) => {

  const { history } = props;
  const [categories, setCategories] = useState([]);
  const [haserror, setHaserror] = useState('');
  const [count, setCount] = useState(20);
  const [verifyEmailPopup, setVerifyEmailPopup] = useState(false);
  const [upcomingTrip, setUpcomingTrip] = useState(0);
  const [size] = useState(10);
  const [page, setPage] = useState(1);
  const [showAd, setShowAd] = useState(false);
  const [popupAd, setPopupAd] = useState(null);
  const [headerAd, setHeaderAd] = useState(null);
  const AD_TYPE = 'web_app_destination_categories';
  
  const { data: adsData } = useQuery(GET_ADS, {
    variables: { ad_user: UserUtils.getUserID(), ad_page: AD_TYPE },
    fetchPolicy: 'no-cache',
    onCompleted() {
      console.log('data ===> ', adsData);
      let popup = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'popup');
      let header = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'banner' && item.ad_location_lookup.ad_value == 'top');

      if(popup.length) {
        setPopupAd(popup[0]);
      }

      if(header.length) {
        setHeaderAd(header[0]);
      }
    }
  });

  const paginateCategories = useCallback(
    (array, pageSize, pageNumber = 1) => {
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
    [categories, count],
  );

  const { data, loading, refetch } = useQuery(DESTINATION_CATEGORIES, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      setCategories(res.getDestinationCategories);
    }
  });

  const {} = useQuery(UPCOMING_WALKS, {
    variables: { "pageNumdata": page, "first": size },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted({ getTravelerUpcomingTrips }) {
      if (getTravelerUpcomingTrips && getTravelerUpcomingTrips.data) {
        setUpcomingTrip(getTravelerUpcomingTrips.data.length);
      }
    }
  });

  useEffect(() => {
    window.removeEventListener('scroll', scrollListener);
    if (categories && categories.length > 0 && data.getDestinationCategories.length > count) {
      window.addEventListener('scroll', scrollListener);
    }
    if (history.location.state && history.location.state.isNewlyRegister) {
      setVerifyEmailPopup(true);
    }
  }, [categories, count]);

  const scrollListener = () => {
    if (
      window.pageYOffset > (document.querySelector('.footer-area').offsetTop - window.innerHeight)
    ) {
      setCount(count + 20);
    }
  }

  const redirectToDestinations = (categoryId, name) => {
    if (commonFunction.isLogin()) {
			history.push({
        pathname: '/select-destinations',
        state: {
          category_id: categoryId,
          category_name: name
        }
      });
			return;
		}
    history.push({
      pathname: '/show-all-destinations',
      state: {
        category_id: categoryId,
        ategory_name: name
      }
    });
  }

  const handleVerifyEmailClose = () => {
    setVerifyEmailPopup(false);
  }

  const handleDeepLink = async (userType) => {
    let urlPath = 'https://play.google.com/store/apps/details?id=com.huvr';
    if (userType === 'ios') {
      urlPath = 'https://itunes.apple.com/app/id1536389358';
    }
    const win = window.open(urlPath, '_blank');
    win.focus();
  }

  const handleUpcomingTrip = () => {
    history.push('/your-trips', { upcoming_trip: true });
  }

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Destinations" />
          {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
          <div id="gtrip-wrapper" className="container">
            {(loading || paginateCategories(categories, count, 1).length <= 0) && 
              <div className="d-flex align-items-center justify-content-center" style={{ height: '435px' }}>
                {loading && <p className="text-muted">Loading Destination Categories...</p>}
                {!loading && <p className="text-muted">No Categories.</p>}
              </div>  
            }
            {headerAd && <AdBanner type={AD_TYPE} content={headerAd} /> }
            <div className="row pt-4 justify-content-center align-self-center" style={{fontSize: 17 }}>
              <div className="col-md-6 align-self-center" style={pageTitleStyle}>
                <h3 style={{fontWeight: 600}}>DESTINATION CATEGORIES</h3>
              </div>
              <div className="col-md-6 align-self-center" style={pageTitleStyle}>
                <p style={{ color: '#383838'}}>Take a Virtual Trip in Real-time! Pick an event or a</p>
                <p style={{ color: '#383838'}}>destination and Go Live!</p>
              </div>
            </div>
            <div className="row completed-trip-row">
              {(!loading && paginateCategories(categories, count, 1).length > 0) && paginateCategories(categories, count, 1).map((value, index) => {
                if (value.count == 0) {
                  return null;
                }
                return (
                  <div className="col-sm-12 col-lg-4 col-md-6 col-xl-4 p-2" key={index} dataLabel={index}>
                    <div className="py-2">
                      <div className="dest-cat-box style-three" style={{ backgroundColor: '#F2F2F2', borderRadius: 7, height: 255, marginBottom: 0 }}>
                        <div className="thumb" style={{height:255}}>
                          <LazyImage src={value.banner}/>
                        </div>
                        <div className="single-blog-details-wrap category-card">
                          <div className="single-blog-details dest-gradient">
                            <h4 className="title font-weight-bold">{value.name}</h4>
                            <p className="walk-request-text">{value.count} Destinations</p>
                            <a className="float-right dest-arrow-right" onClick={() => redirectToDestinations(value.id, value.name)} style={{marginTop: 0}}><span style={{ color: '#fff'}}><i className="la la-arrow-right" /></span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Dialog className="popup-verification" onClose={handleVerifyEmailClose} aria-labelledby="simple-dialog-title" open={verifyEmailPopup}>
        <div>
          <p>Verification Link sent to your registered email address</p>
          <Button className="btn btn-yellow confirm-btn" onClick={handleVerifyEmailClose}>OK</Button>
        </div>
      </Dialog>
      { popupAd && <AdPopup type={AD_TYPE} content={popupAd} /> }
    </>
  )
}

const enhance = compose(
  withRouter,
);

export default enhance(DestinationCategories);

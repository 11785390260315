import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import AdPopup from '../../utilities/ad-popup';
import AdBanner from '../../utilities/ad-banner';
import UserUtils from '../../utilities/UserUtils';
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const MAKE_MONEY_FOR_WALKER = loader('../../graphql/schema/make-money/make-money-for-walker.graphql');
const GET_ADS = loader('../../graphql/schema/ads/get-ads.graphql');

const MakeMoneyForWalker = (props) => {

    const { history } = props;
    const [walkerData, setWalkerData] = useState([]);
    const [showAd, setShowAd] = useState(false);
    const [ads, setAds] = useState([]);
    const [popupAd, setPopupAd] = useState(null);
    const [headerAd, setHeaderAd] = useState(null);
    const AD_TYPE = 'web_app_make_money_walking';

    const { data: myDetails } = useQuery(MY_DETAILS);

    const { data, loading, refetch } = useQuery(MAKE_MONEY_FOR_WALKER, {
        notifyOnNetworkStatusChange: true
    })

    const { data: adsData } = useQuery(GET_ADS, {
        variables: { ad_user: UserUtils.getUserID(), ad_page: AD_TYPE },
        fetchPolicy: 'no-cache',
        onCompleted({ ads }) {
          console.log('data ===> ', adsData);
          let popup = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'popup');
          let header = adsData.ads.filter(item => item.ad_type_lookup.ad_value == 'banner' && item.ad_location_lookup.ad_value == 'top');
        console.log('header ==> ', header);
          if(popup.length) {
            setPopupAd(popup[0]);
          }
    
          if(header.length) {
            setHeaderAd(header[0]);
          }
        }
      });

    useEffect(() => {
        refetch();
        if (data) {
            setWalkerData(data.makeMoneyWalking);
        }
    }, [data]);

    if (walkerData.is_all_verification_completed === true) {
        // history.push('/welcome-back')
    }

    const handlebackgroundcheck = () => {
        history.push('/back-ground-check-walker')
    }

    const handleWalkerKit = () => {
        history.push('/walker-kit', {
            coming_soon: false
        })
    }

    const handleWalkerTraining = () => {
        history.push('/walker-training')
    }

    const handleAccountLinked = () => {
        history.push('/payout-account')
    }

    const handleWalkerTerms = () => {
        history.push('/walker-terms')
    }

    const handleProfilrPicture = () => {
        history.push('/edit-account')
    }

    console.log(walkerData);

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="Make Money Walking" />
                    <div className="container">
                        {headerAd && <AdBanner type={AD_TYPE} content={headerAd} /> }
                        <div className="travelle-body">
                            <Paper elevation={0} style={{ boxShadow: '0px 1px 3px rgb(0 0 0 / 30%)' }}>
                                <div className="profile-row-v2" style={{ backgroundColor: '#12285c', borderRadius: '5px 5px 0px 0px' }}>
                                    <div className="profile-left">
                                        {/* <figure>
                                            <img src={myDetails && myDetails.me.avatar ? myDetails.me.avatar : 'assets/images/inner-page/profile.jpg'} alt="profile" style={{ backgroundColor: '#fff' }} />
                                        </figure> */}
                                        <div className="profile-title">
                                            <h4>Welcome Back</h4>
                                            <p>
                                                {myDetails ? `${myDetails.me.first_name} ${myDetails.me.last_name}` : ''}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="profile-right">
                                        <figure>
                                            <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                                        </figure>
                                        {/* <span>Traveler</span> */}
                                    </div>
                                </div>
                                {walkerData.is_all_verification_completed === true &&
                                    <>
                                        <figure className="coming-soon mb-5">
                                            <img src={publicUrl + "assets/images/inner-page/Verified-Virtual-Walker.png"} alt="" />
                                        </figure>
                                    </>
                                }
                                <div className="px-4">
                                    {walkerData.is_all_verification_completed !== true &&
                                        <>
                                            <div>
                                                <h3 className="requried-title">Required Steps</h3>
                                                <Typography>Here is what still needs to be completed in order to set up your Walker Account.</Typography>
                                                <Typography>Click on steps below that need your attention to complete, so you can start earning money as a Huvr Walker.</Typography>
                                            </div>
                                            <div className="destination-body" style={{ boxShadow: 'none', border: '1px solid #dddddd' }}>
                                                {/* <div onClick={handlebackgroundcheck}>
                                                    {walkerData.backgground_check === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p>Need Your Attention</p>
                                                                <h4>Background Check</h4>
                                                                {walkerData.is_background_check_pending === true ?
                                                                    <p className="text-danger">Pending....</p>
                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                            <div className="box-right">
                                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div> */}
                                                <div onClick={handleProfilrPicture}>
                                                    {walkerData.profie_picture === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p className='mk-status mk-need-attention'>Need Your Attention</p>
                                                                <h4>Profile Picture</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <img className="mk-warning" src={publicUrl + 'assets/images/inner-page/exclamation-solid.png'} />
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                {/* <div onClick={handleWalkerKit}>
                                                    {walkerData.walker_kit_ordered === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p>Need Your Attention</p>
                                                                <h4>Walker Kit</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div> */}
                                                {/* <div onClick={handleWalkerTraining}>
                                                    {walkerData.walker_training === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p>Need Your Attention</p>
                                                                <h4>Walker Training</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div> */}
                                                <div onClick={handleAccountLinked}>
                                                    {walkerData.bank_account_linked === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p  className='mk-status mk-need-attention'>Need Your Attention</p>
                                                                {walkerData.is_stripe_connect_form_submitted === true ?
                                                                    <p className='mk-status mk-pending'>Pending</p>
                                                                    :
                                                                    ''
                                                                }
                                                                <h4>Stripe Account Link</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <img className="mk-warning" src={publicUrl + 'assets/images/inner-page/exclamation-solid.png'} />
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                <div onClick={handleWalkerTerms}>
                                                    {walkerData.walker_terms === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                            <p className='mk-status mk-need-attention'>Need Your Attention</p>
                                                                <h4>Sign Walker Terms</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <img className="mk-warning" src={publicUrl + 'assets/images/inner-page/exclamation-solid.png'} />
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div>

                                            </div>
                                        </>
                                    }
                                    <h3 className="requried-title">Completed Tasks</h3>
                                    <div className="destination-body mt20" style={{ boxShadow: 'none', border: '1px solid #dddddd' }}>
                                        {/* <div>
                                            {walkerData.backgground_check === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p>Completed</p>
                                                        <h4>Background Check</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <i className="fa fa-check bg-success" data-toggle="modal" data-target="#myModal"></i>
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div> */}
                                        <div>
                                            {walkerData.profie_picture === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p className='mk-status mk-completed'>Completed</p>
                                                        <h4>Profile Picture</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <img className="mk-success" src={publicUrl + 'assets/images/success-icon.png'} />
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div>
                                        {/* <div>
                                            {walkerData.walker_kit_ordered === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p>Completed</p>
                                                        <h4>Walker Kit</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <i className="fa fa-check bg-success" data-toggle="modal" data-target="#myModal"></i>
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div> */}
                                        {/* <div>
                                            {walkerData.walker_training === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p>Completed</p>
                                                        <h4>Walker Training</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <i className="fa fa-check bg-success" data-toggle="modal" data-target="#myModal"></i>
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div> */}
                                        <div>
                                            {walkerData.bank_account_linked === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p className='mk-status mk-completed'>Completed</p>
                                                        <h4>Stripe Account Link</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <img className="mk-success" src={publicUrl + 'assets/images/success-icon.png'} />
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div>
                                        <div>
                                            {walkerData.walker_terms === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p className='mk-status mk-completed'>Completed</p>
                                                        <h4>Sign Walker Terms</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <img className="mk-success" src={publicUrl + 'assets/images/success-icon.png'} />
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
            { popupAd && <AdPopup type={AD_TYPE} content={popupAd} /> }                  
        </>
    )
}

MakeMoneyForWalker.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(MakeMoneyForWalker)

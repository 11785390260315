import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const MAKE_MONEY_FOR_WALKER = loader('../../graphql/schema/make-money/make-money-for-walker.graphql');

const CompleteVerificationForAmbassador = (props) => {

    const { history } = props;
    const [walkerData, setWalkerData] = useState([]);
    const [isAmbassadorVerified, setIsAmbassadorVerified] = useState(false);

    // const { data: myDetails } = useQuery(MY_DETAILS);
    const GET_DETAILS = useQuery(MY_DETAILS);

    // const { data, loading, refetch } = useQuery(MAKE_MONEY_FOR_WALKER, {
    const MAKE_MONEY = useQuery(MAKE_MONEY_FOR_WALKER, {
        fetchPolicy: "no-cache",
        onError(errors) {
        }
    })

    useEffect(() => {
        GET_DETAILS.refetch();
        MAKE_MONEY.refetch();
        if (MAKE_MONEY.data) {
            setWalkerData(MAKE_MONEY.data.makeMoneyWalking);
            if (MAKE_MONEY.data.makeMoneyWalking.profie_picture === true && 
                MAKE_MONEY.data.makeMoneyWalking.walker_terms === true) {
                    setIsAmbassadorVerified(true);
                }
        }
    }, [MAKE_MONEY.data]);

    if (walkerData.is_all_verification_completed === true) {
        // history.push('/welcome-back')
    }

    const handleWalkerTraining = () => {
        history.push('/walker-training')
    }

    const handleWalkerTerms = () => {
        history.push('/walker-terms')
    }

    const handleProfilrPicture = () => {
        history.push('/edit-account')
    }

    return (
        <>
            {MAKE_MONEY.loading && <PageLoader loading={MAKE_MONEY.loading} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="Complete Verification" />
                    <div className="container">
                        <div className="travelle-body">
                            <Paper elevation={0} style={{ boxShadow: '0px 1px 3px rgb(0 0 0 / 30%)' }}>
                                <div className="profile-row" style={{ backgroundColor: '#12285c', borderRadius: '5px 5px 0px 0px' }}>
                                    <div className="profile-left">
                                        <figure>
                                            <img src={GET_DETAILS.data && GET_DETAILS.data.me.avatar ? GET_DETAILS.data.me.avatar : 'assets/images/inner-page/profile.jpg'} alt="profile" style={{ backgroundColor: '#fff' }} />
                                        </figure>
                                        <div className="profile-title">
                                            <h4>Welcome Back</h4>
                                            <p>
                                                {GET_DETAILS.data && GET_DETAILS.data.me.first_name}
                                                {' '}
                                                {GET_DETAILS.data && GET_DETAILS.data.me.last_name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="profile-right">
                                        <figure>
                                            <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                                        </figure>
                                    </div>
                                </div>
                                {isAmbassadorVerified &&
                                    <>
                                        <figure className="coming-soon mb-5">
                                            <img src={publicUrl + "assets/images/inner-page/Verified-Ambassador.png"} alt="" />
                                        </figure>
                                    </>
                                }
                                <div className="px-4">
                                    {!isAmbassadorVerified &&
                                        <>
                                            <div>
                                                <h3 className="requried-title">Required Steps</h3>
                                                <Typography>Here is what still needs to be completed in order to set up your Ambassador Account.</Typography>
                                                <Typography>Click on steps below that need your attention to complete.</Typography>
                                            </div>
                                            <div className="destination-body" style={{ boxShadow: 'none', border: '1px solid #dddddd' }}>
                                                <div onClick={handleProfilrPicture}>
                                                    {walkerData.profie_picture === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p>Need Your Attention</p>
                                                                <h4>Profile Picture</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                {/* <div onClick={handleWalkerTraining}>
                                                    {walkerData.walker_training === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p>Need Your Attention</p>
                                                                <h4>Walker Training</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div> */}
                                                <div onClick={handleWalkerTerms}>
                                                    {walkerData.walker_terms === false ?
                                                        (<div className="destination-box" >
                                                            <div className="box-center box-center-new" >
                                                                <p>Need Your Attention</p>
                                                                <h4>Sign Walker Terms</h4>
                                                            </div>
                                                            <div className="box-right">
                                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                                            </div>
                                                        </div>)
                                                        :
                                                        ''
                                                    }
                                                </div>

                                            </div>
                                        </>
                                    }
                                    <h3 className="requried-title">Completed Tasks</h3>
                                    <div className="destination-body mt20" style={{ opacity: .5, boxShadow: 'none', border: '1px solid #dddddd' }}>
                                        <div>
                                            {walkerData.profie_picture === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p>Completed</p>
                                                        <h4>Profile Picture</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <i className="fa fa-check bg-success" data-toggle="modal" data-target="#myModal"></i>
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div>
                                        {/* <div>
                                            {walkerData.walker_training === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p>Completed</p>
                                                        <h4>Walker Training</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <i className="fa fa-check bg-success" data-toggle="modal" data-target="#myModal"></i>
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div> */}
                                        <div>
                                            {walkerData.walker_terms === true ?
                                                (<div className="destination-box" >
                                                    <div className="box-center box-center-new" >
                                                        <p>Completed</p>
                                                        <h4>Sign Walker Terms</h4>
                                                    </div>
                                                    <div className="box-right">
                                                        <i className="fa fa-check bg-success" data-toggle="modal" data-target="#myModal"></i>
                                                    </div>
                                                </div>)
                                                :
                                                ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

CompleteVerificationForAmbassador.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(CompleteVerificationForAmbassador)

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { TextField } from 'final-form-material-ui';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import { loader } from 'graphql.macro';
import ReactFlagsSelect from 'react-flags-select';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const SAVE_WALKER_BACKGROUND_CHECK = loader('../../graphql/schema/background-check/save-walker-background-check-other-country.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const BeginBackgroundCheckTraveler = ( props ) => {

  const { history } = props;

  const [hasError, setHasError] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const { data: myDetails } = useQuery(MY_DETAILS);

  const [updateMyAccount] = useMutation(SAVE_WALKER_BACKGROUND_CHECK, {
    onCompleted() {
      history.push('/');
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const validate = (values) => {

    const errors = {};

    if (!values.first_name) {
      errors.first_name = 'First Name is required.';
    }

    if (!values.middle_name) {
      errors.middle_name = 'Middle Name is required.';
    }

    if (!values.last_name) {
      errors.last_name = 'Last Name is required.';
    }

    if (!values.street_address) {
      errors.street_address = 'Street address is required.';
    }

    if (!values.state) {
      errors.state = 'State is required.';
    }

    if (!countryCode) {
      errors.country = 'Country is required.';
    }

    if (!values.zipcode) {
      errors.zipcode = 'Zipcode is required.';
    }

    if (!values.identification_type) {
      errors.identification_type = 'Identification type is required.';
    }

    if (!values.identification_number) {
      errors.identification_number = 'Identification number is required.';
    }

    return errors;
  };

  const onSubmitAcountDetails = (values) => {
    updateMyAccount({
      variables: {
        data: {
          first_name: values.first_name,
          middle_name: values.middle_name,
          last_name: values.last_name,
          street_address: values.street_address,
          state: values.state,
          country: countryCode,
          zipcode: values.zipcode,
          identification_type: values.identification_type,
          identification_number: values.identification_number,
        },        
      },
    });
  };

  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Background Check Traveler for Begin" />
          <div className="container">
            <div className="travelle-body">
              <div className="profile-row">
                <div className="profile-left">                 
                  <figure>
                    <img src={ myDetails && myDetails.me.avatar ?  myDetails.me.avatar : 'assets/images/inner-page/profile.jpg' } alt="profile"  />
                  </figure>
                  <div className="profile-title">
                    <h4>Welcome back,</h4>
                    <p>
                      {myDetails && myDetails.me.first_name}
                      {' '}
                      {myDetails && myDetails.me.last_name}
                    </p>
                  </div>
                </div>
                <div className="profile-right">
                  <figure>
                    <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                  </figure>
                  <span>Traveler</span>
                </div>
              </div>
              <div className="edit-form-row">
                {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
                <Form
                  onSubmit={onSubmitAcountDetails}
                  validate={validate}
                  
                  render={({ handleSubmit, submitting }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                    >
                    <span><h4>Background Check information for non-US citizens</h4></span>
                      <div className="form-in">
                        <span>First Name</span>
                        <Field
                          id="first_name"
                          placeholder=""
                          type="text"
                          name="first_name"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>Middle Name</span>
                        <Field
                          id="middle_name"
                          placeholder=""
                          type="text"
                          name="middle_name"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>Last Name</span>
                        <Field
                          id="last_name"
                          placeholder=""
                          type="text"
                          name="last_name"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>Street Address</span>
                        <Field
                          id="street_address"
                          placeholder=""
                          type="text"
                          name="street_address"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>STATE OR PROVINCE</span>
                        <Field
                          id="state"
                          placeholder=""
                          type="text"
                          name="state"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>COUNTRY</span>
                        {/* <Field
                          id="country"
                          placeholder=""
                          type="text"
                          name="country"
                          margin="normal"
                          variant="outlined"
                          component={ CountrySelect }
                        /> */}
                        <Field name="country">
                          {({ input }) => (
                            <div>
                              {/* <input {...input} type="text" placeholder="Username" /> */}
                              <ReactFlagsSelect
                                {...input}
                                searchPlaceholder="Search countries"
                                searchable
                                selected={countryCode}
                                onSelect={code => setCountryCode(code)}
                              />
                            </div>
                          )}
                        </Field>
                       
                      </div>

                      <div className="form-in">
                        <span>Zipcode/Postal Code</span>
                        <Field
                          id="zipcode"
                          placeholder=""
                          type="text"
                          name="zipcode"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <label>Identification Type</label>                        
                         <Field
                          name="identification_type"
                          component="select"
                        >
                          <option value="">Account Type</option>
                          <option value="Driving Licence">Driving Licence</option>
                          <option value="Passport">Passport</option>
                        </Field>
                      </div>
                      <div className="form-in">
                        <span>Identification Number</span>
                        <Field
                          id="identification_number"
                          placeholder=""
                          type="text"
                          name="identification_number"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                        />
                      </div>

                      <span>
                        I certify that the above information is my own personal information and does not represent another person. I gave permission for Huvr to use a 3rd party background check service
                        to determine if I am a sutaible candidate for the position of Huvr Walker in my country. I understand that if the background check uncovers any information
                        that Huvr deems unsuitable for the position, I will be notified and will not be allowed to to use the app as a Verified Huvr Walker. I further declare that I have a clean 
                        criminal background and have no outstanding warants for my arrest in any country.
                      </span>

                      <div className="form-btn">
                        <Button type="submit" disabled={submitting}>Submit</Button>
                      </div>
                    </form>
                  )}
                />             

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

BeginBackgroundCheckTraveler.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( BeginBackgroundCheckTraveler );

import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter, useParams } from 'react-router-dom';
import { Dialog, DialogContent, CircularProgress, DialogTitle, Divider, Typography, Button, OutlinedInput, InputAdornment, Box, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment-timezone';
import SubHeader from '../../components/layout/sub-header';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../payments/payment-form';
import { Bo } from 'react-flags-select';
import PageLoader from '../../components/layout/ui/page-loader';
const GET_GROUP_SCHEDULESTRIP = loader('../../graphql/schema/walker/get-group-scheduletrip.graphql');

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const publicUrl = process.env.PUBLIC_URL + '/';

const EventRegistrationSuccess = (props) => {
  const { history, location } = props;
  const { id } = useParams();
  const tripId = atob(id);
  const [step, setStep] = useState('details');
  const [groupTrip, setGroupTrip] = useState(null);
  const [destination, setDestination] = useState(null);

  const { data: scheduleTrip, refetch, loading } = useQuery(GET_GROUP_SCHEDULESTRIP, {
    variables: { schedule_trip_id: tripId },
    fetchPolicy: 'cache-and-network',
    onCompleted({ response }) {
        setGroupTrip(scheduleTrip.groupScheduleTrip.scheduleTrip);
        setDestination(scheduleTrip.groupScheduleTrip.scheduleTrip.destination);
    }
  });


  useEffect(() => {
console.log('step', step);
  });

  const next = () => {
    if(step == 'details') {
      setStep('payment');
    } else if(step == 'payment') {
      setStep('success');
    }
  }

  const cancel = () => {
      if(step == 'payment') {
        setStep('details');
      } else if(step == 'success') {
        setStep('payment');
      }
  }

  const handleDeepLink = async (userType) => {
    let urlPath = 'https://play.google.com/store/apps/details?id=com.huvr';
    if (userType === 'ios') {
        urlPath = 'https://itunes.apple.com/app/id1536389358';
    }
    const win = window.open(urlPath, '_blank');
    win.focus();
}


  return (
    <>
    {loading && <PageLoader loading={loading} /> }
     <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Join Our Event" />
          <div className='inner-menu'>
            {/* <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Event Registration</a> */}
          </div>
          <Box className="content content-wrapper">
            <Box className="row outer-box">
              <Box className="col-md-5 col-sm-12 left-content">
                <img src={`${ destination ? destination.image : '' }`} />
              </Box>
              <Box className="col-md-7 col-sm-12 right-content">
                <Box style={{ margin: 0}} className="row">
                  <Box style={{ padding: 0 }} className="col-md-12">
                    <h3 className="event-success-header">Thank You! We look forward to you joining us for this exclusive event.</h3>
                    <p>You’re all set for this exclusive virtual event hosted by { destination ? destination.name : '' }.
                    <br></br>
                    <br></br>
                    A confirmation email has been sent to your registered email address.</p>
                  </Box>
                  <Box style={{ padding: 0, marginTop:14, marginBottom:7 }} className="col-md-12">
                    <span className="event-section-header">Event Details</span>
                  </Box>
                  <Box className="col-md-5 col-sm-12 event-detail">
                    <Box className="detail-col">
                      <img style={{ width: 15,height: 20}} src={`${publicUrl}assets/images/location-dot-solid.png`} />
                      <span>{ destination ? destination.name : '' }</span>
                    </Box>
                  </Box>
                  <Box className="col-md-6 col-sm-12 event-detail">
                    <Box className="detail-col">
                      <img style={{ width: 15,height: 15}} src={`${publicUrl}assets/images/schedule-icon-solid.png`} />
                      <span>{ groupTrip ? moment(groupTrip.epoch * 1000).format('dddd, MMMM D, YYYY h:mm A') : '' }</span>
                    </Box>
                  </Box>
                  <Box className="col-md-12 pl-0" style={{ marginTop: 10 }}>
                      <p>Install the Huvr App now to receive notifications, and join the upcoming event.</p>
                  </Box>
                  <Box className="col-12 p-0 mt-3">
                    {/* <Button className="event-btn huvr-logo" variant="contained"></Button> */}
                    <Button className="event-btn playstore-logo" variant="contained" onClick={() => handleDeepLink('android')}></Button>
                    <Button className="event-btn applestore-logo" variant="contained" onClick={() => handleDeepLink('ios')}></Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
      </div>
    </div>
    </>
  )
}

EventRegistrationSuccess.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(EventRegistrationSuccess)

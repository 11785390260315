import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserUtils from '../../utilities/UserUtils';
import { Dialog, Button } from '@material-ui/core';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import LazyImage from '../../utilities/lazy-image';
import * as commonFunction from '../../utilities/commonFunctions';
import { useEffect } from 'react';

const DESTINATIONS_DATA = loader('../../graphql/schema/destinationView/get-destinations-for-search.graphql');

const Search = (props) => {

  const { history } = props;
  const [destinationsData, setDestinationsData] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [results, setResults] = useState([]);
  const [userIsNotRegistered, setUserIsNotRegistered] = useState(false);
  const [isAmbassadorOrWalker, setIsAmbassadorOrWalker] = useState(false);
  const [switchToTravelerDialog, setSwitchToTravelerDialog] = useState(false);

  const [getAllDestinations, { data, loading, error }] = useLazyQuery(DESTINATIONS_DATA, {
    fetchPolicy: "no-cache",
    onCompleted(res) {
      console.log(res)
      setDestinationsData(res.getDestinationsForSearch);
      filterResults(keyword, res.getDestinationsForSearch);
    }
  });

  useEffect(() => {
    var isWalkerOrAmbassador = UserUtils.getUserType() == 'W' || UserUtils.getUserType() == 'A';
		setIsAmbassadorOrWalker(isWalkerOrAmbassador);
  }, []);

  const search = (e) => {
    const key = e.target.value.toLowerCase();
    setKeyword(key);
    if (destinationsData.length == 0) {
      getAllDestinations();
    } else {
      filterResults(key, destinationsData)
    }
    if (error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      console.log(errorMsg)
    }
  }

  const filterResults = (key, destinations) => {
    setResults([]);
    const results = destinations.filter(destinationObj =>
      (destinationObj.name.toLowerCase().includes(key) ||
        destinationObj.location.toLowerCase().includes(key))
        && key != '');
    setResults(results.sort());
  }

  const ScheduleTrip = (data) => {
    UserUtils.setDestinationData(JSON.stringify(data));
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').removeClass();

    const token = UserUtils.getAccessToken();
    if (token) {
      history.push('/show-walker-on-map');
    } else {
      setUserIsNotRegistered(true);
    }
  }

  const removeOverlay = () => {
    $('#body-overlay').removeClass('active');
    $(".tp-main-search-area").css({"z-index": 2});
  } 

  const redirectToRegister = () => {
    history.push('/register');
  }

  const resetSearch = () => {
    setKeyword('');
    setResults([]);
  }

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="search-area tp-main-search-area viaje-go-top" style={props.style} >
        <div className="container">
          <div className="tp-main-search">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="tp-search-single-wrap">
                  <i className="ti-search" />
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Search our Available Destinations"
                    value={keyword}
                    onChange={search} />
                  <i className="ti-close" onClick={resetSearch} />
                </div>
              </div>
            </div>
            <div id="results-list-container">
              <nav>
                <ul className="results-list row">
                  {results.map((d, index) => {
                    return (
                      <li key={index} className="result" data-toggle="modal" data-target={`#modal-${index}`} onClick={removeOverlay}>
                        <h6>{d.name}</h6>
                        <p>{d.location}</p>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {results.map((d, index) => {
				let banner = d.image ? d.image : publicUrl + 'assets/images/destination-list/1.png';
				if (d.banner) {
					banner = d.banner;
				}
        return (
          <div key={index} className="modal fade destination-body-popup" id={`modal-${index}`}>
            <div>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content payment-popup-body">
                  <div className="popup-scroll">
                    <button type="button" className="close" data-dismiss="modal"><i className="la la-times"></i></button>
                    <figure className="destination-img">
                      <LazyImage
                        className="select-dest-img-modal"
                        alt={d.name}
                        src={banner}
                      />
                    </figure>
                    <div className="payment-popup-cnt">
                      <h4>{d.name}</h4>
                      <em>{d.location}</em>
                      <p>{d.description}</p>
                    </div>
                  </div>
                  <div className="payment-popup-btn">
                  {!commonFunction.isLogin() || !isAmbassadorOrWalker ?
                    <button className="btn btn-yellow" onClick={() => ScheduleTrip(d)}>Huvr there Now!</button>
                  :
                    <button className="btn btn-yellow" onClick={() => setSwitchToTravelerDialog(true)}>Huvr there Now!</button>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <Dialog className="popup-verification" onClose={() => setUserIsNotRegistered(false)} aria-labelledby="simple-dialog-title" open={userIsNotRegistered}>
        <div>
          <p>To explore with Huvr, you must login or register first.</p>
          <br />
          <Button className="btn btn-yellow confirm-btn" onClick={redirectToRegister}>OK</Button>
        </div>
      </Dialog>
      <Dialog className="popup-verification" aria-labelledby="simple-dialog-title" open={switchToTravelerDialog}>
				<div>
					<p>To book a trip, go to Traveler mode.</p>
					<br />
					<Button className="btn btn-yellow confirm-btn" onClick={() => setSwitchToTravelerDialog(false)}>OK</Button>
				</div>
			</Dialog>
    </>
  )
}


Search.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Search;

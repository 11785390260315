import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');

const WelcomeBack = () => {

  const { data: myDetails ,loading } = useQuery(MY_DETAILS);

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Welcome Profile" />
          <div className="container">
            <div className="travelle-body">
              <div className="profile-row">
                <div className="profile-left">
                <figure>
                    <img src={ myDetails && myDetails.me.avatar ?  myDetails.me.avatar : 'assets/images/inner-page/profile.jpg' } alt="profile"  style={{ backgroundColor: '#fff' }}/>
                  </figure>
                  <div className="profile-title">
                    <h4>Welcome Back</h4>
                    <p>
                      {myDetails && myDetails.me.first_name}
                      {' '}
                      {myDetails && myDetails.me.last_name}
                    </p>
                  </div>
                </div>
                <div className="profile-right">
                  <figure>
                    <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                  </figure>
                  <span>Traveler</span>
                </div>
              </div>
              <figure className="coming-soon">
                <img src={publicUrl + "assets/images/inner-page/Verified-Virtual-Walker.png"} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeBack;

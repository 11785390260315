import React from 'react';
import { Button, Box } from '@material-ui/core';

const customLocaleText = {
    footerRowPerPage: 'Showing {from}-{to} of {count} entries',
  };


const CustomPagination = (props) => {
    const { from, to, total, page, hasMorePages } = props;
  
    return (
      <Box className={`gtrip-custom-pagination ${props.className}`}>
        <Box className="result-count">Showing {from} to {to} of {total} entries</Box>
        <Box>
          {props.className !== 'mobile-pagination' && 
          <Button className="first" onClick={() => props.goToPage(1)} disabled={page === 1}>{'<<'}</Button>
          }
          <Button className="prev" onClick={() => props.previous()} disabled={page === 1}>
            Previous
          </Button>
          <button className="current-page">{page}</button>
          <Button className="next" onClick={() => props.next()} disabled={!hasMorePages}>
            Next
          </Button>
          {props.className !== 'mobile-pagination' && 
          <Button
            className="last"
            onClick={() => props.goToPage(Math.ceil(total / (to - from + 1)))}
            disabled={page === Math.ceil(total / (to - from + 1))}
          >
            {'>>'}
          </Button>
          }
        </Box>
      </Box>
    );
  };

  export default CustomPagination;
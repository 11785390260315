import React from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const CREATE_NEW_DESTINATION = loader('../../graphql/schema/destinationView/create-newDestination.graphql');

const CreateNewDestination = () => {

    const [baseImage, setbaseImage] = useState('');
    const [haserror, setHaserror] = useState('');
    const [image, setImage] = useState('');
    const [errorMessage, seterrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fileName, setFileName] = useState('');
    const validExt = ["jpeg", "png", "jpg"];

    const [createNewDestination, { loading: CreateNewDestination }] = useMutation(CREATE_NEW_DESTINATION, {
        onCompleted() {
            setSuccessMessage('Created New Destination Successfully');
        },
        onError(errors) {
            const formatedErrors = errors.graphQLErrors.map(({ message }) => (
                <span key={`login-error-${Math.random()}`}>{message}</span>
            ));

            setHaserror(formatedErrors);
        },
    });

    const handleUploadImage = async (e) => {
        const file = e.target.files[0];
        setImage(file.name);
        const base64 = await convertBase64(file);
        setbaseImage(base64);
        console.log(base64);
    };

    const convertBase64 = (file) => {

        return new Promise((resolve, reject) => {

            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            }

            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const validate = values => {
        const errors = {};

        if (!values.Name) {
            errors.Name = 'Please Enter Name';
        }

        if (!values.Description) {
            errors.Description = 'Please Enter Description';
        }

        if (!image) {
            seterrorMessage('Please Select Image');
        }

        if (image) {
            setFileName(image.substring(image.lastIndexOf('.') + 1));
            const result = validExt.includes(fileName);

            if (result === false) {
                seterrorMessage('please select image format');
            }
            else {
                seterrorMessage('');
            }

        }

        if (!values.Location) {
            errors.Location = 'Please Enter Location';
        }

        if (!values.Longitude) {
            errors.Longitude = 'Please Enter Logitude';
        }

        if (!values.Latitude) {
            errors.Latitude = 'Please Enter Latitude';
        }

        if (!values.Order) {
            errors.Order = 'Please Enter Order';
        }

        return errors;

    };

    const onSubmit = (values) => {
        createNewDestination({
            variables: {
                data: {
                    name: values.Name,
                    description: values.Description,
                    image: baseImage,
                    location: values.Location,
                    latitude: values.Latitude,
                    longitude: values.Longitude,
                    order: values.Order
                },
            },
        });

    }

    return (
        <>
            {CreateNewDestination && <PageLoader loading={CreateNewDestination} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="Create New Destination" />
                    {successMessage && <p className="success">{successMessage}</p>}
                    <div className="container">
                        <div className="travelle-body">
                            <div className="travelle-body">
                                <div className="edit-form-row">
                                    {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
                                    <Form
                                        onSubmit={onSubmit}
                                        validate={validate}
                                        render={({ handleSubmit, submitting }) => (
                                            <form
                                                onSubmit={handleSubmit}
                                                noValidate
                                            >
                                                <div className="form-in">
                                                    <span>Name</span>
                                                    <Field
                                                        fullWidth
                                                        name="Name"
                                                        type="text"
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="form-in">
                                                    <span>Descripton</span>
                                                    <Field
                                                        fullWidth
                                                        name="Description"
                                                        type="text"
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="form-in">
                                                    <span>Image</span>
                                                    <div className="browse-input">
                                                        <input
                                                            onChange={handleUploadImage}
                                                            type="file"
                                                        />
                                                        <span className="browse-span">Browse File</span>
                                                        <span className="browse-file">{image === '' ? ' No File Chosen' : image}</span>
                                                    </div>
                                                    {errorMessage && <p className="is-invalid">{errorMessage}</p>}
                                                </div>
                                                <div className="form-in">
                                                    <span>Location</span>
                                                    <Field
                                                        fullWidth
                                                        name="Location"
                                                        type="text"
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="form-in">
                                                    <span>Logitude</span>
                                                    <Field
                                                        fullWidth
                                                        name="Longitude"
                                                        type="number"
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="form-in">
                                                    <span>Latitude</span>
                                                    <Field
                                                        fullWidth
                                                        name="Latitude"
                                                        type="number"
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="form-in">
                                                    <span>Order</span>
                                                    <Field
                                                        fullWidth
                                                        name="Order"
                                                        type="number"
                                                        component={TextField}
                                                    />
                                                </div>
                                                <div className="form-btn">
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={submitting}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div className="form-btn">
                                                </div>
                                            </form>
                                        )}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default CreateNewDestination

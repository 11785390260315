import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputBase,
  Button,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { OTSession, OTSubscriber, OTStreams } from 'opentok-react';
import DownloadLink from '../../components/layout/download-link';
const OT = require('@opentok/client');
import moment from 'moment-timezone';
import {
  collection,
  onSnapshot,
  doc,
  setDoc,
  where,
  orderBy,
  query,
} from "firebase/firestore";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { firestore } from './../../utilities/firebase';
import WalkerRating from './../walker-rating/walker-rating';
import SendIcon from '@material-ui/icons/Send';
import ChatIcon from '@material-ui/icons/Chat';
import ChatOffIcon from '@material-ui/icons/SpeakerNotesOff';
import UserUtils from '../../utilities/UserUtils';
import { database, onMessageListener } from '../../utilities/firebase';
import { ref, onValue } from 'firebase/database';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import Countdown from 'react-countdown';
import RemoteTrack from '../traveller/remote-track';
import SubHeader from '../../components/layout/sub-header';

const apiKey = process.env.REACT_APP_API_KEY_AMBASSADOR;
let currentTimestamp = Date.now();

const useStyles = makeStyles((theme) => ({
  container: {
    width: '40%',
    height: 650,
    backgroundColor: '#F2F2F2',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  messagesList: {
    overflowY: 'auto',
    maxHeight: 600,
  },
  messages: {
    width: '100%',
    backgroundColor: '#F2F2F2',
  },
  commentContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  comment: {
    padding: 9,
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    border: 'none !important',
    outline: 'none !important',
  },
  send: {
    height: 50,
    border: 'none !important',
    outline: 'none !important',
    borderRadius: 0,
  }
}));
var publishStreamId = '';
const publicUrl = process.env.PUBLIC_URL + '/';

const VideoCallGroup = (props) => {

  const { history, location } = props;
  const classes = useStyles();

  const roomID = (typeof location.state !== 'undefined') ? location.state.roomID : 'huvr1001';
  const scheduleTripId = (typeof location.state !== 'undefined') ? location.state.scheduleTripId : null;
  const securityToken = (typeof location.state !== 'undefined') ? location.state.securityToken : null;
  const isReJoin = typeof location.state !== 'undefined' && location.state.isReJoin;
  const walkerId = typeof location.state !== 'undefined' && location.state.walkerId;
  const bookingId = typeof location.state !== 'undefined' && location.state.bookingId;
  const timeZone = typeof location.state !== 'undefined' && location.state.timeZone;
  const wss = typeof location.state !== 'undefined' && location.state.wss;
  const streamId = typeof location.state !== 'undefined' && location.state.streamId;
  // const timerWalk = (typeof location.state !== 'undefined') ? (location.state.tripMinutes * 60) * 1000 : (500 * 60);
  const timerWalk = 50000 * 60
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [showDisconnectionModal, setShowDisconnectionModal] = useState(false);
  const [showCallEndModal, setShowCallEndModal] = useState(false);
  const [showWalkerRating, setShowWalkerRating] = useState(false);
  const [walkerRating, setWalkerRating] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [connection, setConnection] = useState({ seconds: 15, status: 'CONNECTING' });
  const [messages, setMessages] = useState([]);
  const [comment, setComment] = useState('');
  const [user, setUser] = useState({});
  const [sending, setSending] = useState(false);
  const [scrollY, setScrollY] = useState(80);
  const [showMessages, setShowMessages] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const commentRef = useRef(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(true);
  const [remoteTracks, setremoteTracks] = useState([]);
  const [viewerCount, setViewerCount] = useState(0);

  const infoCallback = (command, data) => {
    // console.log(command);
    switch (command) {
      case 'pong':
        break;
      case 'initialized':
        // if (!isPlaying) {
        //   console.log('PLAYYYYY');
        //   webRTCAdaptor.play(roomID, undefined, roomID, []);
        //   setIsPlaying(true);
        //   setIsPublishing(true);
        // }
      break
      case 'publish_started':
        console.log('command', command);
        if (!isPlaying) {
          console.log('PLAYYYYY');
          webRTCAdaptor.play(roomID, undefined, roomID, []);
          setIsPlaying(true);
          setIsPublishing(true);
        }
        break;
      case 'publish_finished':
        setIsPublishing(false);
        break;
      case 'play_finished':
        removeRemoteVideo();
        break;
      case 'newTrackAvailable':
        {
          console.log('data', data);


          var incomingTrackId = data.track.id.substring('ARDAMSx'.length);
          if (incomingTrackId == roomID || incomingTrackId == streamId) {
            return;
          }

          setremoteTracks(prevTracks => {
            const updatedTracks = {...prevTracks, [data.track.id]: data};
            return updatedTracks;
          });

          data.stream.onremovetrack = event => {
            console.log('track is removed with id: ' + event.track.id);
            removeRemoteVideo(event.track.id);
          };
        }
        break;
      default:
        break;
    }
  }

  const callbackError = (err, data) => {
    console.log('callbackError',err, data);
    // setStatus(err)
    if (err === 'no_active_streams_in_room') {
      // it throws this error when there is no stream in the room
      // so we shouldn't reset streams list
    } else if(err == 'highResourceUsage') {
      webRTCAdaptor.stop();
      setTimeout(() => {
        handleConnect();
      }, 1500);
    } else {
      // console.error('callbackError', err, data);
    }
  }
  
  const defaultAdaptor = new WebRTCAdaptor({
    websocket_url: wss,
    mediaConstraints: {
      audio: true,
      video: false,
    },
    callback: infoCallback,
    callbackError: callbackError,
    peer_connection_config: {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
      ],
    },
    debug: true,
  });
  const [webRTCAdaptor, setWebRTCAdaptor] = useState(defaultAdaptor);

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    getData();
    setTimeout(() => {
      handleConnect();
    }, 1500);

    if (typeof location.state === 'undefined') {
      history.push('/destination-categories');
    } else {
      setWalkerRating({
        walker_id: walkerId,
        booking_id: bookingId,
        schedule_trip_id: scheduleTripId,
        isScheduleTrip: true
      });

      initializeFirebase();
    }

    return () => {
      isMounted = false; // Component is unmounting, so update the flag
      handleDisconnect();
    };
  }, []);

  useEffect(() => {
    if (showMessages && !isDisconnected && typeof location.state !== 'undefined') {
      const messageList = document.getElementById("messageList");
      const offsetHeight = messageList.offsetHeight;
      const scrollHeight = messageList.scrollHeight
      if ((scrollY + offsetHeight) >= (scrollHeight - 80)) {
        messageList.scrollTop = scrollHeight;
      }
    }
  }, [messages])


  const getData = () => {
    const myId = UserUtils.getUserID();
    const myAvatar = UserUtils.getUserAvatar();
    const myFirstName = UserUtils.getUserFirstName();
    const myLastName = UserUtils.getUserLastName();
    const myEmail = UserUtils.getUserEmail();
    const myPhoneNumber = UserUtils.getPhoneNumber();

    setUser({
      id: myId,
      avatar: myAvatar,
      first_name: myFirstName,
      last_name: myLastName,
      email: myEmail,
      phone_number: myPhoneNumber,
    });
  };

  const handleConnect = () => {
    console.log('handleConnect:webRTCAdaptor', webRTCAdaptor);
    if (webRTCAdaptor) {
      console.log('streamId', streamId);
      console.log('roomID', roomID);
      setIsPublishing(true);

      // if (!isPlaying) {
        console.log('PLAYYYYY');
        webRTCAdaptor.play(roomID, undefined, roomID, []);
        setIsPlaying(true);
        setIsPublishing(true);
      // }

      if(!isPublishing) {
        // webRTCAdaptor.publish(
        //   streamId,
        //   securityToken,
        //   undefined,
        //   undefined,
        //   undefined,
        //   roomID,
        //   JSON.stringify({
        //     schedule_trip_id: scheduleTripId,
        //     booking_id: bookingId,
        //     is_walker: false,
        //   }),
        // );
      }
    }
  };
  
  const handleDisconnect = () => {
    if(webRTCAdaptor) {
      webRTCAdaptor.stop(streamId);
      webRTCAdaptor.stop(roomID);
      removeRemoteVideo();
      setIsPublishing(false);
    }
  };

  const onEndCall = () => {
    handleDisconnect();

    if(isReJoin) {
      history.replace('/your-trips');
      window.location.reload();
    } else {
      setShowWalkerRating(true);
    }
  }

  const removeRemoteVideo = (streamId) => {
    if (streamId != null || streamId != undefined) {
      setremoteTracks(prevTracks => {
        const updatedTracks = {...prevTracks};
        if (updatedTracks[streamId]) {
          updatedTracks[streamId].track.stop();
          delete updatedTracks[streamId];
          console.log('Deleting Remote Track:', streamId);
          return updatedTracks;
        } else {
          return prevTracks;
        }
      });
      return;
    }
    console.warn('clearing all the remote renderer', remoteTracks, streamId);
    setremoteTracks([]);
  }

  const generateRandomString = (length) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const toggleAudio = () => {
    setAudio(!audio);

    if(webRTCAdaptor) {
      (audio) ? webRTCAdaptor.muteLocalMic() : webRTCAdaptor.unmuteLocalMic();
    }
  }

  const onCountdownTick = (time) => {
    setCurrentTimerValue(time.total / 1000);
    if (time.minutes === 2 && time.seconds === 0) {
      // setShowExtendCall(true);
    }
  };

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (!completed) {
      if (hours > 0) {
        return (
          <div className="d-flex flex-row">
            <span style={Styles.counter}>{String(hours).padStart(2, '0')}</span>
            <span style={{ padding: '5px 10px', fontWeight: 'bold' }}>:</span>
            <span style={Styles.counter}>{String(minutes).padStart(2, '0')}</span>
            <span style={{ padding: '5px 10px', fontWeight: 'bold' }}>:</span>
            <span style={Styles.counter}>{String(seconds).padStart(2, '0')}</span>
          </div>
        );
      }
      return (
        <div className="d-flex flex-row">
          <span style={Styles.counter}>{String(minutes).padStart(2, '0')}</span>
          <span style={{ padding: '5px 10px', fontWeight: 'bold' }}>:</span>
          <span style={Styles.counter}>{String(seconds).padStart(2, '0')}</span>
        </div>
      );
    }
  }

  const handleSendMessage = async () => {
    if (comment.trim() !== '') {
      setSending(true);
      const messageRef = doc(collection(firestore, `messages/${roomID}/list`));
      console.log('messageRef', messageRef);
      await setDoc(messageRef, {
        id: user.id,
        avatar: user.avatar,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        is_ambassador: false,
        timestamp: moment().format('X'),
        message: comment,
      });
      setComment('');
      setSending(false);
      commentRef.current.focus();
    }
  };

  const initializeFirebase = () => {
    if (showMessages && !isDisconnected && typeof location.state !== 'undefined') {
      const messageList = document.getElementById("messageList");
      messageList.addEventListener('scroll', (event) => {
        const position = messageList.scrollTop;
        setScrollY(position);
      });
    }

    const q = query(
      collection(firestore, `messages/${roomID}/list`),
      where('timestamp', '>=', moment().format('X').toString()),
      // orderBy('timestamp', 'desc')
    );  

    const subscriber = onSnapshot(q, (snapshot) => {
        const data = [];

        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            setNewMessage(true);
          }
        });
        snapshot.forEach((doc) => data.push(doc.data()));
        setMessages(data);
    });

    // Walker walk status
    const walkerStatusRef =  ref(database, `/users/walkers/${walkerId}/ambassador_session`);
    onValue(walkerStatusRef, (snapshot) => {
      console.log('snapshot', snapshot.val());
      if (snapshot.val()) {
        if (snapshot.val().status == 'disconnected') {
          setShowDisconnectionModal(true);
        } else if (snapshot.val().status == 'connected') {
          setShowDisconnectionModal(false);
        }  else if (snapshot.val().status == 'disconnecting') {
          setShowCallEndModal(true);
        } else if (snapshot.val().status == 'ended') {
          onEndCall();
        } else {
          setShowDisconnectionModal(false);
        }
      } 
    });

    // Viewer counts 
    const sessionRef =  ref(database, `/sessions/${walkerId}/${roomID}`);
    onValue(sessionRef, (snapshot) => {
      console.log('snapshot', `/sessions/${walkerId}/${roomID}`);
      console.log('snapshot', snapshot.val());
      if (snapshot.val()) {
        setViewerCount(Object.values(snapshot.val()).length);
      } else {
        setViewerCount(0);
      }
    });

    return () => {
      subscriber();
    }
  }

  const keyPress = (e) => {
    if(e.keyCode == 13) {
      handleSendMessage();
    }
  }

  const videoTacks = Object.values(remoteTracks).filter(item => item.track.kind === 'video');
  const audioTracks = Object.values(remoteTracks).filter(item => item.track.kind === 'audio');

  return (
    <>
      <div className="traveller-page" style={{ minHeight: 'auto' }}>
        <div className="traveller-row">
          <SubHeader title="Live Walk" />
          <div style={Styles.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1, position: 'relative', height: 650 }}>
                <div id="players" style={{ position: 'relative' }}>
                  {isPublishing &&
                  <>
                  <div className="player" style={{ width: '100%',height: '100%'}}>
                  <RemoteTrack tracks={remoteTracks} />
                  </div>
                  <div id="call-controls" className="d-flex align-items-center justify-content-center w-100" style={Styles.controlsContainer}>
                    <div className="d-flex align-items-center justify-content-center" style={Styles.controls}>
                      <div className="text-center">
                        <button className="btn btn-danger rounded-circle" style={Styles.button} onClick={() => onEndCall()}>
                          <div style={Styles.icon}></div>
                        </button>
                      </div>
                      {/* <div className="text-center">
                        <Countdown
                          date={currentTimestamp + timerValue}
                          now={() => Date.now(currentTimestamp)}
                          // onComplete={() => onEndedVideoCall()}
                          renderer={countdownRenderer}
                          onTick={(time) => onCountdownTick(time)}
                        />
                        <span>Add Time</span>
                      </div> */}
                      <div className="text-center">
                        {/* <button className="btn btn-blue rounded-circle" style={Styles.button} onClick={()=> toggleAudio()}>
                          {audio &&
                            <i className="fa fa-microphone p-0" style={Styles.iconV2}></i>
                          }
                          {!audio &&
                            <i className="fa fa-microphone-slash p-0" style={Styles.iconV2}></i>
                          }
                        </button> */}
                        {/* <span>Mute</span> */}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      top: 'calc(50% - 25px)',
                      right: 0,
                    }}
                  >
                    <button className="btn rounded-circle" style={Styles.transparent} onClick={() => {
                      setShowMessages(!showMessages);
                      setNewMessage(false);
                    }}>
                      {showMessages ? 
                        <ChatOffIcon color="#fff"/>
                      :
                        <>
                          <ChatIcon color="#fff"/>
                          {newMessage &&
                            <div style={{
                              backgroundColor: 'red',
                              width: 10,
                              height: 10,
                              borderRadius: 10,
                              position: 'absolute',
                              top: 10,
                              right: 10,
                            }}/>
                          }
                        </>
                      }
                    </button>
                  </div>

                  {/* <div className="views-count-box d-flex align-items-center justify-content-center">
                    <img src={`${publicUrl}assets/images/inner-page/viewers-icon.png`} /> <span>{ viewerCount }</span>
                  </div> */}
                  </>}
                </div>
                {/* <div className="audio-tracks">
                { audioTracks.map(item => {
                  return <audio
                    trackAssignment={item.track}
                    ref={(node) => refVideo(node, item)}
                    playsInline
                  ></audio>
                })}
                </div> */}
              </div>
              {(isPublishing && showMessages) &&
                  <div className={classes.container}>
                    <div
                      id="messageList"
                      className={classes.messagesList}
                    >
                      {messages.length > 0 ?
                        <List
                          dense={true}
                          component={'div'}
                          className={classes.messages}>
                          {messages.map((item) => (
                            <ListItem>
                              <ListItemAvatar style={{ alignSelf: 'flex-start', marginTop: 12 }}>
                                <Avatar src={item.avatar} />
                              </ListItemAvatar>
                              <div>
                                <ListItemText
                                  primary={`${item.first_name} ${item.last_name.charAt(0).toUpperCase()}.`}
                                  secondary={item.message}
                                  style={{
                                    backgroundColor: '#fff',
                                    padding: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderRadius: 5,
                                  }}
                                />
                              </div>
                            </ListItem>
                          ))}
                        </List>
                      :
                        <div className="d-flex px-4 py-3">
                          <ChatIcon style={{ color: '#555', marginRight: 10 }}/>
                          <p style={{ color: '#555' }}>Comments will appear here.</p>
                        </div>
                      }
                    </div>
                    <div className={classes.commentContainer}>
                      <InputBase
                        inputRef={commentRef}
                        placeholder="Write a comment"
                        className={classes.comment}
                        fullWidth
                        inputProps={{ 'aria-label': 'naked', className: classes.input, maxLength: 100 }}
                        value={comment}
                        onChange={(event) => setComment(event.target.value)}
                        disabled={sending}
                        onKeyDown={keyPress}
                      />
                      <Button
                        className={classes.send}
                        onClick={handleSendMessage}
                        disabled={sending}
                      >
                        <SendIcon style={{ color: '#4caf50' }}/>
                      </Button>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
      {showWalkerRating &&
        // <GroupTripRating {...props} booking={walkerRating}/>
        <WalkerRating {...props} booking={walkerRating}/>
      }

      <Dialog open={showDisconnectionModal} maxWidth="xs">
          <div className="schedule-pick-row walker-map-popup">
              <DialogContent
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 20, overflow: 'hidden' }}>
                  <IconButton
                      aria-label="close"
                      style={{ position: 'absolute', right: 8, top: 8 }}
                      onClick={() => setShowDisconnectionModal(false)}
                      >
                      <i className="la la-times"></i>
                  </IconButton>
                  <CircularProgress style={{ marginBottom: 10 }} />
                  <h6 style={{ marginBottom: 15, }}>Reconnecting...</h6>
                  <p style={{ textAlign: 'center'}}>
                    Please wait while the walker <br />is fixing the connection issue.
                  </p>
              </DialogContent>
          </div>
      </Dialog>

      <Dialog open={showCallEndModal} maxWidth="xs">
          <div className="schedule-pick-row walker-map-popup">
              <DialogContent
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 20, overflow: 'hidden' }}>
                    <IconButton
                        aria-label="close"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                        onClick={() => setShowCallEndModal(false)}
                        >
                        <i className="la la-times"></i>
                    </IconButton>
                  <h6 style={{ marginBottom: 15, }}>Ending Call...</h6>
                  <p style={{ textAlign: 'center'}}>
                    Walker has ended the tour. <br />Thank you for joining.
                  </p>
              </DialogContent>
          </div>
      </Dialog>
    </>
  );
}

const Styles = {
  container: {
    backgroundColor: '#000',
    // backgroundColor: 'red',
    height: 650,
  },
  publisher: {
    display: 'none',
  },
  subscriber: {
    width: '100%',
    height: '650px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 20,
  },
  controlsContainer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
  },
  controls: {
    padding: '10px 30px 0px 30px',
    color: '#fff',
    borderRadius: 50,
    paddingBottom: 10,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    margin: '0 16px',
    padding: 0,
  },
  transparent: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    margin: '0 16px',
    padding: 0,
  },
  counter: {
    backgroundColor: '#071C55',
    color: '#fff',
    borderRadius: '5px',
    fontWeight: 'bold',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    padding: 0,
    margin: 0,
    height: 20,
    width: 20,
    backgroundColor: '#fff',
  },
  iconV2: {
    padding: 0,
    margin: 0,
  }
};

VideoCallGroup.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(VideoCallGroup);
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import UserUtils from '../../utilities/UserUtils';
import { Dialog, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import LazyImage from '../../utilities/lazy-image';
import * as commonFunction from '../../utilities/commonFunctions';

const DESTINATIONS_DATA = loader('../../graphql/schema/destinationView/get-new-destinations.graphql');
let publicUrl = process.env.PUBLIC_URL + '/';

const NewDestinations = ( props ) => {

  const { history } = props;
  const [destinationsData, setDestinationsData] = useState([]);
  const [userIsNotRegistered, setUserIsNotRegistered] = useState(false);
  const [isAmbassadorOrWalker, setIsAmbassadorOrWalker] = useState(false);
  const [switchToTravelerDialog, setSwitchToTravelerDialog] = useState(false);
	
  const { data, loading, error } = useQuery(DESTINATIONS_DATA);

  useEffect(() => {
    var isWalkerOrAmbassador = UserUtils.getUserType() == 'W' || UserUtils.getUserType() == 'A';
		setIsAmbassadorOrWalker(isWalkerOrAmbassador);
    if (data && data.getNewDestinations.length > 0) {
      setDestinationsData(data.getNewDestinations);
    }
  }, [data, loading, error])

  const ScheduleTrip = (data) => {
		UserUtils.setDestinationData(JSON.stringify(data));
		$('.modal').modal('hide');
		$('body').removeClass('modal-open');
		$('.modal-backdrop').removeClass();
		const token = UserUtils.getAccessToken();
		if (token) {
			history.push('/show-walker-on-map');
		} else {
			setUserIsNotRegistered(true);
		}
	}

  const redirectToRegister = () => {
		history.push('/register');
	}

  const showSestinationCards = () => {
    return destinationsData.map((d, index) => {
      return (
        <div className="col-lg-3 col-sm-6 new-destination-item" key={index}>
          <div className="single-destinations-list wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
            <div className="thumb">
              <LazyImage src={d.image} alt="list" />
            </div>
            <div className="details">
              <div className="location-div">
                <p className="location line-clamp">{d.location || 'N/A'}</p>
              </div>
              <div className="title-div">
                <h4 className="title line-clamp">{d.name || 'N/A'}</h4>
              </div>
              <ul className="tp-list-meta border-bt-dot"></ul>
              <div className="new-destination-btn">
							{!commonFunction.isLogin() || !isAmbassadorOrWalker ?
                <i className="btn btn-yellow" data-toggle="modal" data-target={`#modal-new-dest-${index}`}>
                  <img src={publicUrl + "assets/images/huvr-btn.png"} alt='logo' className="huvr-btn" />
                </i>
              :
                <i className="btn btn-yellow" onClick={() => setSwitchToTravelerDialog(true)}>
                  <img src={publicUrl + "assets/images/huvr-btn.png"} alt='logo' className="huvr-btn" />
                </i>
              }
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="holiday-plan-area tp-holiday-plan-area mg-top-96" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/bg/8.png)', position: 'relative', zIndex: 1 }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="section-title text-center">
                <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">New Destinations</h2>
                <p className="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">Check out the latest destinations of the ever expanding Huvr!</p>
              </div>
            </div>
          </div>
          <div className="row">
            {showSestinationCards()}
          </div>
        </div>
      </div>
      {destinationsData.map((d, index) => {
				let banner = d.image ? d.image : publicUrl + 'assets/images/destination-list/1.png';
				if (d.banner) {
					banner = d.banner;
				}
				return (
					<div key={index} className="modal fade destination-body-popup" id={`modal-new-dest-${index}`}>
						<div>
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content payment-popup-body">
									<div className="popup-scroll">
										<button type="button" className="close" data-dismiss="modal"><i className="la la-times"></i></button>
										<figure className="destination-img">
											<LazyImage
												className="select-dest-img-modal"
												alt={d.name}
												src={banner}
											/>
										</figure>
										<div className="payment-popup-cnt">
											<h4>{d.name}</h4>
											<em>{d.location}</em>
											<p>{d.description}</p>
										</div>
									</div>
									<div className="payment-popup-btn">
										<button className="btn btn-yellow" onClick={() => ScheduleTrip(d)}>Huvr there Now!</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			})}
      <Dialog className="popup-verification" onClose={() => setUserIsNotRegistered(false)} aria-labelledby="simple-dialog-title" open={userIsNotRegistered}>
				<div>
					<p>To explore with Huvr, you must login or register first.</p>
					<br />
					<Button className="btn btn-yellow confirm-btn" onClick={redirectToRegister}>OK</Button>
				</div>
			</Dialog>
      <Dialog className="popup-verification" aria-labelledby="simple-dialog-title" open={switchToTravelerDialog}>
				<div>
					<p>To book a trip, go to Traveler mode.</p>
					<br />
					<Button className="btn btn-yellow confirm-btn" onClick={() => setSwitchToTravelerDialog(false)}>OK</Button>
				</div>
			</Dialog>
    </>
  )
}

NewDestinations.propTypes = {
	history: PropTypes.object.isRequired,
};

export default NewDestinations;
import UserUtils from './UserUtils';

export function validateEmail( email ) {
  const re = new RegExp( ['^(([^<>()\\[\\]\\,;:\\s@"]+(\\.[^<>()\\[\\]\\,;:\\s@"]+)*)',
    '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])',
    '|(([a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:\\-0-9]+\\.)',
    '+[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:]{2,}))$'].join( '' ) );

  return re.test( email );
}

export function validateAlpha( value ) {
  let isValidName = true;
  if ( /^[A-z]/.test( value ) ) {
    isValidName = false;
  }
  return isValidName;
}

export function validateLowerAlpha( value ) {
  let isValidName = true;
  if ( /^[a-z]/.test( value ) ) {
    isValidName = false;
  }
  return isValidName;
}
export function validateUpperAlpha( value ) {
  let isValidName = true;
  if ( /^[A-Z]/.test( value ) ) {
    isValidName = false;
  }
  return isValidName;
}

export function validateNumeric( value ) {
  let isValidNumeric = true;
  if ( /\d+/g.test( value ) ) {
    isValidNumeric = false;
  }
  return isValidNumeric;
}

export function IsJsonString( str ) {
  try {
    JSON.parse( str );
  } catch ( e ) {
    return false;
  }
  return true;
}

export function parseGraphQLErrorMessage( error ) {
  let errorMessage;
  errorMessage = error.toString().replace( 'Error: GraphQL error: ', '' );
  errorMessage = errorMessage.toString().replace( '400 - ', '' );
  errorMessage = errorMessage.toString().replace( '401 - ', '' );

  if ( IsJsonString( errorMessage ) ) {
    const errorObj = JSON.parse( errorMessage );
    errorMessage = errorObj.error.message;
  }

  if ( errorMessage.toLowerCase().indexOf( 'network error' ) !== -1 ) {
    errorMessage = 'An error occurred please try again later.';
  }

  return errorMessage;
}

export function parseTypeErrorMessage( error ) {
  let errorMessage;
  errorMessage = error.toString().replace( 'TypeError: ', '' );

  if ( IsJsonString( errorMessage ) ) {
    const errorObj = JSON.parse( errorMessage );
    errorMessage = errorObj.error.message;
  }

  if ( errorMessage.toLowerCase().indexOf( 'network error' ) !== -1 ) {
    errorMessage = 'An error occurred please try again later.';
  }

  return errorMessage;
}

export function FV( rate, nper, pmt, pv, type ) {
  const pow = ( 1 + rate ) ** ( nper );
  let fv;
  if ( rate ) {
    fv = ( ( pmt * ( 1 + rate * type ) * ( 1 - pow ) ) / rate ) - pv * pow;
  } else {
    fv = -1 * ( pv + pmt * nper );
  }
  return fv;
}

export function PMT( ratePerPeriod, numberOfPayments, presentValue, futureValue, type ) {
  let finalpmt = 0;
  let finalpmt1 = 0;
  if ( ratePerPeriod !== 0.0 ) {
    // Interest rate exists
    const q = ( 1 + ratePerPeriod ) ** numberOfPayments;
    finalpmt = -( ratePerPeriod * ( futureValue + ( q * presentValue ) ) ) / ( ( -1 + q ) * ( 1 + ratePerPeriod * ( type ) ) );
    if ( Number.isNaN( finalpmt ) ) {
      finalpmt = 0;
    }
    return finalpmt;
  } if ( numberOfPayments !== 0.0 ) {
    // No interest rate, but number of payments exists
    finalpmt1 = -( futureValue + presentValue ) / numberOfPayments;
    if ( Number.isNaN( finalpmt1 ) ) {
      finalpmt1 = 0;
    }
    return finalpmt1;
  }
  return 0;
}

export const scrollToTop = ( ) => {
  window.scrollTo( {
    top: 0,
  } );
};

export function formatDate( date ) {
  // get the month
  let month = date.getMonth();
  // get the day
  let day = date.getDate();
  // get the year
  let year = date.getFullYear();

  // pull the last two digits of the year
  year = year.toString().substr( 2, 2 );

  // increment month by 1 since it is 0 indexed
  month += 1;
  // converts month to a string
  month += '';

  // if month is 1-9 pad right with a 0 for two digits
  if ( month.length === 1 ) {
    month = `0${month}`;
  }

  // convert day to string
  day += '';

  // if day is between 1-9 pad right with a 0 for two digits
  if ( day.length === 1 ) {
    day = `0${day}`;
  }

  // return the string "MMddyy"
  return `${month}.${day}.${year}`;
}
// eslint-disable-next-line
export function numFormatter( num ) {
  if ( num > 999 && num <= 1000000 ) {
    return `${`$${( num / 1000 ).toFixed( 2 )}`}K`; // convert to K for number from > 1000 < 1 million
  } if ( num <= 999 ) {
    return `$${num}`; // if value < 1000, nothing to do
  }
}

export function perFormatter( num ) {
  return `${num}%`;
}

export function yearFormatter( year ) {
  if ( year === 1 ) {
    return `${year} yr`;
  }
  return `${year} yrs`;
}

export function mosFormatter( num ) {
  return `${num} mos`;
}
export function carValueFormatter( num ) {
  return `Car Value $${num}`;
}
// eslint-disable-next-line
export function thousandFormatter( num ) {
  if ( num > 999 && num <= 1000000 ) {
    return `${`$${( num / 1000 ).toFixed( 1 )}`}K`; // convert to K for number from > 1000 < 1 million
  } if ( num <= 999 ) {
    return `$${num}`; // if value < 1000, nothing to do
  }
}
export function nunAbv( num ) {
  return num / 1000;
}

export function isEmpty(str) {
  return str && !str.replace(/^\s+/g, '').length;
}

export function isEmptyV2(str) {
  return str == '' || str == null || str == undefined;
}

//CHECKED USER IS LOGGED OR NOT
export const isLogin = () => {
  const token = UserUtils.getAccessToken();
  if (token !== null) {
    return true;
  }
  return false;
}

// Validate password
export function isValidPassword(password) {
  const re =/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\sa-zA-Z0-9]).{8,}/;
  return re.test(password);
}

export const getQueryParam = (location, name) => {
  const params = new URLSearchParams(location.search);
  return params.get(name);
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery } from '@apollo/react-hooks';
import { confirmAlert } from 'react-confirm-alert';
import PageLoader from '../../components/layout/ui/page-loader';
import UserUtils from '../../utilities/UserUtils';

const AMBASSADOR_ACTIVATION_LINK = loader('../../graphql/schema/ambassador/activation-link.graphql');
const AMBASSADOR_ACCEPT_INVITATION= loader('../../graphql/schema/ambassador/accept-invitation.graphql');

const useStyles = makeStyles(() => ({
    section: {
        backgroundColor: '#eee',
    },
    container: {
        minHeight: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    message:  {
        maxWidth: 650,
        width:'100%',
        marginBottom: 60,
        backgroundColor: '#fff',
        padding: 50,
        borderRadius: 5
    },
    expired: {
        opacity: 0.5
    }
}));

const AmbassadorInvitation = (props) => {

    const { history, location } = props;
    const classes = useStyles();
    const params = new URLSearchParams(location.search);
    const [activation, setActivation] = useState({});
    const [hasError, setHasError] = useState('');
    const [userId, setUserId] = useState('');
    const [accessToken, setAccessToken] = useState(null);
    const [verify, setVerify] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    
    const { data, loading, refetch } = useQuery(AMBASSADOR_ACTIVATION_LINK, {
        notifyOnNetworkStatusChange: true,
        variables: {
            code: params.get('invitation_code'),
        },
        onCompleted({ ambassadorActivationLink }) {
            setActivation(ambassadorActivationLink);
            setVerify(true);
        },
        onError(errors) {
          const formatedErrors = errors.graphQLErrors.map(({ message }) => (
            <span key={`login-error-${Math.random()}`}>{message}</span>
          ));
    
          setHasError(formatedErrors);
        },
    });

    const [acceptInvitation, { acceptLoading }] =  useMutation(AMBASSADOR_ACCEPT_INVITATION, {
        errorPolicy: 'ignore',
        onCompleted({ acceptInvitation }) {
            setShowLoader(false);
            if(acceptInvitation.status == 'error') {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="react-confirm-alert-body text-center p-5" style={{ maxWidth: 550, borderRadius: 5 }}>
                                <h1 className="pb-3">Not match!</h1>
                                <p style={{ hyphens: 'none' }}>{ acceptInvitation.message }</p>
                                <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                                    onClose();
                                }}>
                                    Ok
                                </button>
                            </div>
                        );
                    },
                    overlayClassName: 'header-overlay',
                    afterClose: () => {
                      
                    }
                });
            } else {
                UserUtils.setUserType('A');
                history.push('/');
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="react-confirm-alert-body text-center p-5" style={{ maxWidth: 550, borderRadius: 5 }}>
                                <h1 className="pb-3">Welcome to Huvr Pro!</h1>
                                <p style={{ hyphens: 'none' }}>{ acceptInvitation.message }</p>
                                {acceptInvitation.message.includes('verification steps') &&
                                    <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                                        history.push('/complete-verification-ambassador');
                                        onClose();
                                    }}>
                                        Complete Verification
                                    </button>
                                }

                                {!acceptInvitation.message.includes('verification steps') &&
                                    <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                                        onClose();
                                    }}>
                                        Close
                                    </button>
                                }
                            </div>
                        );
                    },
                    overlayClassName: 'header-overlay',
                    afterClose: () => {
                    UserUtils.removeAmbassadorId();
                    }
                });
            }
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        setUserId(UserUtils.getUserID());
        setAccessToken(UserUtils.getAccessToken());
    });

    const handleAcceptInvitation = () => {
        if (accessToken) {
            setShowLoader(true);
            acceptInvitation({
                variables: {
                    ambassador_id: activation.ambassador_id,
                    walker_id: userId
                }
            })
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body text-center p-5" style={{ maxWidth: 550, borderRadius: 5 }}>
                            <h1 className="pb-3">You are not logged in!</h1>
                            <p style={{ hyphens: 'none' }}>Please login to your walker account to accept invitation.</p>
                            <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                                history.push('/complete-verification-ambassador');
                                onClose();
                            }}>
                                Go to login page
                            </button>
                        </div>
                    );
                },
                overlayClassName: 'header-overlay',
                afterClose: () => {
                    UserUtils.setAmbassadorId(activation.ambassador_id);
                    history.push('/login');
                }
            });
        }
    }

    return (
        <>
            {(loading || acceptLoading || showLoader) && <PageLoader loading={loading || acceptLoading} />}
            <div className={`blog-details-area pd-top-142 viaje-go-top ${classes.section}`}>
                <div className="container">
                    <div className={`${classes.container}`}>
                        <div className={`${classes.message}`}>
                            <h3>Join as an Ambassador</h3>
                            {moment().diff(moment(activation.expired_at)) > 0 ?
                            <>
                                <p className='my-4'>You’ve been invited to become a brand ambassador by <strong>{activation.company_name}</strong>. This link has expired {moment(activation.expired_at).fromNow()}.</p>
                                <button className={`btn btn-yellow ${classes.expired}`}>Invitation Expired</button>
                            </>
                            :
                            <>
                                <p className='my-4'>You’ve been invited to become a brand ambassador by <strong>{activation.company_name}</strong>. This link will expire in {moment(activation.expired_at).fromNow()}.</p>
                                <button className='btn btn-yellow' onClick={() => handleAcceptInvitation()} disabled={!verify}>Accept Invitation</button>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

AmbassadorInvitation.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(AmbassadorInvitation);


import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Banner from './banner';
import Search from './search';
import IntroSection from './intro-section';
import DestinationCarousel from './destination-carousel';
import VideoSection from './video-section';
import NewDestinations from './new-destinations';
import Review from './review-section';
import Blog from './blog-section';
import Add from './add-section';
import Subscribe from './subscribe';

const Home = (props) => {

  const { history } = props;

  return (
    <>
      <Banner />

      {/* search section */}
      <Search history={history}/>

      {/* intro section  */}
      <IntroSection />

      {/* Beta Destinations, offer section  */}
      <DestinationCarousel history={history}/>
      
      {/* video section */}
      <VideoSection />

      {/*New Destinations*/}
      <NewDestinations history={history}/>

      {/* reivew section  */}
      <Review />

      {/* blog section  */}
      <Blog history={history}/>

      {/* Add Section */}
      {/* <Add /> */}

      {/* subscribe */}
      {/* <Subscribe /> */}
    </>
  )
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(Home);
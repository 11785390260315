import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getMessaging, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBR98O8ZVVDhHuGfyte4Z4R5hk1bcO84wM",
  authDomain: "huvruat.firebaseapp.com",
  databaseURL: "https://huvruat-default-rtdb.firebaseio.com",
  projectId: "huvruat",
  storageBucket: "huvruat.appspot.com",
  messagingSenderId: "233403325909",
  appId: "1:233403325909:web:b958e3ba69f849000c715c",
  measurementId: "G-Z6GR4JKMEQ"
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const messaging = getMessaging(app);
const firestore = getFirestore(app);

export { database, messaging, firestore };
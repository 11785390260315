import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LazyImage from '../../utilities/lazy-image';
import ScrollIntoView from 'react-scroll-into-view';
import * as commonFunction from '../../utilities/commonFunctions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Slide } from '@material-ui/core';
import { detect } from 'detect-browser';
import { useCookies } from 'react-cookie';
import userUtils from '../../utilities/UserUtils';

const publicUrl = process.env.PUBLIC_URL + '/';
const disableSafari = process.env.REACT_APP_DISABLE_SAFARI === 'true';
const WALKER_TRAVELER_TOGGLE = loader('../../graphql/schema/auth/walker-traveler-toggle.graphql');

const useStyles = makeStyles((theme) => ({
	link: {
		color: 'rgba(255, 255, 255, 0.75)',
		cursor: 'pointer',
		'&:hover': {
			color: 'var(--main-color-one)'
		}
	},
	cookies: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		width: '100%',
		backgroundColor: 'var(--main-color-one)',
		zIndex: 98,
		fontSize: 13,
		boxShadow: '0px 0px 20px rgb(0 0 0 / 40%)',
		'&> div': {
			minHeight: 115,
			padding: '15px 0px',
		},
		'& p': {
			marginBottom: 10,
		},
		'& .btn': {
			width: 180,
			padding: '0.25rem 0.5rem !important',
			fontSize: '.875rem',
			lineHeight: '1.5 !important',
			borderRadius: '0.2rem !important',
			height: '40px !important',
		}
	}
}));

const Footer = (props) => {
	
	const { history } = props;
    const classes = useStyles();
	const browser = detect();
	const [showCookies, setShowCookies] = useState(false);
	const [cookies, setCookie] = useCookies(['unsupported_browser']);
	const [isAmbassadorOrWalker, setIsAmbassadorOrWalker] = useState(false);

	const { data: toggleMode } = useQuery(WALKER_TRAVELER_TOGGLE);
  
	useEffect(() => {
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";
		document.body.appendChild(minscript);
		
		if (disableSafari && (browser.name === 'safari'  && cookies && !cookies.unsupported_browser)) {
			setTimeout(() => {
				setShowCookies(true);
			}, 2000);
		}

		var isWalkerOrAmbassador = userUtils.getUserType() == 'W' || userUtils.getUserType() == 'A';
		setIsAmbassadorOrWalker(isWalkerOrAmbassador);
	}, []);

	const homeHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#home").offset().top - 100
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	}

	const aboutHuvrHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#about-huvr").offset().top - 100
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const blogHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".blog-area").offset().top - 160
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const handleLoggedInUserDestination = (e) => {
		e.preventDefault();
		if (!commonFunction.isLogin() || !isAmbassadorOrWalker ) {
			history.push('/destination-categories');
		} else {
			const pageName = props.location.pathname;
			if (pageName !== '/') {
				$('#loader-overlay').css({ "visibility": "visible" });
				history.push('/');
				setTimeout(function () {
					$('html, body').animate({
						scrollTop: $(".destination-carousel").offset().top - 160
					}, 0);
				}, 500);
				setTimeout(function () {
					$('#loader-overlay').css({ "visibility": "hidden" });
				}, 2000);
			}
		}
	};

	const handleCookies = () => {
		setShowCookies(false);
		setCookie('unsupported_browser', true, { path: '/' });
	}

	return (
		<footer className="footer-area" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/bg/2.png)', display: 'none' }}>
			<div className="container">
				<div className="row">
					<div className="col-lg-5 col-md-6">
						<div className="footer-widget widget">
							<div className="about_us_widget">
								<Link to="/" className="footer-logo">
									<LazyImage src={publicUrl + "assets/images/footer-logo.png"} alt="footer logo" />
								</Link>
								<p>Forget canned virtual travel and pre-recorded videos. <span className="text-block">Explore the world and experience virtual teleportation in real-time.</span> <span className="text-block">You are in the drivers seat and if you seek, you shall find plenty with Huvr.</span></p>
								<ul className="social-icon">
									<li>
										<a className="facebook" href="https://www.facebook.com/huvrapp" target="_blank" rel="noreferrer"><i className="fa fa-facebook" /></a>
									</li>
									<li>
										<a className="twitter" href="https://twitter.com/HuvrApp" target="_blank" rel="noreferrer"><i className="fa fa-twitter" /></a>
									</li>
									<li>
										<a className="pinterest" href="https://www.instagram.com/huvrapp/" target="_blank" rel="noreferrer"><i className="fa fa-instagram" /></a>
									</li>
									<li>
										<a className="pinterest" href="https://www.linkedin.com/company/69161747/" target="_blank" rel="noreferrer"><i className="fa fa-linkedin" /></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-2 col-md-6">
						<div className="footer-widget widget">
							<h4 className="widget-title">For Businesses</h4>
							<ul className="widget_nav_menu  viaje-go-top">
								<li><a href="https://go.huvr.com/huvrpro/" target="_blank" rel="noreferrer">Huvr Pro</a></li>
								<li><a href="https://go.huvr.com/huvrcares/" target="_blank" rel="noreferrer">Huvr Cares</a></li>
								<li><a href="https://go.huvr.com/huvr-educate/" target="_blank" rel="noreferrer">Huvr Educate</a></li>
								<li><a href="https://go.huvr.com/advertising/" target="_blank" rel="noreferrer">Advertise</a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-2 col-md-6">
						<div className="footer-widget widget body-font">
							<h4 className="widget-title">Quick Link</h4>
							<ul className="widget_nav_menu  viaje-go-top">
								<li className={`${classes.link} menu-item-has-children`}>
									<ScrollIntoView selector="#home" onClick={homeHandler}>Home</ScrollIntoView>
								</li>
								<li className={`${classes.link} menu-item-has-children`}>
									<ScrollIntoView selector="#about-huvr" onClick={aboutHuvrHandler}>About Huvr</ScrollIntoView>
								</li>
								<li className={`${classes.link} menu-item-has-children`}>
									<ScrollIntoView selector="#destination-carousel" onClick={handleLoggedInUserDestination}>Destinations</ScrollIntoView>
								</li>
								<li className={`${classes.link} menu-item-has-children`}>
									<ScrollIntoView selector="#our-blog" onClick={blogHandler}>Our Blog</ScrollIntoView>
								</li>
								<li><a href="https://go.huvr.com/contact-us/" target="_blank" rel="noreferrer">Contact</a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="footer-widget widget body-font">
							<h4 className="widget-title">Instagram Gallery</h4>
							<ul className="widget-instagram-feed">
								<li><a><LazyImage src={publicUrl + "assets/images/footer/1.png"} alt="img" /></a></li>
								<li><a><LazyImage src={publicUrl + "assets/images/footer/2.png"} alt="img" /></a></li>
								<li><a><LazyImage src={publicUrl + "assets/images/footer/3.png"} alt="img" /></a></li>
								<li><a><LazyImage src={publicUrl + "assets/images/footer/4.png"} alt="img" /></a></li>
								<li><a><LazyImage src={publicUrl + "assets/images/footer/5.png"} alt="img" /></a></li>
								<li><a><LazyImage src={publicUrl + "assets/images/footer/6.png"} alt="img" /></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright-inner">
				<div className="copyright-text">
					© 2021 Huvr, Inc. All International Rights Reserved.
					</div>
			</div>
			<Slide direction="up" in={showCookies}>
				<div className={classes.cookies}>
					<div className="container">
						<p>The Huvr Web Application currently does not support traveling to destinations in the Safari browser, we apologize for any inconvenience. Please use an alternate browser like Google Chrome or download the Huvr mobile application for iOS and Android as we work to quickly resolve this temporary issue with Safari.</p>
						<button className="btn btn-blue btn-sm" onClick={handleCookies}>I Understand</button>
					</div>
				</div>
			</Slide>
		</footer>
	)
}

export default Footer;

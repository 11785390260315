import { useState, useEffect, useRef, memo } from 'react';
import { useApolloClient, useMutation} from '@apollo/react-hooks';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import userUtils from './UserUtils';
import { loader } from 'graphql.macro';
import DOMPurify from "dompurify";

const useStyles = makeStyles((theme) => ({
    box : { 
        marginTop: '20px',
        width: '100%', 
        // height: '150px', 
        // background: 'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(28,119,245,1) 0%, rgba(28,119,245,1) 0%, rgba(0,72,203,1) 100%)'
    },
    ad: {
        margin: 'auto', 
        display: 'block'
    }
}))

const AdBanner = (props) => {

	const { history } = props;
    const classes = useStyles();
	const apolloClient = useApolloClient();
    const [content, setContent] = useState(null);

	useEffect(() => {
        if(props.content.ad_content) {
            setContent(DOMPurify.sanitize(props.content.ad_content.replace('></a>', '/></a>')).replace('<a', '<a target="_blank"'))
        }

        console.log('props.content ==> ', props.content);
	}, [props.content]);

    return (
        <div className={classes.box+' ad-banner'} dangerouslySetInnerHTML={{ __html: content }}></div>
    );
}

AdBanner.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const enhance = compose(
	withRouter,
);

export default enhance(AdBanner);
import React, { useState } from 'react';
import { withRouter, Link} from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import PropTypes from "prop-types";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import $ from 'jquery';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const FORGOT_PASSWORD = loader('./../../../graphql/schema/auth/forgot-password.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';
const wpWebApp = process.env.REACT_APP_WP_WEBAPP_URL;

const ForgotPassword = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumerError, setPhoneNumberError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});

  const handlePhoneNumber = (number) => {
    if ((number !== undefined)) {
      setHasError({});
      $('#outlined-phone-number-input').prop('disabled', false);
      if (isValidPhoneNumber(number)) {
        setPhoneNumber(number);
        setPhoneNumberError("");
      } else {
        setPhoneNumberError('Please Enter valid Phone Number.');
      }
    }
  }

  const handleFocus = () => {
    if ($('#outlined-phone-number-input').val() === '') {
      $('#outlined-phone-number-input').prop('disabled', true);
      setPhoneNumberError('Please Select Country Calling Code.');
    }
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneNumber === "") {
      setPhoneNumberError('Please enter Phone Number.');
      formIsValid = false;
    }
    setHasError(errors);
    return formIsValid;
  }

  const postforgotPassword = (response) => {
    let error = {};
    apolloClient.writeData({ data: { isforgotPassword: true } });
    console.log(response)
    if (response.status === "SMS_SENT") {
     UserUtils.setPhoneNumber(phoneNumber);
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push('/update-forgot-password');
      }, 1500)
    } else {
      error['otpError'] = response.message;
      setHasError(error);
    } 
  };

  const [submitforgotPassword, {loading: forgotPasswordLoading}] = useMutation(FORGOT_PASSWORD, {
    onCompleted({
      forgotPassword :{
        status: status,
        message: message
    },
    }) {
      postforgotPassword({ status, message});
    },
   onError(errors) {
     const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      let error = {};
      error['failed'] = formatedErrors;
      setHasError(error);
    },
  });
 const SubmitForgotPassword= (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitforgotPassword({
        variables: {
          "phone": phoneNumber
        },
      });
     }
  };

  
return(
    <div className="signup-container-row2">
      {forgotPasswordLoading && <PageLoader loading={forgotPasswordLoading} />}
        <div className="signup-row">
          <div className="signup-left2">
            <div className="signup-cnt">
              <h4 component="h4">Forgot Password</h4>
              <p>Huvr opens you to the world to virtual exploration in travel, news, healthcare, education, sports, events, real estate, and more—all through the power of the gig economy and licensed brands.</p>
            </div>
          </div>
          <div className="signup-right2">
            <div className="signup-form">
              <div className="form-title">
                <h4 component="h4">Welcome Back</h4>
                <p>Regain access to your account by resetting your password for a seamless and personalized experience.</p>
              </div>
              <form name="phoneVerfication" onSubmit={SubmitForgotPassword}>
                {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
                <div className="error-box">
                  {(hasError.otpError !== undefined || phoneNumerError !== "")  && Object.values(hasError) && <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />}
                  {hasError.otpError !== undefined && <p className="is-invalid">{hasError.otpError}</p>}
                  {phoneNumerError !== "" && <p className="is-invalid">{phoneNumerError}</p>}
                </div>
                <div className="form-input-box2">
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry=""
                  id="outlined-phone-number-input"
                  placeholder="Enter Phone Number"
                  className={`phone-input-data`}
                  type="text"
                  name="phoneNumber"
                  margin="normal"
                  variant="outlined"
                  onFocus={handleFocus}
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                />
              </div>
                <div className="form-btn">
                 <input type="submit" color="inherit" value="Submit" />
                </div>  
              </form>
            </div>
          </div>
        </div>
         </div>
        );
     };
     ForgotPassword.propTypes = {
        history: PropTypes.object.isRequired,
      };
      
      const enhance = compose(
        withRouter,
      );
      
    export default enhance(ForgotPassword);
      
import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import { confirmAlert } from 'react-confirm-alert';
import SubHeader from '../../components/layout/sub-header';


const CREATE_STRIPEACCOUNT_AND_GENERATE_LINK = loader('../../graphql/schema/payments/create-stripe-account-and-generate-link.graphql');
const GET_USER_STRIPE_CONNECT_STATUS = loader('../../graphql/schema/payments/get-user-stripe-connect-status.graphql');
const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

const PayoutAccount = () => {

  const [countryCode, setCountryCode] = useState('');
  const [hasError, setHasError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [disabledCountry, setDisabledCountry] = useState(false);

  const { data: stripeStatus, loading: stripeConnectStatus } = useQuery(GET_USER_STRIPE_CONNECT_STATUS);

  const [submitCreateStripeAccountAndGenerateLink, { loading: CreateStripeAccountAndGenerateLinkLoading }] = useMutation(CREATE_STRIPEACCOUNT_AND_GENERATE_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted({
      createStripeAccountAndGenerateLink: {
        url,
        return_url,
        refresh_url,
        message,
        status
      },
    }) {
      if (status === "SUCCESS") {
        setDisabledCountry(true);
        postCreateStripeAccountAndGenerateLink({ url, return_url, refresh_url, status, message });
      }
    }
  });

  useEffect(() => {
    if (stripeStatus && stripeStatus.getUserStripeConnectStatus) {
      submitCreateStripeAccountAndGenerateLink({
        variables: {
          country: countryCode
        },
      })
    } else {
      disabledCountry
    }
  }, [stripeStatus]);

  const postCreateStripeAccountAndGenerateLink = (resUser) => {
    if (resUser.status === "SUCCESS") {
      setSuccessMessage(resUser.message);
      window.open(`${resUser.url}?return_url=${resUser.return_url}&refresh_url=${resUser.refresh_url}`, '_blank');
    }
  }

  const onSubmitCreateStripeAccountAndGenerateLink = () => {
    if (countryCode == '') {
      showAlertMessage("Please select a country");
    } else {
      submitCreateStripeAccountAndGenerateLink({
        variables: {
          country_code: countryCode
        },
      })
    }
  }

  const verifyCountryCode = (code) => {
    fetch('https://api.stripe.com/v1/country_specs/' + code, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + process.env.REACT_APP_STRIPE_KEY,
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result && !result.error) {
            setCountryCode(code)
          } else {
            setCountryCode('');
            showAlertMessage(`${regionNamesInEnglish.of(code)} is not currently supported.`);
          }
        },
        (error) => {
          console.log('--error', error);
        }
      )
  }

  const showAlertMessage = (message) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body text-center p-5" style={{ maxWidth: 340, borderRadius: 5 }}>
            <h1 className="pb-3">Stripe Error</h1>
            <p style={{ hyphens: 'none' }}>{ message }</p>
            <button className="btn btn-yellow mt-3" style={{ minWidth: 150 }} onClick={onClose}>
              Okay
            </button>
          </div>
        );
      },
      overlayClassName: 'header-overlay'
    });
  }

  return (
    <div className="traveller-page">
      {(CreateStripeAccountAndGenerateLinkLoading || stripeConnectStatus) && <PageLoader loading={CreateStripeAccountAndGenerateLinkLoading || stripeConnectStatus} />}
      <div className="traveller-row">
        <SubHeader title="Payout Accounts" />
        <div className="container">
          <div className="travelle-body">
            <div className="destination-para">
              <p className="mb-3">This is the account where Huvr will deposit your earnings.</p>
              <p>A week for Huvr runs from Monday at 4:00 AM to the following  Monday at 3:59 AM. All walks during that time period will count towards that week&apos;s pay period.</p>
            </div>
            <div className="edit-form-row">
              {hasError && <p className="is-invalid">{hasError}</p>}
              <Form
                onSubmit={onSubmitCreateStripeAccountAndGenerateLink}
                render={({ handleSubmit, submitting }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <React.Fragment>
                      <h3 className="mb-3">Link Payment Methods</h3>
                      <div className="form-in mb-0">
                        <Field name="country">
                          {({ input }) => (
                            <div style={disabledCountry ? { opacity: 0.5 } : {}}>
                              <ReactFlagsSelect
                                {...input}
                                searchPlaceholder=""
                                searchable
                                selected={countryCode}
                                onSelect={code => verifyCountryCode(code)}
                                disabled={disabledCountry}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </React.Fragment>
                    <small className="text-danger">Attention Needed</small>
                    <div className="form-btn">
                      <Button type="submit" onSubmit={submitting} disabled={successMessage !== ""}>
                        {successMessage !== "" ?
                          <span>Redirecting to Stripe Connect</span>
                        :
                         <span>Connect to Stripe Account</span>
                        }
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PayoutAccount;
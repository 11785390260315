import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter, useParams } from 'react-router-dom';
import { Dialog, DialogContent, CircularProgress, DialogTitle, Divider, Typography, Button, OutlinedInput, InputAdornment, Box, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment-timezone';
import PageLoader from '../../components/layout/ui/page-loader';
import SubHeader from '../../components/layout/sub-header';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../payments/payment-form';
import { Bo } from 'react-flags-select';
import * as commonFunction from '../../utilities/commonFunctions';
const GET_GROUP_SCHEDULESTRIP = loader('../../graphql/schema/walker/get-group-scheduletrip.graphql');

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const publicUrl = process.env.PUBLIC_URL + '/';
const tripDetails = { color: '#00A9CB', marginBottom: '15px' };
const tripDetailsFinal = { color: '#00A9CB', fontSize: '15px' };
const detailValue = { color: '#6F6F6F', fontSize: '15px' };
const btnContained = { border: '1px solid #F1AB54', color: '#fff', backgroundColor: '#F1AB54', borderRadius: '100px', padding: 10, width: 120, marginLeft: 15};
const stepTitle = { color: '#00A9CB', textAlign: 'center' };

const EventRegistration = (props) => {
  const { history, location } = props;
  const { id } = useParams();
  const tripId = atob(id);
  const [step, setStep] = useState('details');
  const [groupTrip, setGroupTrip] = useState(null);
  const [destination, setDestination] = useState(null);

  useEffect(() => {
console.log('step', step);
  });

  const { data: scheduleTrip, refetch, loading } = useQuery(GET_GROUP_SCHEDULESTRIP, {
    variables: { schedule_trip_id: tripId },
    fetchPolicy: 'cache-and-network',
    onCompleted({ response }) {
        setGroupTrip(scheduleTrip.groupScheduleTrip.scheduleTrip);
        setDestination(scheduleTrip.groupScheduleTrip.scheduleTrip.destination);
    }
  });

  const next = () => {
    if(step == 'details') {
      setStep('payment');
    } else if(step == 'payment') {
      setStep('success');
    }
  }

  const cancel = () => {
      if(step == 'payment') {
        setStep('details');
      } else if(step == 'success') {
        setStep('payment');
      }
  }

  const register = () => {
    if(!commonFunction.isLogin()) {
      localStorage.setItem('event_id', id);
      history.push('/login');
    } else {
      history.push(`/join-group-trip/${id}`);
    }
  }


  return (
    <>
    {loading && <PageLoader loading={loading} />}
     <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Join Our Event" />
          <Box className="content content-wrapper">
            <div className='inner-menu-v3'>
              <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Event Registration</a>
            </div>
            <Box className="row outer-box">
              <Box className="col-md-5 col-sm-12 left-content">
                <img src={ destination ? destination.image : ''} />
              </Box>
              <Box className="col-md-7 col-sm-12 right-content">
                <Box style={{ margin: 0}} className="row">
                  <Box style={{ padding: 0 }} className="col-md-12">
                    <h3>{ destination ? destination.name : '' }</h3>
                    <p style={{ wordBreak: 'break-word' }}>{ destination ? destination.description : '' }</p>
                  </Box>
                  <Box style={{ padding: 0, marginTop:14, marginBottom:7 }} className="col-md-12">
                    <span className="event-section-header">Event Details</span>
                  </Box>
                  <Box className="col-md-5 col-sm-12 event-detail">
                    <Box className="detail-col p-2">
                      <img style={{ width: 15,height: 20}} src={`${publicUrl}assets/images/location-dot-solid.png`} />
                      <span>{ destination ? destination.location : '' }</span>
                    </Box>
                  </Box>
                  <Box className="col-md-5 col-sm-12 event-detail">
                    <Box className="detail-col p-2">
                      <img style={{ width: 15,height: 15}} src={`${publicUrl}assets/images/schedule-icon-solid.png`} />
                      <span>{ groupTrip ? moment(groupTrip.epoch * 1000).format('dddd, MMMM D, YYYY h:mm A') : '' }</span>
                    </Box>
                  </Box>
                  <Box className="col-md-2 col-sm-12 event-detail event-detailv2">
                    <Box className="detail-col p-2 border-2">
                      <img style={{ width: 15,height: 20}} src={`${publicUrl}assets/images/money-icon-solid.png`} />
                      <span>{ groupTrip ? groupTrip.schedule_price.toFixed(0) : '' }</span>
                    </Box>
                  </Box>
                  {(groupTrip && [1, 4].includes(groupTrip.status)) && (
                    <Box className="col-12 p-0 mt-3">
                      <Button className="event-btn" variant="contained" onClick={register}>
                      {!commonFunction.isLogin() ? 'Login to Register' : 'Register for the Event' }
                      </Button>
                    </Box>
                  )}
                  {(groupTrip && [3, 2].includes(groupTrip.status)) && (
                    <Box className="col-12 p-0 mt-3">
                      <p style={{fontSize: 14, color: 'red'}}>This group event has {groupTrip.status == 3 ? 'ended' : 'been cancelled'}.</p>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
      </div>
    </div>
    </>
  )
}

EventRegistration.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(EventRegistration)

import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import ReactFlagsSelect from 'react-flags-select';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { FormHelperText } from '@material-ui/core';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import jsonCountries from "../payments/countries.json";
import { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import SubHeader from '../../components/layout/sub-header';

const UPDATE_WALKER_PROFILE = loader('../../graphql/schema/walker/update-walker-profile.graphql');
const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');

const AmbassadorProfile = (props) => {
    const { history, location } = props;
    const [hasError, setHasError] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [details, setDetails] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [showUpdatedDialog, setShowUpdatedDialog] = useState(false);

    const { data: myDetails, loading } = useQuery(MY_DETAILS, {
        fetchPolicy: "no-cache",
        onCompleted() {
            console.log('MY_DETAILS', myDetails);
            setCountry(myDetails);
            setDetails({
                full_name: `${myDetails && myDetails.me.first_name}` + ' ' + `${myDetails && myDetails.me.last_name}`,
                city_or_town: myDetails.me.walker_profile && myDetails.me.walker_profile.city_or_town ? myDetails.me.walker_profile.city_or_town : '',
                state_or_province: myDetails.me.walker_profile && myDetails.me.walker_profile.state_or_province ? myDetails.me.walker_profile.state_or_province : '',
                countryCode: myDetails.me.walker_profile && myDetails.me.walker_profile.country ? myDetails.me.walker_profile.country : '',
                languages: myDetails.me.walker_profile && myDetails.me.walker_profile.languages ? myDetails.me.walker_profile.languages : '',
                description: myDetails.me.walker_profile && myDetails.me.walker_profile.ambassador_description ? myDetails.me.walker_profile.ambassador_description : '',
            })
        },
    });

    const setCountry = (myDetails) => {
        if (myDetails.me.walker_profile && myDetails.me.walker_profile.country) {
            if (myDetails.me.walker_profile.country.length != 2) {
                for (var key of Object.keys(jsonCountries)) {
                    if (jsonCountries[key].name.common == myDetails.me.walker_profile.country) {
                        setCountryCode(key);
                        return;
                    }
                }
            } else {
                setCountryCode(myDetails.me.walker_profile.country);
            }
        }
    }

    const [updateMyAccount] = useMutation(UPDATE_WALKER_PROFILE, {
        onCompleted() {
            setShowUpdatedDialog(true);
        },
        onError(errors) {
            const formatedErrors = errors.graphQLErrors.map(({ message }) => (
                <span key={`signup-error-${Math.random()}`}>{message}</span>
            ));
            setHasError(formatedErrors);
        },
    });

    const validate = (values) => {

        const errors = {};

        if (!values.city_or_town) {
            errors.city_or_town = 'Please Enter City / Town';
        }

        if (!values.state_or_province) {
            errors.state_or_province = 'Please Enter State / Province';
        }
        if (!countryCode) {
            errors.country = 'Please Select Country';
        }
        if (!values.languages) {
            errors.languages = 'Please Enter Language | Speak';
        }
        if (!values.description) {
            errors.description = 'Please Enter Ambassador Description';
        }
        return errors
    }

    const onSubmit = (values) => {
        updateMyAccount({
            variables: {
                data: {
                    city_or_town: values.city_or_town,
                    state_or_province: values.state_or_province,
                    country: countryCode,
                    languages: values.languages,
                    description: values.description,
                    is_ambassador: true
                },
            },
        });
    }

    const handleCloseDialog = () => {
        setShowUpdatedDialog(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="My Ambassador Profile" />
                    <div className="container">
                        <div className="travelle-body">
                            <div className="edit-form-row edit-form-row-new">
                                {hasError && <p className="is-invalid">{hasError}</p>}
                                <Form
                                    onSubmit={onSubmit}
                                    validate={validate}
                                    initialValues={{
                                        full_name: details.full_name,
                                        city_or_town: details.city_or_town,
                                        state_or_province: details.state_or_province,
                                        country: details.countryCode,
                                        languages: details.languages,
                                        description: details.description
                                    }}
                                    render={({ handleSubmit, submitting }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            noValidate
                                        >
                                            <div className="form-in">
                                                <label> Name</label>
                                                <Field
                                                    id="full_name"
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    name="full_name"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>City / Town <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="city_or_town"
                                                    placeholder="Enter City / Town"
                                                    type="type"
                                                    name="city_or_town"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>State / Province <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="state_or_province"
                                                    placeholder="Enter State / Province"
                                                    type="type"
                                                    name="state_or_province"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>Country <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="country"
                                                    name="country"
                                                >
                                                    {({ input }) => (
                                                        <div>
                                                            <ReactFlagsSelect
                                                                {...input}
                                                                searchPlaceholder=""
                                                                searchable
                                                                selected={countryCode}
                                                                onSelect={code => setCountryCode(code)}
                                                                className={(submitted && countryCode == '') ? 'has-error' : ''}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <FormHelperText error hidden={!(submitted && countryCode == '')}>Please Select Country</FormHelperText>
                                            </div>
                                            <div className="form-in">
                                                <label>Language I Speak <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="languages"
                                                    placeholder=""
                                                    type="type"
                                                    name="languages"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>

                                            <div className="form-in form-textarea">
                                                <label>Ambassador Description <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="description"
                                                    placeholder="Walker Description"
                                                    type="type"
                                                    name="description"
                                                    multiline
                                                    rows={7}
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>

                                            <div className="form-btn">
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={submitting}
                                                    onClick={() => setSubmitted(true)}
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                            <div className="form-btn">
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog className="popup-verification" onClose={() => setShowUpdatedDialog(false)} aria-labelledby="simple-dialog-title" open={showUpdatedDialog}>
                    <div>
                        <p>Ambassador profile updated successfully.</p>
					    <br />
                        <Button className="btn btn-yellow confirm-btn" onClick={handleCloseDialog}>OK</Button>
                    </div>
                </Dialog>
            </div>
        </>
    )
}
AmbassadorProfile.propTypes = {
    history: PropTypes.object.isRequired
};

const enhance = compose(
    withRouter,
);

export default enhance(AmbassadorProfile);

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { confirmAlert } from 'react-confirm-alert';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import userUtils from '../../utilities/UserUtils';
import SubHeader from '../../components/layout/sub-header';

const WALKER_TRAINING = loader('../../graphql/schema/walker/walker-training.graphql');
const WALKER_TRAINING_VIDEO = loader('../../graphql/schema/walker/walker-training-video.graphql');


const WalkerTraining = (props) => {

  const { history } = props;

  const [hasError, setHasError] = useState('');

  const { data: trainingVideo, loading } = useQuery(WALKER_TRAINING_VIDEO);

  //console.log('trainingVideo-----', trainingVideo)

  const [updateWalkerKit, { loading: submitting }] = useMutation(WALKER_TRAINING, {
    fetchPolicy: "no-cache",
    onCompleted() {
      if (userUtils.getUserType() == 'A') {
        history.push('/complete-verification-ambassador');
      } else {
        history.push('/make-money-for-walker');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const onEnd = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body text-center" style={{ borderRadius: 5 }}>
            <p>I agree to follow the recommended best practices for Walker and to provide the traveler with an excellent trip experience.</p>
            <div className="react-confirm-alert-button-group justify-content-center">
              <button onClick={onClose} className="btn btn-primary" style={{ backgroundColor: 'var(--main-color-two)', padding: '0 30px', minWidth: 100 }}>
                No
              </button>
              <button onClick={() => {
                updateWalkerKit({
                  variables: {
                    status: true,
                  },
                }).then(onClose);
              }} className="btn btn-yellow" style={{ backgroundColor: 'var(--main-color-one)', padding: '0 30px', minWidth: 100 }}>
                I Agree
              </button>
            </div>
          </div>
        );
      },
      overlayClassName: 'header-overlay'
    });
  }

  return (
    <>
      {(loading || submitting) && <PageLoader loading={loading || submitting} />}
      {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Walker Training" />
          <div className="travelle-body p-0">
            <div className="py-5 w-100" style={{ backgroundColor: '#000' }}>
              <video controls={false} autoPlay={true} src={trainingVideo && trainingVideo.walkerTrainingVideo.video_url} onEnded={onEnd} 
                style={{ maxHeight: 600, position: 'relative', width: '100%' }}>
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WalkerTraining.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(WalkerTraining);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

const publicUrl = process.env.PUBLIC_URL + '/';

const MobileGridTravelerInvite = (props) => {
    console.log('MobileGridTravelerInvite', props);
    return <>
      { props.rows.map(row => {
        return <Box className="event-row">
                    <Box className="event-row-inner">
                        <Box className="col-4">NAME</Box>
                        <Box className="col-8"><span>{`${row.first_name} ${row.last_name}`}</span></Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">EMAIL</Box>
                        <Box className="col-8">{row.email}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">ACTIONS</Box>
                        <Box className="col-8">
                            <Button 
                              variant="contained" 
                              className="gt-resend-invite" 
                              onClick={() => props.resendInvitation({group_schedule_trip_invite_id: row.id })}
                              disabled={props.resendDisabled}>
                                Resend
                            </Button>
                        </Box>
                    </Box>
                </Box>
      })
    }
    </>;
  };

  MobileGridTravelerInvite.propTypes = {
    history: PropTypes.object.isRequired,
  };
  
  const enhance = compose(
    withRouter,
  );

  export default withRouter(enhance(MobileGridTravelerInvite));
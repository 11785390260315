
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import 'date-fns';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

const ScheduleTripTimer = (props) => {

  const { history, onCloseScheduleTrip, scheduleTrip, serverTime } = props;

  const epoch = moment.utc(moment.unix(scheduleTrip.epoch), 'DD-MM-YYYY HH:mm:ss');

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [countDownFinished, setCountDownFinished] = useState(false);

  useEffect(() => {
    const currentTimezoneDateTime = moment.utc(serverTime, null).tz(scheduleTrip.destinationTimeZone);
    const scheduleDateTime = epoch.clone().tz(scheduleTrip.destinationTimeZone);
    const timeDiffInMilliseconds = scheduleDateTime.diff(currentTimezoneDateTime);
    countDown(timeDiffInMilliseconds);
  }, []);
  
  const countDown = (timeDiffInMilliseconds) => {
    setHours(Math.floor((timeDiffInMilliseconds / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((timeDiffInMilliseconds / 1000 / 60) % 60));
    setSeconds(Math.floor((timeDiffInMilliseconds / 1000) % 60));

    if (timeDiffInMilliseconds <= 0) {
      setCountDownFinished(true);
      return;
    }

    const timeMinusOneSecond = timeDiffInMilliseconds - 1000;
    setTimeout(() => countDown(timeMinusOneSecond), 1000);
  };

  const printTime = () => {
    const hourPrint = hours != 0? hours == 1? "1 hr " : `${hours} hrs ` : "";
    const minsPrint = minutes != 0? minutes == 1? " 1 min " : `${minutes} mins ` : "";
    const secsPrint = seconds != 0? seconds == 1? " 1 sec " : `${seconds} secs` : "0 sec";

    if (countDownFinished) {
      return "Please give the Walker \r a few minutes to get started.";
    }

    return `${hourPrint}${minsPrint}${secsPrint}`;
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="modal show fade destination-body-popup" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.3)'}}>
          <div style={{ width: '100%', padding: 20 }}>
            <div className="modal-dialog modal-dialog-centered h-100">
              <div className="modal-content payment-popup-body h-100" style={{ maxWidth: 600 }}>
                <button type="button" className="close" onClick={onCloseScheduleTrip}><i className="la la-times"></i></button>
                <div style={Styles.container}>
                  <div className="d-flex flex-column align-items-center justify-content-center" style={Styles.title}>
                    <h3 className="text-white">SCHEDULE</h3>
                    <p className="text-white">a virtual trip to</p>
                    <h4 className="text-white">{scheduleTrip.location}</h4>
                  </div>
                  <div className="schedule-pick-row">
                    <div className="text-center">
                      {scheduleTrip.station &&
                        <h4>{scheduleTrip.station}</h4>
                      }
                    </div>
                    <div className="schedule-pick-cnt">
                      <h4>{epoch.clone().tz(scheduleTrip.destinationTimeZone).format('dddd').toUpperCase()}</h4>
                      <p>{epoch.clone().tz(scheduleTrip.destinationTimeZone).format('MMM DD, YYYY')} </p>
                      <p>{epoch.clone().tz(scheduleTrip.destinationTimeZone).format('hh:mm A')}</p>
                    </div>
                    <div className="text-center">
                      <p className="mt-0">{moment().tz(moment.tz.guess()).zoneName()}</p>
                    </div>
                    <div className="text-center mt-3">
                      {!countDownFinished && <h4>TRIP STARTS IN</h4>} 
                      <h3 className="mb-0" style={{ fontWeight: 600 }}>{printTime()}</h3>
                    </div>
                  </div>
                  <div className="schedule-pick-row" >
                    <h4 className="package-title">Important</h4>
                    <div className="schedulePickRowList text-center">
                      <p style={Styles.p}>You will be notified 5 minutes before the<br/>start the tour</p>
                      <br/>
                      <p style={Styles.p}>Scheduled trips status can be checked to<br/><a href='#upcoming' onClick={() => history.push('/your-trips', { upcoming_trip: true })}>Your Trips -> Upcoming</a></p>
                      <br/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Styles = {
  container: {
    overflow: 'auto',
    backgroundColor: '#f2f2f2',
    borderRadius: 5
  },
  title: {
    backgroundColor: '#071C55',
    borderRadius: '5px 5px 0px 0px',
    paddingBottom: 20,
    paddingTop: 20,
  },
  blue: {
    color: '#01B9B7',
  },
  gray: {
    color: '#777',
  },
  p: {
    hyphens: 'none',
  }
};

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#01B9B7',
    '&$checked': {
      color: '#01B9B7',
    },
    '&$checked + $track': {
      backgroundColor: '#01B9B7',
    },
  },
  checked: {},
  track: {},
})(Switch);

ScheduleTripTimer.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(ScheduleTripTimer);

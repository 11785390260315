import React, { useEffect, useState, useCallback, useRef } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PageLoader from '../../components/layout/ui/page-loader';
import { withRouter } from 'react-router-dom';
import { Switch, Button, Modal, Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment-timezone';
import SubHeader from '../../components/layout/sub-header';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { makeStyles } from '@mui/styles';
import CreateGroupTripModal from '../../components/group-trips/create-group-trip-modal';
import CustomPagination from '../../components/group-trips/custom-pagination';
import CustomNoRowsOverlay from '../../components/group-trips/custom-nodata-overlay';
import MobileDataGrid from './mobile-data-grid';

const publicUrl = process.env.PUBLIC_URL + '/';
const SAVE_GROUP_SCHEDULETRIP = loader('../../graphql/schema/walker/save-group-scheduletrip.graphql');
const GET_GROUP_SCHEDULESTRIPS = loader('../../graphql/schema/walker/get-group-scheduletrips.graphql');
const REMOVE_GROUP_SCHEDULETRIP = loader('../../graphql/schema/walker/remove-group-scheduletrip.graphql');

const fetchPolicy = { fetchPolicy: 'network-only' };
const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-cell': {
      fontSize: '12px !important', // Change font size here
    },
    '& .MuiDataGrid-colCell, .MuiDataGrid-colCellWrapper': {
      fontSize: '12px !important', // Change header font size here
    }
  },
});

const ManageGroupTrips = (props) => {
  const { history, location } = props;
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [firsItem, setFirstItem] = useState(0);
  const [lastItem, setLastItem] = useState(0);
  const [selected, setSelected] = useState(null);
  const [showSubmitting, setShowSubmitting] = useState(false);
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(false);
  const [viewportSize, setViewportSize] = useState({ width: 0, height: 0 });
  const [firsLoad, setFirstLoad] = useState(false);
  const columns = [
    {
      field: 'destination.name',
      headerName: 'NAME',
      headerAlign: 'center',
      flex: 1,
      fontSize: 12,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.destination.name}
          </div>
        );
      }
    },
    {
      field: 'destination.location',
      headerName: 'DESTINATION',
      headerAlign: 'center',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.destination.location}
          </div>
        );
      }
    },
    {
      field: 'epoch',
      headerName: 'DATE & TIME',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            {moment.unix(params.row.epoch).format('MMM DD, YYYY h:mm A')}
          </div>
        );
      }
    },
    {
      field: 'schedule_minute',
      headerName: 'MINUTES',
      align: 'center',
      width: 88,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <p>{params.row.schedule_minute}</p>
        );
      }
    },
    {
      field: 'schedule_price',
      headerName: 'PRICE',
      align: 'center',
      width: 70,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <p>{`$${params.row.schedule_price}`}</p>
        );
      }
    },
    // {
    //   field: 'schedule_publish',
    //   headerName: 'PRIVATE',
    //   align: 'center',
    //   width: 90,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Switch
    //         checked={ !params.row.publish }
    //         onChange={(e) => {
    //           const rowIndex = rows.findIndex(item => item.id == params.row.id);
    //           updatePublish(rowIndex);
    //         }}
    //         name="isPrivate"
    //         color="primary"
    //         size="small"
    //         className="is-private-switch"
    //       />
    //     );
    //   }
    // },
    {
      field: 'status',
      headerName: 'STATUS',
      align: 'center',
      headerAlign: 'center',
      width: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          evaluateStatus(params.row.status)
        );
      }
    },
    {
      field: 'actions',
      width: 230,
      headerName: 'ACTION',
      align: 'center', 
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className="row">
              <Button class="gtrip-action-btn mr-1 gtrip-view-btn" onClick={() => props.history.push(`/view-group-trip/${params.row.id}`)}>
                <img src={publicUrl + "assets/images/inner-page/eye-solid.png"} /> View
              </Button>
              <Button class="gtrip-action-btn mr-1 gtrip-edit-btn" onClick={() => onEdit(params.row)}>
                <img src={publicUrl + "assets/images/inner-page/edit-solid.png"} /> Edit
              </Button>
              <Button class="gtrip-action-btn mr-1 gtrip-remove-btn" onClick={() => onRemove(params.row)}>
                <img src={publicUrl + "assets/images/inner-page/trash-solid.png"} /> Delete
              </Button>
          </Box>
        );
      }
    }
  ];
  
  const { data: scheduleTrips, refetch, loading } = useQuery(GET_GROUP_SCHEDULESTRIPS, {
    variables: { 'first': limit, page: page },
    fetchPolicy: 'network-only',
    onCompleted({ groupScheduleTrips }) {
      
    }
  });

  const [removeGroupScheduletrip, { loading: removingTrip }] = useMutation(REMOVE_GROUP_SCHEDULETRIP, {
    onCompleted({ response }) {
      refetch();
    },
    onError(errors) {
      console.log(errors);
    },
  });

  const [saveGroupScheduletrip, { loading: updatingTrip }] = useMutation(SAVE_GROUP_SCHEDULETRIP, {
    onCompleted({ response }) {
      refetch();
    },
    onError(errors) {
    props.close();
      console.log(errors);
    },
  });

  useEffect(() => {
    const handleResize = () => {
      let inMobile = window.innerWidth <= 621;
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setIsMobile(inMobile);

      if(!firsLoad) {
        setLimit(inMobile ? 5 : 10);
        setFirstLoad(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(scheduleTrips) {
      setRows(scheduleTrips.groupScheduleTrips.data);

      setTotal(scheduleTrips.groupScheduleTrips.paginatorInfo.total);
      setHasMorePages(scheduleTrips.groupScheduleTrips.paginatorInfo.hasMorePages);
      setFirstItem(scheduleTrips.groupScheduleTrips.paginatorInfo.firstItem);
      setLastItem(scheduleTrips.groupScheduleTrips.paginatorInfo.lastItem);
    }
  }, [scheduleTrips]);

  const updatePublish = (rowIndex) => {
    let row = { ...rows[rowIndex] };
    row.publish = !row.publish;
    
    let newRows = [...rows];
    newRows[rowIndex] = row;
    setRows(newRows);

    saveGroupScheduletrip({
      variables: {
        schedule_trip: {
          id: row.id,
          hour_format: row.hour_format,
          schedule_date: row.schedule_date,
          schedule_time: row.schedule_time,
          time_zone:row. time_zone,
          schedule_minute: row.schedule_minute,
          schedule_price: row.schedule_price,
          stream_code: row.stream_code,
          epoch: row.epoch,
          publish: row.publish
        },
        destination: {
          name: row.destination.name,
          description: row.destination.description,
          location: row.destination.location,
          longitude: row.destination.longitude,
          latitude: row.destination.latitude,
          time_zone: row.destination.time_zone,
        }
      }
    });
  };

  const evaluateStatus = (status) => {
    if(status == 1) {
      return <span className="gtrip-status status-upcoming">UPCOMING</span>;
    } else if(status == 2) {
      return <span className="gtrip-status status-cancelled">CANCELLED</span>;
    } else if(status == 3) {
      return <span className="gtrip-status status-completed">COMPLETED</span>;
    } else if(status == 4) {
      return <span className="gtrip-status status-live">LIVE</span>;
    } else {
      return <span className="gtrip-status status-expired">EXPIRED</span>;
    }
  }

  const goToNextPage = () => {
    const currentPage = page + 1;
    setPage(currentPage);
    refetch({ page: currentPage});
  }

  const goToPreviousPage = () => {
    const currentPage = page - 1;
    setPage(currentPage);
    refetch({ page: currentPage});
  }

  const goToPage = (val) => {
    setPage(val);
    refetch({ page: val});
  }

  const onEdit = (row) => {
    setSelected(row);
    setShowModal(true);
  }

  const onRemove = (row) => {
    setSelected(row);
    setShowConfirmationModal(true);
  }

  const removeGroupTrip = () => {
    setShowConfirmationModal(false);
    removeGroupScheduletrip({
      variables: { schedule_trip_id: selected.id }
    });
  }

  const refreshList = (mode = 'refresh') => {
    if(mode == 'create') {
      refetch({
        variables: { 'first': limit, page: round(total/limit) },
        fetchPolicy
      });
    } else {
      refetch(fetchPolicy);
    }
    setSelected(null);
  }

  const showLoader = () => {
    return showSubmitting || loading || removingTrip || updatingTrip;
  }

  return (
    <>
    {showLoader() && <PageLoader loading={showLoader()} />}
     <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Events" />
          <div className="container-full p-5 ml-5 mr-5 gtrip-list-wrapper">
            <div className='inner-menuv3'>
              <a className='prev-nav'>Manage Events</a>
            </div>
            <Box className="row">
              <Box className="col-md-12">
                <Button 
                  variant="contained" 
                  className="contained-btn create-event-btn"
                  onClick={() => {
                    setShowModal(true);
                    setSelected(null);
                  }}>
                    Create Event
                </Button>
              </Box>
              <Box className={`col-md-12 datagrid-wrapper ${isMobile ? 'datagrid-mobile' : ''}`}>
                {isMobile ? 
                <>
                 <MobileDataGrid rows={rows} onEdit={(data) => onEdit(data)} onRemove={(data) => onRemove(data)} />
                 {rows.length == 0 ? 
                  <p className="text-center">No data available</p> : 
                  <CustomPagination 
                    from={firsItem} 
                    to={lastItem} 
                    page={page} 
                    total={total}
                    hasMorePages={hasMorePages}
                    next={() => goToNextPage()}
                    previous={() => goToPreviousPage()}
                    goToPage={(e) => goToPage(e)}
                    className="mobile-pagination"
                  />
                 }
                </>
                :
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={limit}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    disableColumnMenu
                    headerHeight={35}
                    autoHeight
                    // className={`${classes.root} gtrip-datagrid`}
                    pagination // Enable pagination
                    components={{
                      Pagination: () => {
                        return <CustomPagination 
                        from={firsItem} 
                        to={lastItem} 
                        page={page} 
                        total={total}
                        hasMorePages={hasMorePages}
                        next={() => goToNextPage()}
                        previous={() => goToPreviousPage()}
                        goToPage={(e) => goToPage(e)}
                      />;
                      },
                      NoRowsOverlay: CustomNoRowsOverlay
                    }}
                  />
                }
              </Box>
            </Box>
          </div>
      </div>
    </div>
    {showModal && 
      <CreateGroupTripModal 
        show={showModal} 
        data={selected}
        close={() => setShowModal(false)} 
        open={() => setShowModal(true)} 
        setShowLoader={(e) => setShowSubmitting(e)}
        refreshList={() => refreshList()} />
    }

    <Modal
      open={showConfirmationModal}
      onClose={() => setShowConfirmationModal(false)}
      className="popupv2"
    >
      <div className="modal-dialog modal-dialog-centered dialog-centered remove-confirmation">
        <div className="modal-content popup-body">
          <div className="popup-scroll">
            <button type="button" className="close" onClick={() => setShowConfirmationModal(false)}><i className="la la-times"></i></button>
            <img className="modal-icon" src={publicUrl + 'assets/images/inner-page/danger-icon.png'} />
            <h3 style={{ textAlign: 'center'}}>Delete<br/>Confirmation</h3>
            <p>Are you sure you want to continue?</p>
          </div>
          <Box className="footer-btns" style={{ textAlign: 'center', marginTop: 25 }}>
              <Button variant="outlined" className="outlined-btn confirm" onClick={() => removeGroupTrip()}>Yes</Button>
              <Button variant="contained" className="contained-btn cancel" onClick={() => setShowConfirmationModal(false)}>No</Button>
          </Box>
        </div>
      </div>
    </Modal>
    </>
    
  )
}

ManageGroupTrips.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);


export default withRouter(enhance(ManageGroupTrips));
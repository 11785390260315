import React, { useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';

const DESTINATION_WISH_LIST = loader('../../graphql/schema/destinationView/destinationwishlist.graphql');
const DELETE_DESTINATION_WISH_LIST = loader('../../graphql/schema/destinationView/delete-DestinationWishList.graphql');

const DestinationWishList = (props) => {

    const { history } = props;

    const [destinationWishlistData, setDestinationWishlistData] = useState([]);
    const [open, setOpen] = useState(false);
    const [dest_id, setDest_id] = useState('');

    const [deleteDestinationWishList] = useMutation(DELETE_DESTINATION_WISH_LIST, {
        notifyOnNetworkStatusChange: true,
        onCompleted() {
            refetch();
        }
    });

    const { data, loading, refetch } = useQuery(DESTINATION_WISH_LIST, {
        notifyOnNetworkStatusChange: true,
        onCompleted({
            getDestinationWishlist
        }) {
            setDestinationWishlistData(getDestinationWishlist);
        }
    });

    useEffect(() => {
        refetch();
    }, [data]);

    const handleDeleteDestinationWishList = (value) => {
        setDest_id(value.id);
        setOpen(true);
    }

    const handleYes = () => {
        deleteDestinationWishList({
            variables: {
                id: dest_id
            },
        });
        setOpen(false);
    }

    const handleNo = () => {
        setOpen(false);
    }


    const handleCreateNewDestination = () => {
        history.push('/create-destinationwish-list');
    }

    return (
        <>
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="Destination Wishlist" />
                    <div className="container">
                        <div className="destination-body-new">
                                <div className="destination-body">
                                {loading ?
                                    <p className="no-data"><CircularProgress /></p>
                                :
                                    <>
                                        {destinationWishlistData.length <= 0 && <p className="no-data">No Data To Display</p>}
                                        {destinationWishlistData.length > 0 && destinationWishlistData.map((value, index) => {
                                            return (
                                                <div className="destination-box" key={index}>
                                                    <div className="box-left">
                                                        <figure>
                                                            <img alt="" src={publicUrl + "assets/images/inner-page/opera.jpg"} />
                                                        </figure>
                                                    </div>
                                                    <div className="box-center" >
                                                        <h4>{value.destination || 'N/A'}</h4>
                                                        <p>{value.city || 'N/A'}   {value.country || 'N/A'}</p>
                                                    </div>
                                                    <div className="box-right">
                                                        <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={() => handleDeleteDestinationWishList(value)}></i>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                            <div className="schedule-btn ">
                                <button className="btn btn-yellow m-3" onClick={handleCreateNewDestination}>Create New Destination</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                {/* <div className="box-right">
                    <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={handleNo}></i>
                </div> */}
                <DialogContent>
                    <div className="schedule-popup-div">
                        <h4>Are you sure you want to delete</h4>
                        <div className="delete-popup">
                            <button className="btn btn-yellow" onClick={handleYes} style={{ width: 100 }}>Yes</button>
                            <button className="btn btn-blue" onClick={handleNo} style={{ width: 100 }}>No</button>
                        </div>
                    </div>
                    
                </DialogContent>
                {/* <DialogActions >
                    <button className="btn btn-yellow" onClick={handleYes}>yes</button>
                    <button className="btn btn-yellow" onClick={handleNo}>no</button>
                </DialogActions> */}
            </Dialog>
        </>
    )
}

DestinationWishList.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(DestinationWishList)

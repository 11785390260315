import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter, useParams } from 'react-router-dom';
import {  Button,Box } from '@material-ui/core';
import SubHeader from '../../components/layout/sub-header';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import PageLoader from '../../components/layout/ui/page-loader';
const CANCEL_GROUP_TRIP = loader('../../graphql/schema/traveller/cancel-group-trip.graphql');
const VERIFY_GROUP_TRIP_PAYMENT = loader('../../graphql/schema/traveller/verify-group-trip-payment.graphql');

const EventPolicyCancellation = (props) => {
  const { history, location } = props;
  const { id } = useParams();
  const [canCancel, setCanCancel] = useState(false);

  const { loading: verifyLoading } = useQuery(VERIFY_GROUP_TRIP_PAYMENT, {
    variables: {
        booking_id: parseInt(id)
    },
    onCompleted({ verifyGroupTripPayment }) {
        if(verifyGroupTripPayment && verifyGroupTripPayment.status == 'REFUNDABLE') {
            setCanCancel(true);
        }
    },
   onError(errors) {
    console.log(errors);
    },
  });

  const [cancelGroupTrip, {loading: loading}] = useMutation(CANCEL_GROUP_TRIP, {
    variables: {
        booking_id: parseInt(id)
    },
    onCompleted({ response }) {
      history.push('/your-trips');
    },
   onError(errors) {
    console.log(errors);
    },
  });



  return (
    <>
    {(loading || verifyLoading) && <PageLoader loading={loading|| verifyLoading} /> }
     <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Policy Cancellation" />
          <div id="content-wrapper" className="container p-5">
            <div className='row mb-5'>
                <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Policy Cancellation</a>
            </div>
            <Box className="row traveller-head2 p-4">
                <Box className="col-md-8 col-sm-12 d-flex align-items-center">
                    <h4 style={{ color: '#fff'}}>
                        { canCancel ? 'Are you sure you want to cancel your booking?' : 'Cancellation period has already expired.' }
                    </h4>
                </Box>
                <Box className="col-md-4 col-sm-12">
                    <Button 
                        variant="contained" 
                        className={`${ canCancel ? 'proceed-btn' : 'disabled-btn'} custom-btnV3`}
                        onClick={() => cancelGroupTrip()}>
                            Proceed
                    </Button>
                </Box>
            </Box>
            <Box className="row p-4 policy-box">
                <Box className="col-md-12 cancellation-policy">
                    <p className="mt-2 mb-4">You are within the cancellation period. Please review our cancellation policies below and see the refund you are eligible for:</p>
                    <ul>
                        <li>If you cancel within 24 hours of payment, your payment is fully refundable.</li>
                        <li>If you cancel more than 24 hours after payment, you are eligible for a 50% refund.</li>
                        <li>Cancellations are only permitted up to 24 hours before the trip.</li>
                    </ul>
                </Box>
            </Box>
          </div>
      </div>
    </div>
    </>
  )
}

EventPolicyCancellation.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(EventPolicyCancellation)

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PageLoader from '../../components/layout/ui/page-loader';
import { withRouter, useParams } from 'react-router-dom';
import { Switch, Button, Modal, Box, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment-timezone';
import SubHeader from '../../components/layout/sub-header';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { makeStyles } from '@mui/styles';
import { ReactComponent as MailIcon } from '../../resource/images/group-trip/mail-icon.svg';
import { ReactComponent as CalendarIcon } from '../../resource/images/group-trip/calendar-lg-icon.svg';
import { ReactComponent as TimerIcon } from '../../resource/images/group-trip/timer-icon.svg';
import { ReactComponent as JoinedIcon } from '../../resource/images/group-trip/joined-icon.svg';
import { ReactComponent as DollarIcon } from '../../resource/images/group-trip/dollar-icon.svg';
import GroupScheduletripInviteModal from '../../components/group-trips/group-scheduletrip-invite-modal'
import CustomPagination from '../../components/group-trips/custom-pagination';
import CustomNoRowsOverlay from '../../components/group-trips/custom-nodata-overlay';
import MobileGridTravelerInvite from './mobile-grid-traveler-invite';
import Rating from '@material-ui/lab/Rating';
import { confirmAlert } from 'react-confirm-alert'; // Import the confirmAlert function
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
import axios from 'axios'
import fileDownload from 'js-file-download'
import MobileGridBooking from './mobile-grid-booking';

const publicUrl = process.env.PUBLIC_URL + '/';
const awsUrl = process.env.REACT_APP_AWS_S3_URL + '/';
const SAVE_GROUP_SCHEDULETRIP = loader('../../graphql/schema/walker/save-group-scheduletrip.graphql');
const GET_GROUP_SCHEDULESTRIP = loader('../../graphql/schema/walker/get-group-scheduletrip.graphql');
const GET_GOUP_SCHEDULETRIP_INVITES = loader('../../graphql/schema/walker/get-group-scheduletrip-invites.graphql');
const GET_GROUP_BOOKINGS = loader('../../graphql/schema/walker/get-group-bookings.graphql');
const RESEND_GROUP_TRIP_INVITE = loader('../../graphql/schema/walker/resend-group-trip-invite.graphql');
const GROUP_TRIP_DOWNLOAD_CHAT = loader('../../graphql/schema/walker/group-schedultrip-download-chat.graphql');

const fetchPolicy = { fetchPolicy: 'no-cache' };
const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-cell': {
            fontSize: '12px !important', // Change font size here
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-colCellWrapper': {
            fontSize: '12px !important', // Change header font size here
        }
    },
});

const ViewGroupTrip = (props) => {
    const { history, location } = props;
    const { id } = useParams();
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [groupTrip, setGroupTrips] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [tripInvites, setTripInvites] = useState([]);
    const [booked, setBooked] = useState([]);
    const [destination, setDestination] = useState(null);
    const [showSubmitting, setShowSubmitting] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [totalInvites, setTotalInvites] = useState(0);
    const [totalBoookings, setTotalBoookings] = useState(0);
    const [firstItemInvites, setFirstItemInvites] = useState(0);
    const [lastItemInvites, setLastItemInvites] = useState(0);
    const [hasMoreInvites, setHasMoreInvites] = useState(true);
    const [pageInvites, setPageInvites] = useState(1);
    const [firstItemBookings, setFirstItemBookings] = useState(0);
    const [lastItemBookings, setLastItemBookings] = useState(0);
    const [hasMoreBookings, setHasMoreBookings] = useState(true);
    const [pageBookings, setPageBookings] = useState(1);
    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(false);
    const invitesColumns = [
        { 
            field: 'name', 
            headerName: 'NAME', 
            width: 250, 
            headerAlign: 'center', 
            renderCell: (params) => (
                <span>{`${params.row.first_name} ${params.row.last_name}`}</span>
            )
        },
        { field: 'email', headerName: 'EMAIL', flex: 1, headerAlign: 'left', },
        {
          field: 'actions',
          headerName: 'ACTIONS',
          headerAlign: 'center',
          width: 150,
          renderCell: (params) => (
            <Button 
                variant="contained" 
                className="gt-resend-invite" 
                onClick={() => resendInvitation({group_schedule_trip_invite_id: params.row.id })}
                disabled={groupTrip && ![1, 4].includes(groupTrip.scheduleTrip.status)} >
              Resend
            </Button>
          ),
        },
      ];

    const bookedColumns = [
        { 
            field: 'first_name', 
            headerName: 'FIRST NAME', 
            width: 150, 
            headerAlign: 'center', 
            renderCell: (params) => (
                <span>{params.row.traveler.first_name}</span>
            )
        },
        { 
            field: 'last_name', 
            headerName: 'LAST NAME', 
            width: 150, 
            headerAlign: 'center', 
            renderCell: (params) => (
                <span>{params.row.traveler.last_name}</span>
            )
        },
        { 
            field: 'booking_date',
            headerName: 'DATE', 
            flex: 1, 
            headerAlign: 'left', 
            renderCell: (params) => (
                <span>{moment(params.row.created_at).format('MMMM DD, YYYY')}</span>
            )
        },
        { 
            field: 'booking_time', 
            headerName: 'TIME', 
            flex: 1, 
            headerAlign: 'left', 
            renderCell: (params) => (
                <span>{moment(params.row.created_at).format('h:mm A')}</span>
            )
        },
      ];

    const { data: scheduleTrip, refetch, loading } = useQuery(GET_GROUP_SCHEDULESTRIP, {
        variables: { schedule_trip_id: id },
        fetchPolicy: 'network-only',
        onCompleted({ response }) {
            setGroupTrips(scheduleTrip.groupScheduleTrip);
            setDestination(scheduleTrip.groupScheduleTrip.scheduleTrip.destination);

            if(scheduleTrip.groupScheduleTrip) {
                let videoSession = JSON.parse(scheduleTrip.groupScheduleTrip.videoRoomSession);

                videoSession && setVideoUrl(`${awsUrl}${videoSession.saved_video_url}`);
            }
        }
    });

    const { data: invites, refetch: getInvites, loading: fetchingInvites } = useQuery(GET_GOUP_SCHEDULETRIP_INVITES, {
        variables: { schedule_trip_id: id, first: limit, page: pageInvites },
        fetchPolicy: 'network-only',
        onCompleted({ response }) {
            
        }
    });

    const { data: bookings, refetch: getBooked, loading: fetchingBooked } = useQuery(GET_GROUP_BOOKINGS, {
        variables: { schedule_trip_id: id, first: limit, page: pageBookings },
        fetchPolicy: 'network-only',
        onCompleted({ response }) {
            setBooked(bookings.groupBookings.data)
            setTotalBoookings(bookings.groupBookings.paginatorInfo.total);
            setHasMoreBookings(bookings.groupBookings.paginatorInfo.hasMorePages);
            setFirstItemBookings(bookings.groupBookings.paginatorInfo.firstItem);
            setLastItemBookings(bookings.groupBookings.paginatorInfo.lastItem);
        }
    });

    const { data: resentData, refetch: resendInvitation, loading: resendingInvite } = useQuery(RESEND_GROUP_TRIP_INVITE, {
        skip: true,
        onCompleted({ response }) {
            // showAlert('Success', 'Invitation resent!');
        },
        onError(errors) {
            console.log(errors);
            // showAlert('Error', 'An error occured while sending invitaion!');
        },
    });

    const [saveGroupScheduletrip, { loading: updatingTrip }] = useMutation(SAVE_GROUP_SCHEDULETRIP, {
        onCompleted({ response }) {
            refetch();
        },
        onError(errors) {
            props.close();
            console.log(errors);
        },
    });

    const { data: chatData, refetch: downloadChat, loading: downloadingChat } = useQuery(GROUP_TRIP_DOWNLOAD_CHAT, {
        variables: { schedule_trip_id: id },
        fetchPolicy: 'network-only',
        skip: true, // Skip initial fetch
        onError(errors) {
            console.log(errors);
        },
    });

    useEffect(() => {
        let isMounted = true;
        fetchInvites();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 621);
        }

        handleResize();
        return () => {
            isMounted = false;
          };
    }, []);

    const handleDownloadChat = async () => {
        setShowSubmitting(true);
        
        setTimeout(async() => {
            const { data } = await downloadChat({
                variables: { schedule_trip_id: id },
                skip: false
            });
    
            if (data && data.groupScheduleTripDownloadChat && data.groupScheduleTripDownloadChat.csvData) {
                const csvData = data.groupScheduleTripDownloadChat.csvData;
                const blob = new Blob([csvData], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = data.groupScheduleTripDownloadChat.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }

            setShowSubmitting(false);
        }, 1500);
    }

    const updatePublish = () => {
        let row = groupTrip ? groupTrip.scheduleTrip : false;

        saveGroupScheduletrip({
            variables: {
                schedule_trip: {
                    id: row.id,
                    hour_format: row.hour_format,
                    schedule_date: row.schedule_date,
                    schedule_time: row.schedule_time,
                    time_zone: row.time_zone,
                    schedule_minute: row.schedule_minute,
                    schedule_price: row.schedule_price,
                    stream_code: row.stream_code,
                    epoch: row.epoch,
                publish: !row.publish
                },
                destination: {
                    name: row.destination.name,
                    description: row.destination.description,
                    location: row.destination.location,
                    longitude: row.destination.longitude,
                    latitude: row.destination.latitude,
                    time_zone: row.destination.time_zone,
                }
            }
        });
    };

    const handleDownload = (url) => {
        setShowSubmitting(true);
        let name = destination ? destination.name : 'event_archive';

        axios.get(url, {
            responseType: 'blob',
        })
        .then((res) => {
            fileDownload(res.data, `${name}.mp4`)
        })
        setShowSubmitting(false);
    }

    const showLoader = () => {
        return showSubmitting || loading || updatingTrip || resendingInvite || downloadingChat;
    }

    const showAlert = (type, message) => {
        confirmAlert({
			title: type,
			message: message,
            buttons: [ ]
		});

        setTimeout(() => {
            const modal = document.querySelector('.react-confirm-alert-overlay');
                if (modal) {
                    modal.click(); // Simulate clicking on the modal overlay to close it
                }
            }, 3000);
    }

    const getFormattedDate = (datetime) => {
        const dateTime = moment(datetime);

        return dateTime.format('MMM DD, YYYY hh:mm A');
    }

    const fetchInvites = async(variables = {}) => {
        const { data } = await getInvites({ schedule_trip_id: id, first: limit, ...variables });

        setTripInvites(data.groupScheduleTripInvites.data);
        setTotalInvites(data.groupScheduleTripInvites.paginatorInfo.total);
        setHasMoreInvites(data.groupScheduleTripInvites.paginatorInfo.hasMorePages);
        setFirstItemInvites(data.groupScheduleTripInvites.paginatorInfo.firstItem);
        setLastItemInvites(data.groupScheduleTripInvites.paginatorInfo.lastItem);
    }


  const goToNextPage = (type) => {
    if(type == 'invites') {
        const currentPage = pageInvites + 1;
        setPageInvites(currentPage);
        fetchInvites({ page: currentPage });
    } else {
        const currentPage = pageBookings + 1;
        setPageBookings(currentPage);
        getBooked({ page: currentPage });
    }
  }

  const goToPreviousPage = (type) => {
    if(type == 'invites') {
        const currentPage = pageInvites - 1;
        setPageInvites(currentPage);
        fetchInvites({ page: currentPage });
    } else {
        const currentPage = pageBookings - 1;
        setPageBookings(currentPage);
        getBooked({ page: currentPage });
    }
  }

  const goToPage = (val, type) => {
     if(type == 'invites') {
        setPageInvites(val);
        fetchInvites({ page: val });
    } else {
        setPageBookings(val);
        getBooked({ page: val });
    }
  }

    return (
        <>
            {showLoader() && <PageLoader loading={showLoader()} />}
            <div className="traveller-pagev2">
                <div className="traveller-row">
                    <SubHeader title="Events" />
                    <div className="container-full pt-5 pb-5 gtrip-list-wrapper">
                        <Box className="row">
                            <Box className={`col-md-12 ${isMobile ? 'p-0' : ''}`}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item container  spacing={2} justifyContent="center">
                                        <Grid item xs={isMobile ? 12 : 5}>
                                            <div className=''>
                                                <a className='prev-nav view-trip-title'>Scheduled Events</a>
                                            </div>
                                        </Grid>
                                        <Grid item container xs={isMobile ? 12 : 5} className="gt-top-header">
                                            <Grid item xs={4} md={4} lg={4} sx={{ mx: 1 }}>
                                                <Button className="gt-header-btn" disabled={!videoUrl} onClick={() => setShowVideoModal(true)}>Watch Video</Button>
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4} sx={{ mx: 1 }}>
                                                <Button className="gt-header-btn" disabled={!videoUrl} onClick={() => handleDownload(videoUrl)}>Download Video</Button>
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4} sx={{ mx: 1 }}>
                                                <Button className="gt-header-btn" disabled={groupTrip && groupTrip.scheduleTrip.status !== 3} onClick={() => handleDownloadChat()}>Download Chats</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                        <Box className="trip-widget" bgcolor="white" color="white" p={2}>
                                            <span>Invited</span>
                                            <h6>{ groupTrip ? groupTrip.totalInvited : 0 }</h6>
                                            <MailIcon className="gdash-icon" />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                        <Box className="trip-widget" bgcolor="white" color="white" p={2}>
                                            <span>Booked</span>
                                            <h6>{ groupTrip ? groupTrip.totalBooking : 0 }</h6>
                                            <CalendarIcon className="gdash-icon" />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                        <Box className="trip-widget" bgcolor="white" color="white" p={2}>
                                            <span>Minutes</span>
                                            <h6>{ groupTrip ? groupTrip.scheduleTrip.schedule_minute : 0 }</h6>
                                            <TimerIcon className="gdash-icon" />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                        <Box className="trip-widget" bgcolor="white" color="white" p={2}>
                                            <span>Joined</span>
                                            <h6>{ groupTrip ? groupTrip.totalJoined : 0 }</h6>
                                            <JoinedIcon className="gdash-icon" />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                        <Box className="trip-widget" bgcolor="white" color="white" p={2}>
                                            <span>Earning</span>
                                            <h6>{ groupTrip ? groupTrip.totalEarning : 0 }</h6>
                                            <DollarIcon className="gdash-icon" />
                                        </Box>
                                    </Grid>
                                    <Grid item container sx={12} md={10} lg={10}>
                                        <Grid item container xs={12} className="gt-section-box">
                                            <Grid item xs={12} md={3} lg={3} className="gt-view-details">
                                                <Box className='event-image'>
                                                    { destination && <img src={destination.image} /> }
                                                </Box>
                                                <p className="event-name">{ destination ? destination.name : '' }</p>
                                                <Rating name="read-only" value={groupTrip ? parseInt(groupTrip.avgRating) : 0} readOnly precision={0.5} size={isMobile ? 'medium' : 'small'}/>
                                                <p className="rating-details">{groupTrip ? groupTrip.avgRating : 0} (50 Ratings)</p>
                                            </Grid>
                                            {isMobile ? 
                                            <>
                                                <Grid item xs={4} className='gt-view-details2'>Destination</Grid>
                                                <Grid item xs={2} className='gt-view-details2'>-</Grid>
                                                <Grid item xs={6} className='gt-view-details2'>
                                                    <p>{destination ? destination.location : ''}</p>
                                                </Grid>
                                                <Grid item xs={4} className='gt-view-details2'>Description</Grid>
                                                <Grid item xs={2} className='gt-view-details2'>-</Grid>
                                                <Grid item xs={6} className='gt-view-details2'>
                                                    <p>{destination ? destination.description : ''}</p>
                                                </Grid>
                                                <Grid item xs={4} className='gt-view-details2'>Date & Time</Grid>
                                                <Grid item xs={2} className='gt-view-details2'>-</Grid>
                                                <Grid item xs={6} className='gt-view-details2'>
                                                    <p>{groupTrip ? getFormattedDate(`${groupTrip.scheduleTrip.schedule_date} ${groupTrip.scheduleTrip.schedule_time}`) : ''}</p>
                                                </Grid>
                                                <Grid item xs={4} className='gt-view-details2'>Time Zone</Grid>
                                                <Grid item xs={2} className='gt-view-details2'>-</Grid>
                                                <Grid item xs={6} className='gt-view-details2'>
                                                    <p>{destination ? destination.time_zone : ''}</p>
                                                </Grid>
                                            </> : 
                                            <>
                                            <Grid item xs={12} md={5} lg={5} className='gt-view-details2' container justifyContent="center" alignItems="center">
                                               <Box>
                                                    <p>Destination - {destination ? destination.location : ''}</p>
                                                    <p>Description - {destination ? destination.description : ''}</p>
                                               </Box>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4} className='gt-view-details2' container justifyContent="center" alignItems="center">
                                                <Box>
                                                    <p>Date & Time - {groupTrip ? getFormattedDate(`${groupTrip.scheduleTrip.schedule_date} ${groupTrip.scheduleTrip.schedule_time}`) : ''}</p>
                                                    <p>Time Zone - {destination ? destination.time_zone : ''}</p>
                                                </Box>
                                            </Grid>
                                            </>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item sx={12} md={2} lg={2}>
                                        <Box className="gt-section-box" bgcolor="white" color="white" p={2} height='100%' textAlign='center' justifyContent="center" alignContent="center">
                                            <p style={{ fontSize: 18}}>Private</p>
                                            <Switch
                                                checked={groupTrip ? !groupTrip.scheduleTrip.publish : false}
                                                onChange={(e) => updatePublish()}
                                                name="isPrivate"
                                                color="primary"
                                                size="small"
                                                className="is-private-switch"
                                            />
                                        </Box>
                                    </Grid> */}
                                    <Grid item container sx={10} md={10} lg={10} justifyContent="center">
                                       <Grid item container xs={12} className="gt-section-box">
                                            <Grid item xs={6} md={9} lg={9} className="gt-view-header">
                                                {!isMobile && 
                                                <Box className="header-icon">
                                                    <img src={publicUrl + "assets/images/inner-page/envelope-solid.png"} />
                                                </Box>
                                                }
                                                <h6>Invitation List</h6>
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={3}>
                                                <Button 
                                                    onClick={() => setShowInviteModal(true)} 
                                                    disabled={groupTrip && ![1, 4].includes(groupTrip.scheduleTrip.status)} 
                                                    className="gt-header-btn">
                                                        Email invitation
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box width='100%' marginTop={2} className={isMobile ? 'datagrid-mobile p-0' : ''}>
                                                { isMobile ?
                                                    <>
                                                    <MobileGridTravelerInvite 
                                                        rows={tripInvites} 
                                                        resendInvitation={resendInvitation} 
                                                        resendDisabled={groupTrip && ![1, 4].includes(groupTrip.scheduleTrip.status)}
                                                    /> 
                                                    {tripInvites.length == 0 ? 
                                                        <p className="text-center">No data available</p> : 
                                                        <CustomPagination 
                                                            from={firstItemInvites} 
                                                            to={lastItemInvites} 
                                                            page={pageInvites} 
                                                            total={totalInvites}
                                                            hasMorePages={hasMoreInvites}
                                                            next={() => goToNextPage('invites')}
                                                            previous={() => goToPreviousPage('invites')}
                                                            goToPage={(e) => goToPage(e, 'invites')}
                                                            className="mobile-pagination"
                                                        />
                                                    }
                                                    </>:
                                                    <DataGrid
                                                    rows={tripInvites}
                                                    columns={invitesColumns}
                                                    pageSize={limit}
                                                    checkboxSelection={false}
                                                    disableSelectionOnClick
                                                    autoHeight
                                                    disableColumnMenu
                                                    headerHeight={35}
                                                    // className={`${classes.root} gtrip-datagrid`}
                                                    rowHeight={40}
                                                    pagination
                                                    components={{
                                                        Pagination: () => {
                                                          return <CustomPagination 
                                                          from={firstItemInvites} 
                                                          to={lastItemInvites} 
                                                          page={pageInvites} 
                                                          total={totalInvites}
                                                          hasMorePages={hasMoreInvites}
                                                          next={() => goToNextPage('invites')}
                                                          previous={() => goToPreviousPage('invites')}
                                                          goToPage={(e) => goToPage(e, 'invites')}
                                                        />;
                                                        },
                                                        NoRowsOverlay: CustomNoRowsOverlay
                                                      }}
                                                />
                                                }
                                                </Box>
                                            </Grid>
                                       </Grid>
                                    </Grid>
                                    <Grid item container sx={10} md={10} lg={10} justifyContent="center">
                                        <Grid item container xs={12} className="gt-section-box">
                                            <Grid item sx={10} md={9} lg={9} className="gt-view-header">
                                                <Box className="header-icon">
                                                    <img src={publicUrl + "assets/images/inner-page/book-filled.png"} />
                                                </Box>
                                                <h6>Booking List</h6>
                                            </Grid>
                                            <Grid item sx={10} md={3} lg={3}>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box width='100%' marginTop={2} className={isMobile ? 'datagrid-mobile p-0' : ''}>
                                                { isMobile ?
                                                    <>
                                                    <MobileGridBooking rows={booked} /> 
                                                    {tripInvites.length == 0 ? 
                                                        <p className="text-center">No data available</p> : 
                                                        <CustomPagination 
                                                            from={firstItemBookings} 
                                                            to={lastItemBookings} 
                                                            page={pageBookings} 
                                                            total={totalBoookings}
                                                            hasMorePages={hasMoreBookings}
                                                            next={() => goToNextPage('bookings')}
                                                            previous={() => goToPreviousPage('bookings')}
                                                            goToPage={(e) => goToPage(e, 'bookings')}
                                                            className="mobile-pagination"
                                                        />
                                                    }
                                                    </> :
                                                    <DataGrid
                                                        rows={booked}
                                                        columns={bookedColumns}
                                                        pageSize={limit}
                                                        checkboxSelection={false}
                                                        disableSelectionOnClick
                                                        autoHeight
                                                        disableColumnMenu
                                                        headerHeight={35}
                                                        // className={`${classes.root} gtrip-datagrid`}
                                                        rowHeight={40}
                                                        pagination
                                                        components={{
                                                            Pagination: () => {
                                                            return <CustomPagination 
                                                            from={firstItemBookings} 
                                                            to={lastItemBookings} 
                                                            page={pageBookings} 
                                                            total={totalBoookings}
                                                            hasMorePages={hasMoreBookings}
                                                            next={() => goToNextPage('bookings')}
                                                            previous={() => goToPreviousPage('bookings')}
                                                            goToPage={(e) => goToPage(e, 'bookings')}
                                                            />;
                                                            },
                                                            NoRowsOverlay: CustomNoRowsOverlay
                                                        }}
                                                    />
                                                }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
            <GroupScheduletripInviteModal
             show={showInviteModal} 
             scheduleId={groupTrip ? groupTrip.scheduleTrip.id : null}
             close={() => setShowInviteModal(false)} 
             open={() => setShowInviteModal(true)} 
             setShowLoader={() => setShowSubmitting(true)} 
             showAlert={(type, message) => showAlert(type, message)}
             refreshList={() => fetchInvites()}  />

            <Modal
                open={showVideoModal}
                onClose={() => setShowVideoModal(false)}
                className="popupv2"
                >
                <div className="modal-dialog modal-dialog-centered dialog-centered remove-confirmation" style={{ width: '85%', maxWidth: 700 }}>
                    <div className="modal-content popup-body">
                    <div className="popup-scroll">
                        <button type="button" className="close" onClick={() => setShowVideoModal(false)}><i className="la la-times"></i></button>
                        <Box>
                            <video src={videoUrl} controls style={{width: '100%'}}></video>
                        </Box>
                    </div>
                    </div>
                </div>
                </Modal>
        </>

    )
}

ViewGroupTrip.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);


export default enhance(ViewGroupTrip)
import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ScrollIntoView from 'react-scroll-into-view';
import PropTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Rating from '@material-ui/lab/Rating';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Search from '../../screen/home/search';
import List from '@material-ui/core/List';
import Switch from 'react-switch';
import UserUtils from '../../utilities/UserUtils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as commonFunction from '../../utilities/commonFunctions';
import PageLoader from '../../components/layout/ui/page-loader';
import Select from 'react-select';
import { Dialog, Button } from '@material-ui/core';


const EMAIL_STRIPE_CONNECT = loader('../../graphql/schema/traveller/send-email-stripe-connect.graphql');
const LOGOUT = loader('../../graphql/schema/auth/logout.graphql');
const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const SWITCH_USER_TYPE = loader('../../graphql/schema/auth/switch-user-type.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';
const disableWalker = process.env.REACT_APP_DISABLE_WALKER === 'true';
const wpWebApp = process.env.REACT_APP_WP_WEBAPP_URL;
const baseUrl = window.location.origin;
const imgattr = 'logo';

const HeaderV2 = (props) => {

	const { history } = props;

	const apolloClient = useApolloClient();

	const [drawer, setDrawer] = useState(false);
	const [addClass, setAddClass] = useState('');
	const [userType, setUserType] = useState('Traveler');
	const [roleClass, setRoleClass] = useState('');
	const [loading, setLoading] = useState(false);
	const [isAmbassador, setIsAmbassador] = useState(false);
	const [rating, setRating] = useState(0);
	const [switchToTravelerDialog, setSwitchToTravelerDialog] = useState(false);
	const [showSearch, setShowSearch] = useState(false);

	const [getMyDetails, { data: myDetails }] = useLazyQuery(MY_DETAILS, {
		fetchPolicy: "no-cache",
		onCompleted(res) {
			setIsAmbassador(res.me.is_ambassador);
			setRoles(res.me.type);
		}
	});

	const roles = [
	{ value: 'Traveler', label: <span className='ambassador-label font-weight-bold'>
		<img src={publicUrl + "assets/images/side-panel/traveler_icon_nav.png"} alt={imgattr} style={{width:18}}/>
		&nbsp;&nbsp;&nbsp;Traveler Mode</span> },
	{ value: 'Walker', label: <span className='ambassador-label font-weight-bold'>
		<img src={publicUrl + "assets/images/side-panel/walker_icon_nav_dark.png"} alt={imgattr} style={{width:18}}/>
		&nbsp;&nbsp;&nbsp;Walker Mode</span> },
	{ value: 'Ambassador', label: <span className='ambassador-label font-weight-bold'>
		<img src={publicUrl + "assets/images/side-panel/announcement.png"} alt={imgattr} style={{width:18}}/>
		&nbsp;&nbsp;&nbsp;Ambassador Mode</span> }
	]

	const setRoles = (type) => {
		if (type == 'W') {
			setUserType('Walker');
			setRoleClass('');
			setRating(myDetails.me.over_all_rating);
		} else if (type == 'T') {
			setUserType('Traveler');
			setRoleClass('profile-row-two');
		} else {
			setUserType('Ambassador');
			setRoleClass('profile-row-three')
			setRating(myDetails.me.ambassador_total_rating);
		}
		UserUtils.setUserType(type);
	};
	  

	const [sendEmailStripeConnect] = useMutation(EMAIL_STRIPE_CONNECT);

	const [userTypeSwitch, {}] = useMutation(SWITCH_USER_TYPE, {
		onCompleted(res) {
			const type = res.switchUserType.type;
			apolloClient.writeData({ data: { isWalkerTraveller: type === 'W' } });
			setRoles(type);
			if (type === 'W' || type === 'A') {
				const pageName = props.location.pathname;
				if (pageName === '/') {
					window.location.reload(true);
				} else {
					history.replace('/');
				}
			} else {
				history.replace('/destination-categories');
			}
		},
	});


	const [logout] = useMutation(LOGOUT, {
		onCompleted(res) {
			setDrawer(false);
			UserUtils.setClosedAds([]);
			UserUtils.removeAccessToken();
			apolloClient.resetStore();
			// history.push('/');
			window.location.href = '/login';
		},
	})

	useEffect(() => {
		if (commonFunction.isLogin() && UserUtils.getUserType()) {
			getMyDetails();
		}
	}, [])

	useEffect(() => {
		if (myDetails) {
			if (myDetails.me.type == 'A') {
				setRating(myDetails.me.ambassador_total_rating);
			} else {
				setRating(myDetails.me.over_all_rating);
			}
			apolloClient.writeData({ data: { isWalkerTraveller: myDetails.me.type === 'W' } });
		}
	}, [myDetails])

	useEffect(() => {	
		const pageName = props.location.pathname;
		if (pageName !== '/login' || pageName !== '/verify-login' || pageName !== '/register') {
			setAddClass('inner-page2');
		}
		handleScroll();
		window.addEventListener('scroll', (event) => handleScroll());
		
	}, [addClass]);

	const handleScroll = () => {
		const pageName = window.location.pathname;
		if (document.getElementById('navigation-header')) {
			if (window.pageYOffset < 5 && pageName === '/') {
				document.getElementById('navigation-header').classList.remove('inner-page');
			} else if (window.pageYOffset < 5 && pageName !== '/') {
				document.getElementById('navigation-header').classList.remove('py-3');
			} else {
				document.getElementById('navigation-header').classList.add('inner-page');
				document.getElementById('navigation-header').classList.add('py-3');
			}
		}
	}

	const handleRoleSelect = (event) => {
		fetch('https://api.stripe.com/v1/country_specs/' + UserUtils.getUserPhoneCountryKey(), {
			method: 'get',
			headers: new Headers({
				'Authorization': 'Bearer ' + process.env.REACT_APP_STRIPE_KEY,
			})
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (disableWalker && result && !result.error) {
						handleWalkerSwitch();
					} else if (result && !result.error) {
						var type = 'Traveler';
						if (typeof event == "object") {
							type = event.value;
						} else {
							if (event) {
								type = 'Walker';
							}
						}
						userTypeSwitch({
							variables: {
								data: type
							}
						});
						setUserType(type);
					} else {
						sendEmail();
					}
				},
				(error) => {
					console.log('--error', error);
				}
			)
	}

	const toggleDrawer = () => {
		setDrawer(!drawer)
	};

	const closeDrawer = () => {
		setDrawer(false)
	}

	const handleSignOut = () => {
		apolloClient.writeData({ data: { isLoggedIn: false } });
		logout({
			variables: {
				data: {
					online: false,
					device_token: ""
				}
			},
		});
	};

	const handleSettings = () => {
		history.push('/settings');
	};

	const handleEditAccount = () => {
		history.push('/edit-account');
	};

	const handleWalkerProfile = () => {
		history.push('/walker-profile');
	};

	const handleAmbassadorProfile = () => {
		history.push('/ambassador-profile');
	};

	const handleMakeMoneyWalking = () => {
		history.push('/make-money-for-walker');
	};

	const handleCompleteVerification = () => {
		history.push('/complete-verification-ambassador');
	};

	const handlePayoutAccount = () => {
		history.push('/payout-account');
	};

	const handleCompletedTrips = () => {
		history.push('/completed-trips');
	};

	const handlePaymentMethods = () => {
		history.push('/payment-methods');
	};

	const handleWalkerKits = () => {
		history.push('/walker-kit');
	};

	const handleRatings = () => {
		history.push('/ratings');
	};

	const handleManageMembership = () => {
		history.push('/membership');
	};

	const handleDestinationWishlist = () => {
		history.push('/destination-wishlist');
	};

	const handleYourTrips = () => {
		history.push('/your-trips');
	};

	const handleSendGiftCard = () => {
		history.push('/send-gift-card');
	};

	const handleHelp = () => {
		if (userType == 'Walker' || userType == 'Ambassador') {
			history.push('/walker-help');
		} else {
			history.push('/help');
		}
	};

	const handleEarnings = () => {
		history.push('/earnings')
	}

	const homeHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#home").offset().top - 100
				}, 0);
			}, 500);
		}
	}

	const aboutHuvrHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#about-huvr").offset().top - 100
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const blogHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".blog-area").offset().top - 160
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const handleLoggedInUserDestination = (e) => {
		e.preventDefault();
		if (commonFunction.isLogin() && userType == 'Traveler') {
			history.push('/destination-categories');
		} else {
			const pageName = props.location.pathname;
			if (pageName !== '/') {
				$('#loader-overlay').css({ "visibility": "visible" });
				history.push('/');
				setTimeout(function () {
					$('html, body').animate({
						scrollTop: $(".destination-carousel").offset().top - 160
					}, 0);
				}, 500);
				setTimeout(function () {
					$('#loader-overlay').css({ "visibility": "hidden" });
				}, 2000);
			}
		}
	};

	const searchHandler = () => {
		const pageName = props.location.pathname;
		setShowSearch(true);
		if (pageName === '/') {
			$('html, body').animate({
				scrollTop: $(".tp-main-search").offset().top - 300
			}, 500);
		} else {
			$('#loader-overlay').css({ "visibility": "visible" });
			// history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".tp-main-search").offset().top - 200
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	}

	const handleWalkerSwitch = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert-body text-center" style={{ borderRadius: 5 }}>
						<h1>Alert</h1>
						<p>The walker function is not available yet for this web app release.</p>
						<div className="react-confirm-alert-button-group justify-content-center">
							<button onClick={onClose} className="btn btn-yellow" style={{ backgroundColor: 'var(--main-color-one)', padding: '0 30px' }}>
								Okay
							</button>
						</div>
					</div>
				);
			},
			overlayClassName: 'header-overlay',
			afterClose: () => {
				// sendEmailStripeConnect(UserUtils.getUserID());
			}
		});
	}

	const sendEmail = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert-body text-center" style={{ borderRadius: 5 }}>
						<h1>Alert</h1>
						<p>Your country is not yet available to Stripe Connect.</p>
						<div className="react-confirm-alert-button-group justify-content-center">
							<button onClick={onClose} className="btn btn-yellow" style={{ backgroundColor: 'var(--main-color-one)', padding: '0 30px' }}>
								Okay
							</button>
						</div>
					</div>
				);
			},
			overlayClassName: 'header-overlay',
			afterClose: () => {
				sendEmailStripeConnect(UserUtils.getUserID());
			}
		});
	}

	return (
		<>
			{loading && <PageLoader loading={loading} />}
			<nav id="navigation-header" className={`navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top ${addClass}`}>
				<div className="container nav-container">
					<div className="responsive-mobile-menu">
						<div className="mobile-logo">
							<Link to='/destination-categories'>
								<img src={publicUrl + "assets/images/sticky-logo.png"} alt={imgattr} />
							</Link>
						</div>
						{/* <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#tp_main_menu" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggle-icon mobile-bar">
								<span>Menu <i className="la la-angle-down"></i></span>
							</span>
						</button> */}
						<div className="nav-right-content">
							<ul className="pl-0">
								{!commonFunction.isLogin() &&
								<li className="top-bar-btn-booking">
									<ScrollIntoView className="btn btn-yellow" selector="#destination-carousel">
										<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
									</ScrollIntoView>
								</li>
								}
								{commonFunction.isLogin() && userType == 'Traveler' &&
									<>
										<li className="top-bar-btn-booking">
											<a className="btn btn-yellow" onClick={handleLoggedInUserDestination}>
												<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
											</a>
										</li>
										<li className="profile-pic" onClick={toggleDrawer}>
											<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : 'assets/images/hvr-logo.png'} alt="profile" />
										</li>
									</>
								}
								{commonFunction.isLogin() && (userType == 'Walker' || userType == 'Ambassador') &&
									<>
										<li className="top-bar-btn-booking">
											<a className="btn btn-yellow" onClick={() => setSwitchToTravelerDialog(true)}>
												<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
											</a>
										</li>
										<li className="profile-pic" onClick={toggleDrawer}>
											<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : 'assets/images/hvr-logo.png'} alt="profile" />
										</li>
									</>
								}
								{/* <li className="search" onClick={searchHandler}>
									<a className="ti-search" />
								</li> */}
							</ul>
						</div>
					</div>
					<div className="collapse navbar-collapse" id="tp_main_menu">
						<div className="logo-wrapper desktop-logo">
							<Link to='/destination-categories' className="main-logo">
								<img src={publicUrl + "assets/images/huvr-nav-logo-white.png"} alt="logo" />
							</Link>
							<Link href={`${baseUrl}/destination-categories`}  className="sticky-logo">
								<img src={publicUrl + "assets/images/huvr-nav-logo-white.png"} alt="logo" />
							</Link>
						</div>
						<ul className="navbar-nav">
							{/* <li className="menu-item-has-children">
								<ScrollIntoView selector="#home" onClick={homeHandler}>Home</ScrollIntoView>
							</li>
							<li className="menu-item-has-children">
								<ScrollIntoView selector="#about-huvr" onClick={aboutHuvrHandler}>About Huvr</ScrollIntoView>
							</li>
							<li className="menu-item-has-children">
								<ScrollIntoView selector="#destination-carousel" onClick={handleLoggedInUserDestination}>Destinations</ScrollIntoView>
							</li>
							<li className="menu-item-has-children">
								<ScrollIntoView selector="#our-blog" onClick={blogHandler}>Our Blog</ScrollIntoView>
							</li> */}
							{!commonFunction.isLogin() &&
								<>
									<li className="menu-item-has-children desktop-hide">
										<ScrollIntoView selector="#our-blog">
											<Link to="/login">Login</Link>
										</ScrollIntoView>
									</li>
									<li className="menu-item-has-children desktop-hide">
										<ScrollIntoView selector="#our-blog">
											<Link to="/register">Sign Up</Link>
										</ScrollIntoView>
									</li>

								</>
							}
						</ul>
					</div>
					<div className="nav-right-content nav-right-content-new">
						<ul>
							{commonFunction.isLogin() && <li className="profile-pic mobile-hide" onClick={toggleDrawer}>
								<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : 'assets/images/hvr-logo.png'} alt="profile" />
							</li>}
							{!commonFunction.isLogin() &&
								(<React.Fragment>
									<li className="menu-item-has-children mobile-hide">
										<Link to="/register">Signup</Link>
									</li>
									<li className="menu-item-has-children login-link">
										<Link to="/login">Login</Link>
									</li>
								</React.Fragment>
								)}
							{commonFunction.isLogin() && <li className="menu" onClick={toggleDrawer}>
								<i className="fa fa-bars" ></i>
							</li>}
							{!commonFunction.isLogin() &&
								<li className="mobile-hide">
									<ScrollIntoView className="btn btn-yellow" selector="#destination-carousel">
										<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
									</ScrollIntoView>
								</li>
							}
							{/* <li className="search mobile-hide" onClick={searchHandler}>
								<a className="ti-search" />
							</li> */}
						</ul>

						<SwipeableDrawer
							open={drawer}
							docked={false}
							className="app-drawer"
							onRequestChange={closeDrawer}
							onClose={closeDrawer}
							onOpen={toggleDrawer}
						>
							<div className="swipebar-row">
								<p className="mobile-icon"><i className="la la-times" onClick={closeDrawer}></i></p>
								{!commonFunction.isLogin() && <p className="login-text">Login/Signup to start your virtual journey</p>}
								<div>
									{commonFunction.isLogin() &&
										<div className="profile-box-row">
											<div className={`profile-row ${roleClass}`}>
												<div className="profile-row-flex">
													<div className="profile-row-left">
														<div className="profile-icon-row">
															<div className="edit_icon" onClick={handleEditAccount}>
																<i className="la la-edit" ></i>
															</div>
														</div>
														<figure>
															<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : 'assets/images/hvr-logo.png'} alt="profile" />
														</figure>
													</div>
													<div className="icon-row">
														<div className="edit_icon" onClick={closeDrawer}><i className="la la-times"></i></div>
													</div>
													<div className="profile-row-right d-flex align-items-start flex-column">
														<h5>
															{UserUtils.getUserFirstName()}
															{' '}
															{UserUtils.getUserLastName()}
														</h5>
														{!isAmbassador &&
															<div className="toggle-row d-flex">
																<span className="toggle-title">{userType}</span>
																<Switch
																	onChange={handleRoleSelect}
																	checked={userType == 'Walker'}
																	size="small"
																/>
															</div>
														}
														{userType == 'Walker' &&
															<div className="flex-div text-white align-items-center">
																<span style={{ fontSize: '.75rem', lineHeight: '1.5rem' }}>{/^\d$/.test(rating) ? rating+'.00' : rating}</span>
																<Rating name="read-only" value={rating} readOnly precision={0.5} size="small"/>
															</div>
														}
														{userType == 'Ambassador' &&
															<div className="flex-div text-white align-items-center" onClick={handleRatings}>
																<span style={{ fontSize: '.75rem', lineHeight: '1.5rem' }}>{/^\d$/.test(rating) ? rating+'.00' : rating}</span>
																<Rating name="read-only" value={rating} readOnly precision={0.5} size="small"/>
															</div>
														}
														{userType == 'Traveler' &&
															<div className="flex-div" style={{ border: 'none' }}>
																<span className="rating-star">&nbsp;</span>
															</div>
														}
													</div>
												</div>
												{isAmbassador && 
													<div className="role-select-wrapper">
														<Select 
															options={roles} 
															value={roles.filter(({value}) => value === userType)}
															onChange={value => handleRoleSelect(value)}
														/>
													</div>
												}
											</div>

											<div className="header-list">
												<div className="toggle-one">
													<List>
														{userType == 'Walker' && <>
															<ListItem className="submenuList" button onClick={handleWalkerProfile} >
																<div className="side-panel-icon">
																	<i className="fa fa-user"></i>
																</div>
																<ListItemText primary="My Walker Profile" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleMakeMoneyWalking}>
																<div className="side-panel-icon">
																	<i className="fa fa-shield"></i>
																</div>
																<ListItemText primary="Make Money Walking" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handlePayoutAccount}>
																<div className="side-panel-icon">
																	<i className="fa fa-university"></i>
																</div>
																<ListItemText primary="Payout Account" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleCompletedTrips}>
																<div className="side-panel-icon">
																	<i className="fa fa-play-circle"></i>
																</div>
																<ListItemText primary="Completed Trips" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handlePaymentMethods}>
																<div className="side-panel-icon">
																	<i className="fa fa-credit-card"></i>
																</div>
																<ListItemText primary="Payment Methods" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleEarnings}>
																<div className="side-panel-icon">
																	<i className="fa fa-dollar"></i>
																</div>
																<ListItemText primary="Earnings" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleWalkerKits}>
																<div className="side-panel-icon">
																	<i className="fa fa-wrench"></i>
																</div>
																<ListItemText primary="Walker Kits" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleRatings}>
																<div className="side-panel-icon">
																	<i className="fa fa-star"></i>
																</div>
																<ListItemText primary="Ratings" />
															</ListItem>
														</>}
														{userType == 'Traveler' && <>
															<ListItem button onClick={handleManageMembership}>
																<div className="side-panel-icon">
																	<i className="fa fa-user-plus"></i>
																</div>
																<ListItemText>
																	Manage Membership <span className="d-block">(Coming Soon)</span>
																</ListItemText>
															</ListItem>
															<ListItem className="submenuListTwo" button>
																<ListItemText primary="Join A Group Trip" />
															</ListItem>
															<ListItem className="submenuListTwo submenuList" button>
																<ListItemText primary="Schedule Your Favorite Walker" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleMakeMoneyWalking}>
																<div className="side-panel-icon">
																	<i className="fa fa-dollar"></i>
																</div>
																<ListItemText primary="Make Money Walking" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handlePaymentMethods}>
																<div className="side-panel-icon">
																	<i className="fa fa-university"></i>
																</div>
																<ListItemText primary="Payment Methods" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleSendGiftCard}>
																<div className="side-panel-icon">
																	<i className="fa fa-gift"></i>
																</div>
																<ListItemText primary="Send A Gift Card" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleDestinationWishlist}>
																<div className="side-panel-icon">
																	<i className="fa fa-heart"></i>
																</div>
																<ListItemText primary="Destination Wish List" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleYourTrips}>
																<div className="side-panel-icon">
																	<i className="fa fa-play-circle"></i>
																</div>
																<ListItemText primary="Your Trips" />
															</ListItem>
														</>}
														{userType == 'Ambassador' && <>
															<ListItem className="submenuList" button onClick={handleAmbassadorProfile} >
																<div className="side-panel-icon">
																	<i className="fa fa-user"></i>
																</div>
																<ListItemText primary="My Ambassador Profile" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleCompleteVerification}>
																<div className="">
																	<img src={publicUrl + "assets/images/side-panel/Traveler_dark.png"} alt={imgattr} className='side-panel-img'/>
																</div>
																<ListItemText primary="Complete Verification" />
															</ListItem>
															<ListItem className="submenuList" button onClick={handleCompletedTrips}>
																<div className="side-panel-icon">
																	<i className="fa fa-play-circle"></i>
																</div>
																<ListItemText primary="Completed Trips" />
															</ListItem>
														</>}
														<ListItem className="submenuList" button onClick={handleHelp}>
															<div className="side-panel-icon">
																<i className="fa fa-question-circle"></i>
															</div>
															<ListItemText primary="Help" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleSettings}>
															<div className="side-panel-icon">
																<i className="fa fa-cog"></i>
															</div>
															<ListItemText primary="Settings" />
														</ListItem>
														<ListItem className="submenuList" button onClick={handleSignOut}>
															<div className="side-panel-icon">
																<i className="fa fa-sign-out"></i>
															</div>
															<ListItemText primary="Sign out" />
														</ListItem>
													</List>
												</div>
											</div>
										</div>
									}
								</div>
							</div>
						</SwipeableDrawer>
					</div >
				</div>
			</nav>
			<Dialog className="popup-verification" aria-labelledby="simple-dialog-title" open={switchToTravelerDialog}>
				<div>
					<p>To book a trip, go to Traveler mode.</p>
					<br />
					<Button className="btn btn-yellow confirm-btn" onClick={() => setSwitchToTravelerDialog(false)}>OK</Button>
				</div>
			</Dialog>
			{showSearch && <Search history={history} style={{ position: 'fixed', top: '200px', left: 0, right: 0, zIndex: 999 }} />}
		</>
	)
};

HeaderV2.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const enhance = compose(
	withRouter,
);

export default enhance(HeaderV2);

import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from "prop-types";
import OtpInput from 'react-otp-input';
import { loader } from 'graphql.macro';
import { useApolloClient, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { confirmAlert } from 'react-confirm-alert';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const LOGIN = loader('./../../../graphql/schema/auth/login.graphql');
const MY_DETAILS = loader('./../../../graphql/schema/auth/me.graphql');
const AMBASSADOR_ACCEPT_INVITATION = loader('./../../../graphql/schema/ambassador/accept-invitation.graphql');
const TRAVELER_WALKER_TOGGLE = loader('./../../../graphql/schema/auth/traveler-walker-toggle.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const VerifyLogin = (props) => {
  const { history } = props;
  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryKey, setCountryKey] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});
  const [ambassadorId, setAmbassadorId] = useState(null);

  const [getMyDetails, { data }] = useLazyQuery(MY_DETAILS, {
    fetchPolicy: "no-cache",
  });

  const [acceptInvitation] = useMutation(AMBASSADOR_ACCEPT_INVITATION, {
    errorPolicy: 'ignore',
    onCompleted({
      acceptInvitation
    }) {
      UserUtils.setUserType('A');
      history.push('/');
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body text-center p-5" style={{ maxWidth: 550, borderRadius: 5 }}>
              <h1 className="pb-3">Welcome to Huvr Pro!</h1>
              <p style={{ hyphens: 'none' }}>{ acceptInvitation.message }</p>
              {acceptInvitation.message.includes('verification steps') &&
                  <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                      history.push('/complete-verification-ambassador');
                      onClose();
                  }}>
                      Complete Verification
                  </button>
              }

              {!acceptInvitation.message.includes('verification steps') &&
                  <button className="btn btn-yellow mt-4" style={{ minWidth: 150 }} onClick={() => {
                      onClose();
                  }}>
                      Close
                  </button>
              }
            </div>
          );
        },
        overlayClassName: 'header-overlay',
        afterClose: () => {
          UserUtils.removeAmbassadorId();
        }
      });
    }
  });

  const [submitSignUp, { loading: loading }] = useMutation(LOGIN, {
    onCompleted({
      login: {
        access_token: accessToken,
        status: status,
        message: message,
        user: user,
      },
    }) {
      postLogin({ accessToken, status, message, user });
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => message);
      
      setHasError({'errors': formatedErrors});
    },
  });

  const [travelerWalkerToggle] = useMutation(TRAVELER_WALKER_TOGGLE, {
    onCompleted({ travelerWalkerToggle: { type } }) {
      apolloClient.writeData({ data: { isWalkerTraveller: type === 'W' } });
      acceptInvitation({
        variables: {
          ambassador_id: ambassadorId,
          walker_id: data.me.id
        }
      });
    },
  });

  useEffect(() => {
    const phone = UserUtils.getPhoneNumber();
    const country_key = UserUtils.getCountryKey();
    if (phone !== null) {
      setPhoneNumber(phone);
      setCountryKey(country_key);
    } else {
      history.push('/login');
    }
    setPhoneOtp('');

    return () => {
      UserUtils.removeCountryKey();
    };
  }, [])

  useEffect(() => {
    const ambassador_id = UserUtils.getAmbassadorId();
    setAmbassadorId(ambassador_id);
    if (data && data.me) {
      UserUtils.setUserAvatar(data.me.avatar);
      UserUtils.setUserFirstName(data.me.first_name);
      UserUtils.setUserLastName(data.me.last_name);
      UserUtils.setUserRating(data.me.over_all_rating);
      UserUtils.setUserPhoneCountryKey(data.me.phone_country_key);
      UserUtils.setUserType(data.me.type);
      UserUtils.setUserEmail(data.me.email);
      UserUtils.setPhoneNumber(data.me.phone_number);
      if (ambassadorId !== null) {
        acceptInvitation({
          variables: {
            ambassador_id: ambassadorId,
            walker_id: data.me.id
          }
        });
      } else {
        handleRedirect(data);
      }
    }
  }, [data])

  const handleRedirect = (data) => {
    const tripId = localStorage.getItem('event_id');
    console.log('tripId ===> ', tripId);

    if(tripId) {
      localStorage.removeItem('event_id');
      history.push(`/event-registration/${tripId}`);
    } else if (data.me.type === 'W' || data.me.type === 'A') {
      apolloClient.writeData({ data: { isWalkerTraveller: true } });
      history.push('/your-trips');
    } else {
      apolloClient.writeData({ data: { isWalkerTraveller: false } });
      history.push('/destination-categories');
    }
  }

  const clearLocalStorageExceptOneKey = (keyToKeep) => {
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        if (key !== keyToKeep) {
            localStorage.removeItem(key);
        }
    }
}

  //HANDLE PHONE INPUT
  const handlePhoneNumber = (e) => {
    setHasError({});
    setPhoneNumber(e.target.value)
  }
  // HANDLE OTP
  const handleChangeOtp = (otp) => {
    console.log('handleChangeOtp ==> ', otp);
    setPhoneOtp(otp);
    setPassword('');
  }
  // HANDLE PASSWORD
  const handlePassword = (e) => {
    setHasError({});
    setPassword(e.target.value)
  }

  // VALIDATE OTP
  const validatePhoneOtp = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneOtp === '' && password === '') {
      formIsValid = false;
      errors["phoneOtp"] = "Please enter OTP.";
    } else if (phoneOtp.length < 5 && password === '') {
      formIsValid = false;
      errors["phoneOtp"] = "Please enter valid OTP.";
    }
    setHasError(errors);
    return formIsValid;
  }

  const postLogin = (resUser) => {
    clearLocalStorageExceptOneKey('event_id');
    apolloClient.writeData({ data: { isLoggedIn: true } });
    let error = {};
    if (resUser.status === "OTP_NOT_VERIFIED") {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else if (resUser.status === "NOT_VERIFIED") {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else {
      setSuccessMessage(resUser.message)
      UserUtils.setAccessToken(resUser.accessToken);
      localStorage.setItem('access_token', resUser.accessToken);
      apolloClient.writeData({ data: { isLoggedIn: true } });
      UserUtils.setUserID(resUser.user.id);

      getMyDetails();
    }
  };

  const submitLogin = (e) => {
    e.preventDefault();
    const deviceToken = localStorage.getItem('device_token');
    if (validatePhoneOtp()) {
      submitSignUp({
        variables: {
          data: {
            "otp": phoneOtp ? phoneOtp : '',
            "username": phoneNumber,
            "password": password,
            "phone_country_key": countryKey,
            "phone_country_code": "",
            "time_zone": "",
            "device_token": deviceToken
          },
        },
      });
    }
  };

  return (
    <div className="signup-container-row2">
      {loading && <PageLoader loading={loading} />}
      <div className="signup-row">
        {/* <p className="modal-close" ><Link to="/"><i className="la la-times" /></Link></p> */}
        <div className="signup-left2">
          <div className="signup-cnt">
            <h4 component="h4">Login</h4>
            <p>Huvr opens you to the world to virtual exploration in travel, news, healthcare, education, sports, events, real estate, and more—all through the power of the gig economy and licensed brands.</p>
            {/* <Link to="/" className="btn"><i className="fa fa-external-link"></i> Learn More</Link> */}
          </div>
        </div>
        <div className="signup-right2">
          <div className="signup-form">
            <div className="form-title">
              <h4 component="h4">Welcome To Huvr</h4>
            </div>
            <form onSubmit={submitLogin}>
              {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
              {hasError.otpError !== undefined && <p className="is-invalid">{hasError.otpError}</p>}
              {hasError.errors !== undefined && hasError.errors.map(item => {
                return (<p className="is-invalid">{item}</p>);
              })}
              <div className="form-input-box-1">
                <label variant="subtitle2" component="label">Enter OTP Here</label>
                <div className="otp-input">
                  <OtpInput
                    variant="outlined"
                    name="phoneOtp"
                    margin="normal"
                    shouldAutoFocus={false}
                    inputType="number"
                    renderInput={() => setPhoneOtp('')}
                    onChange={handleChangeOtp}
                    numInputs={5}
                    value={phoneOtp}
                    separator={<span>-</span>
                    }
                  />
                </div>
                {hasError.phoneOtp !== undefined && <div className='is-invalid'>{hasError.phoneOtp}</div>}
              </div>

              {/* <div className="or-login text-center">OR</div> */}

              <div className="form-input-box2">
                {/* <label variant="subtitle2" component="label">Phone Number</label> */}
                <input
                  type="text"
                  name="phoneNumber"
                  className={`${hasError.phoneNumber !== undefined ? 'is-invalid' : ''} vl-input`}
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  disabled
                />
              </div>
              <div className="form-input-box2">
                {/* <label variant="subtitle2" component="label">Password</label> */}
                <input
                  type="password"
                  name="password"
                  placeholder="Enter Password Here"
                  className={`${hasError.password !== undefined ? 'is-invalid' : ''} vl-input`}
                  value={password}
                  onChange={handlePassword}
                />
                {hasError.password !== undefined && <p className="is-invalid">{hasError.password}</p>}
              </div>
              <div className="form-btn">
                <input className="verify-btn" type="submit" color="inherit" value="Verify" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

VerifyLogin.propTypes = {
  history: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

const enhance = compose(
  withRouter,
);

export default enhance(VerifyLogin);

import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const MAKE_MONEY_FOR_WALKER = loader('../../graphql/schema/make-money/make-money-for-walker.graphql');

const MakeMoneyForTraveler = (props) => {

    const { history } = props;

    const [WalkerData, setWalkerData] = useState({});

    const { data: myDetails } = useQuery(MY_DETAILS);

    const { data, loading } = useQuery(MAKE_MONEY_FOR_WALKER, {
        onCompleted() {
            setWalkerData(data.makeMoneyWalking);
        }
    })

    if (WalkerData.is_all_verification_completed === true) {
        history.push('/welcome-back')
    }

    const handlebackgroundcheck = () => {
        history.push('/back-ground-check-traveler')
    }

    const handleWalkerKit = () => {
        history.push('/walker-kit')
    }

    const handleWalkerTraining = () => {
        history.push('/walker-training')
    }

    const handleAccountLinked = () => {
        history.push('/payout-account')
    }

    const handleWalkerTerms = () => {
        history.push('/walker-terms-traveler')
    }

    const handleProfilrPicture = () => {
        history.push('/edit-account')
    }

    console.log(WalkerData);

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="Make Money for Traveler" />
                    <div className="container">
                        <div className="travelle-body">
                            <div className="profile-row">
                                <div className="profile-left">
                                <figure>
                                    <img src={ myDetails && myDetails.me.avatar ?  myDetails.me.avatar : 'assets/images/inner-page/profile.jpg' } alt="profile"  />
                                </figure>
                                    <div className="profile-title">
                                        <h4>Welcome Back,</h4>
                                        <p>
                                            {myDetails && myDetails.me.first_name}
                                            {' '}
                                            {myDetails && myDetails.me.last_name}
                                        </p>
                                    </div>
                                </div>
                                <div className="profile-right">
                                    <figure>
                                        <img src={publicUrl + "assets/images/favicon.png"} alt="" />
                                    </figure>
                                    <span>Traveler</span>
                                </div>
                            </div>
                            <div>
                                <h3 className="requried-title">Required Steps</h3>
                                <Typography>Here is what still needs to be completed in order to set up your Walker Account.</Typography>
                                <Typography>Click on steps below that need your attention to complete, so you can start earning money as a Huvr Walker.</Typography>
                            </div>
                            <div className="destination-body">
                                {/* <div onClick={handlebackgroundcheck}>
                                    {WalkerData.backgground_check === false  ?
                                        (<div className="destination-box" >
                                            <div className="box-center box-center-new" >
                                                <p>Need Your Attention</p>
                                                <h4>Back Gound Check</h4>
                                            </div>
                                            <div className="box-right">
                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                            </div>
                                        </div>)
                                        :
                                        ''
                                    }
                                </div> */}
                                <div onClick={handleProfilrPicture}>
                                    {WalkerData.profie_picture === false ?
                                        (<div className="destination-box" >
                                            <div className="box-center box-center-new" >
                                                <p>Need Your Attention</p>
                                                <h4>Profile Picture</h4>
                                            </div>
                                            <div className="box-right">
                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                            </div>
                                        </div>)
                                        :
                                        ''
                                    }
                                </div>
                                {/* <div onClick={handleWalkerKit}>
                                    {WalkerData.walker_kit_ordered === false ?
                                        (<div className="destination-box" >
                                            <div className="box-center box-center-new" >
                                                <p>Need Your Attention</p>
                                                <h4>Walker kit Orderd</h4>
                                            </div>
                                            <div className="box-right">
                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                            </div>
                                        </div>)
                                        :
                                        ''
                                    }
                                </div> */}
                                {/* <div onClick={handleWalkerTraining}>
                                    {WalkerData.walker_training === false ?
                                        (<div className="destination-box" >
                                            <div className="box-center box-center-new" >
                                                <p>Need Your Attention</p>
                                                <h4>Walker Training</h4>
                                            </div>
                                            <div className="box-right">
                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                            </div>
                                        </div>)
                                        :
                                        ''
                                    }
                                </div> */}
                                <div onClick={handleAccountLinked}>
                                    {WalkerData.bank_account_linked === false ?
                                        (<div className="destination-box" >
                                            <div className="box-center box-center-new" >
                                                <p>Need Your Attention</p>
                                                <h4>Stripe Account Linked</h4>
                                            </div>
                                            <div className="box-right">
                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                            </div>
                                        </div>)
                                        :
                                        ''
                                    }
                                </div>
                                <div onClick={handleWalkerTerms}>
                                    {WalkerData.walker_terms === false ?
                                        (<div className="destination-box" >
                                            <div className="box-center box-center-new" >
                                                <p>Need Your Attention</p>
                                                <h4>Sign Walker Terms</h4>
                                            </div>
                                            <div className="box-right">
                                                <i className="fa fa-warning" data-toggle="modal" data-target="#myModal"></i>
                                            </div>
                                        </div>)
                                        :
                                        ''
                                    }
                                </div>
                                
                            </div>
                            <h3 className="requried-title">Completed Tasks</h3>
                            <div className="destination-body mt20">
                            {/* <div onClick={handlebackgroundcheck}>
                                {WalkerData.backgground_check === true ?
                                    (<div className="destination-box" >
                                        <div className="box-center box-center-new" >
                                            <p>Need Your Attention</p>
                                            <h4>Background Check</h4>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target="#myModal"></i>
                                        </div>
                                    </div>)
                                    :
                                    ''
                                }
                            </div> */}
                            <div onClick={handleProfilrPicture}>
                                {WalkerData.profie_picture === true ?
                                    (<div className="destination-box" >
                                        <div className="box-center box-center-new" >
                                            <p>Need Your Attention</p>
                                            <h4>Profile Picture</h4>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target="#myModal"></i>
                                        </div>
                                    </div>)
                                    :
                                    ''
                                }
                            </div>
                            {/* <div onClick={handleWalkerKit}>
                                {WalkerData.walker_kit_ordered === true ?
                                    (<div className="destination-box" >
                                        <div className="box-center box-center-new" >
                                            <p>Need Your Attention</p>
                                            <h4>Walker kit Orderd</h4>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target="#myModal"></i>
                                        </div>
                                    </div>)
                                    :
                                    ''
                                }
                            </div> */}
                            {/* <div onClick={handleWalkerTraining}>
                                {WalkerData.walker_training === true ?
                                    (<div className="destination-box" >
                                        <div className="box-center box-center-new" >
                                            <p>Need Your Attention</p>
                                            <h4>Walker Training</h4>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target="#myModal"></i>
                                        </div>
                                    </div>)
                                    :
                                    ''
                                }
                            </div> */}
                            <div onClick={handleAccountLinked}>
                                {WalkerData.bank_account_linked === true ?
                                    (<div className="destination-box" >
                                        <div className="box-center box-center-new" >
                                            <p>Need Your Attention</p>
                                            <h4>Stripe Account Linked</h4>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target="#myModal"></i>
                                        </div>
                                    </div>)
                                    :
                                    ''
                                }
                            </div>
                            <div onClick={handleWalkerTerms}>
                                {WalkerData.walker_terms === true ?
                                    (<div className="destination-box" >
                                        <div className="box-center box-center-new" >
                                            <p>Need Your Attention</p>
                                            <h4>Sign Walker Terms</h4>
                                        </div>
                                        <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target="#myModal"></i>
                                        </div>
                                    </div>)
                                    :
                                    ''
                                }
                            </div>
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

MakeMoneyForTraveler.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
    withRouter,
);

export default enhance(MakeMoneyForTraveler)

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { InputLabel, TextField, Switch, Button, Modal, InputAdornment, Box } from '@material-ui/core';
import Select from 'react-select';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Form } from 'react-final-form';
import { isEmptyV2 } from '../../utilities/commonFunctions';
import axios from 'axios';
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Register the plugins
registerPlugin(FilePondPluginFileValidateType, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageCrop);

import "react-country-state-city/dist/react-country-state-city.css";

const publicUrl = process.env.PUBLIC_URL + '/';
const SAVE_GROUP_SCHEDULETRIP = loader('../../graphql/schema/walker/save-group-scheduletrip.graphql');

const defaultData = {
  id: null,
  name: null,
  description: null,
  country: null,
  state: null,
  city: null,
  lat: null,
  lng: null,
  date: moment().format('YYYY-MM-DD'),
  timezone: null,
  time: null,
  duration: 0,
  // isPrivate: true,
  // streamCode: null,
  price: 0
};

const CreateGroupTripsModal = (props) => {
  const { history, location } = props;
  const inputRef = useRef();
  const timeRef = useRef()
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [thumbnail, setThumbnail] = useState([]);
  const [banner, setBanner] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(defaultData);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [CountriesData, setCountriesData] = useState([]);
  const [StatesData, setStatesData] = useState([]);
  const [CitiesData, setCitiesData] = useState([]);

  const getCountriesData = () => {
    fetch('data/countries.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setCountriesData(result);
      });
  }

  const getStatesData = () => {
    fetch('data/states.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setStatesData(result);
      });
  }

  const getCitiesData = () => {
    fetch('data/cities.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setCitiesData(result);
      });
  }

  useEffect(() => {
    getCountriesData();
    getStatesData();
    getCitiesData();
  }, [])

  const [saveGroupScheduletrip, { loading: submitting }] = useMutation(SAVE_GROUP_SCHEDULETRIP, {
    onCompleted({ response }) {
      props.close();
      props.refreshList();
    },
    onError(errors) {
      props.close();
      console.log(errors);
    },
  });

  useEffect(() => {
    if (CountriesData) {
      let options = [];
  
      CountriesData.forEach((value, index) => {
        options.push({ value: value.id, label: `${value.emoji} ${value.name}`, name: value.name });
      });
  
      setCountries(options);
    }
  }, [CountriesData]);

  useEffect(() => {
    if (!props.show) {
      clearForm();
    }
  }, [props.show]);

  useEffect(() => {
    if (props.data) {
      evaluateData();
    }
  }, [props.data, countries]);

  useEffect(() => {
    props.setShowLoader(submitting);
  }, [submitting]);


  const getState = async (country, stateName) => {
    return new Promise((resolve) => {
      const stateOptions = setStateOptions(country, StatesData);
      const index = stateOptions.findIndex(item => item.name == stateName);

      setState(stateOptions[index]);
      resolve(stateOptions[index]);
    });
  }

  const getCity = async (country, state, cityName) => {
    return new Promise((resolve) => {
      const stateOptions = setCityOptions(country, state, CitiesData);
      const index = stateOptions.findIndex(item => item.name == cityName);

      setCity(stateOptions[index] ? stateOptions[index] : null);
      resolve(stateOptions[index] ? stateOptions[index] : null);
    });
  }

  const evaluateData = () => {
    const { data } = props;
    const address = data.destination.location.split(', ');
    const countryName = address.length > 2 ? address[2] : address[1];
    const stateName = address.length > 2 ? address[1] : address[0];
    const cityName = address.length > 2 ? address[0] : '';
    const countryIndex = countries.findIndex(item => item.name == countryName);
    const row = countries[countryIndex];

    setCountry(row);
    getState(row, stateName).then(state => {
      getCity(row, state, cityName);
    });

    setFormData({
      id: data.id,
      name: data.destination.name,
      description: data.destination.description,
      country: countryName,
      state: stateName,
      city: cityName,
      lat: data.destination.latitude,
      lng: data.destination.longitude,
      date: data.schedule_date,
      time: data.schedule_time,
      timezone: data.destination.time_zone,
      duration: data.schedule_minute,
      // isPrivate: !data.publish,
      // streamCode: data.stream_code,
      price: data.schedule_price
    });
  }

  const evaluateCity = async (e) => {
    setCity(e);
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${e.latitude},${e.longitude}&timestamp=${Math.floor(Date.now() / 1000)}&key=AIzaSyB2QsIRxpA41LRK7DULBQMftwdwo33EcBA`
      );
      const data = response.data;
      if (data.status === 'OK') {
        setFormData({
          ...formData,
          city: e.name,
          lat: e.latitude,
          lng: e.longitude,
          timezone: data.timeZoneId,
          date: moment().tz(data.timeZoneId).format('YYYY-MM-DD')
        });
      } else {
        console.error('Failed to fetch timezone:', data.status);
      }
    } catch (error) {
      console.error('Error fetching timezone:', error);
    }
  };

  const clearSelection = (selector) => {
    const element = document.querySelector(selector);
    const event = new Event('input', { bubbles: true });
    const previousValue = element.value;

    element.value = '';
    element._valueTracker.setValue(previousValue)
    element.dispatchEvent(event)
  }

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validate = () => {
    let errors = {};

    Object.keys(formData).forEach(key => {
      console.log(key, isEmptyV2(formData[key]));

      if (key === 'id' || (formData.id && (key == 'thumbnail' || (key == 'banner')))) {
        return; // Skip this iteration
      }
      if ((isEmptyV2(formData[key]) || formData[key] == 0) && key !== 'isPrivate') {
        errors[key] = `${key} is required.`;
      }
      else if (key == 'price' && formData[key] < 2) {
        errors[key] = 'Price should not be less than $2.';
      }
      else if (key == 'duration' && parseInt(formData[key]) <= 10) {
        errors[key] = 'Must be greater than 10 minutes.';
      }
    });


    if (isEmptyV2(formData.id) && thumbnail && !thumbnail.length) {
      errors.thumbnail = `Thumbnail is required.`;
    }

    if (isEmptyV2(formData.id) && banner && !banner.length) {
      errors.banner = `Banner is required.`;
    }

    // Check if schedule is a future time
    if (!isEmptyV2(formData.date) && !isEmptyV2(formData.time) && !isEmptyV2(formData.timezone)) {
      moment.tz.setDefault(formData.timezone);
      const today = moment(`${formData.date} ${formData.time}`, 'YYYY-MM-DD hh:mm A').tz(formData.timezone).diff(moment().tz(formData.timezone), 'seconds');

      if (today <= 0) {
        errors.date = 'Must be a future time.';
        errors.time = 'Must be a future time.';
      }
    }

    setErrors(errors);

    return !Object.keys(errors).length;
  }

  const handleUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      if (file && file[0]) {
        reader.addEventListener('load', () => {
          resolve(reader.result);
        });

        reader.readAsDataURL(file[0].file);
      } else {
        resolve(null);
      }
    });
  }

  const convertFiles = () => {
    return new Promise((resolve, reject) => {
      let result = { thumbnail: '', banner: '' };

      handleUpload(thumbnail).then(data => {
        result.thumbnail = data;

        handleUpload(banner).then(item => {
          result.banner = item;

          resolve(result);
        });
      });
    });
  }

  const submit = () => {
    if (validate()) {
      let scheduleTime = moment(formData.time, "h:mm A").format("HH:mm:ss");
      let scheduleDate = moment(`${formData.date} ${scheduleTime}`).tz(formData.timezone);

      let trip = {
        hour_format: 12,
        schedule_date: formData.date,
        schedule_time: scheduleTime,
        time_zone: formData.timezone,
        schedule_minute: formData.duration,
        epoch: scheduleDate.valueOf() / 1000,
        // stream_code: formData.streamCode,
        schedule_price: formData.price,
        // publish: !formData.isPrivate
      };

      let destination = {
        name: formData.name,
        description: formData.description,
        location: `${formData.city}, ${formData.state}, ${formData.country}`,
        latitude: formData.lat,
        longitude: formData.lng,
        time_zone: formData.timezone,
      };

      if (formData.id) {
        trip.id = formData.id;
      }

      convertFiles().then(data => {
        if (data.thumbnail) {
          destination.thumbnail = data.thumbnail;
        }

        if (data.banner) {
          destination.banner = data.banner;
        }

        saveGroupScheduletrip({
          variables: {
            schedule_trip: trip,
            destination: destination
          }
        });
      })
    }
  }

  const setStateOptions = (e, result) => {
    let options = [];

    if (e) {
      const findStates = result.find((value) => value.id == e.value);
      if (findStates && findStates.states) {
        findStates.states.forEach((value, index) => {
          options.push({ value: value.id, label: value.name, name: value.name });
        });
      }
    }

    setStates(options);
    return options;
  }

  const setCityOptions = (country, e, result) => {
    let options = [];

    if (country) {
      const findStates = result.find((value) => value.id == country.value);
      if (findStates && findStates.states) {
        const findCities = findStates.states.find((value) => value.id == e.value);
        if (findCities && findCities.cities) {
          findCities.cities.forEach((value, index) => {
            options.push({
              value: value.id,
              label: value.name,
              name: value.name,
              latitude: value.latitude,
              longitude: value.longitude
            });
          });
        }
      }
    }

    setCities(options);
    return options;
  }

  const clearForm = () => {
    setErrors({});
    setFormData(defaultData);
    setThumbnail([]);
    setBanner([]);
    setCountry(null);
    setState(null);
    setCity(null);
  }

  const handleClose = (event, reason) => {
    console.log('reason', reason);
    if (reason !== 'backdropClick') { // Check if the close reason is not backdropClick
      props.close()
    }
  }

  return (
    <>
      <Modal
        open={props.show}
        onClose={(event, reaso) => handleClose(event, reaso)}
        className="popupv2"
      >
        <div className="modal-dialog modal-dialog-centered dialog-centered create-group-trip">
          <div className="modal-content payment-popup-body" style={{ width: 800 }}>
            <p className="create-gt-title mb-3">Create Group Event</p>
            <div className="popup-scrollv2">
              <button type="button" className="close" onClick={() => props.close()}><i className="la la-times"></i></button>
              <Form
                onSubmit={() => submit()}
                render={({ handleSubmit, submitting }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="create-group-form"
                    noValidate
                  >
                    <Box className="row">
                      {errors && Object.keys(errors).length > 0 &&
                        <Box className="col-md-12 error-box">
                          <p className="is-invalid">{Object.values(errors)[0]}</p>
                        </Box>
                      }
                      <Box className="col-md-12">
                        <InputLabel>Event Name</InputLabel>
                        <TextField
                          variant="outlined"
                          name="name"
                          className={`${errors.name ? 'error' : ''} gt-input`}
                          fullWidth
                          value={formData.name}
                          onChange={(e) => handleChange(e)}
                        />
                      </Box>
                      <Box className="col-md-12 mt-2">
                        <InputLabel>Description</InputLabel>
                        <TextField
                          variant="outlined"
                          name="description"
                          className={`${errors.description ? 'error' : ''} gt-input`}
                          fullWidth
                          value={formData.description}
                          onChange={(e) => handleChange(e)}
                        />
                      </Box>
                      <Box className={`${errors.country ? 'error' : ''} col-md-12 mt-2`}>
                        <InputLabel>Country</InputLabel>
                        <Select
                          className="address-selection"
                          name="country"
                          variant="outlined"
                          value={country}
                          options={countries}
                          isSearchable={true}
                          classNamePrefix="address-custom-select"
                          onChange={(e) => {
                            setFormData({ ...formData, country: e.name });
                            setStateOptions(e, StatesData);
                            setCountry(e);
                            setState(null);
                            setCity(null);
                          }}
                        />
                      </Box>
                      <Box className={`${errors.state ? 'error' : ''} col-md-12 mt-2`}>
                        <InputLabel>State</InputLabel>
                        <Select
                          className="address-selection"
                          name="state"
                          variant="outlined"
                          value={state}
                          options={states}
                          isSearchable={true}
                          classNamePrefix="address-custom-select"
                          onChange={(e) => {
                            setFormData({ ...formData, state: e.name });
                            setCityOptions(country, e, CitiesData);
                            setState(e);
                          }}
                        />
                      </Box>
                      <Box className={`${errors.city ? 'error' : ''} col-md-12 mt-2`}>
                        <InputLabel>City</InputLabel>
                        <Select
                          className="address-selection"
                          name="city"
                          variant="outlined"
                          value={city}
                          options={cities}
                          isSearchable={true}
                          classNamePrefix="address-custom-select"
                          onChange={(e) => evaluateCity(e)}
                        />
                      </Box>
                      <Box className="col-md-6 mt-2">
                        <InputLabel>Date</InputLabel>
                        <TextField
                          id="date"
                          type="date"
                          variant="outlined"
                          className={`${errors.date ? 'error' : ''} gt-input`}
                          name="date"
                          fullWidth
                          inputRef={inputRef}
                          value={formData.date}
                          // defaultValue={ formData.date }
                          inputProps={{ min: formData.date }}
                          onChange={(e) => handleChange(e)}
                          onClick={() => {
                            inputRef.current.showPicker()
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={publicUrl + "assets/images/inner-page/calendar-regular.png"} className="cal-left-icon" />
                              </InputAdornment>
                            ),
                            shrink: true,
                          }}
                        />
                      </Box>
                      <Box className="col-md-6 mt-2 mb-2">
                        <InputLabel>Time</InputLabel>
                        <TextField
                          value={formData.time}
                          type="time"
                          variant="outlined"
                          className={`${errors.time ? 'error' : ''} gt-input`}
                          fullWidth
                          name="time"
                          inputRef={timeRef}
                          onClick={() => {
                            timeRef.current.showPicker()
                          }}
                          onChange={(e) => handleChange(e)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={publicUrl + "assets/images/inner-page/time-regular.png"} className="cal-left-icon" style={{ width: 23 }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box className="col-md-12 mt-2 mb-2">
                        <InputLabel>Duration</InputLabel>
                        <TextField
                          value={formData.duration}
                          type="number"
                          variant="outlined"
                          className={`${errors.duration ? 'error' : ''} gt-input`}
                          fullWidth
                          name="duration"
                          onChange={(e) => handleChange(e)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={publicUrl + "assets/images/inner-page/time-regular.png"} className="cal-left-icon" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      {/* <Box className="col-md-2 mt-2 mb-2">
                      <InputLabel>Private</InputLabel>
                      <Switch
                        checked={ formData.isPrivate }
                        // checked={state.checkedB}
                        // onChange={handleChange}
                        className={`${errors.isPrivate ? 'error' : ''}`} 
                        onChange={(e) => setFormData({ ...formData, isPrivate: !formData.isPrivate })} 
                        name="isPrivate"
                        color="primary"
                        size="small"
                      />
                  </Box> */}
                      {/* <Box className="col-md-5 mt-2">
                    <InputLabel>Stream Code</InputLabel>
                    <TextField 
                        value={formData.streamCode}
                        variant="outlined" 
                        className={`${errors.streamCode ? 'error' : ''} gt-input`} 
                        fullWidth
                        name="streamCode"
                        onChange={(e) => handleChange(e)} 
                    /> 
                  </Box> */}
                      <Box className="col-md-6 mt-2">
                        <InputLabel>Price</InputLabel>
                        <TextField
                          value={formData.price}
                          type="number"
                          variant="outlined"
                          className={`${errors.price ? 'error' : ''} gt-input`}
                          fullWidth
                          name="price"
                          onChange={(e) => handleChange(e)}
                        />
                      </Box>
                      <Box className="col-md-6 mt-4"></Box>
                      <Box className="col-md-6 col-6 mt-2 upload-thumbnail text-center">
                        <InputLabel>Thumbnail</InputLabel>
                        <FilePond
                          name="thumbnail"
                          files={thumbnail}
                          onupdatefiles={setThumbnail}
                          allowMultiple={false}
                          imagePreviewHeight={70}
                          imageCrop={true}
                          labelIdle={`<img src="${publicUrl}assets/images/inner-page/dropzone.png"}/>`}
                          // fileValidateType={handleFileValidate}
                          acceptedFileTypes={['image/*']}
                        />
                      </Box>
                      <Box className="col-md-6 col-6 mt-2 upload-banner text-center">
                        <InputLabel>Banner</InputLabel>
                        <FilePond
                          name="banner"
                          files={banner}
                          onupdatefiles={setBanner}
                          allowMultiple={false}
                          imagePreviewHeight={70}
                          imageCrop={true}
                          labelIdle={`<img src="${publicUrl}assets/images/inner-page/dropzone.png"}/>`}
                          // fileValidateType={handleFileValidate}
                          acceptedFileTypes={['image/*']}
                        />
                      </Box>
                    </Box>
                    <Box style={{ textAlign: 'right', marginTop: 5 }}>
                      <Button variant="outlined" className="outlined-btn" onClick={() => props.close()}>Cancel</Button>
                      <Button type="submit" variant="contained" className="contained-btn">Confirm</Button>
                    </Box>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

CreateGroupTripsModal.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);


export default enhance(CreateGroupTripsModal)
import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import compose from 'recompose/compose';
import PropTypes, { func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Dialog, IconButton, Link, Button, DialogContent } from '@material-ui/core';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import moment from 'moment-timezone';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import { confirmAlert } from 'react-confirm-alert';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SubHeader from '../../components/layout/sub-header';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const WALKER_WEEKLY_STATS = loader('../../graphql/schema/walker/walker-weekly-stats.graphql');

const Earnings = (props) => {

    const { history } = props;
    const [dateFrom, setDateFrom] = useState(moment());
    const [walkerWeeklyStats, setWalkerWeeklyStats] = useState([]);
    const [tips, setTips] = useState(0);
    const [stats, setStats] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);

    const { loading, refetch } = useQuery(WALKER_WEEKLY_STATS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            data: {
                from: moment(dateFrom).day(0).format('YYYY-MM-DD'),
                to: moment(dateFrom).day(6).format('YYYY-MM-DD'),
            }
        },
        onCompleted({ walkerWeeklyStats }) {
            setWalkerWeeklyStats(walkerWeeklyStats.data);
            setTips(walkerWeeklyStats.total_tip);
            setStats({
                online: walkerWeeklyStats.online_hours,
                walks: walkerWeeklyStats.total_walk,
                average: walkerWeeklyStats.average_walk_time,
            })
        }
    });

    const handleWeek = (value) => {
        const newDateFrom = value === 'next' ? moment(dateFrom).add(7, 'day') : moment(dateFrom).subtract(7, 'day');
        setDateFrom(newDateFrom);
        refetch({
            data: {
                from: moment(newDateFrom).day(0).format('YYYY-MM-DD'),
                to: moment(newDateFrom).day(6).format('YYYY-MM-DD'),
            }
        });
    }

    const handleCalculateStat = (e) => {
        e.preventDefault();
        setOpenPopup(true);
    }

    const barChartData = {
        datasets: [
            {
                label: 'Daily Earnings',
                data: walkerWeeklyStats.map((item) => {
                    return {
                        x: moment(item.date).format('ddd'),
                        y: item.total_price,
                    }
                }),
                backgroundColor: '#071C55',
            }
        ]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
            },
            tooltip: false,
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (item) => (`${item.y}`),
                labels: {
                  value: {
                    color: '#071C55'
                  }
                }
      
              }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                }
            },
            y: {
                grid: {
                    color: '#eee',
                    tickColor: '#fff',
                    display: false,
                },
            },
        }
    };

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="Earnings" />
                    <div className="container">
                        <div className="travelle-body">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 my-3">
                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                                        <IconButton color="primary" component="span" onClick={() => handleWeek('last')}>
                                            <ArrowBackIosRounded style={{ color: '#F68702' }}/>
                                        </IconButton>
                                        <div>{`${moment(dateFrom).day(0).format('MMM DD, YYYY')} - ${moment(dateFrom).day(6).format('MMM DD, YYYY')}`}</div>
                                        <IconButton color="primary" component="span" onClick={() => handleWeek('next')}>
                                            <ArrowForwardIosRounded style={{ color: '#F68702' }}/>
                                        </IconButton>
                                    </div>
                                    <Bar options={options} data={barChartData} />
                                </div>
                                <div className="col-md-12 col-lg-6 my-3 d-flex flex-column align-items-center justify-content-center p-5" style={{ backgroundColor: '#F2F2F2', borderRadius: 5 }}>
                                    <h3 className="text-center mb-3">Tips This Week</h3>
                                    <h5 className="text-center font-weight-light">${Number(tips).toFixed(2)}</h5>
                                    <h3 className="text-center mt-3 mb-3">Stats</h3>
                                    <div className="d-flex justify-content-around w-100">
                                        <div className="text-center">
                                            <h4>Online</h4>
                                            <h5 className="font-weight-light">{stats.online}</h5>
                                        </div>
                                        <div className="text-center">
                                            <h4>Walks</h4>
                                            <h5 className="font-weight-light">{stats.walks}</h5>
                                        </div>
                                        <div className="text-center">
                                            <h4>Avg. Walk Time</h4>
                                            <h5 className="font-weight-light">{stats.average} min</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center my-3">
                                    <Link href="#" onClick={handleCalculateStat} ><u>How we calculate stats</u></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog onClose={() => setOpenPopup(false)} open={openPopup}>
                <div className="schedule-pick-row walker-map-popup m-4">
                    <DialogContent className="text-center">
                        <h5>Online Time</h5>
                        <p>This number indicates the total time you were online for the week showing, and not just the amount of time you spent walking on trips.</p>
                        <h5 className="mt-3">Walks</h5>
                        <p>This number indicates the total number of walks you went on for the week showing.</p>
                        <h5 className="mt-3">Average Walk Time</h5>
                        <p>This number indicates the average length of walk you've taken for all the walks you've taken during the week showing.</p>
                        <button className="btn btn-yellow mt-4" onClick={() => setOpenPopup(false)} style={{ width: 150 }}>OK</button>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )
}

Earnings.propTypes = {
    history: PropTypes.object.isRequired
};

const enhance = compose(
    withRouter,
);

export default enhance(Earnings);
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Rating from '@material-ui/lab/Rating';
import { InputAdornment } from '@material-ui/core';
import { TextField } from 'final-form-material-ui';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PageLoader from '../../components/layout/ui/page-loader';

const USER_DETAILS = loader('../../graphql/schema/auth/user.graphql');
const SAVE_RATING = loader('../../graphql/schema/walker/save-rating.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
      '& > * > * > * > .MuiRating-icon > svg' : {
        color: '#cdcdcd'
      },
      '& > * > * > * > .MuiRating-iconFilled > svg' : {
        color: '#F3941E'
      },
    },
    number: {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      }
    }
  }),
);

const WalkerRating = (props) => {

  const { history, booking } = props;
  console.log(booking);

  const isScheduleTrip = typeof booking.isScheduleTrip !== 'undefined' && booking.isScheduleTrip;
  const [rating, setRating] = useState(0);
  const [walkerDetails, setWalkerDetails] = useState({});

  const [saveRating, { loading: saveRatingLoading }] = useMutation(SAVE_RATING, {
    onCompleted({
      saveRating: {
        status,
        message
      }
    }) {
      if (status === 'SUCCESS') {
        history.replace('/destination-categories');
        window.location.reload();
      }
    }
  });

  const onSubmitRating = (values) => {
    saveRating({
      variables: {
        data: {
          rating: values.rating,
          comment: values.comment,
          tip_amount: typeof values.tip_amount !== 'undefined' ? values.tip_amount : 0,
          walker_id: booking.walker_id,
          booking_id: booking.booking_id,
          is_schedule_trip: isScheduleTrip,
        }
      },
    });
  };

  const { user, loading } = useQuery(USER_DETAILS, {
    variables: {
      data: booking.walker_id
    },
    onCompleted({ user }) {
      setWalkerDetails(user);
    }
  });

  const classes = useStyles();

  const validate = (values) => {
    const errors = {};
    if (values.rating === null) {
      errors.rating = 'Rating is required';
    }
    // if (!values.comment) {
    //   errors.comment = 'Comment is required';
    // }
    if ((isNaN(parseInt(values.tip_amount)) || values.tip_amount === '0') && typeof values.tip_amount !== 'undefined') {
      errors.tip_amount = 'Invalid tip value';
    }
    return errors;
  };

  const setInputFilter = (evt) => {
    let theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
        key = String.fromCharCode(key);
    }
    let regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }


  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="modal show fade destination-body-popup" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.3)' }}>
        <div style={{ height: '100%', width: '100%', padding: 20 }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content payment-popup-body" style={{ maxWidth: 600 }}>
              <Form
                onSubmit={onSubmitRating}
                validate={ validate }
                render={({ handleSubmit }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="popup-scroll" style={{ padding: 0, paddingBottom: 80 }}>
                      {!isScheduleTrip && 
                      <button type="button" className="close" onClick={() => onSubmitRating({ comment: '', tip_amount: '', rating: 0 })}><i className="la la-times"></i></button>
                      }
                      <div className="d-flex align-items-center flex-column">
                        <div className="profile-row w-100" style={{ maxWidth: 600, marginBottom: 0, borderRadius: '5px 5px 0px 0px', backgroundColor: isScheduleTrip ? '#C10916' : '#202464' }}>
                          <div className="profile-left w-100">
                            <figure>
                              <img
                                style={{ backgroundColor: '#fff' }}
                                src={walkerDetails.avatar ? walkerDetails.avatar : publicUrl + 'assets/images/testimonial-img1.jpg'}
                                alt="profile"
                              />
                            </figure>
                            <div className="walker-training-div w-100">
                              <div className="profile-title">
                                <h5> {walkerDetails.first_name} {' '} {walkerDetails.last_name} </h5>
                              </div>
                              <div className="profile-title">
                                <p className="mb-2" style={{ hyphens: 'none' }}>Rate {walkerDetails.first_name} {' '} {walkerDetails.last_name}'s walker trip experience provided for you.</p>
                                <div className="form-in star-rating" className={classes.root}>
                                  {/* <Rating name="size-large" defaultValue={0} onChange={(e, value) => setRating(value)} /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="edit-form-row">
                          <div className={`form-in star-rating ${classes.root}`}>
                            <Field
                                id="rating"
                                name="rating"
                                margin="normal"
                                variant="outlined"
                                type="number"
                                defaultValue={5}
                                render={({ input, meta }) => (
                                  <div>
                                    <Rating {...input}/>
                                    {meta.touched && meta.error && <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{meta.error}</p>}
                                  </div>
                                )}
                              />
                          </div>
                          <div className="form-in">
                            <span>Write a comment</span>
                            <Field
                              id="comment"
                              placeholder="Enter comment"
                              type="text"
                              name="comment"
                              margin="normal"
                              variant="outlined"
                              defaultValue=""
                              component={TextField}
                            />
                          </div>
                          {!isScheduleTrip &&
                            <div className="form-in">
                              <span>Add a tip value</span>
                              <Field
                                id="tip_amount"
                                placeholder="Enter tip value"
                                type="text"
                                name="tip_amount"
                                margin="normal"
                                variant="outlined"
                                render={({ input, meta }) => (
                                  <>
                                    <div className="d-flex align-items-center bg-white">
                                      <div className="input-group-addon ml-3" id="basic-addon1"><i className="fa fa-dollar"></i></div>
                                      <input {...input} className={classes.number} onKeyPress={(e) => setInputFilter(e)}/>
                                    </div>
                                    {meta.touched && meta.error && <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{meta.error}</p>}
                                  </>
                                )}
                              />
                            </div>
                          }
                          <div className="text-center">Report an issue</div>
                        </div>
                      </div>
                    </div>
                    <div className="payment-popup-btn">
                      <div className="form-btn m-0 d-flex align-items-center justify-content-center">
                        <Button type="submit" disabled={saveRatingLoading} style={{ maxWidth: 200 }}>Submit</Button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

WalkerRating.propTypes = {
  history: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(WalkerRating);

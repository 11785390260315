import React from 'react';
import compose from 'recompose/compose';
import { withRouter, useLocation } from 'react-router-dom';
import * as commonFunction from '../../utilities/commonFunctions';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkout-form';
import { Box } from '@material-ui/core'; 
import SubHeader from '../../components/layout/sub-header';
import DownloadLink from '../../components/layout/download-link';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AddCard = () => {
  const location = useLocation();
  const trip = commonFunction.getQueryParam(location, 'trip');

  return (
    <>
      <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Payment Method" />
          <div className='inner-menu'>
            {/* <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Payment method</a> */}
          </div>
          <div id="content-wrapper" className="container">
            <Box className="inner-box">
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <CheckoutForm stripe={stripe} elements={elements} trip={trip} />
                  )}
                </ElementsConsumer>
              </Elements>
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

const enhance = compose(
  withRouter,
);

export default enhance(AddCard);
import React from 'react';

const publicUrl = process.env.PUBLIC_URL + '/';

const Banner = () => {
  
  return (
    <>
      <div id="home" className="banner-area viaje-go-top banner-area-new">
        <div className="banner-slider">
          <div className="video-banner-area banner-bg-1">
            <video muted={true} id="video-banner" controls autoPlay loop poster={`${publicUrl}assets/images/banner_video_poster.jpg`}>
              <source src={`${process.env.REACT_APP_AWS_S3_URL}DestinationsCompressed.mp4`} type="video/mp4"></source>
            </video>
            <div className="video-banner-content-wrap">
              <div className="container">
                <div className="video-banner-content text-center">
                  <h2>Huvr is Virtual Travel.</h2>
                  <h2 className="main-title s-animate-2">Explore The World With Us!</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner;
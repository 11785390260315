import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import SignatureCanvas from 'react-signature-canvas'
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import { Paper } from '@material-ui/core';
import userUtils from '../../utilities/UserUtils';
import SubHeader from '../../components/layout/sub-header';

const TERMS = loader('../../graphql/schema/terms/terms.graphql');
const WALKER_TERMS_CONDITIONS = loader('../../graphql/schema/terms/walker-terms-condition.graphql');

const WalkerTerms = ( props ) => {

  const { history } = props;

  const [hasError, setHasError] = useState('');
  const { data: terms } = useQuery(TERMS);
  let signPad = { width: 100, height: 200 };
  const [canvasWidth, setCanvasWidth] = useState(1000);

  const [updateWalkerTermsAndConditions, { loading }] = useMutation(WALKER_TERMS_CONDITIONS, {
    onCompleted() {
      if (userUtils.getUserType() == 'A') {
        history.push('/complete-verification-ambassador');
      } else {
        history.push( '/make-money-for-walker' );
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  useEffect(() => {
    const signCanvasWidth = document.getElementById('sign-canvas').offsetWidth;
    setCanvasWidth(signCanvasWidth);
  });

  const handleClear = () => {
    signPad.clear();
  };

  const handleSubmit = () => {
    updateWalkerTermsAndConditions({
      variables: {
        data: {
          status: true,
          digital_signature: signPad.getTrimmedCanvas().toDataURL('image/png'),
        }
      },
    });
  }
  
  return (
    <>
      {loading && <PageLoader loading={loading} />}
      {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Walker Terms" />
          <div className="container">
            <div className="travelle-body">
              <div className="welcome-sign welcome-sign-title">
                <span>Walker Terms</span>
                <p>Please read and sign below</p>
              </div>
              <Paper className="p-5" elevation={3}>
                <span>Terms Dated <br />
                 {terms && terms.term.updated_at}<br />
                </span>
                <div dangerouslySetInnerHTML={{ __html: terms && terms.term.description}} />
              </Paper>
              <div className="welcome-sign">
                <span>Sign to Accept the Walker Terms</span>
              </div>
              <div className="welcome-form">
                <div className="form-in">
                  <div id="sign-canvas" style={{ position: 'relative', backgroundColor: '#eee' }}>
                    <SignatureCanvas penColor='black'
                      ref={(ref) => signPad = ref}
                      canvasProps={{ width: canvasWidth, height: 130, className: 'sigCanvas'}}
                    />
                    <div className="d-flex align-items-center" style={{ position: 'absolute', top: 0, right: 20, height: 140 }}>
                      <button className="btn btn-danger m-0" onClick={handleClear} style={{ borderRadius: 50 }}>Clear</button>
                    </div>
                  </div>
                  <button className="btn btn-yellow" onClick={handleSubmit} style={{ minWidth: 150 }}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WalkerTerms.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( WalkerTerms );

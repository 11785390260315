import React from 'react';
import LazyImage from '../../utilities/lazy-image';

const publicUrl = process.env.PUBLIC_URL + '/';

const VideoSection = () => {

  return (
    <>
      <div id="about-huvr" className="video-area tp-video-area pd-top-110 video-area-row" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/bg/7.png)' }}>
        <div className="container viaje-go-top">
          <div className="row">
            <div className="col-xl-5 col-lg-6 align-self-center wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
              <div className="section-title mb-lg-0 mb-4 text-center text-lg-left">
                <h2 className="title">What Do You Know <br /> About US</h2>
                <p>HUVR is a global virtual marketplace, that connects Human Walkers to Virtual Travelers who want to explore the world in real-time.</p>
                <p>With thousands of destinations that you can visit from your smartphone, whether you  are using the app as an inspired traveler, an investor or someone who loves to shop, educate or simply experience an array of culture and happenings in another country or city - Huvr can take you there.</p>
                <a className="btn btn-yellow" href="https://go.huvr.com/about/" target="_blank">
                  <span>Read More<i className="la la-arrow-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 offset-xl-1 wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
              <div className="video-popup-wrap">
                <div className="thumb">
                  <LazyImage src={publicUrl + "assets/images/video.png"} alt="video" />
                </div>
                <div className="video-popup-btn">
                  <a href="#explore-huvr" class="video-play-btn"><i className="fa fa-play" /></a>
                  <div id="explore-huvr" className="mfp-hide">
                    <video preload="false" id="explore-huvr-video" controls controlsList="nodownload">
                        <source src={`${process.env.REACT_APP_AWS_S3_URL}huvr_vimeo.mp4`} type="video/mp4"></source>
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoSection;

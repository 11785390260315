import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

const publicUrl = process.env.PUBLIC_URL + '/';

const MobileDataGrid = (props) => {
    const evaluateStatus = (status) => {
        if(status == 1) {
          return <span className="gtrip-status status-upcoming">UPCOMING</span>;
        } else if(status == 2) {
          return <span className="gtrip-status status-cancelled">CANCELLED</span>;
        } else if(status == 3) {
          return <span className="gtrip-status status-completed">COMPLETED</span>;
        } else if(status == 4) {
          return <span className="gtrip-status status-live">LIVE</span>;
        } else {
          return <span className="gtrip-status status-expired">EXPIRED</span>;
        }
      }
    console.log('MobileDataGrid', props);
    return <>
      { props.rows.map(row => {
        return <Box className="event-row">
                    <Box className="event-row-inner">
                        <Box className="col-4">EVENT NAME</Box>
                        <Box className="col-8">{row.destination.name}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">DESTINATION</Box>
                        <Box className="col-8">{row.destination.location}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">DATE & TIME</Box>
                        <Box className="col-8">{moment.unix(row.epoch).format('MMM DD, YYYY h:mm A')}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">MINUTES</Box>
                        <Box className="col-8">{row.schedule_minute}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">PRICE</Box>
                        <Box className="col-8">${row.schedule_price}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">Status</Box>
                        <Box className="col-8">{evaluateStatus(row.status)}</Box>
                    </Box>
                    <Box className="event-row-inner">
                        <Box className="col-4">ACTIONS</Box>
                        <Box className="col-8">
                            <Box className="row">
                                <Button class="gtrip-action-btn mr-1 gtrip-view-btn" onClick={() => props.history.push(`/view-group-trip/${row.id}`)}>
                                    <img src={publicUrl + "assets/images/inner-page/eye-solid.png"} /> View
                                </Button>
                                <Button class="gtrip-action-btn mr-1 gtrip-edit-btn" onClick={() => props.onEdit(row)}>
                                    <img src={publicUrl + "assets/images/inner-page/edit-solid.png"} /> Edit
                                </Button>
                                <Button class="gtrip-action-btn mr-1 gtrip-remove-btn" onClick={() => props.onRemove(row)}>
                                    <img src={publicUrl + "assets/images/inner-page/trash-solid.png"} /> Delete
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
      })
    }
    </>;
  };

  MobileDataGrid.propTypes = {
    history: PropTypes.object.isRequired,
  };
  
  const enhance = compose(
    withRouter,
  );

  export default withRouter(enhance(MobileDataGrid));
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { TextField } from 'final-form-material-ui';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { loader } from 'graphql.macro';
import ReactFlagsSelect from 'react-flags-select';
import { confirmAlert } from 'react-confirm-alert';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const WALKER_KIT = loader('../../graphql/schema/walker/walker-kit-order.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const WalkerKit = (props) => {

  const { history , location } = props;

  const [countryCode, setCountryCode] = useState('');
  const [hasError, setHasError] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const comingSoon = !location.state || location.state.coming_soon;

  const [updateWalkerKit, { loading }] = useMutation(WALKER_KIT, {
    onCompleted() {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body text-center" style={{ borderRadius: 5 }}>
              <p>Walker training kit ordered successfully.</p>
              <div className="react-confirm-alert-button-group justify-content-center">
                <button onClick={onClose} className="btn btn-yellow" style={{ backgroundColor: 'var(--main-color-one)', padding: '0 30px' }}>
                  Okay
                </button>
              </div>
            </div>
          );
        },
        overlayClassName: 'header-overlay',
        afterClose: () => {
          history.push('/make-money-for-walker');
        }
      });
    }
  });

  const validate = (values) => {
    const errors = {};

    if (!values.street_address) {
      errors.street_address = 'Enter Street Address';
    }

    if (!values.city) {
      errors.city = 'Enter City';
    }

    if (!values.state) {
      errors.state = 'Enter State';
    }

    if (!values.zip_code) {
      errors.zip_code = 'Enter Zip Code';
    }

    if (!values.termsCondition) {
      errors.termsCondition = '';
    }

    return errors;
  };

  const onSubmitWalkerKit = (values) => {
    if (countryCode === '' || countryCode === undefined || countryCode === null) {
      setHasError('Select Country');
    } else {
      updateWalkerKit({
        variables: {
          data: {
            city: values.city,
            street_address: values.street_address,
            state: values.state,
            country: countryCode,
            zip_code: values.zip_code,
          },
        },
      });
    }

  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    history.push('/');
  }

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Walker Kit" />
          {comingSoon && 
            <div className="container">
              <div className="travelle-body">
                <figure className="coming-soon">
                  <img src={publicUrl + "assets/images/inner-page/comingSoon.png"} alt="" />
                </figure>
              </div>
            </div>
          }
          {!comingSoon &&
            <div className="container">
              <div className="walkerkit-body">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="walkerkit-row">
                      <figure>
                        <img src={publicUrl + "assets/images/inner-page/phoneKit.png"} alt="" />
                        <span>$0.00 USD</span>
                      </figure>
                      <p>In Beta, we HIGHLY RECOMMEND using a stabilizer stick to enhance the user experience for Traverlers. You will get more business if you provide a quality experience. Instead of requiring you to purchase that here, we are allowing you to use your own or purchase one on a marketplace like Amozon.com.</p>
                    </div>
                    <div className="walkerkit-row">
                      <figure>
                        <img src={publicUrl + "assets/images/inner-page/phoneKit.png"} alt="" />
                        <span>$0.00 USD</span>
                      </figure>
                      <p>Please complete this form with your current mailing address and we will send you some Huvr Swag in the near future.</p>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">

                    <Form
                      onSubmit={onSubmitWalkerKit}
                      validate={validate}
                      initialValues={{
                        termsCondition: false
                      }}
                      render={({ handleSubmit, submitting }) => (
                        <form
                          onSubmit={handleSubmit}
                          noValidate
                          className="edit-form-row"
                        >
                          <div className="form-in">
                            <span>COUNTRY</span>
                            <Field id="country" name="country">
                              {({ input, meta }) => {
                                return (
                                  <div>
                                    <ReactFlagsSelect
                                      {...input}
                                      searchPlaceholder="Search countries"
                                      searchable
                                      selected={countryCode}
                                      onSelect={code => setCountryCode(code)}
                                    />
                                    {meta.touched && countryCode === '' && <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">Select Country</p>}
                                  </div>
                                )
                              }}
                            </Field>

                          </div>

                          <div className="form-in">
                            <span>Street Address</span>
                            <Field
                              id="street_address"
                              placeholder=""
                              type="text"
                              name="street_address"
                              margin="normal"
                              variant="outlined"
                              component={TextField}
                            />
                          </div>
                          <div className="form-in">
                            <span>City</span>
                            <Field
                              id="City"
                              placeholder=""
                              type="text"
                              name="city"
                              margin="normal"
                              variant="outlined"
                              component={TextField}
                            />
                          </div>
                          <div className="form-in">
                            <span>State</span>
                            <Field
                              id="state"
                              placeholder=""
                              type="text"
                              name="state"
                              margin="normal"
                              variant="outlined"
                              component={TextField}
                            />
                          </div>


                          <div className="form-in">
                            <span>Zip Code</span>
                            <Field
                              id="zip_code"
                              placeholder=""
                              type="text"
                              name="zip_code"
                              margin="normal"
                              variant="outlined"
                              component={TextField}
                            />
                          </div>
                          <div className="form-in-check">
                            <Field
                              id="termsCondition"
                              type="checkbox"
                              name="termsCondition"
                              margin="normal"
                              variant="outlined"
                            >
                            {({ input, meta }) => {
                              return (
                                <div className="d-flex">
                                  <input
                                    {...input}
                                    style={{ width: 50 }}
                                  />
                                  <p style={meta.touched && !input.checked ? { color: 'red', fontSize: '13px', lineHeight: '20px' } : {fontSize: '13px', lineHeight: '20px'}}>
                                    I understand that Huvr is not requiring me to purchase a Walker Kit in Beta but a stabilizer stick is HIGHLY recommended
                                    for the best user experience.
                                  </p>
                                </div>
                              )
                            }}
                            </Field>
                          </div>
                          <div className="form-btn">
                            <Button type="submit" disabled={submitting}>Submit</Button>
                          </div>
                        </form>
                      )}
                    />

                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <Dialog onClose={() => setOpenPopup(false)} aria-labelledby="simple-dialog-title" open={openPopup}>
          <div>
            <span>Walker Training kit order successfully</span>
            <Button onClick={handleClosePopup}>OK</Button>
          </div>
        </Dialog>
      </div>
    </>
  )
}

WalkerKit.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(WalkerKit);

import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter, useParams } from 'react-router-dom';
import { TextField, InputLabel, Checkbox, Select, MenuItem, DialogTitle, Divider, Typography, Button, Modal, InputAdornment, Box, Table, TableBody, TableRow, TableCell, FormControlLabel, ListItemIcon, ListItemText, DialogContent, Dialog } from '@material-ui/core';
import moment from 'moment-timezone';
import SubHeader from '../../components/layout/sub-header';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../payments/payment-form';
import { Form } from 'react-final-form';
import { CreditCardOutlined } from '@material-ui/icons';
import PageLoader from '../../components/layout/ui/page-loader';
const GET_GROUP_SCHEDULESTRIP = loader('../../graphql/schema/walker/get-group-scheduletrip.graphql');

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const publicUrl = process.env.PUBLIC_URL + '/';
const tripDetails = { color: '#00A9CB', marginBottom: '15px', wordWrap: 'break-word' };
const tripDetailsFinal = { color: '#00A9CB', fontSize: '15px' };
const detailValue = { color: '#6F6F6F', fontSize: '15px', wordWrap: 'break-word' };
const btnContained = { border: '1px solid #F1AB54', color: '#fff', backgroundColor: '#F1AB54', borderRadius: '100px', padding: 10, width: 120, marginLeft: 15};
const stepTitle = { color: '#00A9CB', textAlign: 'center' };
const GET_USER_CARDS = loader('../../graphql/schema/payments/get-user-cards.graphql');
const GROUP_TRIP_PAYMENT = loader('../../graphql/schema/traveller/group-trip-payment.graphql');

const JoinGroupTrip = (props) => {
  const { history, location } = props;
  const [step, setStep] = useState('details');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedCardData, setSelectedCardData] = useState(null);
  const { id } = useParams();
  const tripId = atob(id);
  const [formData, setFormData] = useState({
    company: '',
    industry: '',
    age_range: '',
    event_updates: false
  });
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [groupTrip, setGroupTrip] = useState(null);
  const [destination, setDestination] = useState(null);

  useEffect(() => {
    let savedData = localStorage.getItem('join_trip_data');

    if(savedData) {
      savedData = JSON.parse(savedData);
      setFormData(savedData);
    }
  }, []);

  const { data: userCards, loading: loading1 } = useQuery(GET_USER_CARDS, {
    fetchPolicy: 'network-only',
    onCompleted() {
      getDefaultCard();
    }
  });

  const { data: scheduleTrip, refetch, loading } = useQuery(GET_GROUP_SCHEDULESTRIP, {
    variables: { schedule_trip_id: tripId },
    fetchPolicy: 'cache-and-network',
    onCompleted({ response }) {
        setGroupTrip(scheduleTrip.groupScheduleTrip.scheduleTrip);
        setDestination(scheduleTrip.groupScheduleTrip.scheduleTrip.destination);
    }
  });

  const [groupTripPayment, {loading: paymentLoading}] = useMutation(GROUP_TRIP_PAYMENT, {
    variables: {
      schedule_trip_id: tripId,
      fee: groupTrip ? groupTrip.schedule_price : null
    },
    onCompleted({ response }) {
      setStep('success');
      console.log(errors);
    },
   onError(errors) {
    console.log(errors);
    },
  });

  const next = () => {
    if(step == 'addtl-info') {
      setStep('details');
    } else if(step == 'details') {
      // setStep('pay-method');
    } else if(step == 'pay-method') {
      setStep('payment');
    } else if(step == 'payment') {
      setStep('success');
    } else if(step == 'success') {
      localStorage.removeItem('join_trip_data');
      history.push(`/event-registration-success/${id}`);
    }
  }

  const cancel = () => {
    console.log('step', step);
    if(step == 'details') {
      setStep('addtl-info');
    } else if(step == 'payment') {
      // setStep('pay-method');
    } else if(step == 'pay-method') {
      setStep('details');
    } else if(step == 'success') {
      setStep('payment');
    }
  }

  const proceedPayment = (data) => {
    console.log('proceedPayment == >',data);
    next();
  }

  const payWithSelectedCard = () => {
    // setStep('success');
    setShowModal(false);

    let params   =  {
      schedule_trip_id: tripId,
      fee: groupTrip ? groupTrip.schedule_price : null
    };

    if(formData.age_range !== '' && formData.company !== '') {
      params.data = formData;
    }

    groupTripPayment({ variables: params });
  }

  const getDefaultCard = () => {
    if(userCards) {
      let index = userCards.getUserCards.findIndex(item => item.default);
      
      if(index > -1) {
        console.log('index', index, userCards.getUserCards[index]);
        return setSelectedCardData(userCards.getUserCards[index]);
      }
    }

    return setSelectedCardData(null);
  }

  const goToPayment = () => {
    if(selectedCardData) {
      setShowModal(true);
    } else {
      setShowErrorModal(true);
    }
  }

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    let newData = { ...formData, [e.target.name]: e.target.name == 'event_updates' ? e.target.checked : e.target.value };
    setFormData(newData);

    localStorage.setItem('join_trip_data', JSON.stringify(newData));
  }

  return (
    <>
    {(paymentLoading || loading) && <PageLoader loading={paymentLoading || loading} /> }
     <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Events Details" />
          <div className='inner-menu'>
            {/* <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Event Registration</a> */}
          </div>
          <div id="content-wrapper" className="container" style={{ paddingBottom: 40 }}>
            <Box className={ step == 'details' ? 'inner-box' : 'hidden'}>
                <DialogTitle disableTypography>
                <Typography variant="h5" style={stepTitle}>GROUP TRIP DETAILS</Typography>
                </DialogTitle>
                <Divider/>

                <Box>
                    <p className="event-info" style={{...tripDetails, marginTop: '20px'}}>Trip Name: <span style={detailValue}>{ destination ?  destination.name : '' }</span></p>
                    <p className="event-info" style={tripDetails}>Description: <span style={detailValue}>{ destination ?  destination.description : '' }</span></p>
                    <p className="event-info" style={tripDetails}>Trip Duration: <span style={detailValue}>{ groupTrip ?  groupTrip.schedule_minute : '' } minutes</span></p>
                    <p className="event-info" style={tripDetails}>Total fee: <span style={detailValue}>${ groupTrip ?  groupTrip.schedule_price.toFixed(0) : '0' }</span></p>
                    <p className="event-info" style={tripDetails}>Location: <span style={detailValue}>{ destination ?  destination.location : '' }</span></p>
                </Box>
                <Box className="row">
                  <Box className="col-md-12 mb-2 reg-event-form-input">
                    <p className="event-add-info-lbl" style={{ ...tripDetails, textAlign: 'center',marginBottom: 15, marginTop: 10}}>Please include the following information - not all fields are required.</p>
                    <InputLabel className="reg-event-lbl">Company</InputLabel>
                    <TextField 
                      value={formData.company} 
                      name="company"
                      variant="outlined" 
                      size="small" 
                      placeholder="Company Name" 
                      className="required-input text-gray" 
                      fullWidth
                      onChange={(e) => handleChange(e)} />
                  </Box>
                  <Box className="col-md-12 mb-2 reg-event-form-input">
                    <InputLabel className="reg-event-lbl">Industry</InputLabel>
                    <Select
                        name="industry"
                        variant="outlined"
                        value={formData.industry}
                        displayEmpty={true}
                        className={`${formData.industry == '' ? 'placeholder-txt' : ''} text-gray`}
                        fullWidth
                        style={{ height: 40 }}
                        onChange={(e) => handleChange(e)}
                      >
                      <MenuItem value="" className="placeholder-txt">Select Industry</MenuItem>
                      <MenuItem value="Real Estate">Real Estate</MenuItem>
                      <MenuItem value="Education Institution">Education Institution</MenuItem>
                      <MenuItem value="Travel & Tourism">Travel & Tourism</MenuItem>
                      <MenuItem value="Sports">Sports</MenuItem>
                    </Select>
                  </Box>
                  <Box className="col-md-12 mb-2 reg-event-form-input">
                    <InputLabel className="reg-event-lbl">Age Range</InputLabel>
                    <Select
                        placeholder="Select Age"
                        name="age_range"
                        variant="outlined"
                        value={formData.age_range}
                        displayEmpty={true}
                        className={`${formData.age_range == '' ? 'placeholder-txt' : ''} text-gray`}
                        fullWidth
                        style={{ height: 40 }}
                        onChange={(e) => handleChange(e)}
                      >
                      <MenuItem value="" className="placeholder-txt">Select Age</MenuItem>
                      <MenuItem value="Under 21">Under 21</MenuItem>
                      <MenuItem value="21 to 29">21 to 29</MenuItem>
                      <MenuItem value="30 to 39">30 to 39</MenuItem>
                      <MenuItem value="40 to 49">40 to 49</MenuItem>
                      <MenuItem value="50 and over">50 and over</MenuItem>
                    </Select>
                  </Box>
                  <Box className="col-md-12 reg-event-form-input  ">
                    <FormControlLabel 
                      control={<Checkbox 
                        name="event_updates" 
                        checked={formData.event_updates}
                        value={formData.event_updates} 
                        onChange={(e) => handleChange(e)} />
                      } 
                      label="Would you like to be updated about new exclusive events?" 
                      className="text-gray"
                    />
                  </Box>
                </Box>
                <Box style={{ textAlign: 'center', marginTop: 25 }}>
                    {/* <Button variant="outlined" onClick={() => cancel()} style={{ border: '1px solid #F1AB54', color: '#F1AB54', borderRadius: '100px', padding: 10, width: 120, marginRight: 15}}>Cancel</Button> */}
                    <Button className="event-reg-btn" variant="contained" onClick={() => goToPayment()} style={{ border: '1px solid #F1AB54', color: '#fff', backgroundColor: '#F1AB54', borderRadius: '100px', padding: 10, width: 120, marginLeft: 15}}>Next</Button>
                </Box>
            </Box>
            <Box className={ step == 'pay-method' ? 'inner-box' : 'hidden'}>
                <Box id="payment-method-selection">
                </Box>
                <Box style={{ textAlign: 'center', marginTop: 25 }}>
                    {/* <Button variant="outlined" onClick={() => next()} style={{ border: '1px solid #F1AB54', color: '#F1AB54', borderRadius: '100px', padding: 10, width: 155, marginRight: 15}}>Add New Card</Button> */}
                    <Button className="event-reg-btn" variant="contained" onClick={() => setShowModal(true)} style={{ border: '1px solid #F1AB54', color: '#fff', backgroundColor: '#F1AB54', borderRadius: '100px', padding: 10, width: 205, marginLeft: 15}}>Proceed to payment</Button>
                </Box>
            </Box>
            <Box className={ step == 'payment' ? 'inner-box' : 'hidden'}>
                <DialogTitle disableTypography>
                    <Typography variant="h5" style={stepTitle}>PAYMENT METHOD</Typography>
                </DialogTitle>
                <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                    <PaymentForm stripe={stripe} elements={elements} next={(data) => proceedPayment(data)} cancel={() => cancel()} />
                    )}
                </ElementsConsumer>
                </Elements>
            </Box>
            <Box className={ step == 'success' ? 'inner-box' : 'hidden'}>
                <DialogTitle disableTypography className="event-success-box">
                    <img src={publicUrl + "assets/images/success-icon.png"} style={{ margin: 'auto', display: 'block'}} alt="" />
                    <Typography variant="h5" style={stepTitle}>PAYMENT SUCCESSFUL</Typography>
                    <p style={{ fontSize: '14px', color: '#6F6F6F', width: '65%', display: 'block', margin: 'auto', textAlign: 'center'}}>Thank you! You have successfully completed the payment.</p>
                </DialogTitle>
                <Divider/>
                <Box>
                  <Table id="trip-details-tbl">
                    <TableBody>
                      <TableRow>
                        <TableCell><p className="event-success-p" style={tripDetailsFinal}>Trip Name: </p></TableCell>
                        <TableCell className="trip-val"><span style={detailValue}>{ destination ?  destination.name : '' }</span></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><p className="event-success-p" style={tripDetailsFinal}>Location: </p></TableCell>
                        <TableCell className="trip-val"><span style={detailValue}>{ destination ?  destination.location : '' }</span></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><p className="event-success-p" style={tripDetailsFinal}>Duration: </p></TableCell>
                        <TableCell className="trip-val"><span style={detailValue}>{ groupTrip ?  groupTrip.schedule_minute : '' } minutes</span></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><p className="event-success-p" style={tripDetailsFinal}>Total Fee: </p></TableCell>
                        <TableCell className="trip-val"><span style={detailValue}>${  groupTrip ?  groupTrip.schedule_price : '' }</span></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Button className="event-reg-btn" variant="contained" onClick={() => next()} style={{...btnContained, display: 'block', margin: 'auto'}}>Done</Button>
                </Box>
            </Box>
          </div>
      </div>
    </div>
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      className="popupv2"
    >
      <div className="modal-dialog modal-dialog-centered dialog-centered payment-confirmation">
        <div className="modal-content payment-popup-body">
          <div className="popup-scroll">
            <button type="button" className="close" onClick={() => setShowModal(false)}><i className="la la-times"></i></button>
            <img src={publicUrl + 'assets/images/inner-page/question-icon.png'} />
            <h3 style={{ textAlign: 'center'}}>Confirm Payment</h3>
            <p>You will be charge a fee of ${ groupTrip ? groupTrip.schedule_price : '' }.</p>
            <p style={{ textAlign: 'center' }}>Using your default card {`XXXX XXXX XXXX ${selectedCardData ? selectedCardData.last4 : ''}`}.</p>
            <p>Press <span>Confirm</span> to continue or <span>Cancel</span>.</p>
          </div>
          <Box style={{ textAlign: 'center', marginTop: 25 }}>
              <Button variant="outlined" className="outlined-btn" onClick={() => setShowModal(false)}>Cancel</Button>
              <Button variant="contained" className="contained-btn" onClick={() => payWithSelectedCard()}>Confirm</Button>
          </Box>
        </div>
      </div>
    </Modal>
    <Modal
      open={showErrorModal}
      onClose={() => setShowErrorModal(false)}
      className="popupv2"
    >
      <div className="modal-dialog modal-dialog-centered dialog-centered payment-confirmation">
        <div className="modal-content payment-popup-body" style={{ width: 400, maxWidth: 400 }}>
          <div className="popup-scroll">
            <button type="button" className="close" onClick={() => setShowErrorModal(false)}><i className="la la-times"></i></button>
            <img src={publicUrl + 'assets/images/inner-page/danger-icon.png'} />
            <h3 style={{ textAlign: 'center'}}>No Payment Method</h3>
            <p>You don't have a default payment method.</p>
          </div>
          <Box style={{ textAlign: 'center', marginTop: 25 }}>
            <Button 
                variant="contained" 
                className="contained-btn reg-event-payment-btn" 
                onClick={() => history.push(`/payment-methods?trip=${id}`)}
                style={{ width: 205 }}
              >
                Add payment method
              </Button>
              <Button variant="outlined" className="outlined-btn reg-event-payment-btn" onClick={() => setShowErrorModal(false)}>Cancel</Button>
          </Box>
        </div>
      </div>
    </Modal>
    </>
  )
}

JoinGroupTrip.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(JoinGroupTrip)

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as commonFunction from '../../utilities/commonFunctions';

const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const GET_AUTH_INFO = loader('../../graphql/schema/auth/auth-info.graphql');
const GET_USER_CARDS = loader('../../graphql/schema/payments/get-user-cards.graphql');
const WALKER_TRAVELER_TOGGLE = loader('../../graphql/schema/auth/walker-traveler-toggle.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const DownloadLink = (props) => {

	const { history } = props;

	const apolloClient = useApolloClient();

    const { data } = useQuery(GET_AUTH_INFO, {
		onCompleted() {
			const token = localStorage.getItem('app-token');
			if (token) {
				apolloClient.writeData({ data: { isLoggedIn: true } });
			}
		},
	});

    if(commonFunction.isLogin()) {
		const { data: toggleMode } = useQuery(WALKER_TRAVELER_TOGGLE);

		const { data: userCards } = useQuery(GET_USER_CARDS);

		const { data: myDetails } = useQuery(MY_DETAILS);
	}

	const handleDeepLink = async (userType) => {
        if (!data.isLoggedIn) {
            appLink(userType);
        } else if (toggleMode && !toggleMode.isWalkerTraveller) {
            appLink(userType);
        } else {
            if (myDetails && myDetails.me.walker_profile && userCards && userCards.getUserCards && userCards.getUserCards[0].card_id !== null) {
                appLink(userType);
            }
            else {
                userCardModal();
            }
        }
	}

	const appLink = (userType) => {
		let urlPath = 'https://play.google.com/store/apps/details?id=com.huvr';
        if (userType === 'ios') {
			urlPath = 'https://itunes.apple.com/app/id1536389358';
        }
		const win = window.open(urlPath, '_blank');
		win.focus();
	}

	const userCardModal = () => {
		if (!myDetails.me.walker_profile) {
			updateWalkerProfile();
		}
		if (userCards.getUserCards && userCards.getUserCards[0].card_id === null) {
			updatePaymentMethod();
		}
	}

	const updatePaymentMethod = () => {
		confirmAlert({
			closeOnEscape: true,
			title: 'Alert',
			message: 'Please add payment method to download.',
			buttons: [
				{
					label: 'Add Payment Method',
					onClick: () => {
						history.push('/payment-methods');
					}
				},
				{
					label: 'Close',
					onClick: () => { }
				}
			],
			overlayClassName: 'header-overlay'
		});
	}

	const updateWalkerProfile = () => {
		confirmAlert({
			closeOnEscape: true,
			title: 'Alert',
			message: 'Please update your walker profile to download.',
			buttons: [
				{
					label: 'Update Walker Profile',
					onClick: () => {
						history.push('/walker-profile');
					}
				},
				{
					label: 'Close',
					onClick: () => { }
				}
			],
			overlayClassName: 'header-overlay'
		});
	}

    return (
        <div className="traveller-head-right">
            <p className='dl-label'>It’s Easier In the Apps! &nbsp;
                <span className="app-icon">
                    <img className='dl-icons' onClick={() => handleDeepLink('ios')} src={publicUrl + "assets/images/inner-page/apple-store-icon.png"} />&nbsp;
                    <img className='dl-icons' onClick={() => handleDeepLink('android')} src={publicUrl + "assets/images/inner-page/play-store-icon.png"} />
                </span>
            </p>
        </div>
    )
}

DownloadLink.propTypes = {
    history: PropTypes.object.isRequired,
  };
  
  const enhance = compose(
    withRouter,
  );
  
  export default enhance(DownloadLink);
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

const awsS3url = process.env.REACT_APP_AWS_S3_URL;

const Blog = (props) => {

  const { history } = props;
  const [blog, setBlog] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setBlog(history.location.state.blog)
  });

  return (
    <>
      <div className="blog-details-area pd-top-142 viaje-go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="blog-banner-area">
                <img src={awsS3url + blog.banner} alt="blog" />
              </div>
            </div>
          </div>
          <div className="row pd-top-50">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="text-center">
                <h2 className="blog-title">{blog.title}</h2>
                <h6 className="blog-author">By {blog.written_by}</h6>
                <p className="date mb-0">{new Date(blog.written_date).toDateString()}</p>
              </div>
            </div>
          </div>
          <div className="row pd-top-50">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="single-blog mb-0">
                <div className="row">
                  <div className="blog-thumbnail col-lg-5">
                    <div className="thumb">
                      <img src={awsS3url + blog.thumbnail} alt="blog" />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="blog-description-box">
                      <div className="single-blog-details">
                        <h3 className="title">"{blog.description}"</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pd-top-50 blog-content">
                  {ReactHtmlParser(blog.content)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Blog.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(Blog);


import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import 'date-fns';
import { Grid, Switch, Slider, Button, Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import tz_country from '../../utilities/timezoneCountryNames';

const GET_SAVE_SCHEDULE_TRIP_AMBASSADOR = loader('../../graphql/schema/walker/save-schedule-trip-ambassador.graphql');

const ScheduleTripAmbassador = (props) => {

  const { history, onCloseScheduleTrip, scheduleTrip } = props;

  const epoch = moment.utc(moment.unix(scheduleTrip.epoch), 'DD-MM-YYYY HH:mm:ss');

  const [showOtherScheduleTrip, setShowOtherScheduleTrip] = useState(false);
  const [inputStreamCode, setInputStreamCode] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const [saveProScheduleTrip, { loading: scheduleTripLoading }] = useMutation(GET_SAVE_SCHEDULE_TRIP_AMBASSADOR, {
    onCompleted({
      saveProScheduleTrip: {
        status,
        message,
      }
    }) {
      setShowOtherScheduleTrip(true);
      if (status === 'SUCCESS') {
        setTitle('Successfully Booked');
        setMessage(message);
        setStatus(status);

        props.updateHeader();
      } else if (status === 'FAIL') {
        setTitle('Booking Failed');
        setMessage(message);
        setStatus(status);
      } else {
        setTitle('Booking Failed');
        setMessage(message);
        setStatus(status);
      }
    }
  });

  const requestScheduleTrip = () => {
    saveProScheduleTrip({
      variables: {
        schedule_trip_id: scheduleTrip.id,
        stream_code: inputStreamCode
      }
    })
  }

  const validateBooking = () => {
    if (status === 'SUCCESS') {
      setShowOtherScheduleTrip(false);
      onCloseScheduleTrip(false);
    } else {
      setShowOtherScheduleTrip(false);
    }
  }

  const getTimezone = (tz) => {
    const data = tz_country.find(o => o.group.some(i => i === tz));

    if(data) {
      return data.alternativeName;
    } else {
      return tz;
    }
  }

  return (
    <>
      {scheduleTripLoading && <PageLoader loading={scheduleTripLoading} />}
      <div style={{ width: '100%', height: '100%' }}>
        <div className="modal show fade destination-body-popup" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.3)'}}>
          <div style={{ height: '100%', width: '100%', padding: 20 }}>
            <div className="modal-dialog modal-dialog-centered h-100">
              <div className="modal-content payment-popup-body h-100" style={{ maxWidth: 600 }}>
                <button type="button" className="close" onClick={onCloseScheduleTrip}><i className="la la-times"></i></button>
                <div style={Styles.container}>
                  <div className="d-flex flex-column align-items-center justify-content-center" style={Styles.title}>
                    <h3 className="text-white">SCHEDULE</h3>
                    <p className="text-white">a virtual trip to</p>
                    <h4 className="text-white">{scheduleTrip.location}</h4>
                  </div>
                  <div className="schedule-pick-row">
                    <div className="text-center">
                      <h3>DESTINATION TIMEZONE</h3>
                      <h3>{scheduleTrip.locationName}</h3>
                      {scheduleTrip.station &&
                        <p>{scheduleTrip.station}</p>
                      }
                    </div>
                    <div className="schedule-pick-cnt">
                      <h4>{epoch.clone().tz(scheduleTrip.destinationTimeZone).format('dddd').toUpperCase()}</h4>
                      <p>{epoch.clone().tz(scheduleTrip.destinationTimeZone).format('MMM DD, YYYY')}</p>
                    </div>
                    <div className="text-center mt-3">
                      <h2 className="mb-0 text-muted" style={{ fontWeight: 600 }}>{epoch.clone().tz(scheduleTrip.destinationTimeZone).format('hh:mm A')}</h2>
                      <p className="mt-0">{ getTimezone(scheduleTrip.destinationTimeZone) }</p>
                      <p className="my-2">FOR</p>
                      <h3>{moment.duration(scheduleTrip.scheduleMinute, 'minute').humanize({ m: 1440 })}</h3>
                    </div>
                  </div>
                  <div className="schedule-pick-row">
                    <div className="text-center">
                      <h3>YOUR TIMEZONE</h3>
                    </div>
                    <div className="schedule-pick-cnt pt-2">
                      <h4>{epoch.clone().tz(moment.tz.guess()).format('dddd').toUpperCase()}</h4>
                      <p>{epoch.clone().tz(moment.tz.guess()).format('MMM DD, YYYY')}</p>
                    </div>
                    <div className="text-center mt-3">
                      <h2 className="mb-0" style={{ fontWeight: 600 }}>{epoch.clone().tz(moment.tz.guess()).format('hh:mm A')}</h2>
                      <p className="mt-0">{ getTimezone(moment.tz.guess())}</p>
                    </div>
                  </div>
                  <div className="schedule-pick-row" style={{ marginBottom: scheduleTrip.streamCode !== '' ? 120 : 80, paddingBottom: 30 }}>
                    <h4 className="package-title">Important</h4>
                    <div className="schedulePickRowList text-center">
                      <p style={Styles.p}>You will be notified 5 minutes before the<br/>start the tour</p>
                      <br/>
                      <p style={Styles.p}>Scheduled trips status can be checked to<br/><a href='#upcoming' onClick={() => history.push('/your-trips', { upcoming_trip: true })}>Your Trips -> Upcoming</a></p>
                    </div>
                  </div>
                </div>
                <div className="payment-popup-btn">
                  <div className="form-btn m-0 d-flex flex-column align-items-center justify-content-center">
                    {scheduleTrip.streamCode !== '' &&
                      <div className="form-input-box">
                        <input
                            className="text-center mb-1"
                            style={{
                              width: 200,
                              backgroundColor: '#eee',
                              color: '#000',
                              borderColor: '#ccc',
                            }}
                            type="text"
                            name="password"
                            placeholder="Stream Code"
                            value={inputStreamCode}
                            onChange={({ target }) => setInputStreamCode(target.value)}
                            autoComplete="off"
                            autoFocus={true}
                        />
                      </div>
                    }
                    <button
                      style={{
                        maxWidth: 200,
                        padding: 0,
                        height: 42,
                        lineHeight: 42,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      type="submit"
                      className="btn btn-yellow"
                      onClick={requestScheduleTrip}
                      disabled={inputStreamCode != scheduleTrip.streamCode}
                    >
                        Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog aria-labelledby="customized-dialog-title" open={showOtherScheduleTrip}>
        <DialogContent>
            <div className="schedule-popup-div">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="select-popup-btn">
                    <button className="btn btn-yellow" onClick={validateBooking}>Okay</button>
                </div>
            </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const Styles = {
  container: {
    overflow: 'auto',
    backgroundColor: '#f2f2f2',
    borderRadius: 5
  },
  title: {
    backgroundColor: '#071C55',
    borderRadius: '5px 5px 0px 0px',
    paddingBottom: 20,
    paddingTop: 20,
  },
  blue: {
    color: '#01B9B7',
  },
  gray: {
    color: '#777',
  },
  p: {
    hyphens: 'none',
  }
};

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#01B9B7',
    '&$checked': {
      color: '#01B9B7',
    },
    '&$checked + $track': {
      backgroundColor: '#01B9B7',
    },
  },
  checked: {},
  track: {},
})(Switch);

ScheduleTripAmbassador.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(ScheduleTripAmbassador);

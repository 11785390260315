import { confirmAlert } from 'react-confirm-alert';

const errorHandler = ( {
  graphQLErrors, networkError,
} ) => {
  const location = window.location.href;
  if ( graphQLErrors ) {
    graphQLErrors.forEach( ( err ) => {
      console.error(err)
      switch ( err.extensions.category ) {
        case 'authentication':
          if ( err.message !== 'The user credentials were incorrect.' && 
            err.path[0] !== 'acceptInvitation' && 
            !location.includes('update-forgot-password') && 
            !location.includes('destination-categories') && 
            !location.includes('login') && 
            !location.includes('register') && 
            !location.includes('event-registration')
          ) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="react-confirm-alert-body text-center p-5" style={{ maxWidth: 340, borderRadius: 5 }}>
                    <h1 className="pb-3">Session Expired</h1>
                    <p style={{ hyphens: 'none' }}>You have been logged out, please login again.</p>
                    <button className="btn btn-yellow mt-3" style={{ minWidth: 150 }} onClick={onClose}>
                      Okay
                    </button>
                  </div>
                );
              },
              overlayClassName: 'header-overlay',
              afterClose: () => {
                localStorage.clear();
                window.location.href = '/login';
              }
            });
          }
          break;
        default:
          console.log( '[GraphQL error]', err ); 
          // confirmAlert({
          //   customUI: ({ onClose }) => {
          //     return (
          //       <div className="react-confirm-alert-body">
          //         <h1>Alert</h1>
          //         Something went wrong. Please try again later.
          //         <div className="react-confirm-alert-button-group">
          //           <button onClick={onClose}>
          //             Okay
          //           </button>
          //         </div>
          //       </div>
          //     );
          //   },
          //   overlayClassName: 'header-overlay',
          //   afterClose: () => {
          //     localStorage.clear();
          //     window.location.href = '/';
          //   }
          // });
      }
    } );
  }
  if ( networkError ) {
    console.log( '[Network error]', networkError ); 
  }
  return null;
};

export default errorHandler;

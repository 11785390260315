import React, { createRef, useState } from 'react';
import { Form, Field } from 'react-final-form';
// import { TextField } from 'final-form-material-ui';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import ReactFlagsSelect from 'react-flags-select';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { FormControl, Select } from '@material-ui/core';
import { MenuItem, Input, Chip, FormHelperText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import jsonCountries from "../payments/countries.json";
import SubHeader from '../../components/layout/sub-header';

const UPDATE_WALKER_PROFILE = loader('../../graphql/schema/walker/update-walker-profile.graphql');
const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));
const equipments = {
    '1': 'MobilePhone',
    '2': 'Bicycle',
    '3': 'MotorScoter',
    '4': 'Drone',
    '5': 'Stabilizer',
    '6': 'Go-PRO'
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(item, itemName, theme) {
    return {
        fontWeight:
            itemName.indexOf(item) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const WalkerProfile = (props) => {
    const { history } = props;
    const classes = useStyles();
    const equipmentSelect = createRef();
    const theme = useTheme();
    const [hasError, setHasError] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [destinations, setDestinations] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [details, setDetails] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const handleDestinations = (values) => {
        setDestinations([values.destination_one, values.destination_two, values.destination_three]);
    }

    const handleChangeEquipments = (e) => {
        setEquipment(e.target.value);
    }

    const { data: myDetails, loading } = useQuery(MY_DETAILS, {
        fetchPolicy: "no-cache",
        onCompleted() {
            console.log('MY_DETAILS', myDetails);

            setCountry(myDetails);
            setEquipment(myDetails.me.walker_profile && myDetails.me.walker_profile.equipments ? myDetails.me.walker_profile.equipments : []);

            let dest_one = '';
            let dest_two = '';
            let dest_three = '';

            if (myDetails.me.walker_profile && myDetails.me.walker_profile.top_three_destinations) {
                dest_one = myDetails.me.walker_profile.top_three_destinations[0];
                dest_two = myDetails.me.walker_profile.top_three_destinations[1];
                dest_three = myDetails.me.walker_profile.top_three_destinations[2];
            }

            setDetails({
                full_name: `${myDetails && myDetails.me.first_name}` + ' ' + `${myDetails && myDetails.me.last_name}`,
                city_or_town: myDetails.me.walker_profile && myDetails.me.walker_profile.city_or_town ? myDetails.me.walker_profile.city_or_town : '',
                state_or_province: myDetails.me.walker_profile && myDetails.me.walker_profile.state_or_province ? myDetails.me.walker_profile.state_or_province : '',
                noOfWalks: myDetails.me.walker_profile && myDetails.me.walker_profile.no_of_walks ? myDetails.me.walker_profile.no_of_walks : 0,
                countryCode: myDetails.me.walker_profile && myDetails.me.walker_profile.country ? myDetails.me.walker_profile.country : '',
                destination_one: dest_one,
                destination_two: dest_two,
                destination_three: dest_three,
                languages: myDetails.me.walker_profile && myDetails.me.walker_profile.languages ? myDetails.me.walker_profile.languages : '',
                equipments: myDetails.me.walker_profile && myDetails.me.walker_profile.equipments ? myDetails.me.walker_profile.equipments : '',
                description: myDetails.me.walker_profile && myDetails.me.walker_profile.description ? myDetails.me.walker_profile.description : '',
            })
        },
    });

    const setCountry = (myDetails) => {
        if (myDetails.me.walker_profile && myDetails.me.walker_profile.country) {
            if (myDetails.me.walker_profile.country.length != 2) {
                for (var key of Object.keys(jsonCountries)) {
                    if (jsonCountries[key].name.common == myDetails.me.walker_profile.country) {
                        setCountryCode(key);
                        return;
                    }
                }
            } else {
                setCountryCode(myDetails.me.walker_profile.country);
            }
        }
    }

    const [updateMyAccount] = useMutation(UPDATE_WALKER_PROFILE, {
        onCompleted() {
            history.push('/');
        },
        onError(errors) {
            const formatedErrors = errors.graphQLErrors.map(({ message }) => (
                <span key={`signup-error-${Math.random()}`}>{message}</span>
            ));
            setHasError(formatedErrors);
        },
    });

    const validate = (values) => {

        const errors = {};

        if (!values.full_name) {
            errors.full_name = 'Please Enter Name';
        }

        if (!values.city_or_town) {
            errors.city_or_town = 'Please Enter City / Town';
        }

        if (!values.state_or_province) {
            errors.state_or_province = 'Please Enter State / Province';
        }

        if (!countryCode) {
            errors.country = 'Please Select Country';
        }

        if (!values.destination_one) {
            errors.destination_one = 'Please Enter First Destination';
        }
        if (!values.destination_two) {
            errors.destination_two = 'Please Enter Second Destination';
        }

        if (!values.destination_three) {
            errors.destination_three = 'Please Enter Third Destination';
        }
        if (!values.languages) {
            errors.languages = 'Please Enter Language | Speak';
        }
        if (equipment.length == 0) {
            errors.equipment = 'Please Select Equipment I Have';
        }

        if (!values.description) {
            errors.description = 'Please Enter Walker Description';
        }
        return errors
    }

    const onSubmit = (values) => {
        updateMyAccount({
            variables: {
                data: {
                    city_or_town: values.city_or_town,
                    state_or_province: values.state_or_province,
                    country: countryCode,
                    top_three_destinations: [values.destination_one, values.destination_two, values.destination_three],
                    languages: values.languages,
                    equipments: equipment,
                    description: values.description,
                    is_ambassador: false
                },
            },
        });
    }

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-pagev2">
                <div className="traveller-row">
                    <SubHeader title="My Walker Profile" />
                    <div className="container">
                        <div className="travelle-body">
                            <div className="edit-form-row-v2 edit-form-row-new">
                                {hasError && <p className="is-invalid">{hasError}</p>}
                                <Form
                                    onSubmit={onSubmit}
                                    validate={validate}
                                    initialValues={{
                                        full_name: details.full_name,
                                        city_or_town: details.city_or_town,
                                        state_or_province: details.state_or_province,
                                        no_of_walks: details.noOfWalks,
                                        country: details.countryCode,
                                        destination_one: details.destination_one,
                                        destination_two: details.destination_two,
                                        destination_three: details.destination_three,
                                        languages: details.languages,
                                        equipments: details.equipments,
                                        description: details.description
                                    }}
                                    render={({ handleSubmit, submitting }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            noValidate
                                        >
                                            <div className="form-in">
                                                <label> Name <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="full_name"
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    name="full_name"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>City / Town <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="city_or_town"
                                                    placeholder="Enter City / Town"
                                                    type="type"
                                                    name="city_or_town"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>State / Province <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="state_or_province"
                                                    placeholder="Enter State / Province"
                                                    type="type"
                                                    name="state_or_province"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>Country <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="country"
                                                    name="country"
                                                >
                                                    {({ input }) => (
                                                        <div>
                                                            <ReactFlagsSelect
                                                                {...input}
                                                                searchPlaceholder=""
                                                                searchable
                                                                selected={countryCode}
                                                                onSelect={code => setCountryCode(code)}
                                                                className={(submitted && countryCode == '') ? 'has-error' : ''}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <FormHelperText error hidden={!(submitted && countryCode == '')}>Please Select Country</FormHelperText>
                                            </div>

                                            <p className="nearest-desti-header">Top 3 Nearest Huvr Destinations</p>

                                            <div className="form-in">
                                                <label>First Destination <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="destination_1"
                                                    placeholder="Enter First Destination"
                                                    type="type"
                                                    name="destination_one"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                    value={destinations}
                                                    onChange={handleDestinations}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>Second Destination <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="destination_2"
                                                    placeholder="Enter Second Destination"
                                                    type="type"
                                                    name="destination_two"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                    value={destinations}
                                                    onChange={handleDestinations}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>Third Destination <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="destination_three"
                                                    placeholder="Enter Third Destination"
                                                    type="type"
                                                    name="destination_three"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                    value={destinations}
                                                    onChange={handleDestinations}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>Number of Walks</label>
                                                <Field
                                                    id="no_of_walks"
                                                    placeholder=""
                                                    type="type"
                                                    name="no_of_walks"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>Language I Speak <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="languages"
                                                    placeholder=""
                                                    type="type"
                                                    name="languages"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className="form-in">
                                                <label>Equipment I Have <span className="is-invalid">*</span></label>
                                                <FormControl ref={equipmentSelect} variant="outlined" className={`equipment-selection ${(submitted && equipment.length == 0) ? 'has-error' : 'no-error'}`}>
                                                    <Select
                                                        multiple
                                                        value={equipment}
                                                        name="equipment"
                                                        onChange={handleChangeEquipments}
                                                        style={{ height: 40, minHeight: 40 }}
                                                        input={<Input id="select-multiple-chip" />}
                                                        renderValue={(selected) => (
                                                            <div className={classes.chips}>
                                                                {selected.map((item) => (
                                                                    <Chip key={item} label={equipments[item]} className={classes.chip} />
                                                                ))}
                                                            </div>
                                                        )}
                                                        MenuProps={MenuProps}
                                                        error
                                                    >
                                                        {Object.keys(equipments).map((item) => (
                                                            <MenuItem key={item} value={item} style={getStyles(equipments[item], equipment, theme)}>
                                                                {equipments[item]}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText error hidden={!(submitted && equipment.length == 0)}>Please Select Equipment I Have</FormHelperText>
                                            </div>

                                            <div className="form-in form-textarea">
                                                <label>Walker Description <span className="is-invalid">*</span></label>
                                                <Field
                                                    id="description"
                                                    placeholder="Walker Description"
                                                    type="type"
                                                    name="description"
                                                    multiline
                                                    rows={7}
                                                    variant="outlined"
                                                    component={TextField}
                                                />
                                            </div>

                                            <div className="form-btn">
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={submitting}
                                                    onClick={() => setSubmitted(true)}
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                            <div className="form-btn">
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
WalkerProfile.propTypes = {
    history: PropTypes.object.isRequired
};

const enhance = compose(
    withRouter,
);

export default enhance(WalkerProfile);

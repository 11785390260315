import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import PageLoader from './../../components/layout/ui/page-loader';
import { TodayOutlined } from '@material-ui/icons';
import DownloadLink from './../../components/layout/download-link';
import userUtils from '../../utilities/UserUtils';
import SubHeader from '../../components/layout/sub-header';
import { Typography } from '@material-ui/core';

const COMPLETED_TRIPS = loader('./../../graphql/schema/auth/completed-trips.graphql');

const CompletedTrips = (props) => {

  const [completedTrips, setCompletedTrips] = useState([]);
  const [haserror, setHaserror] = useState('');
  const [count, setCount] = useState(20);

  const paginateCompletedTrips = useCallback(
    (array, pageSize, pageNumber = 1) => {
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
    [completedTrips, count],
  );

  const { data, loading, refetch } = useQuery(COMPLETED_TRIPS, {
    variables: { is_ambassador: userUtils.getUserType() == 'A' },
    fetchPolicy: 'no-cache',
    onCompleted({ completedInstantTrip }) {
      setCompletedTrips(completedInstantTrip);
    }
  });

  useEffect(() => {
    window.removeEventListener('scroll', scrollListener);
    if (completedTrips && completedTrips.length > 0 && data.completedInstantTrip.length > count) {
      window.addEventListener('scroll', scrollListener);
    }
  }, [completedTrips, count]);

  const scrollListener = () => {
    if (
      window.pageYOffset > (document.querySelector('.footer-area').offsetTop - window.innerHeight)
    ) {
      setCount(count + 20);
    }
  }

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Completed Trips" />

          {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
          <div className="container">
            <div className="destination-list-body">
              <div className="text-center rating-row">
                <Typography>Destination Categories / <a href="#" style={{ color: "#FDA73D" }}>Completed Trips</a></Typography>
              </div>
            </div>
            {(loading || paginateCompletedTrips(completedTrips, count, 1).length <= 0) &&
              <div className="d-flex align-items-center justify-content-center" style={{ height: '435px' }}>
                {loading && <p className="text-muted">Loading Completed Trips...</p>}
                {!loading && <p className="text-muted">No Completed Trips.</p>}
              </div>
            }
            <div className="row completed-trip-row">
              {(!loading && paginateCompletedTrips(completedTrips, count, 1).length > 0) && paginateCompletedTrips(completedTrips, count, 1).map((value, index) => {
                return (
                  <div className="col-sm-12 col-lg-6 col-xl-4" key={index} dataLabel={index}>
                    <div className="py-3">
                      {/* background-color: #FFFFFF;
                      border-radius: 5px;
                      height: 175px;
                      box-shadow: 0px 0px 20px 0px #00000026; */}
                      <div className="p-4 d-flex" style={{ backgroundColor: '#FFFFFF', borderRadius: "15px", height: "178px", boxShadow: "0px 0px 20px 0px #00000026" }}>
                        <div className="mr-4 d-flex align-items-center" style={{ width: 100, minWidth: 100 }}>
                          <img src={value.destination.image} style={{ width: '78px', height: '78px', objectFit: 'cover', borderRadius: '50%', border: '3px solid #fff' }} />
                        </div>
                        <div className="d-flex justify-content-center flex-column">
                          <p className="walk-request-place" style={{ color: "#00467F" }}>{value.destination.name}</p>
                          <p className="walk-request-text" style={{ color: '#6F6F6F' }} > <TodayOutlined style={{ color: '#049DC5' }} />{moment(value.created_at).format('ddd, MMM Do, hh:mm A')}</p>
                          {/* <p className="walk-request-name" style={{ color: '#c72c2c' }}>{value.traveller_details.first_name} {value.traveller_details.last_name.charAt(0)}.</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompletedTrips;

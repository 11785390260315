import React from 'react';
import { Link } from 'react-router-dom';

const Intro = () => {

  return (
    <>
      <div className="intro-area pd-top-112">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="single-intro wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
                <h4 className="intro-title">
                  <span className="intro-count">01</span>
                  <Link className="intro-cat" to="/">Select a Destination</Link>
                </h4>
                <p>Simply login to the Huvr App and select any of the Pre-Loaded Destinations</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-intro wow animated fadeInUp" data-wow-duration="1.0s" data-wow-delay="0.2s">
                <h4 className="intro-title">
                  <span className="intro-count">02</span>
                  <Link className="intro-cat" to="/">Select a Walker</Link>
                </h4>
                <p>Once you are in Live Huvr Map, simply select the Huvr Walker of your choice</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-intro wow animated fadeInUp" data-wow-duration="1.6s" data-wow-delay="0.3s">
                <h4 className="intro-title">
                  <span className="intro-count">03</span>
                  <Link className="intro-cat" to="/">Go Live and Explore</Link>
                </h4>
                <p>Finally, select a time package and you can instantly <span className="text-big">Go-Live</span>, or you can schedule a trip to suit your calendar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Intro;

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { TextField } from 'final-form-material-ui';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PhoneInput from 'react-phone-number-input';
import { Button, Dialog, InputAdornment} from '@material-ui/core';
import { loader } from 'graphql.macro';
import * as commonFunctions from '../../../utilities/commonFunctions';
import DownloadLink from '../../../components/layout/download-link';
import UserUtils from '../../../utilities/UserUtils';
import SubHeader from '../../layout/sub-header';

const MY_DETAILS = loader('./../../../graphql/schema/auth/me.graphql');
const EDIT_PROFILE = loader('./../../../graphql/schema/auth/edit-profile.graphql');
const VERIFY_EMAIL = loader('./../../../graphql/schema/register/verify-email.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const PhoneAdapter = ({ input }) => (
  <PhoneInput {...input} />
);

PhoneAdapter.propTypes = {
  input: PropTypes.object.isRequired,
};

const EditAccount = (props) => {

  const { history } = props;

  const [hasError, setHasError] = useState('');
  const [verifyEmailPopup, setVerifyEmailPopup] = useState(false);
  //const [fileName, setFileName] = useState( null );
  const [imgData, setImgData] = useState( null );

  const { data: myDetails, refetch } = useQuery(MY_DETAILS, {
    notifyOnNetworkStatusChange: true,
    onCompleted(){
      setProfilePic();
    }
  });

  const [updateMyAccount, { loading: submitting }] = useMutation(EDIT_PROFILE, {
    onCompleted({ editProfile }) {
      UserUtils.setUserAvatar(editProfile.avatar);
      UserUtils.setUserFirstName(editProfile.first_name);
      UserUtils.setUserLastName(editProfile.last_name);
      if (UserUtils.getUserType() == 'T') {
        history.push('/destination-categories');
      } else {
        history.push('/');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setHasError(formatedErrors);
    },
  });

  const [updateVerifyEmail] = useMutation(VERIFY_EMAIL, {
    onCompleted() {
      setVerifyEmailPopup(true);
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setHasError(formatedErrors);
    },
  });

  useEffect(() => {
    refetch();
    if ( myDetails ) {
      setProfilePic();
    } else {
      setImgData( publicUrl + "assets/images/hvr-logo.png" );
    }
	}, [myDetails]);

  const setProfilePic = () => {
    if ( myDetails.me.avatar ) {
      setImgData(  myDetails.me.avatar );
    } else {
      setImgData( publicUrl + "assets/images/hvr-logo.png" );
    }
  }

  const validate = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = 'First Name is required.';
    }

    if (!values.last_name) {
      errors.last_name = 'Last Name is required.';
    }

    if (!values.phone_number) {
      errors.phone_number = 'Please Enter valid Phone Number.';
    }

    if (!values.email) {
      errors.email = 'Email is required.';
    } else if (!commonFunctions.validateEmail(values.email)) {
      errors.email = 'Enter valid a email.';
    }

    return errors;
  };

  const onSubmitAcountDetails = (values) => {
    var profileImg = imgData != '/assets/images/hvr-logo.png' ? imgData : null;
    updateMyAccount({
      variables: {
        data: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone_number: values.phone_number,
          profile_image: profileImg,
        },
      },
    });
  };

  const handleVerifyEmail = () => {
    if (myDetails && !myDetails.me.isEmailVerified) {
      updateVerifyEmail();
    }
  };

  const handleVerifyEmailClose = () => {
    setVerifyEmailPopup(false);
  }

  const redirectToChangePassword = () => {
    window.scrollTo(0,0);
    history.push('/change-password');
  }

  const updateProfileImage = ( e ) => {
    if ( e.target.files[0] ) {
      const reader = new FileReader();
      reader.addEventListener( 'load', () => {
        setImgData( reader.result );
      } );
      reader.readAsDataURL( e.target.files[0] );
    }
  };

  return (
    <>
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Edit Account" />
          <div className="container">
            <div className="travelle-body">
              <div className="profile-row profile_max_box">
                <div className="profile-left profile_new_edit">
                  <figure>
                    <img src={ imgData } alt="" />
                   <span className="profile_edit_span">
                    <input type="file" name="profile" onChange={ updateProfileImage } id="profile" />
                    <span><i className="fa fa-pencil-square-o " /></span>
                  </span>
                  </figure>
                  <div className="profile-title">
                    <p>
                      {myDetails && myDetails.me.first_name}
                      {' '}
                      {myDetails && myDetails.me.last_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="edit-form-row edit-form-row-new">
                {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
                <Form
                  onSubmit={onSubmitAcountDetails}
                  validate={validate}
                  initialValues={{
                    first_name: myDetails && myDetails.me.first_name,
                    last_name: myDetails && myDetails.me.last_name,
                    email: myDetails && myDetails.me.email,
                    phone_number: myDetails && myDetails.me.phone_number,
                    password: '01234567890',
                  }}
                  render={({ handleSubmit }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className="form-in">
                        <span>First Name</span>
                        <Field
                          id="first_name"
                          placeholder="ex. Mike"
                          type="text"
                          name="first_name"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                          defaultValue={myDetails && myDetails.me.first_name}
                        />
                      </div>
                      <div className="form-in">
                        <span>Last Name</span>
                        <Field
                          id="last_name"
                          placeholder="ex. test"
                          type="text"
                          name="last_name"
                          margin="normal"
                          variant="outlined"
                          component={TextField}
                          defaultValue={myDetails && myDetails.me.last_name}
                        />
                      </div>

                      <div className="form-in">
                        <span>Phone number</span>
                        <Field
                          id="phone_number"
                          placeholder="Enter phone number"
                          type="text"
                          name="phone_number"
                          margin="normal"
                          disabled
                          variant="outlined"
                          component={PhoneAdapter}
                          defaultValue={myDetails && myDetails.me.phone_number}
                        />
                      </div>

                      <div className="form-in">
                        <span>Email</span>
                        <Field
                          id="email"
                          placeholder="ex. mike@gmail.com"
                          type="text"
                          name="email"
                          margin="normal"
                          variant="outlined"
                          // component={TextField}
                          defaultValue={myDetails && myDetails.me.email}
                          style={{ backgroundColor: '#fff' }}
                          disabled={myDetails && myDetails.me.isEmailVerified}
                        >
                          {props => (
                            <TextField
                              {...props}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={handleVerifyEmail}>
                                    {myDetails && myDetails.me.isEmailVerified ? <span className="form-verify text-success">Verified</span> : <span className="form-verify" onClick={handleVerifyEmail}>Verify</span>}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="form-in">
                        <label>Password</label>
                        <Field
                          type="password"
                          name="password"
                          margin="normal"
                          variant="outlined"
                          defaultValue="01234567890"
                          style={{ backgroundColor: '#fff' }}
                          disabled={true}
                        >
                          {props => (
                            <TextField
                              {...props}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={redirectToChangePassword}>
                                    <span className="form-verify">Change password</span>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className="form-btn">
                        <Button type="submit" disabled={submitting}>Submit</Button>
                      </div>
                    </form>
                  )}
                />

              </div>
            </div>
          </div>
        </div>
        <Dialog className="popup-verification" onClose={handleVerifyEmailClose} aria-labelledby="simple-dialog-title" open={verifyEmailPopup}>
          <div>
            <p>Verification Link sent to your registered email address</p>
            <Button className="btn btn-yellow confirm-btn" onClick={handleVerifyEmailClose}>OK</Button>
          </div>
        </Dialog>
      </div>
    </>
  )
}

EditAccount.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(EditAccount);

const ACCESS_TOKEN = "huvr::access_token";
const USER_ID = "huvr::user_id";
const PHONE_NUMBER = "huvr::phone_number";
const CARD_ID = "huvr::card_id";
const DESTINATIONS_DATA = "huvr::destination_data";
const COUNTRY_KEY = "huvr::country_key";
const USER_AVATAR = "huvr::user_avatar";
const FIRST_NAME = "huvr::first_name";
const LAST_NAME = "huvr::last_name";
const USER_RATING = "huvr::user_rating";
const USER_COUNTRY_KEY = "huvr::user_country_key";
const AMBASSADOR_ID = "huvr::ambassador_id";
const USER_TYPE = "huvr::user_type";
const USER_EMAIL = "huvr::email";
const CLOSED_ADS = "huvr::closed_ads";
const UPCOMING_TRIPS = "huvr::upcoming_trips";
const PAGE_INDEX = "huvr::page_index";

const userUtils = {
  /* Autherization */
  setAccessToken(access_token) {
    localStorage.setItem(ACCESS_TOKEN, access_token);
  },
  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },
  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  },
  //SET USER ID
  setUserID(user_id) {
    localStorage.setItem(USER_ID, user_id);
  },
  getUserID() {
    return localStorage.getItem(USER_ID);
  },
  removeUserID() {
    localStorage.removeItem(USER_ID);
  },
  // PHONE NUMBER
  setPhoneNumber(phone_number) {
    localStorage.setItem(PHONE_NUMBER, phone_number);
  },
  getPhoneNumber() {
    return localStorage.getItem(PHONE_NUMBER);
  },
  removePhoneNumber() {
    localStorage.removeItem(PHONE_NUMBER);
  },
  setCountryKey(country_key) {
    localStorage.setItem(COUNTRY_KEY, country_key);
  },
  getCountryKey() {
    return localStorage.getItem(COUNTRY_KEY);
  },
  removeCountryKey() {
    localStorage.removeItem(COUNTRY_KEY);
  },
  //  CARD ID TO UPDATE
  setCardId(card_id) {
    localStorage.setItem(CARD_ID, card_id);
  },
  getCardId() {
    return localStorage.getItem(CARD_ID);
  },
  removeCardId() {
    localStorage.removeItem(CARD_ID);
  },
  //DESTINATION DATA
  setDestinationData(destination_data) {
    localStorage.setItem(DESTINATIONS_DATA, destination_data);
  },
  getDestinationData() {
    return localStorage.getItem(DESTINATIONS_DATA);
  },
  removeDestinationData() {
    localStorage.removeItem(DESTINATIONS_DATA);
  },
  setUserAvatar(user_avatar) {
    localStorage.setItem(USER_AVATAR, user_avatar);
  },
  getUserAvatar() {
    return localStorage.getItem(USER_AVATAR);
  },
  setUserEmail(email) {
    localStorage.setItem(USER_EMAIL, email);
  },
  getUserEmail() {
    return localStorage.getItem(USER_EMAIL);
  },
  setUserFirstName(first_name) {
    localStorage.setItem(FIRST_NAME, first_name);
  },
  getUserFirstName() {
    return localStorage.getItem(FIRST_NAME);
  },
  setUserLastName(last_name) {
    localStorage.setItem(LAST_NAME, last_name);
  },
  getUserLastName() {
    return localStorage.getItem(LAST_NAME);
  },
  setUserRating(user_rating) {
    localStorage.setItem(USER_RATING, user_rating);
  },
  getUserRating() {
    return localStorage.getItem(USER_RATING);
  },
  setUserPhoneCountryKey(country_key) {
    localStorage.setItem(USER_COUNTRY_KEY, country_key);
  },
  getUserPhoneCountryKey() {
    return localStorage.getItem(USER_COUNTRY_KEY);
  },
  setAmbassadorId(ambassador_id) {
    localStorage.setItem(AMBASSADOR_ID, ambassador_id);
  },
  getAmbassadorId() {
    return localStorage.getItem(AMBASSADOR_ID);
  },
  removeAmbassadorId() {
    localStorage.removeItem(AMBASSADOR_ID);
  },
  setUserType(userType) {
    localStorage.setItem(USER_TYPE, userType);
  },
  getUserType() {
    return localStorage.getItem(USER_TYPE);
  },
  setClosedAds(ads = []) {
    localStorage.setItem(CLOSED_ADS, ads);
  },
  getClosedAds() {
    return localStorage.getItem(CLOSED_ADS);
  },
  setUpcomingTrips(trips) {
    return localStorage.setItem(UPCOMING_TRIPS, trips);
  },
  getUpcomingTrips() {
    return localStorage.getItem(UPCOMING_TRIPS);
  },
  setPageIndex(index) {
    return localStorage.setItem(PAGE_INDEX, index);
  },
  getPageIndex() {
    return localStorage.getItem(PAGE_INDEX);
  },

  /* Logout */
  logout() {
    //localStorage.clear();
    /* Autherization */
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(PHONE_NUMBER);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(CARD_ID);
    localStorage.removeItem(DESTINATIONS_DATA);
    localStorage.removeItem(PAGE_INDEX);
  }
}
export default userUtils;

import { useState, useEffect, useRef, memo } from 'react';
import { useApolloClient, useMutation} from '@apollo/react-hooks';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import userUtils from './UserUtils';
import { loader } from 'graphql.macro';
import UserUtils from './UserUtils';
import DOMPurify from "dompurify";

const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 150,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    closeIcon: {
        right: 0,
        top: -4,
        position: 'absolute',
        zIndex: 9
    },
    modal: {
        height: '100%', 
        width: '100%', 
        padding: 0,
        overflow: 'hidden'
    },
    box : { 
        position: 'relative', 
        height: '100%', 
        width: '100%', 
        zIndex: 8 
    },
    iframe: {
        margin: 0
    }
}))

const AdPopup = (props) => {

	const { history } = props;
    const classes = useStyles();
	const apolloClient = useApolloClient();

    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState(null);

	useEffect(() => {
        console.log('props.content ==> ', props.content.ad_content);
        let closedAds = UserUtils.getClosedAds();
        closedAds = closedAds ? JSON.parse(closedAds) : [];

        if(closedAds && closedAds.includes(`${props.type}_popup`)){ return; }

        if(props.content.ad_content) {
            setContent(DOMPurify.sanitize(props.content.ad_content.replace('></a>', '/></a>')).replace('<a', '<a target="_blank"'))
        }

        setShowModal(true);
	}, [props.content]);

    const onClose = () => {
        let closedAds = UserUtils.getClosedAds();
        closedAds = closedAds ? JSON.parse(closedAds) : [];
        
        closedAds.push(`${props.type}_popup`);
        UserUtils.setClosedAds(JSON.stringify(closedAds));

        setShowModal(false);
    }

    return (
        <>
            <Dialog
                open={showModal}
                disableEscapeKeyDown={true}
            >
                    <IconButton className={classes.closeIcon} onClick={onClose}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="white"/>
                            <path d="M21.3411 12.2619C21.7844 11.8186 21.7844 11.0986 21.3411 10.6553C20.8977 10.2119 20.1777 10.2119 19.7344 10.6553L15.9997 14.3935L12.2614 10.6588C11.8181 10.2155 11.0981 10.2155 10.6548 10.6588C10.2114 11.1021 10.2114 11.8221 10.6548 12.2655L14.393 16.0002L10.6583 19.7384C10.215 20.1818 10.215 20.9017 10.6583 21.3451C11.1017 21.7884 11.8216 21.7884 12.265 21.3451L15.9997 17.6068L19.7379 21.3415C20.1813 21.7849 20.9013 21.7849 21.3446 21.3415C21.7879 20.8982 21.7879 20.1782 21.3446 19.7349L17.6064 16.0002L21.3411 12.2619Z" fill="#044DD0"/>
                        </svg>
                    </IconButton>
                <DialogContent className={classes.modal}>
                    <div className={classes.box} dangerouslySetInnerHTML={{ __html: content }} ></div>
                </DialogContent>
            </Dialog>
        </>
    );
}

AdPopup.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const enhance = compose(
	withRouter,
);

export default enhance(AdPopup);
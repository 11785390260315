import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import UserUtils from '../../utilities/UserUtils';
import UpdateCheckoutForm from './update-checkout-form';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';
import { Box } from '@material-ui/core'; 

const UpdateCard = () => {

  const [cardData, setCardData] = useState('');

  useEffect(() => {
    const cardDetails = JSON.parse(UserUtils.getCardId());
    setCardData(cardDetails);
    return () => {
      UserUtils.removeCardId();
    };
  }, []);

  return (
    <>
      <div className="traveller-pagev2">
        <div className="traveller-row">
          <SubHeader title="Payment Method" />
          <div className='inner-menu'>
            {/* <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Payment method</a> */}
          </div>
          <div id="content-wrapper" className="container">
            <Box className="inner-box">
              <UpdateCheckoutForm cardData={cardData} />
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

const enhance = compose(
  withRouter,
);

export default enhance(UpdateCard);
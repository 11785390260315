import React, { useEffect ,  useState } from 'react';
import { defaultDataIdFromObject, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import * as commonFunctions from '../../utilities/commonFunctions';
import UserUtils from '../../utilities/UserUtils';
import DownloadLink from '../../components/layout/download-link';
import PageLoader from '../../components/layout/ui/page-loader';
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';

const TRAVELLER_CANCELLED_TRIPS = loader('../../graphql/schema/traveller/traveler-cancelledTrips.graphql');

const TravellerCancelledTrips = (props) => {

    const { history } = props;

    const [cancelledTripData, setCancelledTripData] = useState('');
    const [size] = useState(8);
    const [page, setPage] = useState(1);
    const [hasError, setHasError] = useState('');
    const [pageCount, setPageCount] = useState(0);

    const { loading, error } = useQuery(TRAVELLER_CANCELLED_TRIPS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            first: size,
            page: page
        },
        onCompleted({ GetTravelerCancelledTrips }) {
            if (GetTravelerCancelledTrips && GetTravelerCancelledTrips.data) {
                setCancelledTripData(GetTravelerCancelledTrips.data);
                setPageCount(GetTravelerCancelledTrips.paginatorInfo.lastPage);
            }
        }
    });

    const handlePage = (pageNo) => {
        let selected = pageNo.selected + 1;
        setPage(selected);
        scrollToTop();
    }

    const scrollToTop = () => {
        if ($("#destinationsTab").offset() !== undefined) {
            $('html, body').animate({
                scrollTop: $("#destinationsTab").offset().top
            }, 1000)
        }
    }

    const ScheduleTrip = (data) => {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').removeClass();
        UserUtils.setDestinationData(JSON.stringify(data));
        history.push('/show-walker-on-map');
    }

    return (
        <>  
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page">
                <div className="traveller-row">
                    <SubHeader title="Traveler Cancelled Trips" />
                    {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
                    <div className="container">
                        <div className="destination-body">
                            {cancelledTripData.length === 0 && <p className="no-data">No Data To Display</p>}

                            {cancelledTripData.length > 0 && cancelledTripData.map((trip) => {
                                console.log(trip);
                                return (
                                    <div className="destination-box" key={trip.id}>
                                        <div className="box-left">
                                            <figure>
                                                <img className="select-dest-img" alt="" src={trip.destination.image} />
                                            </figure>
                                        </div>
                                        <div className="box-center">
                                            <h4>{trip.destination.name }<small className='ml-2'>({trip.schedule_minute} min)</small></h4>
                                            <p><i className="fa fa-clock-o mr-2"></i>{moment(`${trip.schedule_date} ${trip.schedule_time}`).tz(trip.time_zone).format('LLLL')}</p>
                                        </div>
                                        {/* <div className="box-right">
                                            <i className="fa fa-angle-right" data-toggle="modal" data-target={`#modal-${trip.id}`}></i>
                                        </div> */}
                                    </div>
                                )
                            })}
                            {pageCount > 1
                                ? <div className="search-pagination">
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-double-left"></i>}
                                            nextLabel={<i className="fa fa-angle-double-right"></i>}
                                            breakLabel={<a href="#/">...</a>}
                                            pageCount={pageCount}
                                            onPageChange={handlePage}
                                            breakClassName={"break-me"}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link" />
                                    </ul>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

TravellerCancelledTrips.propTypes = {
    history: PropTypes.object.isRequired,
  };
  
  const enhance = compose(
    withRouter,
  );

export default enhance(TravellerCancelledTrips);

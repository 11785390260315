import { useState, useEffect } from 'react';
import { useApolloClient, useMutation} from '@apollo/react-hooks';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, DialogActions } from '@material-ui/core';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import userUtils from '../utilities/UserUtils';
import { loader } from 'graphql.macro';

const VERIFY_VIDEO_CALL_AMBASSADOR = loader('../graphql/schema/traveller/verify-video-call-ambassador.graphql');
const VERIFY_VIDEO_CALL = loader('../graphql/schema/video-call/verify-videocall-traveller-vonage.graphql');

const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 150,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 20
    }
}))

const NotificationHandler = (props) => {

	const { history } = props;
    const classes = useStyles();
	const apolloClient = useApolloClient();

    const [showModal, setShowModal] = useState(false);
    const [notification, setNotification] = useState([]);
    const [notificationPayload, setNotificationPayload] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [trip, setTrip] = useState(null);

    const [verifyVideoCallAmbassador] = useMutation(VERIFY_VIDEO_CALL_AMBASSADOR, {
        onCompleted({
            verifyVideoCallAmbassador: {
                status,
                message,
                session_id,
                token_id,
                booking_id,
                walker_id,
                is_re_join,
                time_zone,
                wss,
                tripe_minute,
                stream_id
            }
        }) {
            setLoading(false);
            if (status === 'SUCCESS') {
                history.push('/video-call-ambassador', {
                    roomID: session_id,
                    securityToken: token_id,
                    userType: 'traveller',
                    scheduleTripId: scheduled_trip_id,
                    bookingId: booking_id,
                    walkerId: walker_id,
                    isReJoin: is_re_join,
                    timeZone: time_zone,
                    wss: wss,
                    tripMinutes: tripe_minute,
                    streamId: stream_id
                });
            }
            if(status == 'FULL') {
                setShowModal(false);
                setMessage(message);
                setShowMessage(true);
            }
        }
    });

    const [verifyVideoCall] = useMutation(VERIFY_VIDEO_CALL, {
        onCompleted({
            verifyVideoCallTravellerVonage: {
                status,
                vonage_session_id,
                vonage_token_id,
            }
        }) {
            setLoading(false);
            if (status === 'SUCCESS') {
                history.push('/video-call', {
                    vonage_session_id: vonage_session_id,
                    vonage_token_id: vonage_token_id,
                    booking_id: notificationPayload.booking_id,
                    tripe_minute: notificationPayload.tripe_minute,
                    walker_id: notificationPayload.walker_id,
                });
            }
        }
    });

	useEffect(() => {
        self.navigator.serviceWorker.addEventListener('message', ({ data: payload }) => {
            console.log('Notification: ', payload);
            setShowModal(true);
            setNotification(payload.notification);
            setNotificationPayload(payload.data);
            checkNotification(payload.data);
        });
	}, []);

    const checkNotification = (payload) => {
        console.log('payload: ', payload);
        if (typeof history.location.state === 'undefined') {
            history.location.state = {}; 
        }
        if (payload.notification_type === 'booking_accepted') {
            history.location.state.isLoading = false;
            history.push(history);
        }
        if (payload.notification_type === 'walker_end_call_early') {
            history.location.state.isDisconnected = true;
            history.push(history);
        }
        if (payload.notification_type === 'ambassador_tour_auto_join') {
            setTimeout(() => {
                setLoading(true);
                history.push('/video-call-ambassador', {
                    roomID: payload.session_id,
                    securityToken: payload.token_id,
                    userType: 'traveller',
                    scheduleTripId: payload.scheduled_trip_id,
                    bookingId: payload.booking_id,
                    walkerId: payload.walker_id,
                    isReJoin: payload.is_re_join,
                    timeZone: payload.time_zone,
                    wss: payload.wss,
                    tripMinutes: payload.tripe_minute,
                    streamId: payload.stream_id
                });
            }, 1500);
        }
        if(payload.notification_type == 'walker_event_auto_join') {
            if(localStorage.getItem('notif') !== 'walker_event_auto_join') {
                localStorage.setItem('notif', 'walker_event_auto_join');

                setTimeout(() => {
                    history.push('/video-call-group', {
                        roomID: payload.session_id,
                        securityToken: payload.token_id,
                        userType: 'traveller',
                        scheduleTripId: payload.scheduled_trip_id,
                        bookingId: payload.booking_id,
                        walkerId: payload.walker_id,
                        isReJoin: payload.is_re_join,
                        timeZone: payload.time_zone,
                        wss: payload.wss,
                        tripMinutes: payload.tripe_minute,
                        streamId: payload.stream_id
                    });

                    localStorage.removeItem('notif');
                }, 2500);
            }
        }
    }
    
    const onClick = (params) => {
        if (notificationPayload.notification_type === 'booking_accepted' 
            || notificationPayload.notification_type === 'scheduled_booking_accepted'
            ) {
            setLoading(true);
            verifyVideoCall({
                variables: {
                    booking_id: notificationPayload.booking_id,
                }
            })
        }
        if (notificationPayload.notification_type === 'schedule_trip_started') {
            setLoading(true);
            verifyVideoCallAmbassador({
                variables: {
                    booking_id: notificationPayload.booking_id,
                    schedule_trip_id: notificationPayload.schedule_trip_id,
                }
            })
        }
        if (notificationPayload.notification_type === 'ambassador_tour_auto_join') {
            setLoading(true);
            verifyVideoCallAmbassador({
                variables: {
                    booking_id: notificationPayload.booking_id,
                    schedule_trip_id: notificationPayload.scheduled_trip_id,
                }
            })
        }
        if (notificationPayload.notification_type === 'walker_end_call_early') {
            setShowModal(false);
            notificationPayload.isDisconnected = true;
            notificationPayload.showWalkerRating = true;
            history.location.state = { ...notificationPayload, ...history.location.state };
            history.push(history);
        }
        if (notificationPayload.notification_type === 'schedule_trip_traveler') {
            setShowModal(false);
		    history.push('/your-trips', { upcoming_trip: true });
        }
        if (notificationPayload.notification_type === 'login_to_other_device') {
            apolloClient.writeData({ data: { isLoggedIn: false } });
            userUtils.removeAccessToken();
			apolloClient.resetStore();
            history.push(params === 'home' ? '/' : '/login');
			window.location.reload(true);
        }
    }

    const onClose = (type) => {
        if(type == 'rejected_modal') {
            props.callback('modal', false);
            setShowModal(false);
        }
    }

    const ActionButton = () => {
        if (notificationPayload.notification_type === 'booking_accepted') {
            return (
                <button className={`btn btn-yellow ${classes.button}`} onClick={() => onClick()} disabled={loading}>
                    {loading ? 'Connecting' : 'Begin Walk'}
                </button>
            );
        }
        if (notificationPayload.notification_type === 'schedule_trip_started' 
                || notificationPayload.notification_type === 'scheduled_booking_accepted'
            ) {
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className={`btn btn-yellow ${classes.button}`} onClick={() => onClick()} disabled={loading}>
                        {loading ? 'Connecting' : 'Begin Walk'}
                    </button>
                    <a href="javascript: return false;" className={`mt-2 text-small ${classes.button}`} onClick={() => setShowModal(false)}><small><u>Join Later</u></small></a>
                </div>
            );
        }

        if (notificationPayload.notification_type === 'ambassador_tour_auto_join') {
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className={`btn btn-yellow ${classes.button}`} disabled={true}>
                        Joining walk...
                    </button>
                </div>
            );
        }
        if (notificationPayload.notification_type === 'walker_event_auto_join' ) {
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className={`btn btn-yellow ${classes.button}`} onClick={() => onClick()} disabled={true}>
                       Joining...
                    </button>
                </div>
            );
        }
        if (notificationPayload.notification_type === 'walker_end_call_early') {
            return <button className={`btn btn-yellow ${classes.button}`} onClick={() => onClick()}>Walker Rating</button>;
        }
        if (notificationPayload.notification_type === 'schedule_trip_traveler') {
            return <button className={`btn btn-yellow ${classes.button}`} onClick={() => onClick()}>View Appointment</button>;
        }
        if (notificationPayload.notification_type === 'login_to_other_device') {
            return <div className="d-flex flex-column align-items-center">
                <button className={`btn btn-yellow ${classes.button}`} onClick={() => onClick()}>Login</button>
                <a href="javascript: return false;" className={`mt-2 text-small ${classes.button}`} onClick={() => onClick('home')}><small><u>Return To Home Page</u></small></a>
            </div>
        }
        if(notificationPayload.notification_type === 'booking_rejected') {
            return <button className={`btn btn-yellow ${classes.button}`} onClick={() => onClose('rejected_modal')}>I Understand</button>;
        }
        
        return <button className={`btn btn-yellow ${classes.button}`} onClick={() => setShowModal(false)}>I Understand</button>;
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') { // Check if the close reason is not backdropClick
            setShowModal(false);
        }
      }

    return (
        <>
            <Dialog
                id="notification-modal"
                open={showModal}
                disableEscapeKeyDown={true}
                onClose={(event, reason) => handleClose(event, reason)}
            >
                <div className="schedule-pick-row walker-map-popup" style={{ width: 100 }}>
                    {(
                        notificationPayload.notification_type === 'booking_accepted' ||
                        notificationPayload.notification_type === 'schedule_trip_traveler' ||
                        notificationPayload.notification_type === 'schedule_trip_cancelled' ||
                        notificationPayload.notification_type === 'schedule_trip_started'
                    ) &&
                        <DialogTitle>
                            <IconButton
                                aria-label="close"
                                style={{ position: 'absolute', right: 8, top: 8 }}
                                onClick={() => setShowModal(false)}
                                >
                                <i className="la la-times"></i>
                            </IconButton>
                        </DialogTitle>
                    }
                    <DialogContent className={classes.content}>
                        <h6 style={{ marginBottom: 20, textAlign: 'center' }}>{notification.title}</h6>
                        <p style={{ textAlign: 'center' }}>{notification.body}</p>
                        {notificationPayload.notification_type === 'booking_rejected' &&
                            <p style={{ fontWeight: 'bold' }}>"{notificationPayload.reject_reason}"</p>
                        }
                        {notificationPayload.notification_type === 'walker_end_call_early' &&
                            <p style={{ fontWeight: 'bold' }}>{notificationPayload.end_call_reason}</p>
                        }
                        <div className="text-center mt-4">
                            <ActionButton />
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
            <Dialog open={showMessage} maxWidth="xs">
                <div className="schedule-pick-row walker-map-popup">
                    <DialogContent>
                        <div className="walker-close">
                            <i className="la la-times" data-toggle="modal" data-target="#myModal" onClick={() => {
                                setShowMessage(false);
                                setMessage('');
                            }}></i>
                        </div>
                        <h4 className="package-title">{message}</h4>
                    </DialogContent>
                    <DialogActions>
                        <div className="text-center">
                            <button className="btn btn-yellow" onClick={() => {
                                setShowMessage(false);
                                setMessage('');
                            }}>Close</button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
}

NotificationHandler.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const enhance = compose(
	withRouter,
);

export default enhance(NotificationHandler);
import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ScrollIntoView from 'react-scroll-into-view';
import PropTypes from 'prop-types';
import { SwipeableDrawer, Box } from '@material-ui/core';
import { ArrowDropDown, ArrowDropDownOutlined } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Search from '../../screen/home/search';
import List from '@material-ui/core/List';
import Switch from 'react-switch';
import UserUtils from '../../utilities/UserUtils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as commonFunction from '../../utilities/commonFunctions';
import PageLoader from '../../components/layout/ui/page-loader';
// import Select from 'react-select';
import Select from '@material-ui/core/Select';
import { Dialog, Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import SvgIcon from '@material-ui/core/SvgIcon';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as ProfileIcon } from '../../resource/images/sidebar/profile-icon.svg';
import { ReactComponent as MoneyIcon } from '../../resource/images/sidebar/money-icon.svg';
import { ReactComponent as PayoutIcon } from '../../resource/images/sidebar/payout-icon.svg';
import { ReactComponent as CompleteTripsIcon } from '../../resource/images/sidebar/complete-trips-icon.svg';
import { ReactComponent as CardIcon } from '../../resource/images/sidebar/card-icon.svg';
import { ReactComponent as YouTripsIcon } from '../../resource/images/sidebar/yourtrips-icon.svg';
import { ReactComponent as WalkerIcon } from '../../resource/images/sidebar/walker-icon.svg';
import { ReactComponent as RatingIcon } from '../../resource/images/sidebar/rating-icon.svg';
import { ReactComponent as GiftCardIcon } from '../../resource/images/sidebar/gift-card-icon.svg';
import { ReactComponent as WhishlistIcon } from '../../resource/images/sidebar/whishlist-icon.svg';
import { ReactComponent as CompleteVerificationIcon } from '../../resource/images/sidebar/complete-verification-icon.svg';
import { ReactComponent as InfoIcon } from '../../resource/images/sidebar/info-icon.svg';
import { ReactComponent as SettingsIcon } from '../../resource/images/sidebar/settings-icon.svg';
import { ReactComponent as SignoutIcon } from '../../resource/images/sidebar/signout-icon.svg';


const EMAIL_STRIPE_CONNECT = loader('../../graphql/schema/traveller/send-email-stripe-connect.graphql');
const LOGOUT = loader('../../graphql/schema/auth/logout.graphql');
const MY_DETAILS = loader('../../graphql/schema/auth/me.graphql');
const SWITCH_USER_TYPE = loader('../../graphql/schema/auth/switch-user-type.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';
const disableWalker = process.env.REACT_APP_DISABLE_WALKER === 'true';
const wpWebApp = process.env.REACT_APP_WP_WEBAPP_URL;
const baseUrl = window.location.origin;
const imgattr = 'logo';
const UPCOMING_WALKS = loader('../../graphql/schema/traveller/traveller-upcomingWalks.graphql');

const Header = (props) => {

	const { history, counter } = props;

	const apolloClient = useApolloClient();

	const [drawer, setDrawer] = useState(false);
	const [addClass, setAddClass] = useState('');
	const [userType, setUserType] = useState('Traveler');
	const [roleClass, setRoleClass] = useState('');
	const [loading, setLoading] = useState(false);
	const [isAmbassador, setIsAmbassador] = useState(false);
	const [rating, setRating] = useState(0);
	const [switchToTravelerDialog, setSwitchToTravelerDialog] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [upcomingTrip, setUpcomingTrip] = useState(0);
	const [size] = useState(10);
	const [page, setPage] = useState(1);
	const [openMembershipMenu, setOpenMembershipMenu] = useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const pagesNoInnerClass = [
        '/login',
        '/verify-login',
        '/register',
        '/join-group-trip',
        '/group-trips',
		'/your-trips',
		'/destination-categories',
		'/select-destinations',
		'/select-station',
		'/manage-group-trips',
    ];

	const [getMyDetails, { data: myDetails }] = useLazyQuery(MY_DETAILS, {
		fetchPolicy: "no-cache",
		skip: !commonFunction.isLogin(),
		onCompleted(res) {
			setIsAmbassador(res.me.is_ambassador);
			setRoles(res.me.type);
		}
	});

	const roles = [
	{ value: 'Traveler', label: <span className='ambassador-label font-weight-bold'>
		<img src={publicUrl + "assets/images/side-panel/traveler_icon_nav.png"} alt={imgattr} style={{width:18}}/>
		&nbsp;&nbsp;&nbsp;Traveler Mode</span> },
	{ value: 'Walker', label: <span className='ambassador-label font-weight-bold'>
		<img src={publicUrl + "assets/images/side-panel/walker_icon_nav_dark.png"} alt={imgattr} style={{width:18}}/>
		&nbsp;&nbsp;&nbsp;Walker Mode</span> },
	{ value: 'Ambassador', label: <span className='ambassador-label font-weight-bold'>
		<img src={publicUrl + "assets/images/side-panel/announcement.png"} alt={imgattr} style={{width:18}}/>
		&nbsp;&nbsp;&nbsp;Ambassador Mode</span> }
	]

	const rolesV2 = [
		{ value: 'Traveler', label: 'Traveler Mode' },
		{ value: 'Walker', label: 'Walker Mode' },
		{ value: 'Ambassador', label: 'Ambassador Mode' }
	];

	const setRoles = (type) => {
		if (type == 'W') {
			setUserType('Walker');
			setRoleClass('');
			setRating(myDetails.me.over_all_rating);
		} else if (type == 'T') {
			setUserType('Traveler');
			setRoleClass('profile-row-two');
		} else {
			setUserType('Ambassador');
			setRoleClass('profile-row-three')
			setRating(myDetails.me.ambassador_total_rating);
		}
		UserUtils.setUserType(type);
	};
	  

	const [sendEmailStripeConnect] = useMutation(EMAIL_STRIPE_CONNECT);

	const [userTypeSwitch, {}] = useMutation(SWITCH_USER_TYPE, {
		onCompleted(res) {
			const type = res.switchUserType.type;
			apolloClient.writeData({ data: { isWalkerTraveller: type === 'W' } });
			setRoles(type);
			if (type === 'W' || type === 'A') {
				const pageName = props.location.pathname;
				if (pageName === '/your-trips') {
					window.location.reload(true);
				} else {
					history.replace('/your-trips');
				}
			} else {
				history.replace('/destination-categories');
			}
		},
	});


	const [logout] = useMutation(LOGOUT, {
		onCompleted(res) {
			setDrawer(false);
			UserUtils.setClosedAds([]);
			UserUtils.removeAccessToken();
			apolloClient.resetStore();
			// history.push('/');
			window.location.href = '/login';
		},
	})

	useEffect(() => {
		if (commonFunction.isLogin() && UserUtils.getUserType()) {
			getUpcomingTrips();
			getMyDetails();
		}

		setSelectedIndex(UserUtils.getPageIndex());
	}, [])

	useEffect(() => {
		if (myDetails) {
			if (myDetails.me.type == 'A') {
				setRating(myDetails.me.ambassador_total_rating);
			} else {
				setRating(myDetails.me.over_all_rating);
			}
			apolloClient.writeData({ data: { isWalkerTraveller: myDetails.me.type === 'W' } });
		}
	}, [myDetails])

	useEffect(() => {	
		const pageName = props.location.pathname;
		if (!pagesNoInnerClass.includes(pageName) && 
			!pageName.includes('view-group-trip') &&
			!pageName.includes('join-group-trip') &&
			!pageName.includes('event-registration') &&
			!pageName.includes('add-card') && 
			!pageName.includes('payment-methods')
		) {
			setAddClass('inner-page');
		}
		handleScroll();
		window.addEventListener('scroll', (event) => handleScroll());
		
	}, [addClass]);

	const [getUpcomingTrips, { data: myTrips }] = useLazyQuery(UPCOMING_WALKS, {
		variables: { "pageNumdata": page, "first": size },
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "no-cache",
		onCompleted({ getTravelerUpcomingTrips }) {
		  if (getTravelerUpcomingTrips && getTravelerUpcomingTrips.data) {
			setUpcomingTrip(getTravelerUpcomingTrips.data.length);
		  }
		}
	  });

	const handleScroll = () => {
		const pageName = window.location.pathname;
		if (document.getElementById('navigation-header')) {
			if (window.pageYOffset < 5 && pageName === '/') {
				document.getElementById('navigation-header').classList.remove('inner-page');
			} else if (window.pageYOffset < 5 && pageName !== '/') {
				document.getElementById('navigation-header').classList.remove('py-3');
			} else {
				if (!pagesNoInnerClass.includes(pageName) && 
					!pageName.includes('view-group-trip') &&
					!pageName.includes('join-group-trip') &&
					!pageName.includes('event-registration') &&
					!pageName.includes('add-card') && 
					!pageName.includes('payment-methods')
				) {
				    document.getElementById('navigation-header').classList.add('inner-page');
                }
				document.getElementById('navigation-header').classList.add('py-3');
			}
		}
	}

	const handleRoleSelect = (event) => {
		fetch('https://api.stripe.com/v1/country_specs/' + UserUtils.getUserPhoneCountryKey(), {
			method: 'get',
			headers: new Headers({
				'Authorization': 'Bearer ' + process.env.REACT_APP_STRIPE_KEY,
			})
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (disableWalker && result && !result.error) {
						handleWalkerSwitch();
					} else if (result && !result.error) {
						var type = 'Traveler';
						if (typeof event == "object") {
							type = event.target.value;
						} else {
							if (event) {
								type = 'Walker';
							}
						}
						userTypeSwitch({
							variables: {
								data: type
							}
						});
						console.log('type ==> ', type);
						setUserType(type);
					} else {
						sendEmail();
					}
				},
				(error) => {
					console.log('--error', error);
				}
			)
	}

	const toggleDrawer = () => {
		setDrawer(!drawer)
	};

	const closeDrawer = () => {
		console.log('closeDrawer');
		setDrawer(false)
	}

	const handleSignOut = () => {
		apolloClient.writeData({ data: { isLoggedIn: false } });
		logout({
			variables: {
				data: {
					online: false,
					device_token: ""
				}
			},
		});
		
	};
	const handleSettings = () => {
		history.push('/settings');
	};

	const handleEditAccount = () => {
		history.push('/edit-account');
	};

	const handleWalkerProfile = () => {
		history.push('/walker-profile');
	};

	const handleAmbassadorProfile = () => {
		history.push('/ambassador-profile');
	};

	const handleMakeMoneyWalking = () => {
		history.push('/make-money-for-walker');
	};

	const handleCompleteVerification = () => {
		history.push('/complete-verification-ambassador');
	};

	const handlePayoutAccount = () => {
		history.push('/payout-account');
	};

	const handleCompletedTrips = () => {
		history.push('/completed-trips');
	};

	const handleManageGroupTrips = () => {
		history.push('/manage-group-trips');
	};

	const handlePaymentMethods = () => {
		history.push('/payment-methods');
	};

	const handleWalkerKits = () => {
		history.push('/walker-kit');
	};
	
	const handleRatings = () => {
		history.push('/ratings');
	};

	const handleManageMembership = () => {
		history.push('/membership');
	};

	const handleDestinationWishlist = () => {
		history.push('/destination-wishlist');
	};

	const handleYourTrips = () => {
		history.push('/your-trips');
	};

	const handleSendGiftCard = () => {
		history.push('/send-gift-card');
	};

	const handleHelp = () => {
		if (userType == 'Walker' || userType == 'Ambassador') {
			history.push('/walker-help');
		} else {
			history.push('/help');
		}
	};

	const handleEarnings = () => {
		history.push('/earnings')
	}

	const homeHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#home").offset().top - 100
				}, 0);
			}, 500);
		}
	}

	const aboutHuvrHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#about-huvr").offset().top - 100
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const blogHandler = (e) => {
		e.preventDefault();
		const pageName = props.location.pathname;
		if (pageName !== '/') {
			$('#loader-overlay').css({ "visibility": "visible" });
			history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".blog-area").offset().top - 160
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	};

	const handleLoggedInUserDestination = (e) => {
		e.preventDefault();
		if (commonFunction.isLogin() && userType == 'Traveler') {
			history.push('/destination-categories');
		} else {
			const pageName = props.location.pathname;
			if (pageName !== '/') {
				$('#loader-overlay').css({ "visibility": "visible" });
				history.push('/');
				setTimeout(function () {
					$('html, body').animate({
						scrollTop: $(".destination-carousel").offset().top - 160
					}, 0);
				}, 500);
				setTimeout(function () {
					$('#loader-overlay').css({ "visibility": "hidden" });
				}, 2000);
			}
		}
	};

	const searchHandler = () => {
		const pageName = props.location.pathname;
		setShowSearch(true);
		if (pageName === '/') {
			$('html, body').animate({
				scrollTop: $(".tp-main-search").offset().top - 300
			}, 500);
		} else {
			$('#loader-overlay').css({ "visibility": "visible" });
			// history.push('/');
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".tp-main-search").offset().top - 200
				}, 0);
			}, 500);
			setTimeout(function () {
				$('#loader-overlay').css({ "visibility": "hidden" });
			}, 2000);
		}
	}

	const handleWalkerSwitch = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert-body text-center" style={{ borderRadius: 5 }}>
						<h1>Alert</h1>
						<p>The walker function is not available yet for this web app release.</p>
						<div className="react-confirm-alert-button-group justify-content-center">
							<button onClick={onClose} className="btn btn-yellow" style={{ backgroundColor: 'var(--main-color-one)', padding: '0 30px' }}>
								Okay
							</button>
						</div>
					</div>
				);
			},
			overlayClassName: 'header-overlay',
			afterClose: () => {
				// sendEmailStripeConnect(UserUtils.getUserID());
			}
		});
	}

	const sendEmail = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert-body text-center" style={{ borderRadius: 5 }}>
						<h1>Alert</h1>
						<p>Your country is not yet available to Stripe Connect.</p>
						<div className="react-confirm-alert-button-group justify-content-center">
							<button onClick={onClose} className="btn btn-yellow" style={{ backgroundColor: 'var(--main-color-one)', padding: '0 30px' }}>
								Okay
							</button>
						</div>
					</div>
				);
			},
			overlayClassName: 'header-overlay',
			afterClose: () => {
				sendEmailStripeConnect(UserUtils.getUserID());
			}
		});
	}

	const handleListItemClick = (callback, index) => {
		UserUtils.setPageIndex(index);
		setSelectedIndex(index);
		console.log('index ==> ', index);
		callback();
	};

	return (
		<>
			{loading && <PageLoader loading={loading} />}
			<nav id="navigation-header" className={`navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top ${addClass}`}>
				<div className="container nav-container">
					<div className="responsive-mobile-menu">
						<div className="mobile-logo">
							<Link to='/destination-categories'>
								<img src={publicUrl + "assets/images/huvr-nav-logo-white.png"} alt={imgattr} />
							</Link>
						</div>
						{/* <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#tp_main_menu" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggle-icon mobile-bar">
								<span>Menu <i className="la la-angle-down"></i></span>
							</span>
						</button> */}
						<div className="nav-right-content">
							<ul className="pl-0">
								{!commonFunction.isLogin() &&
								<li className="top-bar-btn-booking">
									<ScrollIntoView className="btn btn-yellow" selector="#destination-carousel">
										<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
									</ScrollIntoView>
								</li>
								}
								{commonFunction.isLogin() && userType == 'Traveler' &&
									<>
										<li className="top-bar-btn-booking">
											<a className="btn btn-yellow" onClick={handleLoggedInUserDestination}>
												<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
											</a>
										</li>
										<li className="profile-pic" onClick={toggleDrawer}>
											<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : `${publicUrl}assets/images/hvr-logo.png`} alt="profile" />
										</li>
									</>
								}
								{commonFunction.isLogin() && (userType == 'Walker' || userType == 'Ambassador') &&
									<>
										<li className="top-bar-btn-booking">
											<a className="btn btn-yellow" onClick={() => setSwitchToTravelerDialog(true)}>
												<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
											</a>
										</li>
										<li className="profile-pic" onClick={toggleDrawer}>
											<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : `${publicUrl}assets/images/hvr-logo.png`} alt="profile" />
										</li>
									</>
								}
								{/* <li className="search" onClick={searchHandler}>
									<a className="ti-search" />
								</li> */}
							</ul>
						</div>
					</div>
					<div className="collapse navbar-collapse" id="tp_main_menu">
						<div className="logo-wrapper desktop-logo">
							<Link to='/destination-categories' className="main-logo">
								<img src={publicUrl + "assets/images/huvr-nav-logo-white.png"} alt="logo" />
							</Link>
							<Link href={`${baseUrl}/destination-categories`}  className="sticky-logo">
								<img src={publicUrl + "assets/images/huvr-nav-logo-white.png"} alt="logo" />
							</Link>
						</div>
						<ul className="navbar-nav">
							{/* <li className="menu-item-has-children">
								<ScrollIntoView selector="#home" onClick={homeHandler}>Home</ScrollIntoView>
							</li>
							<li className="menu-item-has-children">
								<ScrollIntoView selector="#about-huvr" onClick={aboutHuvrHandler}>About Huvr</ScrollIntoView>
							</li>
							<li className="menu-item-has-children">
								<ScrollIntoView selector="#destination-carousel" onClick={handleLoggedInUserDestination}>Destinations</ScrollIntoView>
							</li>
							<li className="menu-item-has-children">
								<ScrollIntoView selector="#our-blog" onClick={blogHandler}>Our Blog</ScrollIntoView>
							</li> */}
							{!commonFunction.isLogin() &&
								<>
									<li className="menu-item-has-children desktop-hide">
										<ScrollIntoView selector="#our-blog">
											<Link to="/login">Login</Link>
										</ScrollIntoView>
									</li>
									<li className="menu-item-has-children desktop-hide">
										<ScrollIntoView selector="#our-blog">
											<Link to="/register">Sign Up</Link>
										</ScrollIntoView>
									</li>

								</>
							}
						</ul>
					</div>
					<div className="nav-right-content nav-right-content-new">
						<ul>
							{/* {commonFunction.isLogin() && userType == 'Traveler' &&
								<li className="mobile-hide">
									<a className="btn btn-yellow" onClick={handleLoggedInUserDestination}>
										<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
									</a>
								</li>
							}
							{commonFunction.isLogin() && (userType == 'Walker' || userType == 'Ambassador') &&
								<li className="mobile-hide">
									<a className="btn btn-yellow" onClick={() => setSwitchToTravelerDialog(true)}>
										<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
									</a>
								</li>
							} */}
							{!commonFunction.isLogin() &&
								<li className="mobile-hide">
									<ScrollIntoView className="btn btn-yellow" selector="#destination-carousel">
										<img src={publicUrl + "assets/images/huvr-btn2.png"} alt={imgattr} className="huvr-btn" />
									</ScrollIntoView>
								</li>
							}
							{commonFunction.isLogin() && 
							<>
							<li className="mobile-hide">
								<Link to="/your-trips">
									Upcoming Trips <img className='menu-iconv3' src="assets/images/inner-page/calendar-icon.png" alt="" />
									<div className='notif-bubble'><span>{ upcomingTrip }</span></div>
								</Link>
							</li>
							{/* <li className="mobile-hide">
								<Link to="/group-trips">
									Group Trips <img className='menu-iconv3' src="assets/images/inner-page/group-trip-icon.png" alt="" />
									<div className='notif-bubble'><span>0</span></div>
								</Link>
							</li> */}
							<li className="profile-pic mobile-hide" onClick={toggleDrawer}>
								<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : `${publicUrl}assets/images/hvr-logo.png`} alt="profile" />
							</li>
							</>
							}
							{!commonFunction.isLogin() &&
								(<React.Fragment>
									<li className="menu-item-has-children login-link">
										<Link to="/login">Login</Link>
									</li>
									<li className="menu-item-has-children mobile-hide">
										<Link to="/register">Sign Up</Link>
									</li>
								</React.Fragment>
								)}
							{commonFunction.isLogin() && <li className="menu" onClick={toggleDrawer}>
								{/* <i className="fa fa-bars" ></i> */}
								<img className='menu-iconv2' src={`${publicUrl}assets/images/menu-icon.png`} alt="" />
							</li>}
							{/* <li className="search mobile-hide" onClick={searchHandler}>
								<a className="ti-search" />
							</li> */}
						</ul>

						<SwipeableDrawer
							open={drawer}
							docked={false}
							className="app-drawer"
							onRequestChange={closeDrawer}
							onClose={closeDrawer}
							onOpen={toggleDrawer}
							anchor="right"
							style={{ opacity: .95 }}
						>
							<div className="swipebar-row">
								<p className="mobile-icon">
									{/* <i className="fa fa-user" onClick={closeDrawer}></i> */}
									<img className="sidebar-close-btn" src={publicUrl + "assets/images/sidebar/close-sidebar.png"} onClick={closeDrawer} />
								</p>
								{!commonFunction.isLogin() && <p className="login-text">Login/Signup to start your virtual journey</p>}
								<Box className="row" style={{ maxHeight: '100%', overflowY: 'scroll' }}>
									{commonFunction.isLogin() &&
										<>
											<Box className="col-md-12">
												<Box className="row align-items-center p-3 pt-3 mb-2">
													{/* <Box className="edit_icon" onClick={handleEditAccount}>
														<i className="la la-edit" ></i>
													</Box> */}
													<Box className="col-md-6">
														<Box className="profile-pic2">
															<img src={UserUtils.getUserAvatar() !== "null" ? UserUtils.getUserAvatar() : `${publicUrl}assets/images/hvr-logo.png`} alt="profile" />
														</Box>
													</Box>
													<Box className="col-md-6 profile-name2">
														<h6 style={{fontSize: 16}}>{`${UserUtils.getUserFirstName()} ${UserUtils.getUserLastName()}`}</h6>
														<Box>
														{userType == 'Walker' &&
															<Rating name="read-only" value={rating} readOnly precision={0.5} size="small"/>
														}
														{userType == 'Ambassador' &&
															<Rating name="read-only" value={rating} readOnly precision={0.5} size="small"/>
														}
														</Box>
													</Box>
												</Box>
											</Box>
											<Box className="col-md-12">
												<FormControl key="usertype-control" id="usertype-control" variant="outlined">
													<Select 	
														id="usertype-select"
														// options={rolesV2} 
														value={userType}
														onChange={value => handleRoleSelect(value)}
														MenuProps={{
															anchorOrigin: {
															  vertical: "bottom",
															  horizontal: "left"
															},
															getContentAnchorEl: null
														}}
													>
														{rolesV2.map(item => {
															return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
														})}
													</Select>
												</FormControl>
											</Box>
											<Box className="col-md-12">
												<List  component="nav" className="sidebar-menu2">
													{userType == 'Walker' && <>
														<ListItem button onClick={() => handleListItemClick(handleWalkerProfile, 0)} selected={selectedIndex == 0}>
															<div className="side-panel-icon">
																<ProfileIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Walker Profile" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleMakeMoneyWalking, 1)} selected={selectedIndex == 1}>
															<div className="side-panel-icon">
																<MoneyIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Make Money Walking" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handlePayoutAccount, 2)} selected={selectedIndex == 2}>
															<div className="side-panel-icon">
																<PayoutIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Payout Account" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleCompletedTrips, 3)} selected={selectedIndex == 3}>
															<div className="side-panel-icon">
																<CompleteTripsIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Completed Trips" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleManageGroupTrips, 20)} selected={selectedIndex == 20}>
															<div className="side-panel-icon">
																<CompleteTripsIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Manage Events" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handlePaymentMethods, 4)} selected={selectedIndex == 4}>
															<div className="side-panel-icon">
																<CardIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Payment Methods" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleEarnings, 5)} selected={selectedIndex == 5}>
															<div className="side-panel-icon">
																<YouTripsIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Earnings" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleWalkerKits, 6)} selected={selectedIndex == 6}>
															<div className="side-panel-icon">
																<WalkerIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Walker Kits" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleRatings, 7)} selected={selectedIndex == 7}>
															<div className="side-panel-icon">
																<RatingIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Ratings" />
														</ListItem>
													</>}
													{userType == 'Traveler' && <>
														<ListItem button onClick={() => handleListItemClick(handleManageMembership, 8)} selected={selectedIndex == 8}>
															<div className="side-panel-icon">
																<ProfileIcon />
															</div>
															<ListItemText className="sidebar-menu-text">
																Manage Membership <span className="d-block">(Coming Soon)</span>
															</ListItemText>
															{openMembershipMenu ? 
																<img src={publicUrl + "assets/images/sidebar/expand-less.svg"} onClick={() => setOpenMembershipMenu(!openMembershipMenu)}  /> : 
																<img src={publicUrl + "assets/images/sidebar/expand-more.svg"} onClick={() => setOpenMembershipMenu(!openMembershipMenu)} />}
														</ListItem>
														<Collapse in={openMembershipMenu} timeout="auto" unmountOnExit>
															<List component="div" disablePadding>
																<ListItem className="submenuListTwo" button>
																	<ListItemText className="sidebar-menu-text" primary="Join A Group Trip" />
																</ListItem>
																<ListItem className="submenuListTwo submenuList" button>
																	<ListItemText className="sidebar-menu-text" primary="Schedule Your Favorite Walker" />
																</ListItem>
															</List>
														</Collapse>
														<ListItem className="submenuList" button onClick={() => handleListItemClick(handleMakeMoneyWalking, 9)} selected={selectedIndex == 9}>
															<div className="side-panel-icon">
																<MoneyIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Make Money Walking" />
														</ListItem>
														<ListItem className="submenuList" button onClick={() => handleListItemClick(handlePaymentMethods, 10)} selected={selectedIndex == 10}>
															<div className="side-panel-icon">
																<CardIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Payment Methods" />
														</ListItem>
														<ListItem className="submenuList" button onClick={() => handleListItemClick(handleSendGiftCard, 11)} selected={selectedIndex == 11}>
															<div className="side-panel-icon">
																<GiftCardIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Send A Gift Card" />
														</ListItem>
														<ListItem className="submenuList" button onClick={() => handleListItemClick(handleDestinationWishlist, 12)} selected={selectedIndex == 12}>
															<div className="side-panel-icon">
																<WhishlistIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Destination Wish List" />
														</ListItem>
														<ListItem className="submenuList" button onClick={() => handleListItemClick(handleYourTrips, 13)} selected={selectedIndex == 13}>
															<div className="side-panel-icon">
																<YouTripsIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Your Trips" />
														</ListItem>
													</>}
													{userType == 'Ambassador' && <>
														<ListItem button onClick={() => handleListItemClick(handleAmbassadorProfile, 14)} selected={selectedIndex == 14}>
															<div className="side-panel-icon">
																<ProfileIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="My Ambassador Profile" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleCompleteVerification, 15)} selected={selectedIndex == 15}>
															<div className="side-panel-icon">
																<CompleteVerificationIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Complete Verification" />
														</ListItem>
														<ListItem button onClick={() => handleListItemClick(handleCompletedTrips, 16)} selected={selectedIndex == 16}>
															<div className="side-panel-icon">
																<CompleteTripsIcon />
															</div>
															<ListItemText className="sidebar-menu-text" primary="Completed Trips" />
														</ListItem>
													</>}
												</List>
												<Divider className="mt-3 mb-3" />
												<List 
													className="sidebar-menu2"
													subheader={
														<ListSubheader component="div" className="nested-list-subheader">
														  ACCOUNT MANAGEMENT
														</ListSubheader>
													  }>
													<ListItem className="submenuList" button onClick={() => handleListItemClick(handleHelp, 17)} selected={selectedIndex == 17}>
														<div className="side-panel-icon">
															<InfoIcon />
														</div>
														<ListItemText className="sidebar-menu-text" primary="Help" />
													</ListItem>
													<ListItem className="submenuList" button onClick={() => handleListItemClick(handleSettings, 18)} selected={selectedIndex == 18}>
														<div className="side-panel-icon">
															<SettingsIcon />
														</div>
														<ListItemText className="sidebar-menu-text" primary="Settings" />
													</ListItem>
													<ListItem className="submenuList" button onClick={() => handleListItemClick(handleSignOut, 19)} selected={selectedIndex == 19}>
														<div className="side-panel-icon">
															<SignoutIcon />
														</div>
														<ListItemText className="sidebar-menu-text" primary="Sign out" />
													</ListItem>
												</List>
											</Box>
										</>
									}
								</Box>
							</div>
							<Box className="sidebar-footer">
								<img src={publicUrl + 'assets/images/huvr-nav-logo-white.png'}/>
							</Box>
						</SwipeableDrawer>
					</div >
				</div>
			</nav>
			<Dialog className="popup-verification" aria-labelledby="simple-dialog-title" open={switchToTravelerDialog}>
				<div>
					<p>To book a trip, go to Traveler mode.</p>
					<br />
					<Button className="btn btn-yellow confirm-btn" onClick={() => setSwitchToTravelerDialog(false)}>OK</Button>
				</div>
			</Dialog>
			{showSearch && <Search history={history} style={{ position: 'fixed', top: '200px', left: 0, right: 0, zIndex: 999 }} />}
		</>
	)
};

Header.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const enhance = compose(
	withRouter,
);

export default enhance(Header);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserUtils from '../../utilities/UserUtils';
import { Dialog, Button } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import * as commonFunction from '../../utilities/commonFunctions';
import userUtils from '../../utilities/UserUtils';

const DESTINATIONS_DATA = loader('../../graphql/schema/destinationView/popular-destinations.graphql');

let publicUrl = process.env.PUBLIC_URL + '/';
const awsS3url = process.env.REACT_APP_AWS_S3_URL;

const DestinationCarousel = (props) => {

	const { history } = props;
	const [userIsNotRegistered, setUserIsNotRegistered] = useState(false);
	const [destinationsData, setDestinationsData] = useState([]);
	const [isAmbassadorOrWalker, setIsAmbassadorOrWalker] = useState(false);
	const [switchToTravelerDialog, setSwitchToTravelerDialog] = useState(false);

	const { data, loading, error } = useQuery(DESTINATIONS_DATA);

	useEffect(() => {
		if (data && data.popularDestination.length > 0) {
			setDestinationsData(data.popularDestination);
		}
		
	}, [data, loading, error])


	useEffect(() => {
		recallSlick();
	}, [destinationsData]);

	useEffect(() => {
		const autoPlay = setInterval(autoPlaySlider, 4000);
		return ()=> {
			clearInterval(autoPlay);
		};
	}, []);

	const autoPlaySlider = () => {
		$('.slick-next').trigger('click');
		const sliderIndex = check_number($('.d-list-slider-item.slick-active').data("num"));
		$(".destinations-slider-controls .slider-extra .text .first").text(sliderIndex);
	}

	const recallSlick = () => {
		var sliderLength = $('.d-list-slider-item').length;
		if (sliderLength != null && sliderLength > 0) {
			$('.tp-slider-extra').addClass('slider-extra');
			$('.destinations-list-slider').not('.slick-initialized').slick({
				slidesToShow: 3,
				dots: false,
				slidesToScroll: 1,
				speed: 400,
				loop: true,
				autoplay: false,
				prevArrow: '<span class="slick-prev"><i class="la la-long-arrow-left"></i></span>',
				nextArrow: '<span class="slick-next"><i class="la la-long-arrow-right"></i></span>',
				appendArrows: $('.destinations-slider-controls .slider-nav'),
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 576,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							centerPadding: "10px"
						}
					}
				]
			});
			//active progress
			var $progressBar = $('.d-list-progress');
			var $progressBarLabel = $('.slider__label');
			$('.destinations-list-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
				var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;
				$progressBar
					.css('background-size', calc + '% 100%')
					.attr('aria-valuenow', calc);
				$progressBarLabel.text(calc + '% completed');
			});
			var smSlider = $(".destinations-list-slider").slick("getSlick");
			var smSliderCount = smSlider.slideCount;
			$(".destinations-slider-controls .slider-extra .text .last").text(smSliderCount);
			var isWalkerOrAmbassador = userUtils.getUserType() == 'W' || userUtils.getUserType() == 'A';
			setIsAmbassadorOrWalker(isWalkerOrAmbassador);
		}
		return;
	}

	const check_number = (num) => {
		var IsInteger = /^[0-9]+$/.test(num);
		if (num < 10) {
			return IsInteger ? "0" + num : null;
		}
		return IsInteger ? num : null;
	}

	const sliderMap = () => {
		return destinationsData.map((d, index) => {
			return (
				<div className="d-list-slider-item" key={index} data-num={index + 1}>
					<div className="single-destinations-list text-center">
						<div className="destination-image">
							<img src={d.image} alt="list" />
						</div>
						<div className="details">
							<div className="title-div carousel-title-div">
								<h4 className="title">{d.name || 'N/A'}</h4>
							</div>
							<p className="content">{d.location || 'N/A'}</p>
							<ul className="tp-list-meta border-bt-dot"></ul>
							{!commonFunction.isLogin() || !isAmbassadorOrWalker ?
								<i className="btn btn-yellow" data-toggle="modal" data-target={`#modal-${index}`}>
									<img src={publicUrl + "assets/images/huvr-btn.png"} alt='logo' className="huvr-btn" />
								</i>
							:
								<i className="btn btn-yellow" onClick={() => setSwitchToTravelerDialog(true)}>
									<img src={publicUrl + "assets/images/huvr-btn.png"} alt='logo' className="huvr-btn" />
								</i>
							}
						</div>
					</div>
				</div>
			)
		})
	}

	const ScheduleTrip = (data) => {
		UserUtils.setDestinationData(JSON.stringify(data));
		$('.modal').modal('hide');
		$('body').removeClass('modal-open');
		$('.modal-backdrop').removeClass();
		const token = UserUtils.getAccessToken();
		if (token) {
			history.push('/show-walker-on-map');
		} else {
			setUserIsNotRegistered(true);
		}
	}

	const redirectToRegister = () => {
		history.push('/register');
	}

	const redirectToViewAllDestination = () => {
		history.push('/destination-categories');
	}

	return (
		<>
			{loading && <PageLoader loading={loading} />}
			<div id="destination-carousel" className="destination-carousel pd-top-70">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-lg-8">
							<div className="section-title text-center">
								<h2 className="title wow animated fadeInUp">Popular Destinations</h2>
								<p>
									<span className="text-block wow animated fadeInUp">Forget canned virtual travel and pre-recorded videos.</span>
									<span className="text-block wow animated fadeInUp">Explore the world and experience virtual teleportation in real-time.</span>
									<span className="text-block wow animated fadeInUp">You are in the drivers seat and if you seek, you shall find plenty with Huvr.</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="destinations-list-slider-bg wow animated fadeInUp">
					<div className="container">
						<div className="row">
							<div className="col-xl-9 col-lg-10 offset-xl-1 order-lg-12">
								{!commonFunction.isLogin() || !isAmbassadorOrWalker ?
									<span onClick={redirectToViewAllDestination} className="destinations-view-all font-weight-bold">
										View All Destinations <i className="la la-arrow-right" />
									</span>
								:
									<span onClick={() => setSwitchToTravelerDialog(true)} className="destinations-view-all font-weight-bold">
										View All Destinations <i className="la la-arrow-right" />
									</span>
								}
								<div className="destinations-list-slider">
									{sliderMap()}
								</div>
							</div>
							<div className="col-lg-2 align-self-center order-lg-11">
								<div className="container">
									<div className="destinations-slider-controls">
										<div className="slider-nav tp-control-nav" />
										<div className="tp-slider-extra">
											<div className="text">
												<span className="first">01</span>
												<span className="last" />
											</div>
											<div className="d-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
												<span className="slider__label sr-only" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{destinationsData.map((d, index) => {
				let banner = d.image ? d.image : publicUrl + 'assets/images/destination-list/1.png';
				if (d.banner) {
					banner = d.banner;
				}
				return (
					<div key={index} className="modal fade destination-body-popup" id={`modal-${index}`}>
						<div>
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content payment-popup-body">
									<div className="popup-scroll">
										<button type="button" className="close" data-dismiss="modal"><i className="la la-times"></i></button>
										<figure className="destination-img">
											<img
												className="select-dest-img-modal"
												alt={d.name}
												src={banner}
											/>
										</figure>
										<div className="payment-popup-cnt">
											<h4>{d.name}</h4>
											<em>{d.location}</em>
											<p>{d.description}</p>
										</div>
									</div>
									<div className="payment-popup-btn">
										<button className="btn btn-yellow" onClick={() => ScheduleTrip(d)}>Huvr there Now!</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			})}
			<Dialog className="popup-verification" onClose={() => setUserIsNotRegistered(false)} aria-labelledby="simple-dialog-title" open={userIsNotRegistered}>
				<div>
					<p>To explore with Huvr, you must login or register first.</p>
					<br />
					<Button className="btn btn-yellow confirm-btn" onClick={redirectToRegister}>OK</Button>
				</div>
			</Dialog>
			<Dialog className="popup-verification" aria-labelledby="simple-dialog-title" open={switchToTravelerDialog}>
				<div>
					<p>To book a trip, go to Traveler mode.</p>
					<br />
					<Button className="btn btn-yellow confirm-btn" onClick={() => setSwitchToTravelerDialog(false)}>OK</Button>
				</div>
			</Dialog>
		</>
	)
}

DestinationCarousel.propTypes = {
	history: PropTypes.object.isRequired,
};

export default DestinationCarousel;
import React,{ useState,useEffect} from 'react';
import { useMutation,useApolloClient} from '@apollo/react-hooks';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import PageLoader from '../../layout/ui/page-loader';
import UserUtils from '../../../utilities/UserUtils';
import * as commonFunctions from '../../../utilities/commonFunctions';
import DownloadLink from '../../../components/layout/download-link';
import { error } from 'jquery';

const UPDATE_NEW_PASSWROD= loader('./../../../graphql/schema/auth/update-forgot-password.graphql');
const publicUrl = process.env.PUBLIC_URL + '/';

const UpdateForgotPassword=(props)=>{
const {history}=props;
const apolloClient = useApolloClient();
const [hasError, setHasError] = useState(null);
const [passCheck, setPassCheck] = useState( false );
const [phoneOtp, setPhoneOtp] = useState('');
const [successMessage,setSuccessMessage] = useState('');
const [phoneNumber, setPhoneNumber] = useState("");


useEffect(() => {
 const phone_number = UserUtils.getPhoneNumber();
 
 if(phone_number !== null){
  setPhoneNumber(phone_number);
 }else{
  history.push('/forgot-password');
 }
 return () => {
   }
}, [])

const handleChangeOtp = (otp) => {
 setPhoneOtp(otp);
}


const postUpdateForgotPassword = (resUser) => {
 console.log(resUser,'------- post user')
 apolloClient.writeData({ data: { ispostUpdateForgotPassword: true } });
 let error = {};
  if (resUser.status === "PASSWORD_UPDATED") {
      setSuccessMessage(resUser.message);
      setTimeout(() => {
        history.push('/login');
      }, 1500);
    }
    else if (resUser.status === "PASSWORD_UPDATED_FAIL") {
      error['PASSWORD_UPDATED_FAIL'] = resUser.message;
      setHasError(error);
    }else if (resUser.status === "WRONG_OTP") {
    error['WRONG_OTP'] = resUser.message;
      setHasError(error);
    }
 }
 const [submitUpdateForgotPassword, { loading: UpdateForgotPasswordLoading }] = useMutation(UPDATE_NEW_PASSWROD, {
    onCompleted({
      updateForgotPassword:{
        status,
        message
    },
    }) {
      let error = {};

      if( status === "PASSWORD_UPDATED"  ) {
        postUpdateForgotPassword({ status, message });
      } else if( status === "WRONG_OTP") {
        error['WRONG_OTP'] = message;
        setHasError(error);
      }
     console.log('error', error);
    },
    onError(errors) {
      
      // const formatedErrors = errors.graphQLErrors.map(({ message }) => (
      //   <span key={`signup-error-${Math.random()}`}>{message}</span>
      // ));

      console.log(errors);
      // setHasError(formatedErrors);
    },
  });
 const validate = ( values ) => {
    const errors = {};

    if ( !values.phone_Otp) {
      errors.phone_Otp = 'OTP is required.';
    } 
    if ( !values.new_password ) {
      errors.new_password = 'New password is required.';
    }

    if ( !values.confirm_new_password ) {
      
      errors.confirm_new_password = 'Confirm new password is required.';
    } else if ( values.confirm_new_password !== values.new_password ) {
      errors.confirm_new_password = 'Password and confirm password should be same.';
    }
   
    setHasError(errors);
    return errors;
  };

 const onSubmitUpdateForgotPassword=(values)=>{
  console.log(values,'check values');

  if(validateForm(values)) {
    submitUpdateForgotPassword({
      variables: {
        otp: values.phone_Otp,
        phone:phoneNumber,
        password: values.new_password,
        password_confirmation :values.confirm_new_password, 
      },
    });
  }
};

  const validateField = (value) => {
    let hasError = false;
    if (value == undefined || commonFunctions.isEmpty(value)) {
      hasError = true;
    }
    return hasError;
  }

  const validateForm = (values) => {
    let formIsValid = true;
    let errors = {};

    if (validateField(values.phone_Otp)) {
      errors['phoneOtp'] = 'OTP is required.';
      formIsValid = false;
    }

    console.log('commonFunctions.isValidPassword(values.new_password) ==> ',commonFunctions.isValidPassword(values.new_password));
    if (validateField(values.new_password)) {
      errors['confirmPassword'] = 'Confirm Password is required.';
      formIsValid = false;
    } 
    
    if(!commonFunctions.isValidPassword(values.new_password)) {
      errors['confirmPassword'] = 'Invalid password format.';
      formIsValid = false;
    }

    if (!validateField(values.new_password) && !validateField(values.confirm_new_password) && values.new_password !== values.confirm_new_password) {
      errors['confirmPassword'] = 'Password and confirm password should be the same.';
      formIsValid = false;
    }

    setHasError(errors);
    return formIsValid;
  }  
return(
 <>
    <div className="signup-container-row2">
    {UpdateForgotPasswordLoading && <PageLoader loading={UpdateForgotPasswordLoading} />}
    <div className="signup-row">
      <div className="signup-left2">
        <div className="signup-cnt">
          <h4 component="h4">Forgot Password</h4>
          <p>Huvr opens you to the world to virtual exploration in travel, news, healthcare, education, sports, events, real estate, and more—all through the power of the gig economy and licensed brands.</p>
        </div>
      </div>
      <div className="signup-right2">
        <div className="signup-form">
          <div className="form-title">
            <h4 component="h4">Welcome Back</h4>
            <p>Regain access to your account by resetting your password for a seamless and personalized experience.</p>
          </div>
          <Form
            onSubmit={onSubmitUpdateForgotPassword}
            // validate={ validate }
            render={ ( {handleSubmit,submitting } ) => (
              <>
              <form
                onSubmit={ handleSubmit}
                noValidate
              >
                <div className="error-box">
                  {hasError && Object.values(hasError) && <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />}
                  {hasError && Object.values(hasError).map(error => {
                    return <>
                      <p className="is-invalid">{error}</p>
                    </>
                  })}
                </div>
                <div className="form-input-box2">
                  <Field
                    id="phone_Otp"
                    placeholder="Enter OTP"
                    type="password"
                    name="phone_Otp"
                    margin="normal"
                    value={phoneOtp}
                    onChange={handleChangeOtp}
                    fullWidth
                    component = {TextField}
                    variant="outlined"
                    className="vl-input-field"
                  />
                </div>
                <div className="form-input-box2">
                  <Field
                    id="new_password"
                    placeholder="New Password"
                    type="password"
                    name="new_password"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    component={ TextField }
                    onFocus={() => { setPassCheck( true ); }}
                    onBlur={()=> { setPassCheck( false ); }}
                    on
                    className="vl-input-field"
                  />
                  {passCheck === true && (
                  <div className="password-tips">
                  <ul>
                      <li>
                        Include at least 8 characters
                      </li>
                      <li>
                        Include upper and lower case characters
                      </li>
                      <li>
                        Include at least 1 number
                      </li>
                      <li>
                        Passwords match
                      </li>
                    </ul>
                  </div>
                )}
                </div>
                <div className="form-input-box2">
                    <Field
                      id="confirm_new_password"
                      placeholder="Confirm New Password"
                      type="password"
                      name="confirm_new_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      // onFocus={() => { setPassCheck( true ); } }
                      // onBlur={() => { setPassCheck( false ); } }
                      className="vl-input-field"
                />
                </div>
              <div className="form-btn">
                <input type="submit" disabled={submitting} value="Reset password" />
              </div>
            </form>
            </>
            )}
          />
        </div>  
      </div>
        </div>
      </div>
    </>
  );
};
               
UpdateForgotPassword.propTypes = {
 history: PropTypes.object.isRequired,
};

const enhance = compose(
 withRouter,
);

export default enhance(UpdateForgotPassword);

import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import SubHeader from '../../components/layout/sub-header';

const SAVE_DESTINATION = loader('../../graphql/schema/destinationView/save-destination.graphql');

const CreateDestinationWishList = (props) => {

  const { history } = props;

  const [country, setCountry] = useState('');
  const [hasError, setHasError] = useState('');
  const [errorMes, serErrorMes] = useState('');

  const [saveDestination, { loading }] = useMutation(SAVE_DESTINATION, {
    onCompleted() {
      history.push('/destination-wishlist');
    }
  });

  const validate = values => {
    const errors = {};

    if (!values.destination) {
      errors.destination = 'Please Enter Destination';
    }

    if (country === '') {
      serErrorMes('Please Select Country');
    } else {
      serErrorMes('');
    }

    if (!values.city) {
      errors.city = 'Please Enter City';
    }

    return errors;

  };

  const selectCountry = (val) => {
    setCountry(val);
  }

  const onSubmit = (values) => {
    saveDestination({
      variables: {
        data: {
          destination: values.destination,
          city: values.city,
          country: country,
        },
      },
    });
  };

  const handleCancel = () => {
    history.goBack();
  }

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Create New Destination Wish List" />
          {hasError && <p className="is-invalid authentication-error">{hasError}</p>}
          <div className="container">
            <div className="travelle-body">
              <p className="destination-para">Submit your favorite destinations want to Huvr to, and we're all start working on getting walkers available in those places.</p>
              <div className="edit-form-row">
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  render={({ handleSubmit, submitting }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className="form-in">
                        <span>Destination</span>
                        <Field
                          fullWidth
                          name="destination"
                          placeholder="Enter Destination"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>City</span>
                        <Field
                          fullWidth
                          name="city"
                          placeholder="Enter City"
                          component={TextField}
                        />
                      </div>
                      <div className="form-in">
                        <span>Country</span>
                        <Field name="country" component={TextField}>
                          {({ input }) => (
                            <div>
                              <CountryDropdown
                                {...input}
                                value={country}
                                onChange={val => selectCountry(val)}
                              />
                              {errorMes && <p style={Styles.error}>{errorMes}</p>}
                            </div>
                          )}
                        </Field>
                      </div>

                      <div className="form-btn">
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={submitting}
                        >
                          Submit
                        </Button>
                      </div>
                      <div>
                        <button
                          className="btn btn-default w-100"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Styles = {
  error: {
    color: '#f44336',
    margin: '0',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
  }
}

CreateDestinationWishList.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(CreateDestinationWishList);

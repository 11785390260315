import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { Button, Paper } from '@material-ui/core';
import { TextField } from 'final-form-material-ui';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactFlagsSelect from 'react-flags-select';
import DownloadLink from '../../components/layout/download-link';
import PageLoader from '../../components/layout/ui/page-loader';
import SubHeader from '../../components/layout/sub-header';

const CREATE_CHECKER_INVITATION = loader('../../graphql/schema/background-check/create-checkr-invitation.graphql');
const SAVE_WALKER_BACKGROUND_CHECK = loader('../../graphql/schema/background-check/save-walker-background-check-other-country.graphql');

const BackGroundCheckWalker = (props) => {

  const { history } = props;
  const [hasError, setHasError] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const [createCheckrInvitation, { loading: checkInvitation }] = useMutation(CREATE_CHECKER_INVITATION, {
    onCompleted(res) {
      window.open(res.createCheckrInvitation.invitation_url, '_blank');
    },
  });

  const [updateMyAccount, { loading }] = useMutation(SAVE_WALKER_BACKGROUND_CHECK, {
    onCompleted() {
      history.push('/make-money-for-walker');
    }
  });

  const handleBeginBackgroundCheck = () => {
    createCheckrInvitation();
  };

  const handleBeginNonBackgroundCheck = () => {
    history.push('/begin-background-check-walker');
  };

  const validate = (values) => {

    const errors = {};

    if (!values.first_name) {
      errors.first_name = 'First Name is required.';
    }

    if (!values.middle_name) {
      errors.middle_name = 'Middle Name is required.';
    }

    if (!values.last_name) {
      errors.last_name = 'Last Name is required.';
    }

    if (!values.street_address) {
      errors.street_address = 'Street address is required.';
    }

    if (!values.state) {
      errors.state = 'State is required.';
    }

    if (!countryCode) {
      errors.country = 'Country is required.';
    }

    if (!values.zipcode) {
      errors.zipcode = 'Zipcode is required.';
    }

    if (!values.identification_type) {
      errors.identification_type = 'Identification type is required.';
    }

    if (!values.identification_number) {
      errors.identification_number = 'Identification number is required.';
    }

    return errors;
  };

  const onSubmitAcountDetails = (values) => {
    updateMyAccount({
      variables: {
        data: {
          first_name: values.first_name,
          middle_name: values.middle_name,
          last_name: values.last_name,
          street_address: values.street_address,
          state: values.state,
          country: countryCode,
          zipcode: values.zipcode,
          identification_type: values.identification_type,
          identification_number: values.identification_number,
        },
      },
    });
  };

  return (
    <>
      {(loading || checkInvitation) && <PageLoader loading={loading || checkInvitation} />}
      <div className="traveller-page">
        <div className="traveller-row">
          <SubHeader title="Background Check" />
          <div className="container">
            <div className="travelle-body">
              <Paper className="p-4" elevation={0} style={{ backgroundColor: '#f2f2f2' }}>
                <div className="text-center">
                  <h3 className="background-check-title mt-4">Please select the option below for your country of residence.</h3>
                  <hr className="my-5" />
                  <h4>I am from the USA</h4>
                  <div className="form-btn form-btn-small mb-4 text-center mt-4">
                    <Button onClick={handleBeginBackgroundCheck}>Begin Background Check</Button>
                  </div>
                </div>
                <hr className="my-5" />
                <div className="text-center">
                  <h4>Background Check for Non-US Citizens</h4>
                  <div className="form-btn form-btn-small mb-4 text-center mt-4">
                    <Button onClick={handleBeginNonBackgroundCheck}>Begin Background Check</Button>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
BackGroundCheckWalker.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(BackGroundCheckWalker);

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import HeaderV2 from './headerV2';
import Footer from './footer';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import NotificationHandler from '../../utilities/notification';
import FooterV2 from './footerv2';
import * as commonFunction from '../../utilities/commonFunctions';

const Layout = (props) => {
  let { component: Component, history } = props;
  const [showModal, setShowModal] = useState(false);
  const [counter, setCounter] = useState(0);
  const [urlPath, setUrlPath] = useState('');

  useEffect(() => {
    setUrlPath(props.location.pathname);
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unListen();
    }
  }, []);

  let showHideNav = true;
  if (urlPath === '/login' ||
    urlPath === '/register' ||
    urlPath === '/register-profile-details' ||
    urlPath === '/verify-otp' ||
    urlPath === '/verify-login' ||
    urlPath === '/forgot-password' || 
    urlPath === '/update-forgot-password' ||
    (urlPath == '/destination-categories' && !commonFunction.isLogin()) ||
    urlPath == '/add-card' ||
    urlPath == '/manage-group-trips' ||
    urlPath.includes('view-group-trip')
  ) {
    showHideNav = false;
  }

  const callback = (type, value) => {
    if(type == 'modal') {
      setShowModal(value);
    }
  }

  const updateHeader = () => {
    setCounter(counter + 1);
  }

  return (
    <React.Fragment>
      <NotificationHandler {...props}/>
      <Header counter={counter} {...props}/>
      <Component showModal={showModal} callback={callback} updateHeader={updateHeader}/>
      {![
          '/login',
          '/register',
          '/register-profile-details',
          '/verify-otp',
          '/verify-login',
          '/forgot-password',
          '/update-forgot-password'
        ].includes(urlPath) && (
        <FooterV2 {...props}/>
      )}
    </React.Fragment>
  )
}

Layout.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

const enhance = compose( withRouter );

export default enhance( Layout );

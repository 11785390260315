
import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import 'date-fns';
import { Grid, Switch, Slider, Button, Dialog, DialogContent, colors } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import enGB from 'date-fns/locale/en-GB';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import UserUtils from '../../utilities/UserUtils';
import PageLoader from '../../components/layout/ui/page-loader';
import DownloadLink from '../../components/layout/download-link';
import { makeStyles } from '@material-ui/styles';
import WatchLaterIcon from '@material-ui/icons/WatchLater';



const GET_TRIP_RATE = loader('../../graphql/schema/destinationView/get-trip-rate.graphql');
const GET_SAVE_SCHEDULE_TRIP = loader('../../graphql/schema/walker/save-schedule-trip.graphql');

const timezoneKey = process.env.REACT_APP_GOOGLE_TIMEZONE_KEY;

const abbrs = {
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
};

moment.fn.zoneName = function () {
  const abbr = this.zoneAbbr();
  return abbrs[abbr] || abbr;
};

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '-30px',
  },
  iconButton: {
    '& .MuiSvgIcon-root': {
      color: '#049dc4', // Change this to your desired color
    },
  },
}));

const ScheduleTrip = (props) => {

  const { history, onCloseScheduleTrip } = props;

  const [destinationName, setDestinationName] = useState('');
  const [destinationId, setDestinationId] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [destinationTimeZone, setDestinationTimeZone] = useState(null);
  const [maxDate, setMaxDate] = useState('');
  const [tripRate, setTripRate] = useState([]);
  const [tripRateActive, setTripRateActive] = useState(0);
  const [customTripValue, setCustomTripValue] = useState(10);
  const [customTrip, setCustomTrip] = useState(false);
  const [hourMode, setHourMode] = useState('12');
  const [successScheduleTrip, setSuccessScheduleTrip] = useState(false);
  const [showOtherScheduleTrip, setShowOtherScheduleTrip] = useState(false);
  const [otherScheduleTrip, setOtherScheduleTrip] = useState(false);
  const classes = useStyles();


  const { data, loading } = useQuery(GET_TRIP_RATE, {
    variables: { "data": destinationId },
    fetchPolicy: 'network-only',
  });




  const [saveScheduleTrip, { loading: scheduleTripLoading }] = useMutation(GET_SAVE_SCHEDULE_TRIP, {
    onCompleted({
      saveScheduleTrip: {
        status,
        message,
      }
    }) {
      console.log(status, message);
      if (status === 'SUCCESS') {
        setSuccessScheduleTrip(true);
        document.getElementById('success-schedule-trip').scrollTo(0, 0);
      }
      else if (status === 'OTHER_SCHEDULE_TRIP') {
        setShowOtherScheduleTrip(true);
        setOtherScheduleTrip(message);
      }
    }
  });

  useEffect(() => {
    const { id, latitude, longitude, location } = JSON.parse(UserUtils.getDestinationData());
    if (id !== null && id !== undefined) {
      setDestinationId(id);
      setDestinationName(location);
      googleTimezone(latitude, longitude);
      setMaxDate(moment().add(7, 'day'));
    } else {
      history.push('/destination-categories');
    }
    if (data) {
      setTripRate(data.getTripRate);
    }
  }, [data]);

  const googleTimezone = (latitude, longitude) => {
    console.log(selectedDate);
    console.log(selectedTime);
    const params = new URLSearchParams({
      location: `${latitude},${longitude}`,
      timestamp: moment(selectedDate).hours(moment(selectedTime).hours()).minutes(moment(selectedTime).minutes()).format('X'),
      key: timezoneKey
    }).toString();
    fetch(`https://maps.googleapis.com/maps/api/timezone/json?${params}`)
      .then(res => res.json())
      .then(({ timeZoneId }) => {
        console.log(timeZoneId)
        setDestinationTimeZone(timeZoneId);
      },
        (error) => {
          console.log(error);
        });
  }

  const seconds_with_leading_zeros = () => {
    return /\((.*)\)/.exec(new Date().toString())[1];
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = async (time) => {
    setSelectedTime(time);
  }

  const handleCustomTrip = (event, value) => {
    setCustomTripValue(value);
  };

  const selectTripRate = (trip_rate, index) => {
    setCustomTripValue(trip_rate.minutes);
    setCustomTrip(false);
    setTripRateActive(index + 1);
  }

  const handleChangeCustomTrip = () => {
    setTripRateActive(0);
    setCustomTrip(!customTrip);
  }

  const requestScheduleTrip = () => {
    saveScheduleTrip({
      variables: {
        data: {
          hour_format: 24,
          schedule_time: moment(selectedTime).format('HH:mm'),
          schedule_date: moment(selectedDate).format('YYYY-MM-DD'),
          time_zone: moment.tz.guess(),
          epoch: moment(selectedDate).hours(moment(selectedTime).hours()).minutes(moment(selectedTime).minutes()).format('X'),
          schedule_price: customTripValue + 5,
          schedule_minute: customTripValue,
          destination_id: destinationId,
        }
      }
    })
  }

  return (
    <>
      {loading || scheduleTripLoading && <PageLoader loading={loading || scheduleTripLoading} />}
      {!successScheduleTrip ? (
        <div style={{ width: '90%', height: '100%' }}>
          <div className="modal show fade destination-body-popup" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.3)' }}>
            <div style={{ height: '95%', width: "100%", padding: 20 }}>
              <div className="modal-dialog modal-dialog-centered h-100" style={{ bordeRadius: 20 }}>
                <div className="modal-content payment-popup-body h-100" style={{ maxWidth: 600, borderRadius: 20 }}>
                  <button type="button" className="close" onClick={onCloseScheduleTrip}><i className="la la-times"></i></button>
                  <div style={Styles.container}>
                    <br />
                    <br />
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ padding: 20 }}>
                      <h3 style={{ ...Styles.textBlue }} className="text-s40 text-w400">SCHEDULE</h3>
                      <p style={{ ...Styles.textBlue, paddingBottom: 10 }} className="text-s18">a virtual trip to</p>
                      <center> <h4 style={Styles.textBlue} className="text-s20">{destinationName}</h4></center>
                    </div>
                    <div className="schedule-pick-row">
                      <hr />
                      <p style={{ fontSize: 15, fontWeight: 400, color: "#049DC5" }}>Pick Date (up to 7 days in the future)</p>
                      <div className="schedule-trip-page trip-block"  >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around" style={{ width: "100%" }}>
                            <KeyboardDatePicker
                              clearable
                              minDate={moment() || undefined}
                              maxDate={maxDate || undefined}
                              margin="normal"
                              id="date-picker-dialog"
                              label=""
                              format="MM/dd/yyyy"
                              value={selectedDate}
                              onChange={handleDateChange}
                              style={{ width: "100%" }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                                className: classes.iconButton,
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      {/* <div className="schedule-pick-cnt">
                        <h4>{format(new Date(selectedDate), 'iiii')}</h4>
                        <p>{format(new Date(selectedDate), 'LLLL dd, yyyy')}</p>
                      </div> */}
                    </div>
                    <div className="schedule-pick-row">
                      <p style={{ fontSize: 15, fontWeight: 400, color: "#049DC5" }}>Pick Time</p>
                      <div className="schedule-trip-page trip-block"  >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around" style={{ width: "100%", marginTop: -20 }}>
                            <KeyboardTimePicker
                              clearable
                              margin="normal"
                              id="time-picker"
                              label="Time picker"
                              value={selectedTime}
                              onChange={handleTimeChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                                className: classes.iconButton,
                              }}
                              keyboardIcon={<WatchLaterIcon />} // Use WatchLater icon
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="schedule-pick-time">
                        <p style={{ ...Styles.textSecondary, ...{ fontWeight: 400 } }} className="text-s24 text-w400">My Timezone <span>{moment().tz(moment.tz.guess()).format('zz')}</span></p>
                        <h4>{moment(selectedTime).format(hourMode === '12' ? 'hh:mm A' : 'HH:mm')}</h4>
                        <div className="d-flex align-items-center justify-content-center">
                          <div style={hourMode === '12' ? Styles.blue : {}}>12 Hour</div>
                          <PurpleSwitch
                            checked={hourMode !== '12'}
                            onChange={(e, val) => setHourMode(val ? '24' : '12')}
                          />
                          <div style={hourMode === '24' ? Styles.blue : {}}>24 Hour</div>
                        </div>

                        {destinationTimeZone &&
                          <>
                            <br />
                            <p style={{ ...Styles.textSecondary, ...{ fontWeight: 400 } }} className="text-s24 text-w400">DESTINATION TIMEZON</p>
                            <p style={{ fontSize: 20, fontWeight: 400, color: "#049DC5" }}>{moment(selectedTime).tz(destinationTimeZone).format('zz')}</p>
                            <h4>{moment(selectedTime).tz(destinationTimeZone).format(hourMode === '12' ? 'hh:mm A' : 'HH:mm')}</h4>
                          </>
                        }
                      </div>
                    </div>
                    <div className="schedule-pick-row">
                      <p style={{ fontSize: 15, fontWeight: 400, color: "#049DC5", marginBottom: 20 }}>Pick a Package</p>
                      {!customTrip &&
                        <>
                          {tripRate.map((d, index) => {
                            return (
                              <div key={`trip_rate_${index}`} className={`price-btn ${tripRateActive === (index + 1) ? 'selected-rate' : ''}`} onClick={() => selectTripRate(d, index)}>
                                {`${d.minutes} Minutes for $${d.price}`}
                              </div>
                            )
                          })}
                        </>
                      }

                      <div className={`price-btn price-btn-last ${customTrip ? 'custom-selected-rate' : ''}`} onClick={handleChangeCustomTrip}>Custom Length</div>
                      {customTrip &&
                        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                          <Grid item xs>
                            <Slider value={customTripValue} onChange={handleCustomTrip} aria-labelledby="continuous-slider" min={5} max={60} />
                            <p>{`${customTripValue} Minutes for $${parseFloat(customTripValue) + 5}`}</p>
                          </Grid>
                        </div>
                      }
                    </div>
                    <div className="schedule-pick-row" style={{ marginBottom: 80 }}>
                      <h4 className="package-title">Important</h4>
                      <div className="schedulePickRowList">
                        <p style={Styles.p}>1. Your scheduled trip will be in <span className="text-red">Pending</span> status <span className="text-red">Until</span> the request has been <span className="text-red">Accepted</span> by a walker.</p>
                        <p style={Styles.p}>2. You will be notified once your request has been accepted.</p>
                        <br />
                        <p style={Styles.p}>You can check the status of your scheduled trips in the Upcoming section of <a href='#' onClick={() => history.push('/your-trips', { upcoming_trip: true })}>Your Trips</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="payment-popup-btn" style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                    <div className="form-btn m-0 d-flex align-items-center justify-content-center">
                      <Button type="submit" style={{ maxWidth: 250 }}
                        onClick={requestScheduleTrip}>Request Scheduled Trip</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: '100%', height: '100%' }}>
          <div className="modal show fade destination-body-popup" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.3)' }}>
            <div style={{ height: '100%', width: '100%', padding: 20 }}>
              <div className="modal-dialog modal-dialog-centered h-100">
                <div className="modal-content payment-popup-body h-100" style={{ maxWidth: 600, borderRadius: 20 }}>
                  <button type="button" className="close" onClick={onCloseScheduleTrip}><i className="la la-times"></i></button>
                  <div id="success-schedule-trip" style={Styles.container}>
                    <div className="d-flex flex-column align-items-center justify-content-center" style={Styles.title}>
                      <h3 className="text-white">SCHEDULE</h3>
                      <p className="text-white">a virtual trip to</p>
                      <h4 className="text-white">{destinationName}</h4>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                      <h3>SUCCESS!</h3>
                      <p>Your scheduled trip has been requested on</p>
                    </div>
                    <div className="schedule-pick-row pt-0">
                      <div className="schedule-pick-cnt">
                        <h4 style={Styles.blue}>{format(new Date(selectedDate), 'iiii')}</h4>
                        <p style={Styles.blue}>{format(new Date(selectedDate), 'LLLL dd, yyyy')}</p>
                      </div>
                      <div className="schedule-pick-time">
                        <h2>{moment(selectedTime).format(hourMode === '12' ? 'hh:mm A' : 'HH:mm')}</h2>
                        <p>{moment().tz(moment.tz.guess()).format('zz')}</p>
                      </div>
                      <div className="schedule-pick-time">

                        {destinationTimeZone &&
                          <>
                            <h4 style={Styles.gray}>{moment(selectedTime).tz(destinationTimeZone).format(hourMode === '12' ? 'hh:mm A' : 'HH:mm')}</h4>
                            <p style={Styles.gray}>{moment(selectedTime).tz(destinationTimeZone).format('zz')}</p>
                          </>
                        }
                      </div>
                    </div>
                    <div className="schedule-pick-row">
                      <div className="schedule-pick-cnt">
                        <h5 className="package-title">Trip Package</h5>
                        <h5 className="package-title">{`${customTripValue} Minutes for $${customTripValue + 5}`}</h5>
                      </div>
                    </div>
                    <div className="schedule-pick-row" style={{ marginBottom: 80 }}>
                      <h4 className="package-title">Important</h4>
                      <div className="schedulePickRowList">
                        <p style={Styles.p}>1. Your scheduled trip will be in <span className="text-red">Pending</span> status <span className="text-red">Until</span> the request has been <span className="text-red">Accepted</span> by a walker.</p>
                        <p style={Styles.p}>2. You will be notified once your request has been accepted.</p>
                        <br />
                        <p style={Styles.p}>You can check the status of your scheduled trips in the Upcoming section of <a href='#' onClick={() => history.push('/your-trips', { upcoming_trip: true })}>Your Trips</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="payment-popup-btn">
                    <div className="form-btn m-0 d-flex align-items-center justify-content-center">
                      <Button type="submit" style={{ maxWidth: 200 }} onClick={onCloseScheduleTrip}>Done</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog aria-labelledby="customized-dialog-title" open={showOtherScheduleTrip}>
        <DialogContent>
          <div className="schedule-popup-div">
            <p>{otherScheduleTrip}.</p>
            <div className="select-popup-btn">
              <button className="btn btn-yellow" onClick={() => setShowOtherScheduleTrip(false)}>Okay</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const Styles = {
  container: {
    overflow: 'auto',
    backgroundColor: '#f2f2f2',
    borderRadius: 5,
    borderRadius: 20
  },
  title: {
    backgroundColor: '#071C55',
    borderRadius: '5px 5px 0px 0px',
    paddingBottom: 20,
    paddingTop: 20,
  },
  blue: {
    color: '#01B9B7',
  },
  gray: {
    color: '#777',
  },
  p: {
    hyphens: 'none',
  },
  textBlue: {
    color: "#113062"
  },
  textSecondary: {
    color: "#049DC5"
  },
  textSize40: {
    fontSize: '40px',
  },
};

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#01B9B7',
    '&$checked': {
      color: '#01B9B7',
    },
    '&$checked + $track': {
      backgroundColor: '#01B9B7',
    },
  },
  checked: {},
  track: {},
})(Switch);

ScheduleTrip.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(ScheduleTrip);

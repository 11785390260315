import React, { useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import PageLoader from '../../components/layout/ui/page-loader';
import * as commonFunctions from '../../utilities/commonFunctions';
import Rating from 'react-rating';
import  './style.css'
import DownloadLink from '../../components/layout/download-link';
import userUtils from '../../utilities/UserUtils';
import SubHeader from '../../components/layout/sub-header';

const RATING_DETAILS = loader('../../graphql/schema/ratings/ratings-details.graphql');

const publicUrl = process.env.PUBLIC_URL + '/';

const Ratings = (props) => {

    const { history, location } = props;

    const [ratingData, setRatingData] = useState('');
    const [haserror, setHaserror] = useState('');
    const { data, loading } = useQuery(RATING_DETAILS, {
        variables: {is_ambassador: userUtils.getUserType() == 'A'},
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (data && data.getWalkerRating.length > 0) {
            setRatingData(data.getWalkerRating);
        }
     }, [data])    

    return (
        <>
            {loading && <PageLoader loading={loading} />}
            <div className="traveller-page rating_group">
                <div className="traveller-row">
                    <SubHeader title="Rating Details" />
                    <div className="container">
                    {haserror && <p className="is-invalid authentication-error">{haserror}</p>}
                        <div className="destination-list-body">
                            <div  className="text-center rating-row">
                                <Typography>Number Of Walks</Typography>
                                <div>{ratingData.length || '0'}</div>
                            </div>
                            {!ratingData && <p>You have not yet received a rating come back to this page after completing more walks</p>}
                            {ratingData.length > 0 && ratingData.map((d, index) => {
                                return (
                                    <div key={index} className="m-2">
                                        <div className="border">
                                            <div className="d-flex ">
                                                <figure style={{ width: "100px", height: "200px" }}>
                                                    <img src={d.traveller === null ? publicUrl + "assets/images/inner-page/profile.jpg" : d.traveller.avatar} alt="" />
                                                </figure>
                                                <div>
                                                    <h5>{d.traveller === null ? 'N/A' : d.traveller.first_name}</h5>
                                                    <p>{d.comment || 'no comment'}</p>
                                                </div>
                                            </div>
                                            <div className="rating_icons">
                                                <Rating
                                                    emptySymbol="fa fa-star-o fa-2x"
                                                    fullSymbol="fa fa-star fa-2x "
                                                    initialRating={`${d.rating === null ? 0 : d.rating}`}
                                                    readonly
                                                />
                                                <span className="m-2">{d.rating || '0'}</span>Ratings
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

Ratings.propTypes = {
    history: PropTypes.object.isRequired,
};

const enhance = compose(
withRouter,
);

export default enhance(Ratings);
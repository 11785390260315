import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import compose from 'recompose/compose';
import PropTypes from "prop-types";
import $ from 'jquery';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { loader } from 'graphql.macro';
import UserUtils from '../../../utilities/UserUtils';
import PageLoader from '../../layout/ui/page-loader';

const publicUrl = process.env.PUBLIC_URL + '/';
const VERIFY_PHONE_NUMBER = loader('./../../../graphql/schema/auth/validate-phone-number.graphql');
const wpWebApp = process.env.REACT_APP_WP_WEBAPP_URL;

const SignupStepOne = (props) => {
  const { history } = props;

  const apolloClient = useApolloClient();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumerError, setPhoneNumberError] = useState("");
  const [termsConditions, setTermsConditions] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState({});
  const [mobileNumber, setMobileNumber] = useState(false);
  const [countryKey, setCountryKey] = useState("");

  const handlePhoneNumber = (number) => {
    if ((number !== undefined)) {
      $('#outlined-phone-number-input').prop('disabled', false);
      if (isValidPhoneNumber(number)) {
        setMobileNumber(false);
        setPhoneNumber(number);
        setPhoneNumberError("");
      } else {
        setPhoneNumberError('Please Enter valid Phone Number.');
      }
    }
  }

  const handleFocus = () => {
    if ($('#outlined-phone-number-input').val() === '') {
      $('#outlined-phone-number-input').prop('disabled', true);
      setPhoneNumberError('Please Select Country Calling Code.');
    }
  }

  const handleTermsAndCondition = (e) => {
    setTermsConditions(e.target.checked);
    let errors = {};
    setHasError(errors);
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (phoneNumber === "") {
      setPhoneNumberError('Please enter Phone Number.');
      formIsValid = false;
    }
    if (!termsConditions) {
      formIsValid = false;
      errors["termsConditions"] = "Please accept the terms & conditions.";
    }
    setHasError(errors);
    return formIsValid;
  }

  const postLogin = (resUser) => {
    apolloClient.writeData({ data: { isLoggedIn: false } });
    let error = {};
    UserUtils.setPhoneNumber(phoneNumber);
    UserUtils.setCountryKey(countryKey);
    if (resUser.status === "NEW_USER") {
      setSuccessMessage(resUser.message);
      setTimeout(() => {
        history.push('verify-otp');
      }, 1500)
    } else if (resUser.status === "OTP_FAIL") {
      error['otpError'] = resUser.message;
      setHasError(error);
    } else if (resUser.status === "USER_EXIST") {
      // REDIRECT TO LOGIN OTP PAGE IF USER EXIST
      setSuccessMessage('User Exist with this number, OTP sent Successfully. Please login');
      setTimeout(() => {
        history.push('/verify-login');
      }, 2500)
    }
  };

  const { loading } = useQuery(VERIFY_PHONE_NUMBER, {
    variables: {
      phone: phoneNumber,
      version: process.env.REACT_APP_WEB_VERSION,
      device: 'web',
      token: ''
    },
    fetchPolicy: 'network-only',
    skip: mobileNumber === false,
    onCompleted({
      verifyPhoneNumber: {
        status: status,
        message: message
      },
    }) {
      postLogin({ status, message });
    }
  });

  const submitPhoneNumber = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setMobileNumber(true);
    }
  }

  const handleCountryCode = (country) => {
    setCountryKey(country);
  }

  return (
    <div className="signup-container-row2">
      {loading && <PageLoader loading={loading} />}
      <div className="signup-row">
        <div className="signup-left2">
          <div className="signup-cnt">
            <h4 component="h4">Sign Up</h4>
            <p>Huvr is an app connecting virtual Travelers to human Huvr approved Walkers across the globe. They provide individual or group experiences unlike anything on the market today.</p>
          </div>
        </div>
        <div className="signup-right2">
          <div className="signup-form">
            <div className="form-title">
              <h4 component="h4">Welcome To Huvr</h4>
              <p>Create your account for a seamless, personalized experience.</p>
            </div>
            <form name="phoneVerfication" onSubmit={submitPhoneNumber}>
              {successMessage !== "" && <p className="success-msg">{successMessage}</p>}
              <div className="error-box">
                {hasError.otpError !== undefined && 
                  <>
                    <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />
                    <p className="is-invalid">{hasError.otpError}</p>
                  </>
                }
                {phoneNumerError !== "" && 
                  <>
                    <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />
                    <p className="is-invalid">{phoneNumerError}</p>
                  </>
                }
                {hasError.termsConditions !== undefined && 
                  <>
                    <img src={publicUrl + "assets/images/inner-page/error-icon.png"} />
                    <p className="is-invalid">{hasError.termsConditions}</p>
                  </>
                }
              </div>
              <div className="form-input-box2">
                {/* <span className="moble-number">+91</span> */}
                {/* <label variant="subtitle2" component="label">Phone Number</label> */}
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry=""
                  id="outlined-phone-number-input"
                  placeholder="Enter Phone Number"
                  className={`phone-input-data`}
                  type="text"
                  name="phoneNumber"
                  margin="normal"
                  variant="outlined"
                  onFocus={handleFocus}
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  onCountryChange={handleCountryCode}
                />
              </div>
              <div className="signup-two">
                <div className="signup-checkbox checkbox-full">
                  <input
                    type="checkbox"
                    name="termsandcondition"
                    checked={termsConditions}
                    onChange={handleTermsAndCondition}
                  />
                  <label>I agree to <a href={`${wpWebApp}/terms-privacy`} className="terms-Link" target="_blank">Terms & Conditions</a></label>
                </div>
              </div>
              <div className="form-btn">
                <input type="submit" color="inherit" value="Sign Up" />
              </div>

              <div className="form-signup-cnt">
                <p>Already have an account? <Link to="/login">Login</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

SignupStepOne.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance( SignupStepOne );

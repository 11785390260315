import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter, useLocation, useParams } from 'react-router-dom';
import * as commonFunction from '../../utilities/commonFunctions';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PageLoader from '../../components/layout/ui/page-loader';
import UserUtils from '../../utilities/UserUtils';
import DownloadLink from '../../components/layout/download-link';
import { Modal, Box, Button } from '@material-ui/core'; 
import SubHeader from '../../components/layout/sub-header';

const publicUrl = process.env.PUBLIC_URL + '/';
const GET_USER_CARDS = loader('../../graphql/schema/payments/get-user-cards.graphql');
const DELETE_USER_CARDS = loader('../../graphql/schema/payments/delete-card.graphql');

const PaymentMethods = (props) => {

  const { history } = props;
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const { data: userCardsData, loading: loading1 } = useQuery(GET_USER_CARDS, {
    fetchPolicy: 'network-only'
  });
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const trip = params.get('trip');

  const postResponseData = (res) => {
    if (res.status === 'CARD_DELETED') {
      setSuccessMessage(res.message);
    } else {
      setErrorMsg(res.message)
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMsg("")
    }, 3000)
  };

  const [deleteCartSubmit, { loading: loading }] = useMutation(DELETE_USER_CARDS, {
    refetchQueries: [{
      query: GET_USER_CARDS,
      fetchPolicy: 'network-only',
    }],
    onCompleted({
      deleteCard: {
        message: message,
        status: status,
      },
    }) {
      postResponseData({ status, message });
    },
    awaitRefetchQueries: true,
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`signup-error-${Math.random()}`}>{message}</span>
      ));
      setErrorMsg(formatedErrors);
      setTimeout(() => {
        setErrorMsg("")
      }, 3000)
    },
  });

  const handleClickDelete = () => {
    setShowModal(false);
    deleteCartSubmit({
      variables: {
        "data": selectedCard
      },
    });
  }

  const handleClose = () => { }

  const deleteCard = (card_id) => {
    setSelectedCard(card_id);
    setShowModal(true);
    // confirmAlert({
    //   closeOnEscape: true,
    //   title: 'Delete Card',
    //   message: 'Are you sure? You want to delete this Card.',
    //   buttons: [
    //     {
    //       label: 'Delete',
    //       onClick: () => handleClickDelete(card_id)
    //     },
    //     {
    //       label: 'Close',
    //       onClick: () => handleClose()
    //     }
    //   ],
    // });
  }

  const addCard = () => {
    if(trip) {
      history.push(`/add-card?trip=${trip}`);
    } else {
      history.push('/add-card');
    }
  }

  const editCard = (cardData) => {
    UserUtils.setCardId(JSON.stringify(cardData));
    history.push('/update-card');
  }

  return (
    <>
      <div className="traveller-pagev2">
      {(loading || loading1) && <PageLoader loading={true} />}
        <div className="traveller-row">
          <SubHeader title="Payment Method" />
          <div className='inner-menu'>
            {/* <a className='prev-nav'>Destination Categories</a> / <a className='current-nav'>Payment method</a> */}
          </div>
          <div id="content-wrapper" className="container">
            <Box className="inner-box">
                <p className="destination-para">This is where you store credit cards to be used for purchases.</p>
                {successMessage !== "" && <p className="success-msg">{`${successMessage}.`}</p>}
                {errorMsg !== "" && <div className="is-invalid">{errorMsg}</div>}

                {userCardsData && userCardsData.getUserCards.length > 0 &&
                  userCardsData.getUserCards[0].card_id != null &&
                  userCardsData.getUserCards.map((d, index) => {
                    let defaultCard = "";
                    if (d.default) {
                      defaultCard = 'default-data';
                    }
                    return (
                      <div key={index} className="edit-form-row2 mb-3">
                        <div className="add-card-row bg-white">
                          <div className="card-row-left">
                            <figure>
                              <img className="payment-card" src={publicUrl + "assets/images/inner-page/credit-card-solid.png"} alt={d.brand} />
                            </figure>
                            <span className="card-number">{`XXXX XXXX XXXX ${d.last4}`}</span>
                          </div>
                          <div className="card-row-right card-btns">
                            <span className={`defualt ${defaultCard}`}>Default</span>
                            <span className="card-menu" data-toggle="modal" data-target="#myModal1" onClick={() => editCard(d)}><i className="fa fa-edit"></i>&nbsp;</span>
                            <span className="card-menu" data-toggle="modal" data-target="#myModal1" onClick={() => deleteCard(d.card_id)}><i className="fa fa-trash"></i></span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                <br />
                <div className="edit-form-row2">
                  <div className="add-card-row bg-white">
                    <div className="card-row-left column-left" >
                      <figure>
                        <img className="payment-card" src={publicUrl + "assets/images/inner-page/credit-card-solid.png"} />
                      </figure>
                      <span className="card-number">Add New Credit Card</span>
                    </div>
                    <div className="card-row-right card-btns" onClick={addCard}>
                        <img className="payment-card" src={publicUrl + "assets/images/inner-page/arrow-right.png"} />
                    </div>
                  </div>
                </div>
            </Box>
          </div>
        </div>
      {/* popup start here */}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        className="popupv2"
      >
        <div className="modal-dialog modal-dialog-centered dialog-centered payment-confirmation">
          <div className="modal-content payment-popup-body">
            <div className="popup-scroll">
              <button type="button" className="close" onClick={() => setShowModal(false)}><i className="la la-times"></i></button>
              <img src={publicUrl + 'assets/images/inner-page/question-icon.png'} />
              <h3 style={{ textAlign: 'center'}}>Delete Card</h3>
              <p>Are you sure? You want to delete this Card.</p>
            </div>
            <Box style={{ textAlign: 'center', marginTop: 25 }}>
                <Button variant="outlined" className="outlined-btn" onClick={() => setShowModal(false)}>Cancel</Button>
                <Button variant="contained" className="contained-btn" onClick={() => handleClickDelete()}>Confirm</Button>
            </Box>
          </div>
        </div>
    </Modal>
      <div className="modal fade destination-body-popup" id="myModal">
        <div>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content payment-popup-body">
              <button type="button" className="close" data-dismiss="modal"><i className="la la-times"></i></button>
              <div className="delete-card-row">
                <p>Are you sure want to <span>Delete the card</span></p>
                <p><span className="edit-btn">yes</span><span className="delete-btn">No</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

PaymentMethods.propTypes = {
  history: PropTypes.object.isRequired,
}

const enhance = compose(
  withRouter,
);

export default enhance(PaymentMethods);
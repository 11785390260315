import React, { useEffect, useState, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, CircularProgress, DialogTitle, Divider, Typography, Button, OutlinedInput, InputAdornment, Box, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import DownloadLink from './download-link';
const publicUrl = process.env.PUBLIC_URL + '/';

const SubHeader = (props) => {
  const { history, location } = props;


  useEffect(() => {

  });



  return (
    <div className="traveller-head2">
        <div className="container">
            <div className="traveller-head-row">
            <h2 className="title2">{ props.title }</h2>
            </div>
            <div className="traveller-head-row row-footer">
            <div className="traveller-head-left"></div>
                <DownloadLink {...props} />
            </div>
        </div>
    </div>
  )
}

SubHeader.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(SubHeader)

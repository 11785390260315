import React, { useEffect, useState, useCallback, useRef } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { InputLabel, TextField, Switch, Button, Modal, InputAdornment, Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Form } from 'react-final-form';
import Papa from 'papaparse';
import PageLoader from '../layout/ui/page-loader';
import * as commonFunctions from '../../../src/utilities/commonFunctions';

const publicUrl = process.env.PUBLIC_URL + '/';
const GROUP_SCHEDULETRIP_EMAIL_INVITE = loader('../../graphql/schema/walker/group-scheduletrip-email-invite.graphql');

const initialRows = [{ id: 1, first_name: '', last_name: '', email: '' }];
const fieldMap = {
  'First name': 'first_name',
  'Last name': 'last_name',
  'Email': 'email'
};

const GroupScheduletripInviteModal = (props) => {
  const { history, location } = props;
  const [errors, setErrors] = useState({});
  const [importData, setImportData] = useState([]);
  const [rows, setRows] = useState(initialRows);
  const [rowCount, setRowCount] = useState(1);
  const [invalidFields, setInvalidFields] = useState({});
  const [currentFile, setCurrentFile] = useState(null);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const [groupScheduleTripInvite, { loading }] = useMutation(GROUP_SCHEDULETRIP_EMAIL_INVITE, {
    onCompleted({ response }) {
      setRows(initialRows);
      props.showAlert('Success', 'Invites sent!');
      props.refreshList();
      props.close();
    },
    onError(errors) {
      setRows(initialRows);
      props.close();
      props.showAlert('Error', 'An error occured while sending invites!');
      console.log(errors);
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 621);
    }

    handleResize();
  }, []);

  useEffect(() => {
    if (!props.show) {
      setRows(initialRows);
      setRowCount(1);
      clearForm();
    }
  }, [props.show]);

  const isEmptyField = (row) => {
    return !row.first_name || !row.last_name || !row.email;
  };

  const handleAddRow = (item) => {
    const newInvalidFields = { ...invalidFields }; // Create a copy of the current invalidFields state
    const hasEmptyFields = rows.some(row => isEmptyField(row));
    let hasError = false;

    if (hasEmptyFields) {
      // Loop through all fields in the last row and mark empty ones as invalid
      Object.keys(rows[rows.length - 1]).forEach((field, index) => {
        console.log(field, index);
        if (!rows[rows.length - 1][field]) {
          newInvalidFields[field] = true;
          setError('Fill in all fields.');
          hasError = true;
        }
      });

      setInvalidFields(newInvalidFields); // Update the invalidFields state with the newInvalidFields object
      return;
    }

    console.log('newInvalidFields', newInvalidFields);
    if (!hasError && !commonFunctions.validateEmail(item.email)) {
      newInvalidFields.email = true;
      setError('Invalid email address.');

      return;
    }

    setError(null);
    const newRow = { id: rowCount + 1, first_name: '', last_name: '', email: '' };
    setRows([...rows, newRow]);
    setRowCount(rowCount + 1);
  };

  const handleRemoveRow = (id) => {
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  };

  function renderTextFieldCell(params) {
    const { field, value } = params;
    const isInvalid = invalidFields[params.field]; // Check if the field is invalid

    return (
      <TextField
        variant="outlined"
        value={params.row[field]} // Bind value to the state value
        onChange={(e) => handleCellChange(e, params)}
        error={isInvalid} // Apply error style if the field is invalid
        style={{ borderColor: isInvalid ? '#FDA73D' : undefined }} // Set border color for invalid fields
      />
    );
  }

  function renderActionCell(params) {
    const isLastRow = (params.index !== undefined ? params.index : params.api.getRowIndex(params.id)) == rows.length - 1;

    return (
      <Button
        className="invite-action-btn"
        variant="contained"
        color="primary"
        onClick={() => isLastRow ? handleAddRow(params.row) : handleRemoveRow(params.row.id)}
        style={{ minWidth: '35px', width: '35px', display: 'flex', alignItems: 'center', backgroundColor: isLastRow ? '#FDA73D' : '#FF6666' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          {isLastRow ? <AddIcon style={{ color: 'white' }} /> : <RemoveIcon style={{ color: 'white' }} />}
        </div>
      </Button>
    );
  }

  const columns = [
    { field: 'first_name', headerName: 'First Name', flex: 1, sortable: false, renderCell: renderTextFieldCell },
    { field: 'last_name', headerName: 'Last Name', flex: 1, sortable: false, renderCell: renderTextFieldCell },
    { field: 'email', headerName: 'Email', flex: 1, sortable: false, renderCell: renderTextFieldCell },
    { field: '', headerName: '', flex: 0.5, sortable: false, renderCell: renderActionCell },
  ];

  const handleCellChange = (e, params) => {
    const { id } = params.row;
    const { field } = params;
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        // Update invalidFields state when changing cell value
        setInvalidFields(prevState => ({
          ...prevState,
          [field]: !e.target.value.trim(), // Set to true if the field is empty
        }));

        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setCurrentFile(file);
      // Use PapaParse to parse the CSV file
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: (result) => {
          if (result && result.data.length) {
            setImportData(result.data);
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        }
      });
    }
  };

  const handleInsertData = () => {
    let newRows = [];
    let currentRowCount = rowCount;
    let values = {};

    console.log(importData.length)
    if (importData.length) {
      console.log(importData);
      importData.forEach((value, index) => {

        const isInvalidEmail = !value.Email || value.Email.trim() === '';

        if (!isInvalidEmail) {
          values = {};
          currentRowCount++;
          values.id = currentRowCount;

          Object.keys(fieldMap).forEach(key => {
            values[fieldMap[key]] = value[key];
          });

          newRows.push(values);
        }

      });

      newRows.push(initialRows[0]);

      if (rows.length && isEmptyField(rows[0])) {
        setRows(newRows);
      } else {
        setRows([...rows, ...newRows]);
      }

      setRowCount(currentRowCount);
      setCurrentFile(null);
    }
  }

  const submit = () => {
    let data = [];
    let hasError = false;

    rows.forEach((item, index) => {
      if (rows.length > 1 && (rows.length - 1) == index) {
        return;
      }

      if (!isEmptyField(item) && commonFunctions.validateEmail(item.email)) {
        let row = item;
        row.id = undefined;

        data.push(row);
      } else {
        hasError = true;
      }
    });

    if (hasError) {
      setError('Fill in all fields and add make sure email address is valid.');
      return;
    }

    if (data.length) {
      groupScheduleTripInvite({
        variables: {
          schedule_trip_id: props.scheduleId,
          data: data
        }
      })
    }
  }

  const clearForm = () => {
    setErrors({});
  }

  const handleCsvDownload = () => {
    const a = document.createElement('a');
    a.href = '/assets/csv/group-scheduledtrip-invitations.csv';
    a.download = 'group-scheduledtrip-invitations.csv';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {loading && <PageLoader loading={loading} />}
      <Modal
        open={props.show}
        onClose={() => props.close()}
        className="popupv2"
      >
        <div className="modal-dialog modal-dialog-centered dialog-centered create-group-trip trip-invite">
          <div className="modal-content payment-popup-body" style={{ width: 800, maxWidth: '800 !important' }}>
            <p className="create-gt-title mb-2" style={{ color: '#113062', fontSize: 16, fontWeight: 500 }}>
              Email Invitation
              <Button
                className="trip-invite-import-btns send"
                style={{ background: '#FDA73D', float: 'right' }}
                onClick={() => handleCsvDownload()}>
                Download template
              </Button>
            </p>
            <div className="popup-scrollv2">
              <button type="button" className="close" onClick={() => props.close()}><i className="la la-times"></i></button>
              <Form
                onSubmit={() => submit()}
                render={({ handleSubmit, submitting }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="create-group-form"
                    noValidate
                  >
                    <Box className={`${!isMobile ? 'row' : ''}`}>
                      {error &&
                        <Box className="col-md-12 error-box mb-2">
                          <p className="is-invalid">{error}</p>
                        </Box>
                      }
                      <Box className="col-md-12 mb-3" style={{ height: 220, width: '100%', overflowY: 'scroll' }}>
                        {isMobile ?
                          <Box className="">
                            {rows.map((row, index) => {
                              return <Box className="invite-form-list">
                                <Box className="row invite-form no-bottom-border">
                                  <Box className="col-4 traveler-invite-label">FIRST NAME</Box>
                                  <Box className="col-8 traveler-invite-input">
                                    {renderTextFieldCell({ row, field: 'first_name' })}
                                  </Box>
                                </Box>
                                <Box className="row invite-form no-bottom-border">
                                  <Box className="col-4 traveler-invite-label">LAST NAME</Box>
                                  <Box className="col-8 traveler-invite-input">
                                    {renderTextFieldCell({ row, field: 'last_name' })}
                                  </Box>
                                </Box>
                                <Box className="row invite-form no-bottom-border">
                                  <Box className="col-4 traveler-invite-label">EMAIL</Box>
                                  <Box className="col-8 traveler-invite-input">
                                    {renderTextFieldCell({ row, field: 'email' })}
                                  </Box>
                                </Box>
                                <Box className="row invite-form">
                                  <Box className="col-12">
                                    {renderActionCell({ row, index: index })}
                                  </Box>
                                </Box>
                              </Box>
                            })}
                          </Box>
                          :
                          <DataGrid
                            className="invite-input-grid"
                            rows={rows}
                            columns={columns}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            autoHeight={false}
                            disableColumnMenu
                            hideFooterPagination={true}
                            showCellRightBorder
                            showColumnRightBorder
                            hideFooter />
                        }
                      </Box>
                      <Box className="col-md-12">
                        <input
                          type="file"
                          id="csvFileInput"
                          accept=".csv"
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                        />
                        <label htmlFor="csvFileInput" className="trip-invite-browse-label">{currentFile ? currentFile.name : 'Choose CSV file'}</label>
                        <Button
                          onClick={() => document.getElementById('csvFileInput').click()}
                          style={{ background: '#00A9CB' }}
                          className="trip-invite-import-btns browse">
                          Browse
                        </Button>
                        <Button
                          className="trip-invite-import-btns insert"
                          style={{ background: '#00CF28' }}
                          onClick={handleInsertData}>
                          Insert
                        </Button>
                        <Button
                          type="submit"
                          className="trip-invite-import-btns send"
                          style={{ background: '#FDA73D', float: 'right' }}>
                          Send
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

GroupScheduletripInviteModal.propTypes = {
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
);


export default enhance(GroupScheduletripInviteModal)